import React, { useState } from 'react'

import PageDefault from '../../components/pageDefault'
import FormDataContainer from '../../components/formDataContainer'
import FormTemplateNotificacao from './formTemplateNotificacao'
import GridTemplateNotificacao from './gridTemplateNotificacao'
import { TemplateProvider } from './context'
import './style.css'

function Pacientes() {
    const [dadosPacientesSelecionado, setDadosPacientesSelecionado] = useState({})
    const [atualizar, setAtualizar] = useState(false)
    const [visibleBlockingPanel, setVisibleBlockingPanel] = useState(true)
    const contexto = {
        estado: 'I',
        atualizarGrade: (atualizar) => {
            setAtualizar(atualizar)
        },
        precisaAtualizar: atualizar,
        data: dadosPacientesSelecionado,
        setData: (newData) => {
            setDadosPacientesSelecionado(newData)
        },
       blockingPanel: visibleBlockingPanel,
        setVisibleBlockingPanel: (data) => {
            setVisibleBlockingPanel(data)
        }               
    }
        
    return (
        
        <PageDefault>            
            <TemplateProvider value={ contexto }>           
                <FormDataContainer 
                    btnTitle="Novo template"
                    visibleBlocking={visibleBlockingPanel}
                    setVisibleBlocking={setVisibleBlockingPanel}
                    title={<>Templates de<br />Notificação</>}>              
                <FormTemplateNotificacao />
                </FormDataContainer>   
                <GridTemplateNotificacao />  
            </TemplateProvider>                       
        </PageDefault>
    )
}

export default Pacientes