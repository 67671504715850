import React, { useState, useEffect, useContext } from "react";
import DataList from "../../../components/dataList";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import SimpleButton from "../../../components/formComponents/simpleButton";
import {
  listarUsuarioAdm,
  excluirUsuarioAdm,
} from "../../../services/usuarios";
import { AdministradorContext } from "../context";
import Loading from "../../../components/loading";

import "./style.css";
import Navigator from "../../../components/navigator";

function GridAdministradores() {
  const { setData, precisaAtualizar, atualizarGrade } =
    useContext(AdministradorContext);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [originalListaUsuarios, setOriginalListaUsuarios] = useState([]);
  const [loadingVisible, setLoadingVisible] = useState(false);

  let toFilterText = "";
  useEffect(() => {
    /*listarUsuarioAdm().then((result) => {
      setListaUsuarios(result);
      setOriginalListaUsuarios(result);
    });*/
  }, []);

  function selecionaUsuario(id) {
    setLoadingVisible(true);
    setData(listaUsuarios.filter((item) => item.id == id)[0]);
    setLoadingVisible(false);
  }

  function deletarUsuario(id) {
    setLoadingVisible(true);
    excluirUsuarioAdm(id).then((result) => {
      setLoadingVisible(false);
      atualizarGrade(true);
    });
  }

  function filter(value) {
    if (String(value.id).includes(toFilterText)) return value;
    if (value.nome.includes(toFilterText)) return value;
    if (value.email.includes(toFilterText)) return value;
    if (value.clinica.includes(toFilterText)) return value;
    if (value.perfil.includes(toFilterText)) return value;
  }

  function filtrarUsuarios(filterText) {
    setListaUsuarios(originalListaUsuarios);
    if (!filterText) return;
    toFilterText = filterText;
    const filteredListaUsuarios = listaUsuarios.filter(filter);
    setListaUsuarios(filteredListaUsuarios);
  }

  useEffect(() => {
    listarUsuarioAdm().then((result) => {
      setListaUsuarios(result);
      setOriginalListaUsuarios(result);
      atualizarGrade(false);
    });
  }, [atualizarGrade, precisaAtualizar]);

  const columns = [
    {
      Header: "Nome",
      accessor: "nome",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Perfil",
      accessor: "perfil",
    },

    {
      Header: "",
      accessor: "edit",
      Cell: (row) => (
        <SimpleButton
          alt="Editar cadastro"
          title="Editar cadastro"
          onClick={() => {
            selecionaUsuario(row.row.original.id);
          }}
        >
          <FaEdit />
        </SimpleButton>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (row) => (
        <SimpleButton
          alt="Excluir cadastro"
          title="Excluir cadastro"
          buttonType="delete"
          onClick={() => {
            deletarUsuario(row.row.original.id);
          }}
        >
          <FaTrashAlt />
        </SimpleButton>
      ),
    },
  ];
  return (
    <>
      <Loading forced={true} visible={loadingVisible} />
      <div className="mainContent">
        <Navigator />
        <DataList
          searchfunction={filtrarUsuarios}
          columns={columns}
          data={listaUsuarios}
        ></DataList>
      </div>
    </>
  );
}

export default GridAdministradores;
