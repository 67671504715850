export const circleObject = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '3px',
    marginTop: '7px'
}

export const inlineContainer = {
    display: 'flex',
    flexDirection: 'row'
}