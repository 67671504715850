import React, { useState, useEffect, useRef } from 'react'
import { buscarAgendaPaciente, trocarStatusAgendamento } from '../../../services/agendamentos'
import { FaRegCalendarCheck, FaRegCalendarMinus } from 'react-icons/fa'
import SimpleButton from '../../../components/formComponents/simpleButton'
import DataList from '../../../components/dataList'
import Loading from '../../../components/loading'

import './style.css'

function Prontuario() {   
    const [agendaVigente, setAgendaVigente] = useState([])
    const [agendaPassada, setAgendaPassada] = useState([])
    const [loadingVisible, setLoadingVisible] = useState(false)

    const commonColumns = [
        {
            Header: "Id",
            accessor: "id",
          },
          {
            Header: "Médico",
            accessor: "medico",
          }, 
          {
            Header: "Data",
            accessor: "data_formatada"
          }, 
          {
            Header: "Hora",
            accessor: "hora_formatada"
          }
    ]
    const columnsVigente = [
        ...commonColumns,  
        {
          Header: "",
          accessor: "confirmado",
          Cell: row => (
            <div className="iconConfirma">
              <SimpleButton 
                title='Confirmação do agendamento'
                onClick={() => {trocaStatus(row.row.cells[0]?.value)}}>
                  {                  
                    row.row.cells[4]?.value ? <FaRegCalendarCheck /> : <FaRegCalendarMinus />
                  }
              </SimpleButton>
            </div>
            )
        }, 
      ]

      const columnsPassado = [
        ...commonColumns,  
        {
          Header: "",
          accessor: "confirmado",
          Cell: row => (
            <div className="iconConfirma">
              <SimpleButton enabled='false' title='Confirmação do agendamento'>
                  {                  
                    row.row.cells[4]?.value ? <FaRegCalendarCheck /> : <FaRegCalendarMinus />
                  }
              </SimpleButton>
            </div>
            )
        }, 
      ]
    
    function trocaStatus(id) {
    setLoadingVisible(true)
    trocarStatusAgendamento(id)
        .then((result) => {
            setLoadingVisible(false)
            carregarListas()
        }) 
    }

    function carregarListas() {
        let tempListaVigente = []
        let tempListaPassada = []
        buscarAgendaPaciente()
            .then((result) => {  
                result.map((item) => {                    
                    let data = new Date(item.data_agendamento);
                    const tempDate = new Date();
                    const hoje = new Date (tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate());

                    var dataFinal =new Date (data.getUTCFullYear(), data.getUTCMonth(), data.getUTCDate());
                    item.data_formatada = `${("0" + data.getUTCDate()).slice(-2)}/${("0" + data.getUTCMonth()).slice(-2)}/${data.getUTCFullYear()}`
                    item.hora_formatada = String(item.hora_agendamento).substring(0,5)
                    if (dataFinal >= hoje) {
                        tempListaVigente.push(item)
                    } else {
                        tempListaPassada.push(item)
                    }
                })
                setAgendaVigente(tempListaVigente)
                setAgendaPassada(tempListaPassada)
            })  
    }
    useEffect(() => {
        carregarListas()
    }, [])

    return (
        <>
            <Loading visible={ loadingVisible }/>      
            <div className='containerProntuarioPaciente'>                
                <div  id='report' className='contentProntuarioPaciente' >                  
                    <div className='comorbidadesContainer'>     
                    <h4 className='titleProntuario'>Agendamentos vigentes</h4>            

                        <div className="cardAgenda whiteCard">  
                        <DataList 
                            stylecontainer='contentAgendaPaciente'
                            filter={(<></>)}
                            columns={columnsVigente} 
                            data= {agendaVigente ? agendaVigente : []}></DataList>  
                        </div>
                    </div>
                    <div className='cardContainer'>
                        <h4 className='titleProntuario'>Agendamentos passados</h4>            
                        <div className="cardAgenda whiteCard">        
                        <DataList 
                            filter={(<></>)}
                            columns={columnsPassado} 
                            data= {agendaPassada ? agendaPassada : []}></DataList>    
                        </div>            
                    </div>  
                </div>
            </div>
        </>
    )
}

export default Prontuario