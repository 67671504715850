import React from 'react'

const RecepcionistaContext = React.createContext({ 
        estado: '',
        precisaAtualizar: false,
        atualizarGrade: () => {},
        data: {}, 
        blockingPanel: false,
        setVisibleBlockingPanel: () => {},
        setData: () => {} })

export const RecepcionistaProvider = RecepcionistaContext.Provider

export { RecepcionistaContext }
