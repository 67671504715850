import React, { useState, useEffect, useContext } from "react";
import DataList from "../../../components/dataList";
import { useLocation } from "react-router-dom";
import { FaEdit, FaTrashAlt, FaRegStickyNote, FaStar } from "react-icons/fa";
import { IoMdNotifications, IoMdNotificationsOutline } from "react-icons/io";

import SimpleButton from "../../../components/formComponents/simpleButton";
import SecondaryButton from "../../../components/formComponents/secondaryButton";
import Modal from "../../../components/modal";
import ModalAlert from "../../../components/modalAlert";

import {
  calcColorRiscoRenal,
  calcColorFramingham,
  calcColorPressaoAlta,
} from "../../../services/utils/colorsIndices";

import { PacienteContext } from "../context";
import {
  buscarPacientes,
  listarPacientes,
  deletarPacientes,
  pesquisarPacientes,
} from "../../../services/pacientes";
import { preencheAnotacao } from "../../../services/prontuario";

import Navigator from "../../../components/navigator";
import Loading from "../../../components/loading";
import Prontuario from "../../prontuario";

import "./style.css";
import PatientRecord from "../../../patientRecord/patientRecord";

function GridPacientes() {
  const calcColorRiscoCardioVascular = (value) =>
    ({
      1: "#0000FF",
      2: "#00FF00",
      3: "#FFF000",
      4: "#FF0000",
      5: "#980000",
    })[value.toString()] || "gray";

  const riscoCardioVascularOptions = [
    { label: "Não calculado", value: "-1" },
    { label: "Sem Risco", value: "1" },
    { label: "Baixo Risco", value: "2" },
    { label: "Risco Moderado", value: "3" },
    { label: "Alto Risco", value: "4" },
    { label: "Risco Muito Alto", value: "5" },
  ];

  const doencaRenalOptions = [
    { label: "Não calculado", value: "-1" },
    { label: "Baixo risco", value: "0" },
    { label: "Moderado risco", value: "1" },
    { label: "Alto risco", value: "2" },
    { label: "Muito alto risco", value: "3" },
  ];
  const circleObject = {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "3px",
    marginTop: "2px",
  };

  const inlineContainer = {
    display: "flex",
    flexDirection: "row",
  };

  const {
    data,
    setData,
    precisaAtualizar,
    atualizarGrade,
    setVisibleBlockingPanel,
    FORM_STATE,
  } = useContext(PacienteContext);
  const [listaPaciente, setListaPaciente] = useState([]);
  const [originalListaPaciente, setOriginalListaPaciente] = useState([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [alertObject, setAlertObject] = useState({});
  const [prontuarioVisible, setProntuarioVisible] = useState(false);
  const [pacienteId, setPacienteId] = useState(0);
  const [modalVisivel, setModalVisivel] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [anotacao, setAnotacao] = useState("");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  function carregarPacienteQuery() {
    const idPessoa = query.get("id");
    if (!idPessoa) return;
    setLoadingVisible(true);
    buscarPacientes(idPessoa)
      .then((result) => {
        setLoadingVisible(false);
        setData({ ...result, bloquearDados: true });
        setVisibleBlockingPanel(false);
        setPacienteId(result.id_paciente);
      })
      .catch((error) => {
        console.log(error);
      });
    setProntuarioVisible(true);
  }

  let toFilterText = "";
  useEffect(() => {
    listarPacientes().then((result) => {
      setListaPaciente(result);
      setOriginalListaPaciente(result);
    });
    carregarPacienteQuery();
  }, []);

  function prontuario(id) {
    setLoadingVisible(true);
    buscarPacientes(listaPaciente[id].id).then((result) => {
      setLoadingVisible(false);
      setData({ ...result, formState: FORM_STATE.NOTHING });
      setVisibleBlockingPanel(false);
    });
    if (listaPaciente[id]) setPacienteId(listaPaciente[id].id_paciente);
    setProntuarioVisible(true);
  }

  function handleIncluirAnotacao() {
    setLoadingVisible(true);
    preencheAnotacao(pacienteId, { anotacao })
      .then((result) => {
        setModalVisivel(!modalVisivel);
        setLoadingVisible(false);
        listarPacientes().then((result) => {
          setListaPaciente(result);
          setOriginalListaPaciente(result);
        });
      })
      .catch((erro) => {
        setLoadingVisible(false);
      });
  }

  function handleAnotacao(id) {
    //if (originalListaPaciente[id]){
    setPacienteId(listaPaciente[id].id_paciente);
    setAnotacao(listaPaciente[id].anotacao);
    setModalVisivel(true);
    // }
  }
  function selecionaPaciente(id) {
    setLoadingVisible(true);
    buscarPacientes(listaPaciente[id].id).then((result) => {
      setLoadingVisible(false);
      setData(result);
      setVisibleBlockingPanel(false);
    });
  }

  function excluirPaciente(id) {
    setLoadingVisible(true);
    deletarPacientes(listaPaciente[id].id).then((result) => {
      setLoadingVisible(false);
      atualizarGrade(true);
    });
  }

  function filter(value) {
    if (value.email.includes(toFilterText)) return value;
    if (value.nome.includes(toFilterText)) return value;
    if (String(value.id).includes(toFilterText)) return value;
  }

  function filtrarPaciente(filterText) {
    // setListaPaciente(originalListaPaciente)
    // if (!filterText) return
    // toFilterText = filterText
    // const filteredListaPacientes = listaPaciente.filter(filter)
    // setListaPaciente(filteredListaPacientes)
    if (String(filterText).length < 3 && String(filterText).length > 0) return;
    pesquisarPacientes(filterText).then((result) => {
      setListaPaciente(result);
      setOriginalListaPaciente(result);
      atualizarGrade(false);
    });
  }

  useEffect(() => {
    listarPacientes().then((result) => {
      setListaPaciente(result);
      setOriginalListaPaciente(result);
      atualizarGrade(false);
    });
  }, [atualizarGrade, precisaAtualizar]);

  function alertMessage(index) {
    const now = new Date();
    const prox = new Date(listaPaciente[index].dh_proxima_consulta);
    let text = "Próxima consulta ainda não definida";

    if (listaPaciente[index].dh_proxima_consulta) {
      text =
        prox < now
          ? `Conforme a classificação de risco do paciente, o prazo sugerido para uma nova consulta expirou no dia ${listaPaciente[index].proxima_consulta}`
          : `Conforme a classificação de risco do paciente, o prazo sugerido para uma nova consulta expira no dia ${listaPaciente[index].proxima_consulta}`;
    }

    const alert = {
      title: "",
      text: text,
      widht: "30%",
      height: "160px",
    };
    setAlertObject(alert);
    setVisibleAlert(true);
  }

  function alertaComponent(index) {
    return (
      <div
        title={`Próxima consulta em ${listaPaciente[index].proxima_consulta}`}
      >
        <SimpleButton onClick={() => alertMessage(index)}>
          {listaPaciente[index].alerta ? (
            <IoMdNotifications />
          ) : (
            <IoMdNotificationsOutline />
          )}
        </SimpleButton>
      </div>
    );
  }

  const columns = [
    {
      Header: "",
      accessor: "alerta",
      Cell: (row) => alertaComponent(row.row.index),
    },
    {
      Header: "Nome",
      accessor: "nome",
      Cell: (row) => {
        return (
          <>
            {row.row.cells[8].value == true ? (
              <a
                className={"buttonLink"}
                onClick={() => {
                  prontuario(row.row.index);
                }}
              >
                {row.row.cells[1].value}
              </a>
            ) : (
              <>{row.row.cells[1].value}</>
            )}
          </>
        );
      },
    },
    {
      Header: "Idade",
      accessor: "idade",
    },
    {
      Header: (props) =>
        listaPaciente[0]
          ? listaPaciente[0].riscoCardioVascular !== null
            ? "Risco Cardiovascular"
            : ""
          : "",
      accessor: "risco_cardiovascular",
      Cell: (row) => (
        <>
          {row?.row?.cells[8]?.value == true ? (
            <div style={inlineContainer}>
              <div
                style={{
                  backgroundColor: calcColorRiscoCardioVascular(
                    row?.row?.cells[3]?.value,
                  ),
                  ...circleObject,
                }}
              ></div>
              <div>
                {row?.row?.cells[3]?.value !== null
                  ? riscoCardioVascularOptions.filter(
                      (item) => item.value == row.row.cells[3].value,
                    )[0]?.label
                  : "Não calculado"}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      Header: listaPaciente[0]
        ? listaPaciente[0].score_framingham != null
          ? "Score Framingham"
          : ""
        : "",
      accessor: "score_framingham",
      Cell: (row) => (
        <>
          {row.row.cells[8].value == true ? (
            <div style={inlineContainer}>
              <div
                style={{
                  backgroundColor: calcColorFramingham(row.row.cells[4].value),
                  ...circleObject,
                }}
              ></div>
              <div>
                {row.row.cells[4].value == "-1"
                  ? "Não calculado"
                  : row.row.cells[4].value + "%"}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      Header: (props) =>
        listaPaciente[0]
          ? listaPaciente[0].risco_renal !== null
            ? "Risco Doença Renal"
            : ""
          : "",
      accessor: "risco_renal",
      Cell: (row) => (
        <>
          {row.row.cells[8].value == true ? (
            <div style={inlineContainer}>
              <div
                style={{
                  backgroundColor: calcColorRiscoRenal(row.row.cells[5].value),
                  ...circleObject,
                }}
              ></div>
              <div>
                {row.row.cells[5].value !== null
                  ? doencaRenalOptions.filter(
                      (item) => item.value == row.row.cells[5].value,
                    )[0]?.label
                  : "Não calculado"}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      Header: (props) =>
        listaPaciente[0]
          ? listaPaciente[0].ckd_epi !== null
            ? "TFG (CKD-EPI)"
            : ""
          : "",
      accessor: "ckd_epi",
      Cell: (row) => (
        <>
          {row.row.cells[8].value == true ? (
            <div>
              {row.row.cells[6].value == "-1" ||
              isNaN(row?.row?.cells[6]?.value)
                ? "Não calculado"
                : Number(row?.row?.cells[6]?.value ?? 0).toFixed(1) +
                  " ml/min/1.73m²"}
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      Header: (props) => (
        <>
          <SimpleButton
            alt="Anotações do paciente"
            title="Anotações do paciente"
            onClick={() => {}}
          >
            <FaStar
              style={{ stroke: "#333", strokeWidth: "50" }}
              color="gray"
            />
          </SimpleButton>
        </>
      ),
      accessor: "anotacao",
      Cell: (row) => (
        <>
          {row.row.cells[8].value == true ? (
            <div>
              {row.row.cells[7].value ? (
                <SimpleButton
                  alt="Anotações do paciente"
                  title="Anotações do paciente"
                  onClick={() => {
                    handleAnotacao(row.row.index);
                  }}
                >
                  <FaStar
                    style={{ stroke: "#333", strokeWidth: "50" }}
                    color="yellow"
                  />
                </SimpleButton>
              ) : (
                <SimpleButton
                  alt="Anotações do paciente"
                  title="Anotações do paciente"
                  onClick={() => {
                    handleAnotacao(row.row.index);
                  }}
                >
                  <FaStar
                    style={{ stroke: "#333", strokeWidth: "50" }}
                    color="gray"
                  />
                </SimpleButton>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      Header: "",
      accessor: "prontuario",
      Cell: (row) => (
        <>
          <SimpleButton
            alt="Prontuário paciente"
            title="Prontuário paciente"
            onClick={() => {
              prontuario(row.row.index);
            }}
          >
            <FaRegStickyNote />
          </SimpleButton>
        </>
      ),
    },
    {
      Header: "",
      accessor: "edit",
      Cell: (row) => (
        <SimpleButton
          alt="Editar cadastro"
          title="Editar cadastro"
          onClick={() => {
            selecionaPaciente(row.row.index);
          }}
        >
          <FaEdit />
        </SimpleButton>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (row) => (
        <SimpleButton
          alt="Excluir cadastro"
          title="Excluir cadastro"
          buttonType="delete"
          onClick={() => {
            excluirPaciente(row.row.index);
          }}
        >
          <FaTrashAlt />
        </SimpleButton>
      ),
    },
  ];

  return (
    <>
      <ModalAlert
        visible={visibleAlert}
        setVisible={setVisibleAlert}
        title={alertObject.title}
        text={alertObject.text}
        width={alertObject.widht}
        height={alertObject.height}
      />
      <Loading visible={loadingVisible} />
      <Modal style="modalFull" visible={modalVisivel}>
        <div className="modalContainer" style={{ height: "310px" }}>
          <div>
            <div className="modalHeader">
              <h4 className="titleProntuario">Anotações</h4>
              <div className="fecharModal">
                <button
                  onClick={() => {
                    setModalVisivel(!modalVisivel);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <div className="modalAfericoesContent">
              <div className="modalAfericoesForm">
                <div style={{ width: "100%" }} className="inputContainer">
                  <textarea
                    value={anotacao}
                    onChange={(e) => {
                      setAnotacao(e.target.value);
                    }}
                    className="textAreaAnotacao"
                    name=""
                    id=""
                    cols="90"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <SecondaryButton onClick={handleIncluirAnotacao}>
                + Incluir
              </SecondaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <div className="mainContent">
        <Navigator />
        {prontuarioVisible ? (
          <Prontuario
            closeFunction={() => {
              setProntuarioVisible(false);
              atualizarGrade(true);
              setData({
                ...data,
                bloquearDados: false,
                limparDados: true,
                formState: FORM_STATE.BLOCK,
              });
            }}
            paciente={pacienteId}
          />
        ) : (
          <DataList
            searchfunction={filtrarPaciente}
            columns={columns}
            data={listaPaciente}
          ></DataList>
        )}
      </div>

      <PatientRecord />
    </>
  );
}

export default GridPacientes;
