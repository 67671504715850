import axios from "axios";

const isProduction = true;
const URL = isProduction
  ? "https://api.aortic.com.br/"
  : "http://localhost:3030/";

const api = axios.create({
  baseURL: URL,
});

export default api;
