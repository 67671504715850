import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function cadastrarExame(exame) {
    try {
        const response = await api.post('exame/', exame, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarExamesLaboratoriais() {
    try {
        const response = await api.get('exames', {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarExame(paciente) {
    try {
        const response = await api.get('exame?paciente=' + paciente, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function deletarExame(id) {
    try {
        const response = await api.delete('exame/' + id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

export {cadastrarExame, listarExamesLaboratoriais,
         listarExame, deletarExame}