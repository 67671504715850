import React, { useState, useEffect } from "react";
import LogoAortic from "../../images/logo-aortic.png";
import { useHistory } from "react-router-dom";
import { login } from "../../services/login";
import { requisitarNovaSenha } from "../../services/usuarios";
import { setToken, getToken } from "../../services/utils/token";
import { registrarPushToken } from "../../services/pushNotification";
import ModalAlert from "../../components/modalAlert";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import "./styles.css";

function Login() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [emailRecuperacao, setEmailRecuperacao] = useState("");
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [recuperaSenhaVisible, setRecuperSenhaVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObject, setAlertObject] = useState({});
  const [isDisabledButtonLogin, setIsDisabledButtonLogin] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (getToken()) history.push("/clinicas");
  }, []);

  const handleEmailChange = (e) => {
    const email = e.currentTarget.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email) || email === "";

    setIsDisabledButtonLogin(!isValid);
    setEmail(email);
  };

  function handleRecuperarSenha() {
    if (!emailRecuperacao) return;
    setRecuperSenhaVisible(false);
    setEmailRecuperacao("");
    const alert = {
      title: "",
      text: "O email de recuração foi enviado!",
    };
    setAlertObject(alert);
    setVisibleAlert(true);
    requisitarNovaSenha(emailRecuperacao);
  }

  function autenticarUsuario() {
    setLoadingVisible(true);
    login({ email, senha })
      .then((retornoAutenticacao) => {
        setLoadingVisible(false);
        if (retornoAutenticacao.auth === true) {
          registrarPushToken(retornoAutenticacao.pessoa);
          setToken(retornoAutenticacao.token, retornoAutenticacao.pessoa);
          history.push("/clinicas");
        } else {
          const alert = {
            title: "",
            text: retornoAutenticacao.message,
          };
          setAlertObject(alert);
          setVisibleAlert(true);
        }
      })
      .catch((err) => {
        setLoadingVisible(false);
        const alert = {
          title: "",
          text: "Usuário ou senha inválidos. Tente novamente",
        };
        setAlertObject(alert);
        setVisibleAlert(true);
      });
  }
  return (
    <>
      <ModalAlert
        visible={visibleAlert}
        setVisible={setVisibleAlert}
        title={alertObject.title}
        text={alertObject.text}
      />

      <Modal visible={recuperaSenhaVisible}>
        <div className="containerRecuperaSenhaOnLogin">
          <div className="headerRecuperaSenha">
            <div className="fechar">
              <button
                onClick={() => {
                  setRecuperSenhaVisible(!recuperaSenhaVisible);
                }}
              >
                X
              </button>
            </div>
          </div>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="inputLogin">
                <div>
                  <label htmlFor="login">
                    Digite seu email para recuperar a senha
                  </label>
                </div>
                <div>
                  <input
                    value={emailRecuperacao}
                    onChange={(e) => {
                      setEmailRecuperacao(e.currentTarget.value);
                    }}
                    type="text"
                    name="login"
                    id="loginUser"
                  />
                </div>
              </div>
              <button className="login" onClick={handleRecuperarSenha}>
                Enviar
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <Loading visible={loadingVisible} />
      <div className="containerLogin">
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h4>LOGIN</h4>
            <div className="inputLogin">
              <div>
                <label htmlFor="login">Email</label>
              </div>
              <div>
                <input
                  value={email}
                  onChange={handleEmailChange}
                  type="email"
                  name="login"
                  id="loginUser"
                />
                {isDisabledButtonLogin && (
                  <p className="emailInvalid">Email inválido</p>
                )}
              </div>
            </div>
            <div className="inputLogin">
              <div>
                <label htmlFor="senha">Senha</label>
              </div>
              <div>
                <input
                  value={senha}
                  onChange={(e) => {
                    setSenha(e.currentTarget.value);
                  }}
                  type="password"
                  name="senha"
                  id="senhaLogin"
                />
              </div>
            </div>
            <button
              disabled={isDisabledButtonLogin}
              className="login"
              onClick={autenticarUsuario}
            >
              Entrar
            </button>
            <button
              className="recuperaSenhaButton"
              onClick={() => {
                setRecuperSenhaVisible(!recuperaSenhaVisible);
              }}
            >
              Recuperar senha
            </button>
            <button
              className="createAccount"
              onClick={() => history.push("/create-account")}
            >
              Criar conta
            </button>
          </form>
        </div>
        <div className="logoLogin">
          <img src={LogoAortic} alt="" />
        </div>
      </div>
    </>
  );
}

export default Login;
