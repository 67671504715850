import React from 'react'
import { BiCheckDouble } from 'react-icons/bi'
import './styles.css'

function Mensagem({ tipo, texto, hora }) {
    return (
        <div className={'mensagemContainer' + tipo}>
            <div className={ tipo }>
                { texto }
                <div className="footerMensagem">
                    <span>{ hora }</span>
                    <i><BiCheckDouble /></i>
                </div>
            </div>
            
        </div>
    )
}

export default Mensagem