const validationForm = ({ calculatorRiskData }) => {
  const response = {
    isValid: true,
    error: {
      dataNascimento: false,
      sexo: false,
      pressaoArterial: false,
      dataDiagnostico: false,
      dataExame: false,
    },
  };

  const isAValidYearDate = (value) => {
    const regex = /^(\d{4})$/;
    if (!regex.test(value)) {
      return false;
    }

    const date = new Date(value, 0, 1);
    const today = new Date();

    return date <= today;
  };

  const isValidDate = (str) => {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    if (!regex.test(str)) {
      return false;
    }
    const [day, month, year] = str.split("/");
    const date = new Date(year, month - 1, day);
    const today = new Date();

    return (
      date.getDate() === Number(day) &&
      date.getMonth() === Number(month) - 1 &&
      date.getFullYear() === Number(year) &&
      date < today
    );
  };

  if (
    calculatorRiskData.dataNascimento.length < 10 ||
    !isValidDate(calculatorRiskData.dataNascimento)
  ) {
    response.isValid = false;
    response.error.dataNascimento = true;
  }

  if (!calculatorRiskData.sexo) {
    response.isValid = false;
    response.error.sexo = true;
  }

  if (
    !calculatorRiskData.pressaoArterialSistolica ||
    !calculatorRiskData.pressaoArterialDiastolica
  ) {
    response.isValid = false;
    response.error.pressaoArterial = true;
  }

  if (calculatorRiskData.diabetesMelitus) {
    if (
      calculatorRiskData.diabetesMelitusDataDiagnostico.length < 4 ||
      !isAValidYearDate(calculatorRiskData.diabetesMelitusDataDiagnostico)
    ) {
      response.isValid = false;
      response.error.dataDiagnostico = true;
    }
  }

  if (calculatorRiskData.dataExame) {
    if (
      calculatorRiskData.dataExame.length < 10 ||
      !isValidDate(calculatorRiskData.dataExame)
    ) {
      response.isValid = false;
      response.error.dataExame = true;
    }
  }

  return response;
};

export default validationForm;
