import  React, { useState } from 'react'
import ModalConfirmation from '../../modalConfirmation'
import './style.css'

function SimpleButton({ stylebutton, buttonType, children, onClick, needPrevent, ...rest}) {
    const [visibleConfirmation, setVisibleConfirmation] = useState(false)
    const [visibleConfirmationLogout, setVisibleConfirmationLogout] = useState(false)

    function clickButton() {
        if (buttonType == 'delete') {          
            setVisibleConfirmation(true)      
        } else if (buttonType == 'logout') {
            setVisibleConfirmationLogout(true)
        } else {  
            if (onClick)         
                onClick()           
        }  
    }
    return (
        <>
            <ModalConfirmation 
                visible={ visibleConfirmation } 
                setVisible= { setVisibleConfirmation }
                actionOK={ onClick }
                actionNO={ () => {} }
                title='Confirmar exclusão'
                text='Deseja realmente excluir esse registro?'
            />
            <ModalConfirmation 
                visible={ visibleConfirmationLogout } 
                setVisible= { setVisibleConfirmationLogout }
                actionOK={ onClick }
                actionNO={ () => {} }
                title='Sair'
                text='Deseja realmente sair do sistema?'

            />
            {needPrevent ? 
            <a                  
                className={'simpleButton ' + stylebutton} 
                onClick={(e) => {e.stopPropagation(); clickButton()}}
                { ...rest}>
                { children }
            </a>
            :            
            <button                  
                className={'simpleButton ' + stylebutton} 
                onClick={clickButton}
                { ...rest}>
                { children }
            </button>}

        </>
    )
}

export default SimpleButton