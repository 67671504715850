import React, { useEffect, useState } from 'react'
import PageDefault from '../../components/pageDefault'
import FormDataContainer from '../../components/formDataContainer'
import Navigator from '../../components/navigator'
import { listarPermissoes } from '../../services/usuarios'
import { buscarLogo } from '../../services/clinicas'
import { Link } from 'react-router-dom'
import LogoAortic from '../../images/logo-aortic.png'

import iconagenda from '../../images/icon-agenda.png'
import iconmensagem from '../../images/icon-mensagem.png'
import iconnotificacao from '../../images/icon-notificacao.png'
import iconprontuario from '../../images/icon-prontuario.png'
import iconperfil from '../../images/icon-perfil.png'

import './style.css'

function Menu() {
    const [menu, setMenu] = useState([])
    const [logoClinica, setLogoClinica] = useState('')
    const [nomeClinica, setNomeClinica] = useState('')
    const iconsMennu = {
        perfil: {
            img: iconperfil,
            class: 'iconImageMenu4040'
        },
        prontuario: {
            img: iconprontuario,
            class: 'iconImageMenu3040'             
        },
        agenda: {
            img: iconagenda,
            class: 'iconImageMenu4040'
        },
        mensagem: {
            img: iconmensagem,
            class: 'iconImageMenu4040'
        },
        notificacao: {
            img: iconnotificacao,
            class: 'iconImageMenu3040'   
        }             
    }

    const isMobile = window.innerWidth < 800
    useEffect(() => {
        buscarLogo()
            .then((result) => {
                setNomeClinica(result.nome)
                setLogoClinica(result.foto)
            })
        listarPermissoes()
            .then((result) => { 
                
                setMenu(result.permissoes)
            })
    }, [])

    function MenuMobile() {
        return (
            <div className='menuMobileContainer'>
                <div className="logoClinica">
                    {
                        logoClinica ?
                        <div  style={ {     
                            width: '100%',
                            height: '90px',
                            margin: '10px',
                            backgroundImage: "url('data:image/png;base64, " + logoClinica + "')",
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        } }> </div> :
                        <div  style={ {     
                            width: '100%',
                            height: '90px',
                            margin: '10px',
                            backgroundImage: "url('" + LogoAortic + "')",
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        } }> </div>     
                    }
                    <span>{nomeClinica}</span>
                </div>
                <div className="menuMobileContent">
                    {   menu ?
                        menu.map((item) => {                            
                            return item?.items.map((subItem) => {
                                if (!subItem) return null
                                return (                                    
                                    <Link to={'/' + subItem.link}> 
                                        <div className="menuMobileItem">
                                            <div className="menuMobileItemContainer">
                                                
                                                    {
                                                        iconsMennu[subItem.name] ?
                                                        <div className={iconsMennu[subItem.name].class}>
                                                            <img src={iconsMennu[subItem.name].img} alt=""/> 
                                                        </div>:
                                                        null
                                                    }
                                                
                                                <div className='menuMobileItemText'>
                                                    <span> {subItem.label} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>                                       
                                ) 
                            })
                        }) : null
                    }
                </div>
            </div>
        )
    }
    return (        
        <PageDefault>    
            {
                isMobile ? 
                <MenuMobile /> :
                <>  
                    <FormDataContainer title="Menu">      
                    </FormDataContainer> 
                    <Navigator />
                </>                     
            }                   
        </PageDefault>
    )
}

export default Menu
