import React from 'react'

const UsuariosAdmContext = React.createContext({ 
        estado: '',
        precisaAtualizar: false,
        atualizarGrade: () => {},
        data: {}, 
        setData: () => {} })

export const UsuariosAdmProvider = UsuariosAdmContext.Provider

export { UsuariosAdmContext }
