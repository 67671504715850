import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { getTokenAdm } from '../../../services/utils/token'
import FormDataContainer from '../../../components/formDataContainer'
import { FiLogOut } from 'react-icons/fi'
import { logoutAdm } from '../../../services/login'
import SimpleButton from '../../../components/formComponents/simpleButton'

function PageAdm({formArea, gridArea}) {
    const history = useHistory()
    const [lastMenuClicked, setLasMenuClicked] = useState('')

    useEffect(() => {
        if (!getTokenAdm()) { history.push('/adm/login');  return }    
    }, [])
    const menu = [
        {  
            id: 1, 
            name: 'cadastros', 
            label: 'Cadastros', 
            link: '', 
            level: 1, 
            items: [
                { name: 'clinicas', label: 'Clínicas', link: 'adm/clinicas', level: 2},
                { name: 'usuarios', label: 'Usuários', link: 'adm/usuarios', level: 2}
            ]   
        },
    ]
    function collapseMenu(id) {
        const element = document.getElementById('collapseMenu' + id)
        if (lastMenuClicked) {
            const lastElement = document.getElementById('collapseMenu' + lastMenuClicked)            
            if (id == lastMenuClicked) {
                if (lastElement.classList.contains('visible')) {
                    lastElement.setAttribute('class', 'secondaryContainer invisible')  
                }
                else {
                    lastElement.setAttribute('class', 'secondaryContainer visible')                    
                }
                return
            }
            lastElement.setAttribute('class', 'secondaryContainer invisible')  
                 
        }
        if (element.classList.contains('visible')) {
            element.setAttribute('class', 'secondaryContainer invisible')  
        }
        else {
           element.setAttribute('class', 'secondaryContainer visible') 
           setLasMenuClicked(id)
        }        
    }


    function setInvisibleLastMenu() {
        if (lastMenuClicked) {
            const lastElement = document.getElementById('collapseMenu' + lastMenuClicked)
            lastElement.setAttribute('class', 'secondaryContainer invisible')              
        }
    }
   
    return (
        <main>
            <div className="bg-top">
            </div>
            <div className="content-page-default"> 
                <FormDataContainer title={formArea.title}>      
                    { formArea.content }
                </FormDataContainer> 
                <div className="mainContent">
                    <div className={' navigatorContainer' + ' noselect'}>
                        <div className='primaryContainer'>
                            {   menu ?
                                menu.map((item) => {
                                return (
                                <div className='menuContainer'>  
                                    <div className='pill-primary' id={item.id} onClick={ (e) => { collapseMenu(e.currentTarget.id)  } }>
                                        <span> {item.label} </span>
                                    </div>                       
                                    <div id={'collapseMenu' + item.id} className={"secondaryContainer invisible"}>
                                    {  item.items.map((subItem, index) => {
                                            const style = { marginTop: (index * 40) + 'px' }
                                                return (
                                                    <Link onClick={setInvisibleLastMenu} to={'/' + subItem.link}>
                                                        <div style={ style } className="pill-secondary">
                                                            <span> {subItem.label} </span>
                                                        </div>
                                                    </Link>
                                                    
                                                )
                                        
                                        }) } 
                                    </div>     
                                </div>   
                                )
                            }) : ''}
                        </div>
                        <SimpleButton 
                            buttonType='logout'
                            onClick={ logoutAdm } 
                            stylebutton='btnLogout'
                        >
                                <FiLogOut />
                        </SimpleButton>
                    </div>
                    { gridArea }
                </div>
            </div>  
        </main>
    )
}

export default PageAdm
