import React, { useState, useEffect, useContext } from 'react'
import NoPhotoImage from '../../../images/no-photo.png'
import { MdArrowBack } from 'react-icons/md'
import { NotificacaoContext } from '../context'
import Notificacao from '../notificacao'
import { listarNotificacoes, buscarNotificacao } from '../../../services/notificacoes'
import Loading from '../../../components/loading'
import Navigator from '../../../components/navigator'

import './styles.css'

function HistoricoMensagem() {
        useEffect(() => {
            const objDiv = document.getElementById("scrollNotificacao");
            objDiv.scrollTo(0, objDiv.scrollHeight)
        }, [])
        const isMobile = window.innerWidth < 800
        const [loadingVisible, setLoadingVisible] = useState(false)
        const { data, setData, destinatario, nomeDestinatario, voltarTab } = useContext(NotificacaoContext)

        function listarNotificacoesDetalhe() {
            if (!data[0]) return
            buscarNotificacao(data[0].id_template)
            .then((result) => {
                if (JSON.stringify(result) == JSON.stringify(data) ) {
                    createTimer()
                    return
                }
                setData(result)
            })
            .catch((err) => {
            })
        }

        function createTimer() {
            const timer = setTimeout(() => {
                listarNotificacoesDetalhe()
            }, 5000)    
            return () => clearTimeout(timer);
        }
      
        useEffect(() => {
            createTimer()
          }, [data]);
        return (
        <>
            <Loading visible={ loadingVisible } />
            <div className='mensagensContainer'>  
                <Navigator 
                    disableMobileNavigator={true} 
                    style='navigatorHistoricoMensagem'
                />          
                <div className="historicoMensagemHeader">
                        <div>
                            { 
                                isMobile ?
                                <button title="Voltar" onClick={() => { voltarTab() } }><MdArrowBack /></button>
                                : ''
                            }
                            
                            <img className="historicoMensagemHeaderImg" 
                                src={ NoPhotoImage } alt=""/> 
                            <span>{ nomeDestinatario }</span> 
                        </div>                      
                            
                </div>
                <div id="scrollNotificacao"  className='historicoMensagemContainer'>                    
                    <div className="historicoMensagemContent">
                        {   data ?
                            data.map((item) => {
                                if (!item.mensagem ) return
                                const hora = new Date(item.data_notificacao)
                                const horaFormatada =  String(hora.getHours()).padStart(2, '0') + ':' + String(hora.getMinutes()).padStart(2, '0')
                                
                                return (
                                    <Notificacao 
                                        valorresposta={item.valor_resposta}
                                        identificador={item.id_notificacao}
                                        respondida={item.notificacao_respondida}
                                        template={item.id_template}
                                        tipo={item.tipo_mensagem} 
                                        opcoes={item.opcoes}
                                        hora={horaFormatada} 
                                        texto={ item.mensagem }
                                    />                   
                                )
                                
                            })
                            : ''
                        }                                    
                    </div> 
                </div>   
            </div>
        </>    
        
    )
}

export default HistoricoMensagem