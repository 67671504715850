import React, { useState, useEffect, useContext } from 'react'
import DataList from '../../../components/dataList'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import SimpleButton from '../../../components/formComponents/simpleButton'
import { TemplateContext } from '../context'
import { buscarTemplate, listarTemplates, deletarTemplate } from '../../../services/templateNotificacoes'
import Loading from '../../../components/loading'
import Navigator from '../../../components/navigator'

import './style.css'

function GridRecepcionistas() {
  const { setData, precisaAtualizar, atualizarGrade, setVisibleBlockingPanel } = useContext(TemplateContext)
  const [listaTemplates, setListaTemplates] = useState([])
  const [originalListaTemplates, setOriginalListaTemplates] = useState([])
  const [loadingVisible, setLoadingVisible] = useState(false)

  //const [searchText, setSearchText] = useState('')
  let toFilterText = ''
  useEffect(() => {
    listarTemplates()
      .then((result) => {
        setListaTemplates(result) 
        setOriginalListaTemplates(result)
      })    
  }, [])
  
  function selecionaTemplate(id) {
    setLoadingVisible(true)
    buscarTemplate(id)
      .then((result) => {
        setLoadingVisible(false)
        setData(result)
        setVisibleBlockingPanel(false)
      })    
  }

  function excluirTemplate(id) {
    setLoadingVisible(true)
    deletarTemplate(id)
      .then((result) => {
        setLoadingVisible(false)
        atualizarGrade(true)
      })    
  }


  function filter(value) {
    if (value.titulo.includes(toFilterText)) return value
    if (String(value.id).includes(toFilterText)) return value
    if (value.mensagem.includes(toFilterText)) return value
  }

  function filtrarTemplate(filterText) {
    setListaTemplates(originalListaTemplates)     
    if (!filterText) return
    toFilterText = filterText
    const filteredListaPacientes = listaTemplates.filter(filter)
    setListaTemplates(filteredListaPacientes)
  }

  useEffect(() => {
    listarTemplates()
      .then((result) => {
        setListaTemplates(result) 
        setOriginalListaTemplates(result)
        atualizarGrade(false)
      })
  }, [atualizarGrade, precisaAtualizar])

const columns = [
        {
          Header: "Id",
          accessor: "id",
        },
        {
          Header: "Titulo",
          accessor: "titulo",
        },         
        {
          Header: "Pergunta",
          accessor: "mensagem",
        },    

        {
          Header: '',
          accessor: 'edit',
          Cell: row => (
            <>
              {
                listaTemplates[row.row.index]?.id_clinica ?
                <SimpleButton 
                  alt='Editar cadastro'
                  title='Editar cadastro'
                  onClick={() => {selecionaTemplate(row.row.cells[0].value)} }><FaEdit /></SimpleButton>
                : null
              }
            </>
          )
        },
        {
          Header: '',
          accessor: 'delete',
          Cell: row => (
            <>
              {
                listaTemplates[row.row.index]?.id_clinica ?
                <SimpleButton 
                  alt='Excluir cadastro'
                  title='Excluir cadastro'
                  buttonType='delete' onClick={() => {excluirTemplate(row.row.cells[0].value)} }><FaTrashAlt /></SimpleButton>                
                : null
              }
            </>
          )
        },          
       
      ]
    return (
        <>
          <Loading visible={ loadingVisible }/>
          <div className="mainContent">
            <Navigator />
            <DataList searchfunction={ filtrarTemplate } columns={columns} data= {listaTemplates}></DataList>  
          </div>
        </>
    )
}

export default GridRecepcionistas