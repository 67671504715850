import React, {
  useReducer,
  useContext,
  createContext,
  useState,
  useEffect,
} from "react";

export const PatientRecordContext = createContext();
export const usePatientRecordContext = () => useContext(PatientRecordContext);
export const STEP_CLOSE_MODAL = 0;
export const STEP_CALCULATOR_RISK = 1;
export const STEP_CALCULATOR_RESULT = 2;
export const STEP_COMPLETE_REGISTRATION = 3;

export const STEP_PUBLIC_CALCULATOR_RISK = 1;
export const STEP_PUBLIC_CALCULATOR_RESULT = 2;
const STORAGE_KEY = "patientRecordData";

export default function PatienteRecordProvider({ children }) {
  const initialStateRiskData = {
    classificacaoIMC: "",
    classificacaoPressaoArterial: "",
    scoreFramingham: "",
    riscoCardioVascular: "",
    riscoRenal: "",
    ckdEpi: "",
    imc: "",
    prazoConsulta: "",
  };

  const initialCalculatorRiskData = {
    documento: "",
    telefone: "",
    whatsapp: "",
    sexo: "",
    numeroInscricaoConvenio: "",
    dataNascimento: "",
    tabagismo: false,
    dcvPrematuraParentes: false,
    doencaAteroscleroticaSignificativaOuObstrucao50: false,
    doencaAteroscleroticaSubclinica: false,
    aneurismaAortaAbdominal: false,
    insuficienciaCardiaca: false,
    diabetesMelitus: false,
    diabetesMelitusTipo: null,
    diabetesMelitusDataDiagnostico: "",
    medicacaoHipertensao: false,
    pressaoArterialSistolica: "",
    pressaoArterialDiastolica: "",
    altura: "",
    peso: "",
    colesterolTotal: "",
    ldl: "",
    hdl: "",
    triglicerideos: "",
    glicemia: "",
    hba1c: "",
    creatinina: "",
    microalbuminuria: "",
    dataExame: "",
  };
  const initialStatePatientCreateData = {
    nome: "",
    sexo: "",
    whatsapp: "",
    dataNascimento: "",
    numeroInscricaoConvenio: "",
    cep: "",
    logradouro: "",
    numero: "",
    bairro: "",
    documento: null,
    complemento: "",
    cidade: "",
    uf: "",
    id_medico_responsavel: "",
    convenio: "",
    numero_inscricao_convenio: "",
    login: "",
    isButtonPacientCreateDisabled: false,
  };

  const [stepper, setStepper] = useState(STEP_CLOSE_MODAL);
  const [stepperPublic, setStepperPublic] = useState(
    STEP_PUBLIC_CALCULATOR_RISK,
  );

  const [calculatorRiskData, setCalculatorRiskData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialCalculatorRiskData,
  );
  const [resultRiskData, setResultRiskData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialStateRiskData,
  );
  const [patientCreateData, setPatientCreateData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialStatePatientCreateData,
  );

  const saveToLocalStorage = (data) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  };

  useEffect(() => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setCalculatorRiskData(parsedData.calculatorRiskData);
      setResultRiskData(parsedData.resultRiskData);
      setPatientCreateData(parsedData.patientCreateData);
      setStepper(Number(parsedData.stepper));
      setStepperPublic(Number(parsedData.stepperPublic));
    }
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem(STORAGE_KEY);
  };

  const clearPatientRecordContext = () => {
    setCalculatorRiskData(initialCalculatorRiskData);
    setResultRiskData(initialStateRiskData);
    setPatientCreateData(initialStatePatientCreateData);
    clearLocalStorage();
  };

  useEffect(() => {
    saveToLocalStorage({
      calculatorRiskData,
      resultRiskData,
      patientCreateData,
      stepper,
      stepperPublic,
    });
  }, [
    calculatorRiskData,
    resultRiskData,
    patientCreateData,
    stepper,
    stepperPublic,
  ]);

  return (
    <PatientRecordContext.Provider
      value={{
        calculatorRiskData,
        setCalculatorRiskData,
        resultRiskData,
        setResultRiskData,
        stepper,
        setStepper,
        clearPatientRecordContext,
        patientCreateData,
        setPatientCreateData,
        stepperPublic,
        setStepperPublic,
      }}
    >
      {children}
    </PatientRecordContext.Provider>
  );
}
