import React from "react";

const AdministradorContext = React.createContext({
  estado: "",
  precisaAtualizar: false,
  atualizarGrade: () => {},
  data: {},
  blockingPanel: false,
  setVisibleBlockingPanel: () => {},
  setData: () => {},
});

export const AdministradorProvider = AdministradorContext.Provider;

export { AdministradorContext };
