import React, { useState, useEffect, useContext } from 'react'
import InputText from '../../../../components/formComponents/inputText'
import InputSelect from '../../../../components/formComponents/inputSelect'
import PrimaryButton from '../../../../components/formComponents/primaryButton'
import SecondaryButton from '../../../../components/formComponents/secondaryButton'
import { listarClinicasAdm } from '../../../../services/clinicas'
import { cadastrarUsuarioAdm, alterarUsuarioAdm } from '../../../../services/usuarios'
import { enviarConviteNovoUsuario, verificarEmailCadastradoAdm } from '../../../../services/usuarios'
import ModalConfirmation from '../../../../components/modalConfirmation'
import Check from '../../../../components/formComponents/checkBox'

import { FaCheck, FaTimes } from 'react-icons/fa';
import { UsuariosAdmContext } from '../context'
import ModalAlert from '../../../../components/modalAlert'
import Loading from '../../../../components/loading'

import './style.css'

function FormClinica({ value, classForm, perfil}) {
    const statusMessageEmail = {
        valid: 'Email válido',
        invalid: 'Email inválido'
    }
    const [loadingVisible, setLoadingVisible] = useState(false)
    const { atualizarGrade, data, setData } = useContext(UsuariosAdmContext)
    const [stateEmail, setStateEmail] = useState('')
    const [visibleConfirmation, setVisibleConfirmation] = useState(false)
    const [confirmation, setConfirmation] = useState({
        actionNO: () => {},
        actionOK: () => {}
    })
    /*-----Hooks campos formulario-----*/
    const [id, setId] = useState('')
    const [nome, setNome] = useState('')
    const [clinica, setClinica] = useState('')    
    const [listaClinica, setListaClinica] = useState([])    
    const [senha, setSenha] = useState('')
    const [senhaConfirmacao, setSenhaConfirmacao] = useState('')
    const [email, setEmail] = useState('')
    const [medico, setMedico] = useState(false)
    const [visibleAlert, setVisibleAlert] = useState(false)
    const [alertObject, setAlertObject] = useState({})
    
    /*----------------------------------*/    
    function limparFormulario() {
        setId('')
        setClinica(listaClinica[0] ? listaClinica[0].id : '')
        setSenha('')
        setSenhaConfirmacao('')
        setEmail('')
        setNome('')        
        setMedico(false)
    }
    
    function setDadosForm(usuario) {
        setId(usuario.id)
        setClinica(usuario.id_clinica)
        setEmail(usuario.email)
        setNome(usuario.nome) 
        setMedico(usuario.perfil == 'MEDICO')
    }
    
    function getDadosForm(alterando) {
        const usuarioBase = {            
            nome,
            id_clinica: clinica,
            senha,
            email,
            medico
        }
        if (alterando) {
            return {
            id,
            ...usuarioBase
            }  
        } 
        else {
            return usuarioBase
        }        
    }

    function isValidPassword() {
        return senha === senhaConfirmacao
    }
    
    useEffect(() => {
        setDadosForm(data)        
    }, [data])
    
    useEffect(() => {
        if (id) return
        const btn = document.getElementById('btnSalvar')
        btn.setAttribute('disabled', true)          
        if (!email) {
            setStateEmail('')
            return            
        }
        setStateEmail('invalid')
        if (email.length < 5 ) return 
        const pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!pattern.test(email)) return

        setStateEmail('loading')
        verificarEmailCadastradoAdm(email, clinica)
            .then((result) => {
                if (result.message == 'CADASTRO_CLINICA') {
                    const alert = {
                        title: '',
                        text: 'Esse email já está sendo utilizado por outro cadastro!',
                        widht: '30%'
                    }
                    setAlertObject(alert)
                    setVisibleAlert(true) 
                    setStateEmail('invalid')
                    btn.setAttribute('disabled', true)
                    return
                } 

                if (result.message == 'CADASTRO_OUTRO') {
                    setConfirmation({
                        Title: 'Usuário já cadastrado',
                        Question: 'Esse email já possui cadastro no sistema Aortic. Deseja ' +
                        'enviar um convite para ingressar na sua clínica?',
                        actionOK: () => {
                            enviarConviteNovoUsuario({
                                perfil: 'ADMINISTRADOR',
                                id_pessoa: result.id_pessoa
                            })
                        },
                        actionNO: () => {
                        }  
                    })
                    setVisibleConfirmation(true)     
                    setStateEmail('invalid')
                    btn.setAttribute('disabled', true)
                    return
                } 
                setStateEmail('valid')
                btn.removeAttribute('disabled')    
            })
    }, [email, clinica])

    useEffect(() => {        
        listarClinicasAdm(true)
            .then((response) => {
                const lista = response.map((item) => {
                    return { label: item.nome, value: item.id }
                })
                setListaClinica(lista)
                setClinica(lista[0].value)
            })
        if (value) setDadosForm(value)         
    }, [])

    function enviarDadosUsuario() {      
        setLoadingVisible(true)        
        const alterando = (id > 0) 
        const template = getDadosForm(alterando)
        if (alterando) {
            alterarUsuarioAdm(template)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                })
        }
        else {
            cadastrarUsuarioAdm(template)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                })   
        }              
        
    }
   
    return (
        <>           
            <form autocomplete="off" onSubmit={(e) => {e.preventDefault()}} className={ classForm ? classForm : 'form' }>
                <Loading 
                    forced={ perfil ? true : false }
                    visible={ loadingVisible }
                />        
                <ModalAlert 
                    visible={visibleAlert} 
                    setVisible={setVisibleAlert} 
                    title={alertObject.title} 
                    text={alertObject.text}
                    width={alertObject.widht}
                />   
                <ModalConfirmation
                    height={'160px'} 
                    visible={ visibleConfirmation } 
                    setVisible= { setVisibleConfirmation }
                    actionOK={ confirmation.actionOK }
                    actionNO={ confirmation.actionNO }
                    title={ confirmation.Title }
                    text={ confirmation.Question }
                />
                <InputText
                    status={stateEmail}
                    statusMessage={statusMessageEmail}
                    autocomplete="off"
                    required     
                    value={ email }
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    containerstyle="input" 
                    type="email"
                    label="Email" 
                    name="emailMedico" />
                    
                <InputText 
                    value={ nome }
                    onChange={(e) => {
                        setNome(e.target.value)
                    }}
                    containerstyle="input" 
                    label="Nome" 
                    name="nome"></InputText>


                <InputSelect 
                    containerstyle="input" 
                    name='clinica'
                    value={ clinica }
                    onChange={(e) => {
                        setClinica(e.target.value)
                    }}
                    options={ listaClinica } label="Clínica">                            
                </InputSelect>    

                
                
                <div className="col-2"> 
                    <InputText 
                        value={ senha }
                        onChange={(e) => {
                            setSenha(e.target.value)
                        }}
                        type="password"
                        containerstyle="input-col" 
                        autocomplete="new-password"
                        label="Senha" 
                        id="senha"
                        name="senha" />
                    <InputText 
                        value={senhaConfirmacao}
                        onChange={(e) => {
                            setSenhaConfirmacao(e.target.value)
                        }}
                        type="password"
                        autocomplete="new-password"
                        containerstyle="input-col"                             
                        label="Confirme a senha" 
                        name="confirmacaoSenha" />   
                    
                </div>
                {   id ? null :
                    <Check 
                        containerstyle='input marginTop20' 
                        name='preenchimento'
                        label='Médico?'
                        checked={ medico }
                        onCheck={() => { 
                            setMedico(!medico)                         
                        }}
                    />
                }

                <div className="footer"> 
                    <SecondaryButton 
                        type="submit"                    
                        onClick={limparFormulario}
                        containerstyle ="footerButton" 
                        text="Cancelar"><FaTimes /></SecondaryButton>
                    <PrimaryButton 
                        name='btnSalvar'
                        onClick={enviarDadosUsuario }
                        containerstyle="footerButton" 
                        text="Salvar" ><FaCheck /></PrimaryButton>                
                </div>
            </form> 
        </>
    )
}

export default FormClinica