import React, { useState, useEffect } from 'react'
import SimpleButton from '../../../../components/formComponents/simpleButton'
import SecondaryButton from '../../../../components/formComponents/secondaryButton'
import { FaEdit, FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa'
import InputText from '../../../../components/formComponents/inputText'
import InputSelect from '../../../../components/formComponents/inputSelect'
import DataList from '../../../../components/dataList'
import Modal from '../../../../components/modal'
import Loading from '../../../../components/loading'
import { cadastrarExame, listarExame, listarExamesLaboratoriais, deletarExame } from '../../../../services/exames'

import './styles.css'

function Exame({ content }) {
    const [modalVisivel, setModalVisivel] = useState(false)
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [listaExames, setListaExames] = useState([])
    const [idExame, setIdExame] = useState('')
    const [dataExame, setDataExame] = useState('')
    const [resultado, setResultado] = useState('')
    const [unidade, setUnidade] = useState('')
    const [listaExamesOption, setListaExamesOption] = useState([{ label: '', value: '' }])
    const [alterouDados, setAlterouDados] = useState(false)
   
    useEffect(() => {
        listarExamesLaboratoriais()
            .then((response) => {
                const lista = response.map((item) => {
                    return { label: item.descricao, value: item.id, tipo: item.tipo, unidade: item.unidade_resultado }
                })
                lista.unshift({
                    label: 'Selecione um exame',
                    value: '',
                    tipo: '',
                    descricao: '',
                })
                setListaExamesOption(lista)
                setIdExame(lista[0].value)
            })
    }, [])

    useEffect(() => {
        const filter = listaExamesOption.filter((item) => item.value == idExame) 
        if (filter[0]) {
            setUnidade(filter[0].unidade)
        }
    }, [idExame])

    function handleIncluirExame() {
        if (!dataExame) {
            document.getElementById('dataexame').focus()
            return
        }
        if (!resultado) return
        if (!idExame) return
        setLoadingVisible(true)
        const exameFiltrado = listaExamesOption.filter((item) => item.value == idExame)        
        const exame = { tipo: exameFiltrado[0].tipo, id_paciente: content.paciente, id_exame: idExame, 
            data_exame: dataExame, resultado  }
        cadastrarExame(exame)
            .then((result) => {      
                setAlterouDados(true)
                handleCarregarListaExames()          
                setIdExame('')
                setResultado('')               
                content.refresh()
                setLoadingVisible(false)

            })
            .catch((erro) => {
                setLoadingVisible(false)
            })
    }

    function handleDeletarExame(id) {
        setLoadingVisible(true)
        deletarExame(id)
            .then(() => {
                setAlterouDados(true)
                handleCarregarListaExames()          
                setLoadingVisible(false)
            })
            .catch(() => {
                setLoadingVisible(false)
            })
    }

    function handleCarregarListaExames() {
        listarExame(content.paciente)
            .then((result) => {
                setListaExames(result)
            })
    }

    function handleClose() {
        setModalVisivel(!modalVisivel)
        if (alterouDados){
            content.refresh()
        }
        
    }

    function handleCarregarExames() {
        setModalVisivel(true)
        handleCarregarListaExames()
    }

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
          Header: "Exame",
          accessor: "descricao",
        },
        {
          Header: "Resultado",
          accessor: "resultado",
        },
        {
            Header: "Data",
            accessor: "data_exame"
        },
        {
            Header: 'Ativo',
            accessor: 'ativo',
            Cell: row => (                
            <>{row.row.cells[4].value == true ? <FaCheck color='green' /> : <FaTimes /> }</>
            )
        },
        {
          Header: '',
          accessor: 'delete',
          Cell: row => (
          <SimpleButton 
            alt='Excluir cadastro'
            title='Excluir cadastro'
            buttonType='delete' onClick={() => { handleDeletarExame(row.row.cells[0].value)} }><FaTrashAlt /></SimpleButton>
          )
        },      
       
      ]
     return (
        <>
            <Loading forced={true} visible={ loadingVisible } />
            <Modal style='modalFull' visible={ modalVisivel }>
                <div className="modalContainer">
                    <div>
                        <div className="modalHeader">
                            <h4 className="titleProntuario">
                                Exames Laboratoriais
                            </h4> 
                            <div className='fecharModal'>
                                <button onClick={handleClose}>X</button> 
                            </div>     
                        </div>
                        <div className="modalAfericoesContent">                            
                            <div className="modalAfericoesGrid">
                                <DataList hideDirDescription={ true } stylecontainer='gridModal' filter={<></>} columns={columns} data= {listaExames}></DataList> 
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='cardContainer lastContainer'>
            <h4 className='titleProntuario'>Exames laboratoriais</h4>            
                <div className="cardProntuarioBackground">
                    <div className="modalAfericoesForm">
                                    <div  style={{ width: '100%' }} className="inputContainer">
                                        <div className="medicamentosForm">
                                                <InputText 
                                                    value={ dataExame }
                                                    onChange={(e) => {
                                                        setDataExame(e.target.value)
                                                    }}         
                                                    type='date'                                       
                                                    label="Data do exame" 
                                                    name="dataexame" 
                                                    required
                                                    containerstyle="col-30" 
                                                />   
                                            <div className="medicamentosFormLine">                                            
                                                <InputSelect 
                                                    containerstyle="editExames" 
                                                    value={ idExame }
                                                    onChange={(e) => {
                                                        setIdExame(e.target.value)
                                                    }}
                                                    options={listaExamesOption} label="Exame">                            
                                                </InputSelect> 
                                                <InputText 
                                                    containerstyle="col-20-percent" 
                                                    value={ resultado }
                                                    onChange={(e) => {
                                                        const result = String(e.target.value).replace(',', '.')
                                                        setResultado(result)
                                                    }}
                                                    
                                                    label="Resultado" 
                                                    name="resultado" 
                                                />
                                                <span className='textoUnidade'> {unidade}</span>     
                                            </div>  
                                        </div>            
                                    </div>                                
                                    <SecondaryButton onClick={ handleIncluirExame }>+ Incluir</SecondaryButton>
                    </div>
                    <div className="cardProntuario">      
                        <ul>
                            { 
                                content.exames ?
                                content.exames.map((item) => {
                                    if (item.resultado) {
                                        return (
                                            <li><span className='titleCard'>{ item.descricao }:</span> { item.resultado } </li>
                                        )   
                                    }
                                    
                                }) :
                                <li>-----</li>
                            }            
                        </ul>    
                        <SimpleButton onClick={() => { handleCarregarExames() } }><FaEdit /></SimpleButton>
                    </div>            
                </div>      
        </div>
    </>
    )
}

export default Exame