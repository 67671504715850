const alreadyRegisteredClinic = ({
  setAlertObject,
  setVisibleAlert,
  setLoadingVisible,
}) => {
  const alert = {
    title: "",
    text: "Esse email já está sendo utilizado por outro cadastro!",
    widht: "30%",
  };
  setAlertObject(alert);
  setVisibleAlert(true);
  setLoadingVisible(false);
};

export default alreadyRegisteredClinic;
