import React, { useState, useEffect, useContext } from 'react'
import InputText from '../../../components/formComponents/inputText'
import InputSelect from '../../../components/formComponents/inputSelect'
import PrimaryButton from '../../../components/formComponents/primaryButton'
import SecondaryButton from '../../../components/formComponents/secondaryButton'
import {  cadastrarAgendamento, alterarAgendamento } from '../../../services/agendamentos'
import {  listarMedicos } from '../../../services/medicos'
import { phoneMask } from '../../../services/utils/mask'
import {  listarPacientes } from '../../../services/pacientes'
import { FaCheck, FaTimes } from 'react-icons/fa';
import { AgendamentoContext} from '../context'
import Loading from '../../../components/loading'
import './style.css'

function FormAgendamento() {

    const [loadingVisible, setLoadingVisible] = useState(false)
    const { atualizarGrade, data, setVisibleBlockingPanel } = useContext(AgendamentoContext)

    /*-----Hooks campos formulario-----*/
    
    const [id, setId] = useState('')
    const [idMedico, setIdMedico] = useState(0)
    const [idPaciente, setIdPaciente] = useState('')
    const [dataAgendamento, setDataAgendamento] = useState('')
    const [horaAgendamento, setHoraAgendamento] = useState('')
    const [listaMedicos, setListaMedicos] = useState([])
    const [listaPacientes, setListaPacientes] = useState([])
    const [listaPacientesCompleta, setListaPacientesCompleta] = useState([])
    const [documentoPaciente, setDocumentoPaciente] = useState('')
    const [telefonePaciente, setTelefonePaciente] = useState('')
    const [whatsappPaciente, setWhatsappPaciente] = useState('')

    /*----------------------------------*/
    
    useEffect(() => {
        listarMedicos()
          .then((result) => {
            const lista = result.map((item) => {
                return { value: item.id_medico, label: item.nome }
            })
            setListaMedicos(lista) 
            setIdMedico(lista[0].value)
          })    
      }, []) 

      function listarPacientesMedico() {
        setLoadingVisible(true)
        listarPacientes(idMedico)
            .then((result) => {
                const lista = result.map((item) => {
                    return { value: item.id_paciente, label: item.nome }
                })
                setListaPacientesCompleta(result)
                setListaPacientes(lista) 
                setIdPaciente(lista[0].value)
                setLoadingVisible(false)
            })
            .catch((error) => {
                setLoadingVisible(false)
            })
      }

      useEffect(() => {
        listarPacientesMedico()
      }, [idMedico])

      useEffect(() => {
        listaPacientesCompleta.map((item) => {
            if (item.id_paciente == idPaciente) {
                setDocumentoPaciente(item.documento || '')
                setTelefonePaciente(phoneMask(item.telefone) || '')
                setWhatsappPaciente(phoneMask(item.whatsapp) || '')
            }
        })
      }, [idPaciente])

    function limparFormulario() {
        setId('')
        setIdPaciente('')
        setDataAgendamento('')
        setHoraAgendamento('')    
        setListaPacientes([])   
        setDocumentoPaciente('')   
        setTelefonePaciente('')   
        setWhatsappPaciente ('')          
        setIdMedico(listaMedicos[0].value)
        listarPacientesMedico()
        setVisibleBlockingPanel(true)
    }

    function setDadosForm(agendamento) {
        let dataAgendamento = new Date(agendamento.data_agendamento);
        var dia = ("0" + dataAgendamento.getDate()).slice(-2);
        var mes = ("0" + (dataAgendamento.getMonth() + 1)).slice(-2);  
        var dataAgendamentoFinal = dataAgendamento.getFullYear()+"-"+(mes)+"-"+(dia);

        setId(agendamento.id)
        setIdMedico(agendamento.id_medico)
        setIdPaciente(agendamento.id_paciente)
        setDataAgendamento(dataAgendamentoFinal)
        setHoraAgendamento(agendamento.hora_agendamento)        
    }

    function getDadosForm(alterando) {
        const agendamentoBase = {            
            id_medico: idMedico,
            id_paciente: idPaciente,
            data_agendamento: dataAgendamento,
            hora_agendamento: horaAgendamento,
        }
        if (alterando) {
          return {
            id,
            ...agendamentoBase
            }  
        } 
        else {
            return agendamentoBase
        }
         
    }

    useEffect(() => {
        setDadosForm(data)                 
    }, [data])

    function enviarDadosAgendamento() {

        setLoadingVisible(true)  

        const alterando = (id > 0) 
        const agendamento = getDadosForm(alterando)
        if (alterando) {
            alterarAgendamento(agendamento)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)

                })
        }
        else {
            cadastrarAgendamento(agendamento)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)
                    
                })   
        }      
        
    }

    return (
        <form onSubmit={(e) => {e.preventDefault()}} className="formData form">           
            <Loading visible={ loadingVisible }/>            
            <InputSelect 
                containerstyle="input" 
                value={idMedico}
                onChange={(e) => {
                    setIdMedico(e.target.value)
                }}
                name='medico'
                options={ listaMedicos } label="Médico">                            
            </InputSelect>
            
            <div className="col-2">
                <InputText 
                    value={ dataAgendamento }
                    onChange={(e) => {
                        setDataAgendamento(e.target.value)
                    }}
                    type="date"
                    containerstyle="input-col" 
                    label="Data" 
                    name="dataAgendamento">
                </InputText>
                <InputText 
                    value={ horaAgendamento }
                    onChange={(e) => {
                        setHoraAgendamento(e.target.value)
                    }}
                    type="time"
                    containerstyle="input-col" 
                    label="Hora" 
                    name="horaAgendamento">
                </InputText>
            </div>
            <InputSelect 
                containerstyle="input" 
                value={idPaciente}
                onChange={(e) => {
                    setIdPaciente(e.target.value)
                }}
                name='paciente'
                options={ listaPacientes } label="Paciente">                            
            </InputSelect>
            <InputText 
                    value={ documentoPaciente }                     
                    containerstyle="input" 
                    label="Documento (CPF)" 
                    name="documento" />
            <div className="col-2">                        
                <InputText 
                    value={ telefonePaciente }                     
                    containerstyle="input-col" 
                    label="Telefone" 
                    name="telefone" />
                <InputText 
                    value={ whatsappPaciente }                     
                    containerstyle="input-col" 
                    type="text"
                    label="Whatsapp" 
                    name="whatsapp" />
            </div>
           
            <div className="footer"> 
                <SecondaryButton 
                    type="submit"  
                    name='btnCancelar'                  
                    onClick={limparFormulario}
                    containerstyle ="footerButton" 
                    text="Cancelar"><FaTimes /></SecondaryButton>
                <PrimaryButton 
                    name='btnSalvar'
                    onClick={enviarDadosAgendamento}
                    containerstyle="footerButton" 
                    text="Salvar" ><FaCheck /></PrimaryButton>                
            </div>
        </form> 
    )
}

export default FormAgendamento