import React, { useEffect, useState } from 'react'
import PageDefault from '../../components/pageDefault'
import Navigator from '../../components/navigator'
import InputText from '../../components/formComponents/inputText'
import PrimaryButton from '../../components/formComponents/primaryButton'
import { alterarConfiguracao, listarConfiguracao } from '../../services/clinicas'
import UploadArea from '../../components/uploadArea'
import FormDataContainer from '../../components/formDataContainer'
import { onlyLetters } from '../../services/utils/onlyLetters'
import { consultarCEP } from '../../services/utils/cep'
import { FaCheck } from 'react-icons/fa';



import Loading from '../../components/loading'
import './styles.css'

function Configuracao() {
    const [diasConsultaRiscoAlto,  setDiasConsultaRiscoAlto] = useState('')
    const [diasConsultaRiscoMedio, setDiasConsultaRiscoMedio ] = useState('')
    const [diasConsultaRiscoBaixo,setDiasConsultaRiscoBaixo ] = useState('')
    const [diasConsultaRiscoZero, setDiasConsultaRiscoZero] = useState('')
    const [alertaAntecedenciaConsulta, setAlertaAntecedenciaConsulta] = useState('')
    const [notificarNovamenteApos, setNotificarNovamenteApos ] = useState('')
    const [afericaoSisLimite, setAfericaoSisLimite] = useState('')
    const [afericaoDiaLimite,setAfericaoDiaLimite ] = useState('')
    const [diasAlertaProximidadeConsulta, setDiasAlertaProximidadeConsulta] = useState('')
    const [nomeClinica, setNomeClinica] = useState('')
    const [logo, setLogo] = useState('')
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [visibleBlockingPanel, setVisibleBlockingPanel] = useState(false)
    const [telefone, setTelefone] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [logradouro, setLogradouro] = useState('')
    const [numero, setNumero] = useState('')
    const [bairro, setBairro] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cidade, setCidade] = useState('')
    const [uf, setUf] = useState('')


    function alterarConfiguracaoClinica() {
        setLoadingVisible(true)
        const configuracao = {
            dias_consulta_risco_alto: diasConsultaRiscoAlto,
            dias_consulta_risco_medio: diasConsultaRiscoMedio,
            dias_consulta_risco_baixo: diasConsultaRiscoBaixo,
            dias_consulta_risco_zero: diasConsultaRiscoZero,
            alerta_antecedencia_consulta: alertaAntecedenciaConsulta,
            notificar_novamente_apos: notificarNovamenteApos,
            afericao_sis_limite: afericaoSisLimite,
            afericao_dia_limite: afericaoDiaLimite,
            dias_alerta_proximidade_consulta: diasAlertaProximidadeConsulta,
            nome_clinica: nomeClinica,
            logo_base64: logo,
            telefone,
            whatsapp,
            email,
            cep,
            logradouro,
            numero,
            bairro,
            complemento,
            cidade,
            uf
        }

        alterarConfiguracao(configuracao)
            .then(() => {
                setLoadingVisible(false)
            })
            .catch(() => {
                setLoadingVisible(false)
            })

    }


    function handleUpload(files) {
        const reader = new FileReader()
        reader.readAsBinaryString(files[0])
        reader.onload = function() {
           setLogo((btoa(reader.result))) 
        }   
    }

    function handleConsultaCEP() {
        if(!cep) return
        if (cep.length < 8) return 
        consultarCEP(cep)
            .then((dadosEndereco) => {
                setLogradouro(dadosEndereco.data.logradouro)
                setBairro(dadosEndereco.data.bairro)
                setCidade(dadosEndereco.data.localidade)
                setUf(dadosEndereco.data.uf)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        setLoadingVisible(true)        
        listarConfiguracao()
            .then((result) => {
                setDiasConsultaRiscoAlto(result.dias_consulta_risco_alto)
                setDiasConsultaRiscoMedio(result.dias_consulta_risco_medio)
                setDiasConsultaRiscoBaixo(result.dias_consulta_risco_baixo)
                setDiasConsultaRiscoZero(result.dias_consulta_risco_zero)
                setAlertaAntecedenciaConsulta(result.alerta_antecedencia_consulta)
                setNotificarNovamenteApos(result.notificar_novamente_apos)
                setAfericaoSisLimite(result.afericao_sis_limite)
                setAfericaoDiaLimite(result.afericao_dia_limite)
                setDiasAlertaProximidadeConsulta(result.dias_alerta_proximidade_consulta)
                setNomeClinica(result.nome_clinica)
                setLogo(result.foto)
                setTelefone(result.telefone)
                setWhatsapp(result.whatsapp)
                setEmail(result.email)
                setCep(result.cep)
                setLogradouro(result.logradouro)
                setNumero(result.numero)
                setBairro(result.bairro)
                setComplemento(result.complemento)
                setCidade(result.cidade)
                setUf(result.uf)
                setLoadingVisible(false)
            })
    }, [])
    return (        
        <>
            <Loading visible={loadingVisible} />
            <PageDefault>           
                <FormDataContainer 
                    visibleBlocking={visibleBlockingPanel}
                    setVisibleBlocking={setVisibleBlockingPanel}
                    title="Dados Clínica">       
                     <form onSubmit={(e) => {e.preventDefault()}} className={ 'formData formMedico formConfig' }>
                     <div className="">
                            <div className='containerInputlogo'>                                 
                                <UploadArea 
                                    onUpload={ handleUpload } 
                                    uploadedPhoto={ 'data:image/png;base64, ' + (logo || '') } 
                                />
                            </div>
                            <div className=''>                                 
                                <InputText  
                                    label='Nome'
                                    value={ nomeClinica }
                                    onChange={(e) => {
                                        setNomeClinica(e.target.value)
                                    }} 
                                    containerstyle='input' />
                                    <div className="col-2">                        
                                <InputText 
                                    value={ telefone }
                                    onChange={(e) => {
                                        setTelefone(e.target.value)
                                    }}
                                    containerstyle="input-col" 
                                    label="Telefone" 
                                    name="telefone" />
                                <InputText 
                                    value={ whatsapp }
                                    onChange={(e) => {
                                        setWhatsapp(e.target.value)
                                    }}
                                    containerstyle="input-col" 
                                    type="text"
                                    label="Whatsapp" 
                                    name="whatsapp" />
                            </div>
                            <InputText
                                required     
                                value={ email }
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                containerstyle="input" 
                                type="email"
                                label="Email" 
                                name="email" />
                            
                            <div className="col-2"> 
                                <InputText 
                                    value={ cep }
                                    onChange={(e) => {
                                        setCep(e.target.value)
                                    }}
                                    onfocusout={handleConsultaCEP()}
                                    containerstyle="col-30-percent" 
                                    label="CEP" 
                                    id="cep"
                                    name="cep" />
                                <InputText 
                                    value={ logradouro }
                                    onChange={(e) => {
                                        setLogradouro(e.target.value)
                                    }}
                                    containerstyle="input-col"                             
                                    label="Logradouro" 
                                    name="logradouro" />   
                            </div>  
                            <div className="col-2"> 
                                <InputText 
                                    value={ numero }
                                    onChange={(e) => {
                                        setNumero(e.target.value)
                                    }}
                                    containerstyle="col-30-percent" 
                                    label="Número" 
                                    id="numero"
                                    name="numero" />
                                <InputText 
                                    value={ complemento }
                                    onChange={(e) => {
                                        setComplemento(e.target.value)
                                    }}
                                    containerstyle="input-col"                             
                                    label="Complemento" 
                                    name="complemento" />   
                            </div> 
                            <InputText 
                                    value={ bairro }
                                    onChange={(e) => {
                                        setBairro(e.target.value)
                                    }}
                                    containerstyle="input" 
                                    label="Bairro" 
                                    id="bairro"
                                    name="bairro" />
                            <div className="col-2"> 
                                <InputText 
                                    value={ cidade }
                                    onChange={(e) => {
                                        setCidade(e.target.value)
                                    }}
                                    containerstyle="input-col" 
                                    label="Cidade" 
                                    id="cidade"
                                    name="cidade" />
                                <InputText 
                                    value={ uf }
                                    onChange={(e) => {
                                        const newUF = onlyLetters(String(e.target.value).toUpperCase())
                                        setUf(newUF)
                                    }}
                                    containerstyle="col-20-percent-right uf"                             
                                    label="UF" 
                                    name="uf" />   
                            </div> 
                            </div>
                        </div>
                    </form>
                </FormDataContainer>   
                <div className="mainContent">
                <Navigator /> 
                    <div className="containerConfiguracao">               
                        
                    {/* </div> */}
                    {/* <div className="containerConfiguracao"> */}
                        {/* <h4>Outras Configurações</h4> */}
                        <div className="contentConfiguracao">
                        <div className="diretorio">
                            <h3> {  'Configurações > Clínica' }</h3>
                        </div>                           

                            {/*<div className="itemConfiguracao">          
                                Lembrar/confirmar a consulta de um paciente com  
                                <InputText 
                                    value={ alertaAntecedenciaConsulta }
                                    onChange={(e) => {
                                        setAlertaAntecedenciaConsulta(e.target.value)
                                    }}
                                    containerstyle='inputConfig' /> horas de antecedência.                 
                            </div>*/}

                            {/* <div className="itemConfiguracao">        
                                Valor limite de pressão arterial média (final de controle em domicílio):
                                <InputText  
                                    value={ afericaoSisLimite }
                                    onChange={(e) => {
                                        setAfericaoSisLimite(e.target.value)
                                    }}
                                    containerstyle='inputConfig' 
                                /> / 
                                <InputText 
                                    value={ afericaoDiaLimite }
                                    onChange={(e) => {
                                        setAfericaoDiaLimite(e.target.value)
                                    }}
                                    containerstyle='inputConfig' 
                                />                
                            </div> */}

                            {/*<div className="itemConfiguracao">  
                                Notificar novamente o usuario caso a mesma não for avaliada em  
                                
                                <InputText 
                                    value={ notificarNovamenteApos }
                                    onChange={(e) => {
                                        setNotificarNovamenteApos(e.target.value)
                                    }}
                                    containerstyle='inputConfig' 
                                /> horas.                   
                                </div>*/}

                            <div className="itemConfiguracao">  
                                Alertar previsão de retorno do paciente com antecedência de:                                 
                                <InputText 
                                    value={ diasAlertaProximidadeConsulta }
                                    onChange={(e) => {
                                        setDiasAlertaProximidadeConsulta(e.target.value)
                                    }}
                                    containerstyle='inputConfig' 
                                /> dias.                   
                            </div>
                        </div>
                    </div>
                <div className="btnProntuario overlay90">
                    <PrimaryButton 
                        containerstyle='btnGravarConfig' 
                        onClick={alterarConfiguracaoClinica}><FaCheck />  Salvar
                    </PrimaryButton>
                </div>      
                </div>  
            </PageDefault>
        </>
    )
}

export default Configuracao