import React from "react";
import "./style.css";

const CheckBox = ({
  name,
  label,
  onCheck,
  checked,
  containerstyle,
  ...rest
}) => {
  return (
    <div className={"checkboxComponent " + containerstyle}>
      <input
        {...rest}
        id={name}
        checked={checked}
        onChange={onCheck}
        type="checkbox"
      ></input>
      <label htmlFor={name} className="checkboxComponentLabel">
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
