import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function listarAgendamentos() {
    try {
        const response = await api.get('agendamento',{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function cadastrarAgendamento(agendamento) {
    try {
        const response = await api.post('agendamento', agendamento,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function alterarAgendamento(agendamento) {
    try {
        const response = await api.put('agendamento', agendamento,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function cancelarAgendamento(id) {
    try {
        const response = await api.delete('agendamento/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function trocarStatusAgendamento(id) {
    try {
        const response = await api.put(`agendamento/status/${id}`, {},{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function buscarAgendaPaciente() {
    try {
        const response = await api.get('agenda',{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

export { listarAgendamentos, cadastrarAgendamento,    
         alterarAgendamento, cancelarAgendamento,
         trocarStatusAgendamento, buscarAgendaPaciente }

