import React, { useState } from "react";
import "./style.css";
import SecondaryButton from "../../../components/formComponents/secondaryButton";
import {
  STEP_CLOSE_MODAL,
  usePatientRecordContext,
} from "../../../context/patientRecordContext";
import { FaCheck, FaTimes } from "react-icons/fa";
import PrimaryButton from "../../../components/formComponents/primaryButton";
import { useHistory } from "react-router-dom";
import BasicData from "./components/basicData";
import AdditionalInformation from "./components/additionalInformation";
import {
  alterarPacientes,
  cadastrarPacientes,
} from "../../../services/pacientes";

const PatientCreate = () => {
  const history = useHistory();
  const {
    calculatorRiskData,
    setStepper,
    patientCreateData,
    clearPatientRecordContext,
    resultRiskData,
  } = usePatientRecordContext();
  const [activeTab, setActiveTab] = useState(1);
  const [stateDoc, setStateDoc] = useState("");

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleCreate = async () => {
    const dataNascimentoString = String(patientCreateData?.dataNascimento);
    const partesData = dataNascimentoString?.split("/");
    const dataNascimento = new Date(
      partesData[2],
      partesData[1] - 1,
      partesData[0],
    );

    try {
      const newDateString = String(resultRiskData?.prazoConsulta);
      const partesData = newDateString?.split("/");
      const prazoConsulta = new Date(
        partesData[2],
        partesData[1] - 1,
        partesData[0],
      );
      const data = {
        nome: patientCreateData?.nome,
        sexo: patientCreateData?.sexo,
        documento: patientCreateData?.documento,
        dh_ultima_consulta: new Date(),
        whatsapp: String(patientCreateData?.whatsapp)
          .replace(" ", "")
          .replace("-", "")
          .replace("(", "")
          .replace(")", "")
          .trim(),
        telefone: String(patientCreateData?.telefone)
          .replace(" ", "")
          .replace("-", "")
          .replace("(", "")
          .replace(")", "")
          .trim(),
        dt_nascimento: dataNascimento,
        ...(patientCreateData?.cep && { cep: patientCreateData.cep }),
        ...(patientCreateData?.logradouro && {
          logradouro: patientCreateData.logradouro,
        }),
        ...(patientCreateData?.numero && { numero: patientCreateData.numero }),
        ...(patientCreateData?.bairro && { bairro: patientCreateData.bairro }),
        ...(patientCreateData?.complemento && {
          complemento: patientCreateData.complemento,
        }),
        ...(patientCreateData?.cidade && { cidade: patientCreateData.cidade }),
        ...(patientCreateData?.uf && { uf: patientCreateData.uf }),
        ...(patientCreateData?.id_medico_responsavel && {
          id_medico_responsavel: patientCreateData.id_medico_responsavel,
        }),
        ...(patientCreateData?.convenio && {
          convenio: patientCreateData.convenio,
        }),
        ...(patientCreateData?.numeroInscricaoConvenio && {
          numero_inscricao_convenio: patientCreateData.numeroInscricaoConvenio,
        }),
        login: "",
        prontuario: { ...resultRiskData, ...calculatorRiskData, prazoConsulta },
      };
      await cadastrarPacientes(data);

      clearPatientRecordContext();
      setStepper(STEP_CLOSE_MODAL);
      history.push("/paciente");
    } catch (e) {
      console.error("error create patient");
    }
  };

  return (
    <div className="tabs-container">
      <div className="tab-header">
        <div
          className={activeTab === 1 ? "tab active" : "tab"}
          onClick={() => handleTabClick(1)}
        >
          Dados Básicos
        </div>
        <div
          className={activeTab === 2 ? "tab active" : "tab"}
          onClick={() => handleTabClick(2)}
        >
          Informações Adicionais
        </div>
      </div>
      <div className="tab-content">
        <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`}>
          <BasicData stateDoc={stateDoc} setStateDoc={setStateDoc} />
        </div>
        <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`}>
          <AdditionalInformation />
        </div>
      </div>
      <div className="tab-footer">
        <PrimaryButton
          disabled={
            !patientCreateData?.nome ||
            !patientCreateData?.dataNascimento ||
            !patientCreateData?.sexo
          }
          onClick={handleCreate}
          name="btnPacientCreate"
          text="Cadastrar"
          id="btnPacientCreate"
        >
          <FaCheck />
        </PrimaryButton>
        <SecondaryButton
          type="submit"
          onClick={() => {
            clearPatientRecordContext();
            setStepper(STEP_CLOSE_MODAL);
          }}
          text="Fechar"
        >
          <FaTimes />
        </SecondaryButton>
      </div>
    </div>
  );
};

export default PatientCreate;
