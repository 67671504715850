import React, { useState, useEffect } from 'react'
import SimpleButton from '../../../../components/formComponents/simpleButton'
import SecondaryButton from '../../../../components/formComponents/secondaryButton'
import PrimaryButton from '../../../../components/formComponents/primaryButton'
import InputSelect from '../../../../components/formComponents/inputSelect'
import Radio from '../../../../components/formComponents/radioGroup'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';
import { FaCheck, FaTimes, FaTrashAlt } from 'react-icons/fa'
import InputText from '../../../../components/formComponents/inputText'
import DataList from '../../../../components/dataList'
import Modal from '../../../../components/modal'
import Loading from '../../../../components/loading'

import { listarTemplates } from '../../../../services/templateNotificacoes'
import { cadastrarNotificacaoPesoa, alterarStatusNotificacaoPesoa, 
         excluirNotificacaoPesoa, listarNotificacaoPesoa,
         listarRespostasPessoa } from '../../../../services/notificacoes'

import './styles.css'
function NotificacoesPaciente({ content, visible, onClick, close }) {
   const tipoControleOptions = [
        { id: 'unico', label: 'Único', value: 'unico' },
        { id: 'intervalo', label: 'Em um intervalo de tempo', value: 'intervalo' },
        { id: 'indefinido', label: 'Tempo indeterminado', value: 'indefinido' },

    ]

    const [loadingVisible, setLoadingVisible] = useState(false)
    const [listaTemplates, setListaTemplates] = useState([])
    const [listaNotificacaoPessoa, setListaNotificacaoPessoa] = useState([])
    
    const [modelo, setModelo] = useState('unico')
    const [periodicidade, setPeriodicidade] = useState('')
    const [datahoraInicio, setDatahoraInicio] = useState('')
    const [datahoraFinal, setDatahoraFinal] = useState('')
    const [hora, setHora] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [idTemplate, setIdTemplate] = useState('')
    const [listaRespostaPessoa, setListaRespostaPessoa] = useState([])
    
    
    useEffect(() => {        
        listarTemplates()
            .then((response) => {
                const lista  = []
                lista.push({ label: 'Selecione um template', value: -1 })
                response.map((item) => {
                    lista.push(
                        { 
                            label: item.titulo, 
                            value: item.id,
                            periodicidade: item.periodicidade,
                            hora_notificacao: item.hora_notificacao,
                            data_hora_vigencia_inicial: item.data_hora_vigencia_inicial,
                            data_hora_vigencia_final: item.data_hora_vigencia_final,
                            unico: item.unico,
                            mensagem: item.mensagem
                        }
                    )
                })
                setListaTemplates(lista)
                setIdTemplate(lista[0].value)
            })
    }, [])

    useEffect(() => {
        handleCarregarListaNotificacao()
        handleCarregarListaRespostas()
    }, [visible])

    function formatDate(data) {
        if (!data) {
            return ''
        }
        const dataInicial = new Date(data);
        const dia = ("0" + dataInicial.getDate()).slice(-2);
        const mes = ("0" + (dataInicial.getMonth() + 1)).slice(-2);  
        return dataInicial.getFullYear()+"-"+(mes)+"-"+(dia);
    }

    useEffect(() => {
        const filter = listaTemplates.filter((item) => item.value == idTemplate)
        if (filter[0]) {
            setPeriodicidade(filter[0].periodicidade)
            setHora(filter[0].hora_notificacao)
            setDatahoraInicio(formatDate(filter[0].data_hora_vigencia_inicial))
            setDatahoraFinal(formatDate(filter[0].data_hora_vigencia_final))
            setModelo(filter[0].unico ? 'unico' : 'intervalo')
            setMensagem(filter[0].mensagem)
        }
    }, [idTemplate])

    function handleIncluirNotificacao() {
        if (!idTemplate) return
        if (idTemplate <= 0) return
        setLoadingVisible(true)
        const notificacao = { 
            id_paciente: content.paciente, 
            unico: (modelo == 'unico'),
            periodicidade,
            data_hora_vigencia_inicial: datahoraInicio,
            data_hora_vigencia_final: (modelo == 'unico') ? datahoraInicio : datahoraFinal,
            hora_notificacao: hora,
            id_template: idTemplate,
            id_pessoa: content.id_pessoa }

        cadastrarNotificacaoPesoa(notificacao)
            .then((result) => {      
                handleCarregarListaNotificacao()  
                setPeriodicidade('')
                setHora('')
                setDatahoraInicio('')
                setDatahoraFinal('')
                setModelo('intervalo')    
                setIdTemplate(-1)
                setLoadingVisible(false)
            })
            .catch((erro) => {
                console.log('erro', erro)
                setLoadingVisible(false)
            })
    }

    function handleDeletarNotificacao(id) {
        setLoadingVisible(true)
        excluirNotificacaoPesoa(id)
            .then(() => {
                setLoadingVisible(false)
                handleCarregarListaNotificacao()
            })
            .catch(() => {
                setLoadingVisible(false)                
            })
    }

    function handleChangeTipoControle(value) {
        setModelo(value)
        if (value == 'indefinido') {
            let hoje = new Date();
            let dia = ("0" + hoje.getDate()).slice(-2);
            let mes = ("0" + (hoje.getMonth() + 1)).slice(-2);  
            let data = hoje.getFullYear()+"-"+(mes)+"-"+(dia);
            let dataFinal = (hoje.getFullYear() + 1000)+"-"+(mes)+"-"+(dia);
            setDatahoraInicio(data)
            setDatahoraFinal(dataFinal)
        } else {
            setDatahoraInicio('')
            setDatahoraFinal('')
        }
    }

    function handleCarregarListaRespostas() {
        if (!content.id_pessoa) return
        listarRespostasPessoa(content.id_pessoa)
            .then((result) => {
                setListaRespostaPessoa(result)
            })
    }

    function handleCarregarListaNotificacao() {
        if (!content.id_pessoa) return
        listarNotificacaoPesoa(content.id_pessoa)
            .then((result) => {
                setListaNotificacaoPessoa(result)
            })
    }

    function handleChangeStatus(id) {
        setLoadingVisible(true)
        alterarStatusNotificacaoPesoa(id)
            .then(() => {
                setLoadingVisible(false)
                handleCarregarListaNotificacao()
            })
            .catch(() => {
                setLoadingVisible(false)
            })
    }

    const columnsRespostas = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Título",
            accessor: "titulo",
        },
        {
          Header: "Mensagem",
          accessor: "mensagem",
        },     
        {
            Header: "Notificado em",
            accessor: "data_notificacao"
        },
        // {
        //     Header: 'Respondida?',
        //     accessor: 'notificacao_respondida',
        //     Cell: row => (  
        //         <SimpleButton>
        //             { 
        //                 row.row.cells[4].value == true ? 
        //                 <FaCheck color='green' /> :
        //                 <FaTimes /> 
        //             }
        //         </SimpleButton>
        //     )
        // },
        {
            Header: "Data Resposta",
            accessor: "data_resposta"
        },
        {
            Header: "Resposta",
            accessor: "valor_resposta"
        },
    ]

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
          Header: "Criado em",
          accessor: "created_at",
        },
        {
          Header: "Notificação",
          accessor: "titulo",
        },
        {
          Header: "Repetir",
          accessor: "unico",
          Cell: row => (                
            <>{row.row.cells[3].value == true ? 'Unico' : 'Intervalo de dias'}</>
            )
        },
        {
            Header: "Validade",
            accessor: "validade"
          },
        {
            Header: 'Status',
            accessor: 'ativo',
            Cell: row => (  
                <SimpleButton onClick={() => { handleChangeStatus(row.row.cells[0].value)} }>
                    { 
                        row.row.cells[5].value == true ? 
                        <FaCheck color='green' /> :
                        <FaTimes /> 
                    }
                </SimpleButton>
            )
        },
        {
          Header: '',
          accessor: 'delete',
          Cell: row => (
          <SimpleButton 
            alt='Excluir cadastro'
            title='Excluir cadastro'
            buttonType='delete' onClick={() => { handleDeletarNotificacao(row.row.cells[0].value)} }><FaTrashAlt /></SimpleButton>
          )
        },      
       
      ]
     return (
        <>
        <Loading forced={true} visible={ loadingVisible } />
            <Modal style='modalFull' visible={ visible } >                
                <div className="modalContainer" style={{ width: '60%'}}> 
                    <div className='fecharModalTabControl'>
                        <button onClick={ () => { close() } }>X</button> 
                    </div>                   
                    <Tabs>
                        <TabList className='react-tabs-list'>
                            <Tab className='react-tabs selectedTab' role='tab'>                               
                                <h4 className="titleProntuario">
                                    Notificações do paciente
                                </h4>                                
                            </Tab>
                            <Tab className='react-tabs selectedTab' role='tab'> 
                                <h4 className="titleProntuario">
                                    Respostas
                                </h4> 
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <div>             
                                <div className="modalAfericoesContent">
                                    <div className="modalNotificacoesForm">
                                        <div className="inputContainerColumn">
                                            <div className='componentTipoControle'>
                                                <InputSelect 
                                                    name='listaTemplates' 
                                                    value={ idTemplate }
                                                    label="Template"  
                                                    onChange={(e) => {
                                                        setIdTemplate(e.target.value)
                                                    }}
                                                    options={ listaTemplates }
                                                    containerstyle="input" >                            
                                                </InputSelect>  
                                            </div>
                                            <div className={"inputTextContainer mensagemNotifContainer"}>
                                                <div>
                                                    <label>Mensagem</label>
                                                </div>
                                                <div className='inputContainer'>
                                                    <span className='textMessageNotification'> { mensagem } </span>               
                                                </div>            
                                            </div>
                                                            
                                            <div className='componentTipoControle'>
                                                <Radio 
                                                    containerstyle="input" 
                                                    name='tipoControle'
                                                    label='Tipo de controle'
                                                    orientation='horizontal' 
                                                    onSelect={(value) => { handleChangeTipoControle(value) }}
                                                    options={ tipoControleOptions } 
                                                />    
                                            </div>
                                            {
                                                modelo == 'unico' ?
                                                <div className='componentsUnico'>
                                                    <InputText 
                                                        containerstyle="col-20-percent " 
                                                        value={ datahoraInicio }
                                                        onChange={(e) => {
                                                            setDatahoraInicio(e.target.value)
                                                        }}
                                                        type="date"
                                                        label="Notificar em">
                                                    </InputText> 
                                                    <div className="text col-30-percent-right" >as: </div>
                                                        <InputText 
                                                            value={ hora }
                                                            onChange={(e) => {
                                                                setHora(e.target.value)                    
                                                            }}
                                                            containerstyle="col-10-percent" 
                                                            label="Hora" 
                                                            type="time"
                                                            name="horaNotificacao">                    
                                                    </InputText>
                                                </div>:
                                                <>
                                                    {
                                                        modelo == 'indefinido' ?
                                                        <>
                                                            <div className='componentsUnico'>
                                                            <div className="text col-30-percent-right" > Repetir a cada: </div>

                                                            <InputText 
                                                                value={ periodicidade }
                                                                onChange={(e) => {
                                                                    setPeriodicidade(e.target.value)                    
                                                                }}
                                                                containerstyle="col-5-percent" 
                                                                label="Intervalo" 
                                                                name="peridiocidades">                    
                                                            </InputText>                                                                
                                                            <div className="text col-5-percent" > dia(s)</div>
                                                            <div className="text col-5-percent" >as: </div>
                                                                    <InputText 
                                                                        value={ hora }
                                                                        onChange={(e) => {
                                                                            setHora(e.target.value)                    
                                                                        }}
                                                                        containerstyle="col-20-percent" 
                                                                        label="Hora" 
                                                                        type="time"
                                                                        name="horaNotificacao">                    
                                                                </InputText>
                                                                
                                                            </div>
                                                        </>:
                                                        <>
                                                        <div className='componentsIntervalo'>
                                                        <div className="text col-30-percent" > Repetir a cada: </div>
                                                        <InputText 
                                                            value={ periodicidade }
                                                            onChange={(e) => {
                                                                setPeriodicidade(e.target.value)                    
                                                            }}
                                                            containerstyle="col-5-percent" 
                                                            label="Intervalo" 
                                                            name="peridiocidades">                    
                                                        </InputText>
                                                        <div className="text col-5-percent" > dia(s)</div>
                                                        <div className="text col-5-percent" >as: </div>

                                                        <InputText 
                                                            value={ hora }
                                                            onChange={(e) => {
                                                                setHora(e.target.value)                    
                                                            }}
                                                            containerstyle="col-10-percent" 
                                                            label="Hora" 
                                                            type="time"
                                                            name="horaNotificacao">                    
                                                        </InputText>
                                                
                                                        <div className="text col-30-percent" >No período de:</div>
                                                        <div className="col-2">                                    
                                                            <InputText 
                                                                containerstyle="input-col" 
                                                                value={ datahoraInicio }
                                                                onChange={(e) => {
                                                                    setDatahoraInicio(e.target.value)
                                                                }}
                                                                type="date"
                                                            label="Data inicial">                                                                 
                                                            </InputText>
                                                            <InputText 
                                                                containerstyle="input-col" 
                                                                value={ datahoraFinal }
                                                                onChange={(e) => {
                                                                    setDatahoraFinal(e.target.value)
                                                                }}
                                                                type="date"
                                                            label="Data inicial">                                                                 
                                                            </InputText> 
                                                        </div>
                                                    </div>
                                                    </>
                                                    }
                                                    
                                                </>
                                            }
                                            
                                    
                                        </div>                                
                                        <SecondaryButton containerstyle='btnIncluirNotificacao' onClick={ handleIncluirNotificacao }>+ Incluir</SecondaryButton>
                                    </div>
                                    <div className="modalAfericoesGrid">
                                        <DataList 
                                            hideDirDescription={ true }
                                            stylecontainer='gridModal' 
                                            filter={<></>} 
                                            columns={columns} 
                                            data= {listaNotificacaoPessoa}>
                                        </DataList> 
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div>
                                <div className="modalAfericoesGrid">
                                    <DataList 
                                        hideDirDescription={ true }
                                        stylecontainer='gridModal' 
                                        filter={<></>} 
                                        columns={columnsRespostas} 
                                        data= {listaRespostaPessoa}>
                                    </DataList> 
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs> 
                </div>
            </Modal>
            <PrimaryButton onClick={ onClick } >Notificações do Paciente</PrimaryButton>
        </>
        
    )
}

export default NotificacoesPaciente