import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getToken } from "../../services/utils/token";
import InputSearch from "../../components/inputSearch";
import LogoAortic from "../../images/logo-aortic.png";
import ImgClinica from "../../images/clinica-image.png";
import { listarClinicas, setClinicaAtiva } from "../../services/clinicas";
import Loading from "../../components/loading";
import ModalAlert from "../../components/modalAlert";
import { logout } from "../../services/login";
import { FaPlusCircle } from "react-icons/fa";

import "./styles.css";
import CreateClinic from "./components/createClinic/createClinic";

function Clinicas() {
  const [listaClinicas, setListaClinicas] = useState([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [alertObject, setAlertObject] = useState({});
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [isShowCreateClinic, setIsShowCreateClinic] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (!getToken()) history.push("/");
    setLoadingVisible(true);
    listarClinicas()
      .then((result) => {
        setListaClinicas(result);
        setLoadingVisible(false);
      })
      .catch((err) => {
        setLoadingVisible(false);
      });
  }, []);

  function selecionarClinica(id, perfil) {
    setClinicaAtiva(id);

    if (!perfil) {
      const temp = listaClinicas;
      const [clinica] = temp.filter((value) => (value.id = id));
      perfil = clinica?.perfil || "";
    }
    if (perfil == "MEDICO") history.push("/paciente");
    else history.push("/agendamentos");
  }

  const handleCreateNewClinic = () => {
    setIsShowCreateClinic(true);
  };

  return (
    <>
      <ModalAlert
        visible={visibleAlert}
        setVisible={setVisibleAlert}
        title={alertObject.title}
        text={alertObject.text}
        width={alertObject.widht}
        onClickOK={alertObject.onClickOK}
        height={alertObject.height}
      />
      <Loading visible={loadingVisible} />
      <div
        className={
          listaClinicas.length == 0
            ? "containerClinicasEmpty"
            : "containerClinicas"
        }
      >
        {!isShowCreateClinic ? (
          <div
            className={
              listaClinicas.length == 0
                ? "contentClinicasEmpty"
                : "contentClinicas"
            }
          >
            <h4 className="titulo">
              Selecione a clínica que deseja se conectar
            </h4>
            <div className="containerInputSearch">
              {/* <InputSearch ></InputSearch>     */}
            </div>

            <div className="containerListaClinicas">
              {listaClinicas
                ? listaClinicas.map((item) => {
                    return (
                      <div
                        value={item.id}
                        onClick={(e) => {
                          selecionarClinica(
                            e.currentTarget.attributes["value"].value,
                          );
                        }}
                        key={item.id}
                        className="cardClinica"
                      >
                        {item.foto ? (
                          <div
                            style={{
                              width: "40px",
                              height: "40px",
                              margin: "10px",
                              backgroundImage:
                                "url('data:image/png;base64, " +
                                item.foto +
                                "')",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          >
                            {" "}
                          </div>
                        ) : (
                          <img src={ImgClinica} alt={item.nome} />
                        )}
                        <h5>{item.nome}</h5>
                      </div>
                    );
                  })
                : ""}
              <div
                onClick={handleCreateNewClinic}
                key={"createClinic"}
                className="cardClinica"
              >
                <div
                  style={{
                    width: "70px",
                    height: "80px",
                    margin: "10px",
                  }}
                >
                  <FaPlusCircle style={{ fontSize: 70 }} />
                </div>
                <h5>Nova Clínica</h5>
              </div>
            </div>
          </div>
        ) : (
          <CreateClinic setIsShowCreateClinic={setIsShowCreateClinic} />
        )}
        <div className="logoAorticClinica">
          <img src={LogoAortic} alt="" />
        </div>
      </div>
    </>
  );
}

export default Clinicas;
