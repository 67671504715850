import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageDefault from '../../components/pageDefault'
import HistoricoNotificacao from './historicoNotificacao'
import MenuMensagem from './menuNotificacao'
import { NotificacaoProvider } from './context'
import Navigator from '../../components/navigator' 

function Notificacoes() {
    const [dadosConversaSelecionada, setDadosConversaSelecionada] = useState([])
    const [ idDestinatario, setIdDestinatario] = useState(0)
    const [ nomeDest, setNomeDest] = useState('')
    const [atualizar, setAtualizar] = useState(false)
    const [indexTab, setIndexTab] = useState(0)
    const isMobile = window.innerWidth < 800
    const contexto = {
        estado: 'I',
        atualizarGrade: (atualizar) => {
            setAtualizar(atualizar)
        },
        precisaAtualizar: atualizar,
        destinatario: idDestinatario,
        nomeDestinatario: nomeDest,
        atualizaNomeDestinatario: (novo) =>{
            setNomeDest(novo)
        },
        voltarTab: () => {
            setIndexTab(0)
        },
        setDestinatario: (newDestinatario) => {
            setIdDestinatario(newDestinatario)
        },
        data: dadosConversaSelecionada,
        setData: (newData) => {
            setDadosConversaSelecionada(newData)
            if (indexTab === 0) setIndexTab(1)
            else setIndexTab(0)
        },
    }
        
    return (
        
        <PageDefault>     
                 
            <NotificacaoProvider value={ contexto }> 
                { 
                    isMobile ?
                    <>
                        <Navigator />   
                        <Tabs selectedIndex={indexTab}>
                            <TabList  style={ { display: 'none' } }>
                                <Tab></Tab>
                                <Tab></Tab>
                            </TabList>
                            <TabPanel>
                                <MenuMensagem /> 
                            </TabPanel>
                            <TabPanel>
                                <HistoricoNotificacao />
                            </TabPanel>
                        </Tabs> 
                    </>
                    : 
                    <>
                        <MenuMensagem /> 
                        <HistoricoNotificacao />
                    </>
                }
                
            </NotificacaoProvider>                       
        </PageDefault>
    )
}

export default Notificacoes