import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function listarTemplates() {
    try {
        const response = await api.get('templateNotificacao',{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }

}

async function alterarTemplate(template) {
    try {
        const response = await api.put('templateNotificacao', template,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function cadastrarTemplate(template) {
    try {
        const response = await api.post('templateNotificacao', template,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function buscarTemplate(id) {
    try {
        const response = await api.get('templateNotificacao/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function deletarTemplate(id) {
    try {
        const response = await api.delete('templateNotificacao/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

export { listarTemplates, alterarTemplate, cadastrarTemplate, buscarTemplate, deletarTemplate }