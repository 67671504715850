import React from 'react'

import './style.css'

function Loading({visible, forced}) {
    return (
        <div className={ (forced ? 'forced-loading-background ' : "loading-background") + (visible ? '' : ' invisible')}>
                <div className="loader"></div>           
        </div>
    )
}

export default Loading