import React, { useState, useEffect } from "react";
import SimpleButton from "../../../../components/formComponents/simpleButton";
import SecondaryButton from "../../../../components/formComponents/secondaryButton";
import { FaEdit, FaTrashAlt, FaCheck, FaTimes } from "react-icons/fa";
import InputText from "../../../../components/formComponents/inputText";
import DataList from "../../../../components/dataList";
import Modal from "../../../../components/modal";
import Loading from "../../../../components/loading";
import {
  calcularIMC,
  cadastrarExameFisico,
  listarExameFisico,
  deletarExameFisico,
} from "../../../../services/exameFisico";
import "./styles.css";

function ExameFisico({ content }) {
  const [modalVisivel, setModalVisivel] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [listaExamesFisicos, setListaExamesFisicos] = useState([]);
  const [altura, setAltura] = useState("");
  const [peso, setPeso] = useState("");
  const [imc, setImc] = useState("");
  const [classificacao, setClassificacao] = useState("");

  function handleIncluirExameFisico() {
    setLoadingVisible(true);
    const exame = {
      id_paciente: content.paciente,
      altura: Number(altura) * 100,
      peso: Number(peso),
      imc,
      classificacao,
    };
    cadastrarExameFisico(exame)
      .then((result) => {
        content.refresh();
        handleCarregarListaExames();
        setAltura("");
        setPeso("");
        setImc("");
        setClassificacao("");
        setLoadingVisible(false);
      })
      .catch((erro) => {
        setLoadingVisible(false);
      });
  }

  function handleDeletarExame(id) {
    setLoadingVisible(true);
    deletarExameFisico(id)
      .then(() => {
        //content.refresh()
        handleCarregarListaExames();
        setLoadingVisible(false);
      })
      .catch(() => {
        setLoadingVisible(false);
      });
  }

  function handleCarregarListaExames() {
    listarExameFisico(content.paciente).then((result) => {
      setListaExamesFisicos(result);
    });
  }

  function handleCarregarExameFisico() {
    setModalVisivel(true);
    handleCarregarListaExames();
  }

  useEffect(() => {
    if (altura && peso) {
      const dadosIMC = calcularIMC(peso, altura);
      setImc(dadosIMC.imc);
      setClassificacao(dadosIMC.classificacao);
    }
  }, [altura, peso]);

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Peso",
      accessor: "peso",
    },
    {
      Header: "Altura",
      accessor: "altura",
    },
    {
      Header: "IMC",
      accessor: "imc",
    },
    {
      Header: "Classificação",
      accessor: "classificacao",
    },
    {
      Header: "Inclusao",
      accessor: "data_exame",
    },
    {
      Header: "Ativo",
      accessor: "ativo",
      Cell: (row) => (
        <>
          {row.row.cells[6].value == true ? (
            <FaCheck color="green" />
          ) : (
            <FaTimes />
          )}
        </>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (row) => (
        <SimpleButton
          alt="Excluir cadastro"
          title="Excluir cadastro"
          buttonType="delete"
          onClick={() => {
            handleDeletarExame(row.row.cells[0].value);
          }}
        >
          <FaTrashAlt />
        </SimpleButton>
      ),
    },
  ];
  return (
    <>
      <Loading forced={true} visible={loadingVisible} />
      <Modal style="modalFull" visible={modalVisivel}>
        <div className="modalContainer">
          <div>
            <div className="modalHeader">
              <h4 className="titleProntuario">Exame Físico</h4>
              <div className="fecharModal">
                <button
                  onClick={() => {
                    setModalVisivel(!modalVisivel);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <div className="modalAfericoesContent">
              <div className="modalAfericoesGrid">
                <DataList
                  hideDirDescription={true}
                  stylecontainer="gridModal"
                  filter={<></>}
                  columns={columns}
                  data={listaExamesFisicos}
                ></DataList>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="cardContainer">
        <h4 className="titleProntuario">Exame físico</h4>
        <div className="cardProntuarioBackground">
          <div className="modalAfericoesForm">
            <div className="inputContainer">
              <InputText
                placeholder="0.00"
                value={altura}
                onChange={(e) => {
                  const altura = String(e.target.value).replace(",", ".");
                  if (String(altura).length > 4) return;
                  if (Number(altura) > 90)
                    setAltura(Number(altura / 100).toFixed(2));
                  else setAltura(altura);
                }}
                containerstyle="inputAfericao"
                label="Altura"
                name="altura"
              />
              <InputText
                placeholder="0.00"
                value={peso}
                onChange={(e) => {
                  setPeso(e.target.value);
                }}
                containerstyle="inputAfericao"
                label="Peso"
                name="peso"
              />
              <InputText
                value={imc}
                containerstyle="inputAfericao"
                label="IMC"
                name="imc"
              />
              <InputText
                value={classificacao}
                containerstyle="inputAfericao"
                label="Classificacao"
                name="classificacao"
              />
            </div>
            <SecondaryButton onClick={handleIncluirExameFisico}>
              + Incluir
            </SecondaryButton>
          </div>
          <div className="cardProntuario">
            <ul>
              <li>
                <span className="titleCard">Altura:</span>{" "}
                {content.altura ? `${(content.altura / 100).toFixed(2)} m` : ""}{" "}
              </li>
              <li>
                <span className="titleCard">Peso:</span>{" "}
                {content.peso && `${content.peso} kg`}{" "}
              </li>
              <li>
                <span className="titleCard">IMC:</span>{" "}
                {content.imc && `${content.imc} kg/m²`}{" "}
              </li>
              <li>
                <span className="titleCard">Classificação:</span>{" "}
                {content.classificacao}{" "}
              </li>
            </ul>
            <SimpleButton
              onClick={() => {
                handleCarregarExameFisico();
              }}
            >
              <FaEdit />
            </SimpleButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExameFisico;
