import api from "../api";
import { getToken } from "../utils/token";
import { logout } from "../login";
import { getClinicaAtiva } from "../clinicas";

async function listarRecepcionistas() {
  try {
    const response = await api.get("recepcionista", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function pesquisarRecepcionistas(filter) {
  try {
    let query = "";
    if (filter) query = "?filter=" + filter;
    const response = await api.get("recepcionista" + query, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function alterarRecepcionistas(recepcionista) {
  try {
    const response = await api.put("recepcionista", recepcionista, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function deletarRecepcionistas(id) {
  try {
    const response = await api.delete("recepcionista/" + id, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function cadastrarRecepcionistas(recepcionista) {
  try {
    const response = await api.post("recepcionista", recepcionista, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function buscarRecepcionistas(id) {
  try {
    const response = await api.get("recepcionista/" + id, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

export {
  listarRecepcionistas,
  alterarRecepcionistas,
  deletarRecepcionistas,
  cadastrarRecepcionistas,
  buscarRecepcionistas,
  pesquisarRecepcionistas,
};
