import React, { useState, useEffect } from 'react'
import SimpleButton from '../../../../components/formComponents/simpleButton'
import SecondaryButton from '../../../../components/formComponents/secondaryButton'
import { FaEdit, FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa'
import InputText from '../../../../components/formComponents/inputText'
import DataList from '../../../../components/dataList'
import Modal from '../../../../components/modal'
import Loading from '../../../../components/loading'
import { cadastrarAfericao, listarAfericoes, deletarAfericao } from '../../../../services/afericoes'
import './styles.css'

function Afericoes({ content }) {
     const [modalVisivel, setModalVisivel] = useState(false)
     const [loadingVisible, setLoadingVisible] = useState(false)
     const [listaAfericoes, setListaAfericoes] = useState([])
     const [sis, setSis] = useState('')
     const [dia, setDia] = useState('')
     const [fc, setFc] = useState('')
     const [sat, setSat] = useState('')
     const [glicemia, setGlicemia] = useState('')


     function handleIncluirAfericao() {
        setLoadingVisible(true)
        const afericao = { id_paciente: content.paciente, sis, dia, fc, sat, glicemia }
        cadastrarAfericao(afericao)
            .then((result) => {
                content.refresh()
                handleCarregarAfericoes() 
                setSis('')
                setDia('')
                setLoadingVisible(false)
            })
            .catch((erro) => {
                setLoadingVisible(false)
            })
     }

     function handleDeletarAfericao(id) {
        setLoadingVisible(true)       
        deletarAfericao(id)
            .then((result) => {
                //content.refresh()
                handleCarregarAfericoes() 
                    .then(() => {
                        setLoadingVisible(false)
                    })
                    .catch(() => {
                        setLoadingVisible(false)
                    })                
            })
            .catch((erro) => {
                setLoadingVisible(false)
            })
     }
     function handleCarregarAfericoes() {
         listarAfericoes(content.paciente, 'CONSULTORIO')
            .then((result) => {
                setListaAfericoes(result)
            }) 
     }

     function handleAbrirModal() {
        handleCarregarAfericoes()
        setModalVisivel(!modalVisivel) 
     }

     function getClassificacao(dia, sis) {
        let grauSis = 0
        let grauDia = 0
        const grau = [
            "Não calculado",
            "PA ótima",
            "Pré-hipertensão",
            "HA estágio 1",
            "HA estágio 2",
            "HA estágio 3",
        ]
    
        if (sis <= 129)
            grauSis = 1    
        else if (sis >= 130 && sis <= 139)
            grauSis = 2
        else if (sis >= 140 && sis <= 159)
            grauSis = 3
        else if (sis >= 160 && sis <= 179)
            grauSis = 4
        else if (sis >= 180)
            grauSis = 5    
    
        if (dia <= 84)
            grauDia = 1            
        else if (dia >= 85 && dia <= 89)
            grauDia = 2
        else if (dia >= 90 && dia <= 99)
            grauDia = 3
        else if (dia >= 100 && dia <= 109)
            grauDia = 4
        else if (dia >= 110)
            grauDia = 5
        const grauFinal = grauDia > grauSis ? grauDia : grauSis
        return grau[grauFinal]
    }

     const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
          Header: "SIS",
          accessor: "sis",
        },
        {
          Header: "DIA",
          accessor: "dia",
        },
        {
            Header: "FC (bpm)",
            accessor: "fc",
        },
        {
            Header: "Sat (%)",
            accessor: "sat",
        },
        {
            Header: "Glicemia (mg/dl)",
            accessor: "glicemia",
        },
        {
            Header: "Inclusao",
            accessor: "created_at"
        },
        {
            Header: "Classificação",
            accessor: "classificacao"
        },
        {
            Header: 'Ativo',
            accessor: 'ativo',
            Cell: row => (                
            <>{row.row.cells[8].value == true ? <FaCheck color='green' /> : <FaTimes /> }</>
            )
        },
        {
          Header: '',
          accessor: 'delete',
          Cell: row => (
          <SimpleButton 
            alt='Excluir cadastro'
            title='Excluir cadastro'
            buttonType='delete' onClick={() => {handleDeletarAfericao(row.row.cells[0].value)} }><FaTrashAlt /></SimpleButton>
          )
        },      
       
      ]
     return (
        <>
            <Loading forced={true} visible={ loadingVisible } />
            <Modal style='modalFull' visible={ modalVisivel }>
                <div className="modalContainer">
                    <div>
                        <div className="modalHeader">
                            <h4 className="titleProntuario">
                                Sinais vitais
                            </h4> 
                            <div className='fecharModal'>
                                <button onClick={ () => { setModalVisivel(!modalVisivel) } }>X</button> 
                            </div>     
                        </div>
                        <div className="modalAfericoesContent">                            
                            <div className="modalAfericoesGrid">
                                <DataList hideDirDescription={ true } stylecontainer='gridModal' filter={<></>} columns={columns} data= {listaAfericoes}></DataList> 
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='cardContainer'>
                <h4 className='titleProntuario'>Sinais vitais</h4>            
                <div><span className='subTitleProntuario'>Última aferição:</span> <span class='common-text'>{ content.ultima_afericao }</span> </div>
                <div className="cardProntuarioBackground">
                    <div className="modalAfericoesForm">
                        <div className="inputContainer">
                            <InputText 
                                value={ sis }
                                onChange={(e) => {
                                    setSis(e.target.value)
                                }}
                                containerstyle='inputAfericao'
                                label="SIS" 
                                name="sis" 
                            />
                            <InputText 
                                value={ dia }
                                onChange={(e) => {
                                    setDia(e.target.value)
                                }}
                                containerstyle='inputAfericao'
                                label="DIA" 
                                name="dia" 
                            /> 
                                <InputText 
                                value={ fc }
                                onChange={(e) => {
                                    setFc(e.target.value)
                                }}
                                containerstyle='inputAfericao'
                                label="FC" 
                                name="fc" 
                            /> 
                                <InputText 
                                value={ sat }
                                onChange={(e) => {
                                    setSat(e.target.value)
                                }}
                                containerstyle='inputAfericao'
                                label="Sat" 
                                name="sat" 
                            /> 
                                <InputText 
                                value={ glicemia }
                                onChange={(e) => {
                                    setGlicemia(e.target.value)
                                }}
                                containerstyle='inputAfericao'
                                label="Glicemia" 
                                name="glicemia" 
                            /> 
                        </div>                                
                        <SecondaryButton onClick={ handleIncluirAfericao }>+ Incluir</SecondaryButton>
                    </div>
                    <div className="cardProntuario">      
                        <ul>      
                            <div className="double-columns">
                                <div>
                                    <li><span className='titleCard'>SIS:</span> { content.sis && `${content.sis} mmHg` } </li>
                                    <li><span className='titleCard'>DIA:</span> { content.dia && `${content.dia} mmHg`} </li>
                                    <li><span className='titleCard'>Classificação:</span> { content.dia && content.sis &&`${getClassificacao(content.dia, content.sis)}`}</li>
                                </div>
                                <div>
                                    <li><span className='titleCard'>FC:</span> { content.fc && `${content.fc} bpm` } </li>
                                    <li><span className='titleCard'>Sat:</span> { content.sat && `${content.sat} %`} </li>
                                    <li><span className='titleCard'>Glicemia:</span> { content.glicemia && `${content.glicemia} mg/dl` } </li>
                                </div>
                            </div>
                        </ul>    
                        <SimpleButton onClick={() => { handleAbrirModal() } }><FaEdit /></SimpleButton>
                    </div>            
                </div>
            </div>
        </> 
    )
}

export default Afericoes