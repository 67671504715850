import React from 'react'
import './style.css'

const RadioGroup = ({ name, label, options, orientation, onSelect, containerstyle, children,  ...rest}) => {
    
    return (    
        <div className={ 'radioContainer ' + containerstyle }> 
        <label for='hiddenRadio' id='labelRadio' className='radioGroupLabel'>{ label }</label>  
        <input id='hiddenRadio' type='hidden' />
        <div className="radioGroupComponent">
           {    
                options.map((item) => {
                    return (
                        <div>                            
                            <input onChange={(e) => { onSelect(e.target.value) }} type="radio" id={ item.id } name={ name } value={ item.value }></input>
                            <label for={ item.id }>{ item.label }</label>
                        </div>
                    )
                })
            }        
        </div>
                 
        </div>
    )
}

export default RadioGroup