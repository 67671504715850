import React, { useState } from 'react'
import PageDefaultAdm from '../pageDefaultAdm'
import Grid from './gridUsuarios'
import Form from './formUsuarios'
import { UsuariosAdmProvider } from './context'

function PageAdm() {
    const [dadosUsuarioSelecionado, setDadosUsuarioSelecionado] = useState({})
    const [atualizar, setAtualizar] = useState(false)
    const contexto = {
        estado: 'I',
        atualizarGrade: (atualizar) => {
            setAtualizar(atualizar)
        },
        precisaAtualizar: atualizar,
        data: dadosUsuarioSelecionado,
        setData: (newData) => {
            setDadosUsuarioSelecionado(newData)
        },
    }
    return (
        <div>
            <UsuariosAdmProvider value={ contexto }>
                <PageDefaultAdm 
                    formArea={{ title: 'Usuários', content: <Form /> }}
                    gridArea={<Grid />}
                />
            </UsuariosAdmProvider>
        </div>
    )
}

export default PageAdm