import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ativarUsuario  } from '../../services/usuarios'

function SmartLink() {
    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    const history = useHistory()
    
    useEffect(() => {
        ativarUsuario(query.get('c'), query.get('u'))
            .then((result) => {
                history.push('/clinicas')
            })
    }, [])
    return (
        <div></div>
    )
}

export default SmartLink