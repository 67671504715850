import React, { useState } from "react";
import UploadArea from "../../../../components/uploadArea";
import InputText from "../../../../components/formComponents/inputText";
import { onlyLetters } from "../../../../services/utils/onlyLetters";
import { consultarCEP } from "../../../../services/utils/cep";
import PrimaryButton from "../../../../components/formComponents/primaryButton";
import SecondaryButton from "../../../../components/formComponents/secondaryButton";
import { useHistory } from "react-router-dom";
import {
  cadastrarClinica,
  cadastrarClinicaToken,
  setClinicaAtiva,
} from "../../../../services/clinicas";
import { phoneMask } from "../../../../services/utils/mask";

const CreateClinic = ({ setIsShowCreateClinic }) => {
  const history = useHistory();

  const [nome, setNome] = useState("");
  const [logo, setLogo] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");

  function handleUpload(files) {
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
    reader.onload = function () {
      setLogo(btoa(reader.result));
    };
  }

  function handleConsultaCEP() {
    if (!cep) return;
    if (cep.length < 8) return;
    consultarCEP(cep)
      .then((dadosEndereco) => {
        setLogradouro(dadosEndereco.data.logradouro);
        setBairro(dadosEndereco.data.bairro);
        setCidade(dadosEndereco.data.localidade);
        setUf(dadosEndereco.data.uf);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleCreateClinic = async () => {
    try {
      const clinicCreated = await cadastrarClinicaToken({
        nome,
        logo,
        whatsapp: String(whatsapp)
          .replace(" ", "")
          .replace("-", "")
          .replace("(", "")
          .replace(")", "")
          .trim(),
        email,
        cep,
        logradouro,
        numero,
        bairro,
        complemento,
        cidade,
        uf,
        ativa: true,
      });
      const clinicId = clinicCreated.id;
      setClinicaAtiva(clinicId);
      history.push("/paciente");
    } catch (e) {
      console.log("error create clinic", e);
    }
  };

  return (
    <div style={{ borderRadius: 10, backgroundColor: "#fff" }}>
      <div className={"formData formMedico"}>
        <div className="">
          <div className="containerInputlogo">
            <UploadArea
              onUpload={handleUpload}
              uploadedPhoto={"data:image/png;base64, " + (logo || "")}
            />
          </div>
          <div className="">
            <InputText
              required
              label="Nome da clínica*"
              value={nome}
              onChange={(e) => {
                setNome(e.target.value);
              }}
              containerstyle="input"
            />
            <div className="col-2">
              <InputText
                value={cep}
                onChange={(e) => {
                  setCep(e.target.value);
                }}
                onBlur={handleConsultaCEP}
                containerstyle="col-30-percent"
                label="CEP*"
                id="cep"
                name="cep"
                required
              />
              <InputText
                value={logradouro}
                onChange={(e) => {
                  setLogradouro(e.target.value);
                }}
                containerstyle="input-col"
                label="Logradouro"
                name="logradouro"
              />
            </div>
            <div className="col-2">
              <InputText
                value={numero}
                onChange={(e) => {
                  setNumero(e.target.value);
                }}
                containerstyle="col-30-percent"
                label="Número"
                id="numero"
                name="numero"
              />
              <InputText
                value={complemento}
                onChange={(e) => {
                  setComplemento(e.target.value);
                }}
                containerstyle="input-col"
                label="Complemento"
                name="complemento"
              />
            </div>
            <InputText
              value={bairro}
              onChange={(e) => {
                setBairro(e.target.value);
              }}
              containerstyle="input"
              label="Bairro"
              id="bairro"
              name="bairro"
            />
            <div className="col-2">
              <InputText
                value={cidade}
                onChange={(e) => {
                  setCidade(e.target.value);
                }}
                containerstyle="input-col"
                label="Cidade*"
                id="cidade"
                name="cidade"
                required
              />
              <InputText
                value={uf}
                onChange={(e) => {
                  const newUF = onlyLetters(
                    String(e.target.value).toUpperCase(),
                  );
                  setUf(newUF);
                }}
                containerstyle="col-20-percent-right uf"
                label="UF*"
                name="uf"
                required
              />
            </div>
            <div className="col-2">
              <InputText
                value={whatsapp}
                onChange={(e) => {
                  const whatsappWithMask = phoneMask(e.target.value);
                  setWhatsapp(whatsappWithMask);
                }}
                containerstyle="input-col"
                type="text"
                label="Whatsapp"
                name="whatsapp"
              />
              <InputText
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                containerstyle="input"
                type="email"
                label="Email"
                name="email"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            margin: "20px 0",
          }}
        >
          <PrimaryButton
            style={{ fontSize: "16px" }}
            disabled={!nome || !cep || !uf}
            onClick={handleCreateClinic}
          >
            Criar Clínica
          </PrimaryButton>
          <SecondaryButton
            onClick={() => setIsShowCreateClinic(false)}
            style={{ fontSize: "16px" }}
          >
            Voltar
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateClinic;
