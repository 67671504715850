import React from 'react'
import './style.css'

const FormButton = ({ name, text, typeButton, containerstyle, children,   ...rest}) => {
    return (    
        <div className={ containerstyle + ' buttonContainer' }>            
            <button className= "primaryButton" id={ name } name={ name } { ...rest } >
              <div>{ children } </div> <div> <p>{ text }</p></div>
                
            </button>    
        </div>
    )
}

export default FormButton