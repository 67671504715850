import React, { useState } from "react";

import PageDefault from "../../components/pageDefault";
import FormDataContainer from "../../components/formDataContainer";
import FormRecepcionista from "./formRecepcionistas";
import GridRecepcionista from "./gridRecepcionistas";
import { RecepcionistaProvider } from "./context";
import "./style.css";
import ModalNewUser from "../../components/modalNewUser";

function Recepcionistas() {
  const [dadosRecepcionistaSelecionado, setDadosRecepcionistaSelecionado] =
    useState({});
  const [atualizar, setAtualizar] = useState(false);
  const [visibleBlockingPanel, setVisibleBlockingPanel] = useState(true);
  const [isShowModalNewUser, setIsShowModalNewUser] = useState(false);

  const contexto = {
    estado: "I",
    atualizarGrade: (atualizar) => {
      setAtualizar(atualizar);
    },
    precisaAtualizar: atualizar,
    data: dadosRecepcionistaSelecionado,
    setData: (newData) => {
      setDadosRecepcionistaSelecionado(newData);
    },
    blockingPanel: visibleBlockingPanel,
    setVisibleBlockingPanel: (isVisible) => {
      setVisibleBlockingPanel(isVisible);
    },
  };

  return (
    <PageDefault>
      <RecepcionistaProvider value={contexto}>
        <ModalNewUser
          visible={isShowModalNewUser}
          setIsShowModalNewUser={setIsShowModalNewUser}
          isRecepcionista
          isDoctor={false}
        />
        <FormDataContainer
          title={"Secretaria"}
          btnTitle="Novo Usuário"
          visibleBlocking={visibleBlockingPanel}
          setVisibleBlocking={setVisibleBlockingPanel}
          setIsShowModalNewUser={setIsShowModalNewUser}
          isCreateUserByMail
        >
          <FormRecepcionista />
        </FormDataContainer>
        <GridRecepcionista />
      </RecepcionistaProvider>
    </PageDefault>
  );
}

export default Recepcionistas;
