import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function listarConversas() {
    try {
        const response = await api.get('mensagem', {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarMensagens(id) {
    try {
        const response = await api.get('mensagem/' + id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function deletarMensagens(id) {
    try {
        const response = await api.delete('mensagem/' + id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}


async function novaConversa(conversa) {
    try {
        const response = await api.post('mensagem', conversa, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function novaMalaDireta(malaDireta) {
    try {
        const response = await api.post('maladireta', malaDireta, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function novaMensagem(conversa) {
    try {
        const response = await api.put('mensagem', conversa, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

export { listarConversas, novaConversa, listarMensagens, novaMensagem, deletarMensagens, novaMalaDireta }