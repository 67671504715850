import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getToken } from "../../services/utils/token";
import { getClinicaAtiva } from "../../services/clinicas";
import { validarRota } from "../../services/usuarios";
import "./style.css";

function PageDefault(props) {
  const whiteList = [
    "/menu",
    "/clinicas",
    "/perfil",
    "/ativacao",
    "/prontuario",
    "/recepcao",
  ];
  let diffLocation = true;
  const [usuarioLogado, setUsuarioLogado] = useState("");
  const [clinicaLogado, setClinicaLogada] = useState("");
  const [messageRoute, setMessageRoute] = useState(false);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (!getToken()) {
      history.push("/");
      return;
    }
    if (!getClinicaAtiva()) {
      history.push("/clinicas");
      return;
    }
    whiteList.map((item) => {
      if (item == location.pathname) diffLocation = false;
    });
    if (location.pathname == "/mensagens") setMessageRoute(true);
    if (diffLocation == true) {
      validarRota(
        location.pathname.substring(1, location.pathname.length),
      ).then(({ rotaValida, logado }) => {
        setUsuarioLogado(logado.usuario);
        setClinicaLogada(logado.clinica);
        if (rotaValida == false) {
          history.push("/menu");
          return;
        }
      });
    }
  }, []);

  return (
    <main>
      <div className="bg-top">
        <div className="containerUsuarioLogado">
          <span>
            {usuarioLogado
              ? `Olá, ${usuarioLogado} você está logado na clínica ${clinicaLogado}`
              : null}
          </span>
        </div>
      </div>
      <div
        className={
          messageRoute ? "content-page-default-message" : "content-page-default"
        }
      >
        {props.children}
      </div>
    </main>
  );
}

export default PageDefault;
