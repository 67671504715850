import React, { useState, useContext, useEffect } from 'react'
import { BiCheckDouble } from 'react-icons/bi'
import InputText from '../../../components/formComponents/inputText'
import PrimaryButton from '../../../components/formComponents/primaryButton'
import { responderNotificacao, buscarNotificacao } from '../../../services/notificacoes'
import { NotificacaoContext } from '../context'
import Loading from '../../../components/loading'

import './styles.css'

function Mensagem({ tipo, texto, hora, opcoes, identificador, template, respondida, valorresposta }) {

    const [loadingVisible, setLoadingVisible] = useState(false)
    const [valorResposta, setValorResposta] = useState([])

    const { setData, data } = useContext(NotificacaoContext)

    useEffect(() => {
        if (valorresposta) {
            const arrayRespostas = valorresposta.split(',')
            setValorResposta(arrayRespostas)
        }
        else {
            setValorResposta([])
        }
    }, [data])

    function handleClickOpcao(value, respondido) {
        if (respondido) return
        handleResponderNotificacao(value)
    }

    function handleResponderNotificacao(value) {
        setLoadingVisible(true) 
        let valores = ''
        valorResposta.map((item, index) => {
            valores = valores + item + ','
        })     

        valores = valores.substring(0, valores.length - 1)
        const resposta = {
            id_notificacao: identificador,
            valor_resposta: (value ? value : valores)
        }
        responderNotificacao(resposta)
            .then((result) => {
                buscarNotificacao(template)
                    .then((result) => {
                        setData(result)
                        setLoadingVisible(false) 
                        valorResposta.map((item, index) => {
                            item = ''
                        }) 
                    })
                    .catch((err) => {
                        setLoadingVisible(false)   
                    })
            })
            .catch((err) => {
                setLoadingVisible(false)   
            })
    }
    function ComponentOpcoes() {
        let component
        if (tipo == 'EDITAVEL') {
            component = opcoes.map((item, index) => {                
                return (
                    
                    <InputText 
                        value={ valorResposta[index] }
                        onChange={(e) => {                           
                            valorResposta[index] = e.target.value                      
                        }}
                        containerstyle={'opcoesNotificacaoItem ' + (respondida ? ' disable ' : '') }
                        label={item.descricao} 
                    />
                )
            })
        }
        else if (tipo == 'SELECIONAVEL') {
            component = opcoes.map((item) => {
                return (
                    <PrimaryButton                         
                        style={valorresposta ? { opacity:'0.7' } : {}}
                        className={(String(valorresposta).toUpperCase() == String(item.descricao).toLocaleUpperCase()) ? 'primaryButton' : 'secondaryButton'}
                        onClick={(e) => { handleClickOpcao(item.descricao, valorresposta)}}
                        containerstyle='opcoesNotificacaoItem'>{item.descricao}</PrimaryButton>
                )
            })
        }

        return component
    }
    return (
        <>
            <Loading forced={true} visible={ loadingVisible } />
                     
            
            <div className='mensagemContainer'>
                <div className='remetenteNotificacao'>
                    { texto }
                    <div className='opcoesNotificacao'>
                        <ComponentOpcoes />                    
                    </div>
                    {
                        (respondida || tipo == 'SELECIONAVEL') ? '' : (
                            <PrimaryButton onClick={() => { handleResponderNotificacao('') }}> Responder</PrimaryButton>
                        )
                    }
                    
                    <div className="footerMensagem">
                        <span>{ hora }</span>
                        <i><BiCheckDouble /></i>
                    </div>
                </div>
                
            </div>
        </>
        
    )
}

export default Mensagem