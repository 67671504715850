import React, { useState, useEffect, useContext } from "react";
import DataList from "../../../components/dataList";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import SimpleButton from "../../../components/formComponents/simpleButton";
import { RecepcionistaContext } from "../context";
import {
  buscarRecepcionistas,
  listarRecepcionistas,
  deletarRecepcionistas,
  pesquisarRecepcionistas,
} from "../../../services/recepcionistas";
import Loading from "../../../components/loading";
import Navigator from "../../../components/navigator";
import "./style.css";

function GridRecepcionistas() {
  const { setData, precisaAtualizar, atualizarGrade, setVisibleBlockingPanel } =
    useContext(RecepcionistaContext);
  const [listaRecepcionista, setListaRecepcionista] = useState([]);
  const [originalListaRecepcionista, setOriginalListaRecepcionista] = useState(
    [],
  );
  const [loadingVisible, setLoadingVisible] = useState(false);

  //const [searchText, setSearchText] = useState('')
  let toFilterText = "";
  useEffect(() => {
    listarRecepcionistas().then((result) => {
      setListaRecepcionista(result);
      setOriginalListaRecepcionista(result);
    });
  }, []);

  function selecionaRecepcionista(id) {
    setLoadingVisible(true);
    buscarRecepcionistas(id).then((result) => {
      setLoadingVisible(false);
      setData(result);
      setVisibleBlockingPanel(false);
    });
  }

  function excluirRecepcionista(id) {
    setLoadingVisible(true);
    deletarRecepcionistas(id).then((result) => {
      setLoadingVisible(false);
      atualizarGrade(true);
    });
  }

  function filter(value) {
    if (value.email.includes(toFilterText)) return value;
    if (value.nome.includes(toFilterText)) return value;
    if (String(value.id).includes(toFilterText)) return value;
  }

  function filtrarRecepcao(filterText) {
    // setListaRecepcionista(originalListaRecepcionista)
    // if (!filterText) return
    // toFilterText = filterText
    // const filteredListaRecepcionistas = listaRecepcionista.filter(filter)
    // setListaRecepcionista(filteredListaRecepcionistas)
    if (String(filterText).length < 3 && String(filterText).length > 0) return;
    pesquisarRecepcionistas(filterText).then((result) => {
      setListaRecepcionista(result);
      setOriginalListaRecepcionista(result);
      atualizarGrade(false);
    });
  }

  useEffect(() => {
    listarRecepcionistas().then((result) => {
      setListaRecepcionista(result);
      setOriginalListaRecepcionista(result);
      atualizarGrade(false);
    });
  }, [atualizarGrade, precisaAtualizar]);

  const columns = [
    /*{
          Header: "Id",
          accessor: "id",
        },*/
    {
      Header: "Nome",
      accessor: "nome",
    },
    {
      Header: "Email",
      accessor: "email",
    },

    {
      Header: "",
      accessor: "edit",
      Cell: (row) => {
        return (
          <SimpleButton
            alt="Editar cadastro"
            title="Editar cadastro"
            onClick={() => {
              selecionaRecepcionista(row.row.original.id);
            }}
          >
            <FaEdit />
          </SimpleButton>
        );
      },
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (row) => (
        <SimpleButton
          alt="Excluir cadastro"
          title="Excluir cadastro"
          buttonType="delete"
          onClick={() => {
            excluirRecepcionista(row.row.original.id);
          }}
        >
          <FaTrashAlt />
        </SimpleButton>
      ),
    },
  ];
  return (
    <>
      <Loading visible={loadingVisible} />
      <div className="mainContent">
        <Navigator />
        <DataList
          searchfunction={filtrarRecepcao}
          columns={columns}
          data={listaRecepcionista ? listaRecepcionista : []}
        ></DataList>
      </div>
    </>
  );
}

export default GridRecepcionistas;
