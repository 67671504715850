import React, { useState, useEffect, useContext } from 'react'
import InputText from '../../../components/formComponents/inputText'
import ModalConfirmation from '../../../components/modalConfirmation'
import UploadArea from '../../../components/uploadArea'

import InputSelect from '../../../components/formComponents/inputSelect'
import PrimaryButton from '../../../components/formComponents/primaryButton'
import SecondaryButton from '../../../components/formComponents/secondaryButton'
import { onlyLetters } from '../../../services/utils/onlyLetters'
import {  listarEspecialidades } from '../../../services/especialidades'
import {  cadastrarMedico, alterarMedico } from '../../../services/medicos'
import { FaCheck, FaTimes } from 'react-icons/fa';
import { MedicoContext } from '../context'
import { cpfMask, phoneMask } from '../../../services/utils/mask'
import { isValidCPF } from '../../../services/utils/validate'
import Loading from '../../../components/loading'
import { useHistory } from 'react-router-dom'
import ModalAlert from '../../../components/modalAlert'
import { enviarConviteNovoUsuario, verificarEmailCadastrado, gerarLinkAtivacao } from '../../../services/usuarios'


import './style.css'

function FormMedico({value, classForm, perfil}) {
    const statusMessageEmail = {
        valid: 'Email válido',
        invalid: 'Email inválido'
    }
    const history = useHistory()
    const sexoOptions = [{label: 'Selecione', value: ''}, {label: 'Masculino', value: 'M'},{label: 'Feminino', value: 'F'}]
    const listaDefault = [{id: 0, rqe: ''}, {id: 0, rqe: ''}]
    const [listaEspecialidadesDefault, setListaEspecialidadesDefault] = useState([])
    const [especialidade, setEspecialidade] = useState([1, 1])
    const [listaEspecialidades, setListaEspecialidades] = useState(listaDefault)
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [visibleAlert, setVisibleAlert] = useState(false)
    const [alertObject, setAlertObject] = useState({})
    const [visibleConfirmation, setVisibleConfirmation] = useState(false)
    const [stateEmail, setStateEmail] = useState('')
    const [fotoPerfil, setFotoPerfil] = useState('')
    const [confirmation, setConfirmation] = useState({
        actionNO: () => {},
        actionOK: () => {}
    })

    const { atualizarGrade, data, setVisibleBlockingPanel } = useContext(MedicoContext)

    /*-----Hooks campos formulario-----*/
    const [id, setId] = useState('')
    const [idMedico, setIdMedico] = useState('')
    const [nome, setNome] = useState('')
    const [documento, setDocumento] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [telefone, setTelefone] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [senha, setSenha] = useState('')
    const [senhaConfirmacao, setSenhaConfirmacao] = useState('')
    const [linkAtivacao, setLinkAtivacao] = useState('')
    const [crm, setCrm] = useState('')
    const [crmSecundario, setCrmSecundario] = useState('')
    const [ufCrm, setUfCrm] = useState('')
    const [ufCrmSecundario, setUfCrmSecundario] = useState('')
    const [email, setEmail] = useState('')
    
    /*----------------------------------*/

    function limparFormulario() {
        if (perfil) {
            history.push('/menu')
            return
        }
        setId('')
        setIdMedico('')
        setNome('')
        setDocumento('')
        setDataNascimento('')
        setTelefone('')
        setWhatsapp('')
        setEmail('')
        setSenha('')
        setSenhaConfirmacao('')
        setCrm('')
        setCrmSecundario('')
        setUfCrm('')
        setUfCrmSecundario('')
        setFotoPerfil('')
        setEspecialidade([1, 1])
        setListaEspecialidades(listaDefault)
        const editEmail = document.getElementById('email')
        editEmail.removeAttribute('disabled')  
        setVisibleBlockingPanel(true)
    }

    function setDadosForm(medico) {
        //controlaEstado()
        let dataNascimento = new Date(medico.dt_nascimento);
        var dia = ("0" + dataNascimento.getDate()).slice(-2);
        var mes = ("0" + (dataNascimento.getMonth() + 1)).slice(-2);  
        var dataNascimentoFinal = dataNascimento.getFullYear()+"-"+(mes)+"-"+(dia);

        setId(medico.id)
        setIdMedico(medico.id_medico)
        setNome(medico.nome)
        setDocumento(medico.documento)
        setDataNascimento(dataNascimentoFinal)        
        setTelefone(phoneMask(medico.telefone))
        setWhatsapp(phoneMask(medico.whatsapp))
        setEmail(medico.email)
        setFotoPerfil(medico.foto)
        setSenha('')
        setCrm(medico.crm)
        setCrmSecundario(medico.crm_secundario || '')
        setUfCrm(medico.uf_crm || '')
        setUfCrmSecundario(medico.uf_crm_secundario || '')
        console.log(medico)
        if (medico.id) {
            gerarLinkAtivacao(medico.id)
                .then((result) => {
                    const link = 
                    `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`                     
                    setLinkAtivacao(link)
                })
        }
        if ( medico.especialidades) {
            const especialidades = medico.especialidades.map((item) => {
                return { id: item.id_especialidade, rqe: item.rqe}
            })
            const newEsp = []
            especialidades.map((item) => {
                newEsp.push(item)
            })
            
            setEspecialidade(newEsp)
            setListaEspecialidades(newEsp)  
        }    
    }

    function getDadosForm(alterando) {
        const medicoBase = {            
            nome,
            documento,
            sexo,
            dt_nascimento: dataNascimento,
            telefone: String(telefone)
                            .replace(' ', '')
                            .replace('-', '')
                            .replace('(', '')
                            .replace(')', '')
                            .trim(),
            whatsapp: String(whatsapp)
                            .replace(' ', '')
                            .replace('-', '')
                            .replace('(', '')
                            .replace(')', '')
                            .trim(),
            senha,
            crm,
            crm_secundario: crmSecundario,
            uf_crm: ufCrm,
            uf_crm_secundario: ufCrmSecundario,
            email,
            foto: fotoPerfil,
            especialidades: listaEspecialidades
        }
        if (alterando) {
          return {
              id,
            id_medico: idMedico,
            ...medicoBase
        }  
        } 
        else {
            return medicoBase
        }
         
    }


    function handleUpload(files) {
        const reader = new FileReader()
        reader.readAsBinaryString(files[0])
        reader.onload = function() {
           setFotoPerfil( (btoa(reader.result))) 
        }   
    }

    const [teste, setTeste] = useState('')

    useEffect(() => {       
        
       const promisse = new Promise((resolve, reject) => {
            resolve(setDadosForm(data))
       })
       promisse
           .then(() => {
               if (data.id) {
                   const editEmail = document.getElementById('email')
                   editEmail.setAttribute('disabled', true)  
               }
           }) 
     
    }, [data])
    
    useEffect(() => {
    const timer = setTimeout(() => {
        if (id) return
        const btn = document.getElementById('btnSalvar')
          
        if (!email) {
            setStateEmail('')
            return            
        }
        setStateEmail('invalid')
        if (email.length < 5 ) return 
        const pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!pattern.test(email)) return

        setStateEmail('loading')
        verificarEmailCadastrado(email)
            .then((result) => { 
                if (result.message == 'CADASTRO_CLINICA') {
                    const alert = {
                        title: '',
                        text: 'Esse email já está sendo utilizado por outro cadastro!',
                        widht: '30%'
                    }
                    setAlertObject(alert)
                    setVisibleAlert(true) 
                    setStateEmail('invalid')
                    btn.setAttribute('disabled', true)
                    return
                } 

                if (result.message == 'CADASTRO_OUTRO') {
                    setConfirmation({
                        Title: 'Usuário já cadastrado',
                        Question: 'Esse email já possui cadastro no sistema Aortic. Deseja ' +
                        'enviar um convite para ingressar na sua clínica?',
                        extra: {
                            type: 'whatsapp_link',
                            data: {
                                whatsapp: result.whatsapp,
                                message: `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`
                            }
                        },
                        actionOK: () => {
                            enviarConviteNovoUsuario({
                                perfil: 'MEDICO',
                                id_pessoa: result.id_pessoa
                            })
                        },
                        actionNO: () => {
                        }  
                    })
                    setVisibleConfirmation(true)     
                    setStateEmail('invalid')
                    btn.setAttribute('disabled', true)
                    return
                } 
                setStateEmail('valid')
                btn.removeAttribute('disabled')    
            })
        }, 500)    
        return () => clearTimeout(timer);
    }, [email])
    
    useEffect(() => {
        if (value) setDadosForm(value)         
        listarEspecialidades()
        .then((result) => {
            const tempResult = result.map((item) => {
                return { value: item.id, label: item.descricao, other: item.rqe }
            })
            setListaEspecialidadesDefault(tempResult)
        })
        
    }, [])
    
    function handleTrocaItemEspecialidade(index, value) {        
        const tempArray = listaEspecialidades
        tempArray[index].id = value      
        setTeste(tempArray.map((item) => {return item}))  
        setListaEspecialidades(tempArray)
    }
    
    function isValidPassword() {
        return senha === senhaConfirmacao
    }
    
    function handleTrocaRQEEspecialidade(index, value) {        
        const tempArray = listaEspecialidades
        tempArray[index].rqe = value      
        setTeste(tempArray.map((item) => {return item}))  
        setListaEspecialidades(tempArray)
    }

    function validateValues(alterando) {
        if (alterando)
            return (nome && dataNascimento)
        else
            return (nome && dataNascimento && senha)

    }
    
    function enviarDadosMedico() {
        if (documento)
            if (isValidCPF(documento) === false) {
                const alert = {
                    title: '',
                    text: 'CPF inválido',
                    widht: '20%'
                }
                setAlertObject(alert)
                setVisibleAlert(true)  
                return
            }
            if (!isValidPassword()) {
                const element = document.getElementById('senha')
                element.setAttribute('title', 'Senhas não conferem')
                element.focus()
                setLoadingVisible(false)
                return
            }
        const alterando = ((id) && (idMedico)) 
        if (!validateValues(alterando)) return
        const medico = getDadosForm(alterando)
        setLoadingVisible(true)
        if (alterando) {
            alterarMedico(medico)
                .then((result) => {
                    if (!perfil) {
                        limparFormulario()
                        atualizarGrade(true)
                    }                    
                    setLoadingVisible(false)
                })
                .catch((error) => {
                    setLoadingVisible(false)
                })
        }
        else {
            cadastrarMedico(medico)
                .then((result) => {
                    if (result.message) {
                        if (result.message.message == 'CADASTRO_CLINICA') {
                            const alert = {
                                title: '',
                                text: 'Esse email já está sendo utilizado por outro cadastro!',
                                widht: '30%'
                            }
                            setAlertObject(alert)
                            setVisibleAlert(true) 
                            setLoadingVisible(false)
                            return
                        } else  if (result.message.message == 'CADASTRO_OUTRO') {
                            setConfirmation({
                                Title: 'Usuário já cadastrado',
                                Question: 'Esse email já possui cadastro no sistema Aortic. Deseja ' +
                                'enviar um convite para ingressar na sua clínica?',
                                hasExtra: result.whatsapp ? true : false,
                                extra: {
                                    type: 'whatsapp_link',
                                    data: {
                                        whatsapp: result.whatsapp,
                                        message: `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`
                                    }
                                },
                                actionOK: () => {
                                    enviarConviteNovoUsuario({
                                        perfil: 'MEDICO',
                                        id_pessoa: result.id_pessoa
                                    })
                                },
                                actionNO: () => {
                                }  
                            })
                            setLoadingVisible(false)
                            setVisibleConfirmation(true)                          
                            return
                        } 
                    }
                    if (!perfil) {
                        limparFormulario()
                        atualizarGrade(true)
                    } 
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)

                }) 
                .catch((error) => {
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)

                })
        }      
        
    }
    return (
        <>
            <ModalAlert 
                visible={visibleAlert} 
                setVisible={setVisibleAlert} 
                title={alertObject.title} 
                text={alertObject.text}
                width={alertObject.widht}
            />
            <ModalConfirmation
                height={'160px'} 
                visible={ visibleConfirmation } 
                setVisible= { setVisibleConfirmation }
                actionOK={ confirmation.actionOK }
                actionNO={ confirmation.actionNO }
                title={ confirmation.Title }
                text={ confirmation.Question }
                hasExtra={confirmation.hasExtra}
                extras={confirmation.extra}
            />
            <form autocomplete='off' onSubmit={(e) => {e.preventDefault()}} className={ 'formData ' + (classForm ? classForm : 'formMedico') }>
                <Loading 
                    forced={ perfil ? true : false }
                    visible={ loadingVisible }
                />
                <UploadArea 
                    onUpload={ handleUpload } 
                    uploadedPhoto={ 'data:image/png;base64, ' + (fotoPerfil || '') } 
                />
                <InputText
                    status={stateEmail}
                    statusMessage={statusMessageEmail}
                    autocomplete="off"
                    required     
                    value={ email }
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    containerstyle="input" 
                    type="email"
                    label="Email" 
                    name="email" />
                <InputText 
                    value={ nome }
                    onChange={(e) => {
                        setNome(e.target.value)
                    }}
                    containerstyle="input" 
                    label="Nome" 
                    name="nomeMedico"></InputText>
                <InputText 
                    value={ documento }
                    onChange={(e) => {
                        const validCPF = cpfMask(e.target.value)
                        setDocumento(validCPF)
                    }}
                    containerstyle="input" 
                    label="Documento (CPF)" 
                    name="documentoMedico"></InputText>
                <div className="col-2">
                    <InputSelect 
                        containerstyle="input-col" 
                        value={sexo}
                        onChange={(e) => {
                            setSexo(e.target.value)
                        }}
                        name='sexo'
                        options={sexoOptions} label="Sexo">                            
                    </InputSelect>
                    <InputText 
                        required
                        value={ dataNascimento }
                        onChange={(e) => {
                            setDataNascimento(e.target.value)
                        }}
                        type="date" 
                        containerstyle="input-col" 
                        label="Data Nascimento" 
                        name="dtNascimento" />
                </div>
                <div className="col-2">                        
                    <InputText 
                        value={ telefone }
                        onChange={(e) => {
                            const phone = phoneMask(e.target.value)     
                            setTelefone(phone)
                        }}
                        containerstyle="input-col" 
                        label="Telefone" 
                        name="telefoneMedico" />
                    <InputText 
                        value={ whatsapp }
                        onChange={(e) => {
                            const phone = phoneMask(e.target.value)     
                            setWhatsapp(phone)
                        }}
                        containerstyle="input-col" 
                        type="text"
                        label="Whatsapp" 
                        name="whatsappMedico" />
                </div>                
                <div className="col-2"> 
                    <InputText 
                        value={ senha }
                        onChange={(e) => {
                            setSenha(e.target.value)
                        }}
                        type="password"
                        autocomplete='new-password'
                        containerstyle="input-col" 
                        label="Senha" 
                        id="senha"
                        name="senha" />
                    <InputText 
                        value={senhaConfirmacao}
                        onChange={(e) => {
                            setSenhaConfirmacao(e.target.value)
                        }}
                        type="password"
                        autocomplete='new-password'
                        containerstyle="input-col"                             
                        label="Confirme a senha" 
                        name="confirmacaoSenha" />   
                </div>
                <div className="col-2"> 
                    <InputText 
                        value={ crm }
                        onChange={(e) => {
                            setCrm(e.target.value)
                        }}
                        containerstyle="input" 
                        type="text"
                        label="CRM" 
                        name="crmMedico" />     
                        <InputText 
                            value={ ufCrm }
                            onChange={(e) => {
                                const newUF = onlyLetters(String(e.target.value).toUpperCase())
                                setUfCrm(newUF)
                            }}
                            containerstyle="col-20-percent-right uf"                             
                            label="UF" 
                            name="uf" />   
                </div> 

                <div className="col-2">         
                    <InputText 
                        value={ crmSecundario }
                        onChange={(e) => {
                            setCrmSecundario(e.target.value)
                        }}
                        containerstyle="input" 
                        type="text"
                        label="CRM" 
                        name="crmSecundarioMedico" />
                    <InputText 
                        value={ ufCrmSecundario }
                        onChange={(e) => {
                            const newUF = onlyLetters(String(e.target.value).toUpperCase())
                            setUfCrmSecundario(newUF)
                        }}
                        containerstyle="col-20-percent-right uf"                             
                        label="UF" 
                        name="uf" />   
                </div> 

                                    
                                        
                { especialidade.map((item, index) => {
                
                    return (
                    <div key={index} className="containerSelectEspecialidade">
                        <InputSelect                        
                            containerstyle="input-col"  
                            value={listaEspecialidades[index] ? listaEspecialidades[index].id : ''}
                            onChange={(e) => {
                                handleTrocaItemEspecialidade(index, Number(e.target.value))                                        
                            }}  
                            name={"especialidade" + index}                               
                            options={ listaEspecialidadesDefault } label="Especialidades">                            
                        </InputSelect>
                        <InputText 
                            onChange={(e) => {
                                handleTrocaRQEEspecialidade(index, (e.target.value))                                        
                            }}
                            value={ listaEspecialidades[index] ? listaEspecialidades[index].rqe : '' }                           
                            containerstyle="input-col"
                            type="text"
                            label="RQE" 
                            name={"rqe" + index} />    
                    
                    </div>
                    )
                }) } 
                <div className='linkAtivacaoFormulario'>
                    {
                        ((linkAtivacao && whatsapp) && (!perfil)) ?
                        <a className='primaryButtonLink ' 
                        href={`https://wa.me/55${String(whatsapp)
                                                .replace(' ', '')
                                                .replace('-', '')
                                                .replace('(', '')
                                                .replace(')', '')
                                                .trim()}?text=${linkAtivacao}`} 
                        target='_blank'>Link Whatsapp</a>
                        : null
                    }
                </div>
                <div className="footer"> 
                    <SecondaryButton 
                        type="submit"      
                        name='btnCancelar'              
                        onClick={limparFormulario}
                        containerstyle ="footerButton" 
                        text="Cancelar"><FaTimes /></SecondaryButton>
                    <PrimaryButton 
                        onClick={enviarDadosMedico}
                        name='btnSalvar'             

                        containerstyle="footerButton" 
                        text="Salvar" ><FaCheck /></PrimaryButton>                
                </div>
            </form> 
        </>
    )
}

export default FormMedico