import React from 'react'

const FORM_STATE = {
        EDIT : 0,
        INSERT : 1,
        BLOCK : 2,
        NOTHING : 3
}

const PacienteContext = React.createContext({ 
        FORM_STATE: FORM_STATE,
        estado: '',
        precisaAtualizar: false,
        atualizarGrade: () => {},
        data: {}, 
        blockingPanel: false,
        setVisibleBlockingPanel: () => {},
        setData: () => {} })

export const PacienteProvider = PacienteContext.Provider

export { PacienteContext }
