import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function cadastrarComorbidade(comorbidade) {
    try {
        const response = await api.post('comorbidade/', comorbidade, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarComorbidades(paciente) {
    try {
        const response = await api.get('comorbidade?paciente='+paciente, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarOpcoesComorbidades() {
    try {
        const response = await api.get('comorbidades', {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}


async function deletarComorbidade(id) {
    try {
        const response = await api.delete('comorbidade/'+id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

export { cadastrarComorbidade, listarComorbidades, listarOpcoesComorbidades, deletarComorbidade }
