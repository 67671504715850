import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function buscarDadosProntuario(paciente) {
    try {
        const response = await api.get('prontuario/' + paciente,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data      
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function buscarDadosProntuarioPaciente() {
    try {
        const pessoa = localStorage.getItem('@aortic-app/p')
        const response = await api.get('prontuario/paciente/' + pessoa,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]      
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function preencheAnotacao(paciente, anotacao) {
    try {
        const response = await api.post('prontuario/' + paciente + '/anotacao', anotacao,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function atualizaIndice(paciente) {
    try {
        const response = await api.post('prontuario/' + paciente + '/indices', {},{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function atualizarDataProximaConsulta(data, paciente) {
    try {
        const response = await api.post('prontuario/' + paciente + '/consulta', { data: data },{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

export { buscarDadosProntuario, preencheAnotacao, atualizaIndice, 
         buscarDadosProntuarioPaciente, atualizarDataProximaConsulta }
