import React, { useState, useEffect } from 'react'
import LogoAortic from '../../images/logo-aortic.png'
import { useHistory, useLocation } from 'react-router-dom'
import { validarTokenNovaSenha, trocarSenha } from '../../services/usuarios'
import Loading from '../../components/loading'
import './styles.css'
import ModalAlert from '../../components/modalAlert'

import { deleteToken, setToken } from '../../services/utils/token'

function RecuperaSenha() {
   
    const history = useHistory()
    const [idUsuario, setIdUsuario] = useState('')
    const [statusToken, setStatusToken] = useState('AGUARDANDO')
    const [visibleAlert, setVisibleAlert] = useState(false)
    const [alertObject, setAlertObject] = useState({})

    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }

    const query = useQuery()
    useEffect(() => {        
        validarTokenNovaSenha(query.get('token'))
            .then((result) => {
                if (result.token == true) {
                    setIdUsuario(result.id)
                    setStatusToken('VALIDO')
                } else if (result.token == false) {
                    setStatusToken('INVALIDO')
                } else {
                    setStatusToken('INDEFINIDO')
                }
            })
            .catch((error) => {
                setStatusToken('INDEFINIDO')
            })
    }, [])

    function enviarNovaSenha() {
        const senha = document.getElementById('novaSenha')
        const repeteSenha = document.getElementById('repeteSenha')
         if (senha.value !== repeteSenha.value) {            
            senha.focus()
            const alert = {
                title: '',
                text: 'Senhas não conferem!',
                widht: '20%'
            }
            setAlertObject(alert)
            setVisibleAlert(true)  
            return
        }
        setStatusToken('AGUARDANDO')
        //console.log(idUsuario, senha.value)
        trocarSenha(idUsuario, senha.value)
            .then((result) => {
                const alert = {
                    title: '',
                    text: 'Nova senha trocada com sucesso',
                    widht: '20%'
                }
                setAlertObject(alert)
                setVisibleAlert(true)  
                deleteToken()
                history.push('/')
            })
            .catch(() => {
                validarTokenNovaSenha(query.get('token'))
                    .then((result) => {
                        if (result.token == true) {
                            setIdUsuario(result.id)
                            setStatusToken('VALIDO')
                        } else if (result.token == false) {
                            setStatusToken('INVALIDO')
                        } else {
                            setStatusToken('INDEFINIDO')
                        }
                    })
                    .catch((error) => {
                        setStatusToken('INDEFINIDO')
                    })
            })


    }

    function FormNovaSenha() {
        return (
            <>
                <ModalAlert 
                    visible={visibleAlert} 
                    setVisible={setVisibleAlert} 
                    title={alertObject.title} 
                    text={alertObject.text}
                    width={alertObject.widht}
                />
                <div className="containerRecuperaSenha">                 
                    <div>                
                        <form onSubmit={(e) => {e.preventDefault()}}>

                            <div className="inputLogin">
                                <div>
                                    <label htmlFor='novaSenha'>Nova senha</label>
                                </div>
                                <div>
                                    <input                                     
                                        type='password' 
                                        name='novaSenha' 
                                        id='novaSenha'  />    
                                </div>  
                            </div>
                            <div className="inputLogin">
                                <div>
                                    <label htmlFor='repeteSenha'>Repita a senha</label>
                                </div>
                                <div>
                                    <input
                                        
                                        type='password' 
                                        name='repeteSenha' 
                                        id='repeteSenha'  />    
                                </div>  
                            </div>  
                            <button className='login' onClick={enviarNovaSenha}>Atualizar senha</button>   
                        </form>
                    </div>
                    <div className="logo">
                        <img src={ LogoAortic } alt=""/>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>      
            <Loading visible={(statusToken == 'AGUARDANDO')} />
            {
                statusToken == 'INVALIDO' ? <h4>Token inválido</h4> : ''
            }
            {
                statusToken == 'INDEFINIDO' ? <h4>Ocorreu um erro. Tente novamente.</h4> : ''
            }
            {
                statusToken == 'VALIDO' ? <FormNovaSenha /> : ''
            }
            
        </>
        
    )
}

export default RecuperaSenha