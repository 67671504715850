import React, { useState, useEffect, useContext } from 'react'
import DataList from '../../../components/dataList'
import { useHistory } from 'react-router-dom'
import InputText from '../../../components/formComponents/inputText'
import InputSelect from '../../../components/formComponents/inputSelect'
import {  listarMedicos } from '../../../services/medicos'
import { FaEdit, FaTrashAlt, FaRegStickyNote } from 'react-icons/fa'
import SimpleButton from '../../../components/formComponents/simpleButton'
import { AgendamentoContext } from '../context'
import { listarAgendamentos, cancelarAgendamento, trocarStatusAgendamento } from '../../../services/agendamentos'
import { FaRegCalendarMinus, FaRegCalendarCheck } from 'react-icons/fa'
import Loading from '../../../components/loading'
import Navigator from '../../../components/navigator'
import './style.css'

function GridAgendamentos() {
  const { setData, precisaAtualizar, atualizarGrade, setVisibleBlockingPanel } = useContext(AgendamentoContext)
  const [listaAgendamento, setListaAgendamento] = useState([])
  const [listaAgendamentoOriginal, setListaAgendamentoOriginal] = useState([])
  const [loadingVisible, setLoadingVisible] = useState(false)
  const [listaMedicos, setListaMedicos] = useState([])
  const [teste, setTeste] = useState([])
  const history = useHistory()
  /* Hooks para filtros */
  const [filtroDataInicial, setFiltroDataInicial] = useState('')
  const [filtroDataFinal, setFiltroDataFinal] = useState('')
  const [filtroMedico, setFiltroMedico] = useState('')

  /* ------------------ */


  function carregarDataAtual() {
    let data = new Date()
    var dia = ("0" + data.getDate()).slice(-2)
    var mes = ("0" + (data.getMonth() + 1)).slice(-2)
    var dataFinal = data.getFullYear()+"-"+(mes)+"-"+(dia)
    setFiltroDataInicial(dataFinal)
    setFiltroDataFinal(dataFinal)    
  }

  function carregaGrid(lista) {

    carregarDataAtual()

    setListaAgendamento(lista) 
    setListaAgendamentoOriginal(lista)

    filtrarDados()
  }

  useEffect(() => {
    atualizarGrade(true)

    // listarAgendamentos()
    //   .then((result) => {
    //     const listaAgendamentos = result.map((item) => {

    //       let data = new Date(item.data_agendamento);
    //       var dia = ("0" + data.getDate()).slice(-2);
    //       var mes = ("0" + (data.getMonth() + 1)).slice(-2);  
    //       var dataFinal = dia + '/' + mes + '/'  +  data.getFullYear(); 
    //       return { data_formatada: dataFinal,...item }
    //     })
    //     //carregaGrid(listaAgendamentos)
    //   }) 
    listarMedicos()
      .then((result) => {
        const lista = result.map((item) => {
            return { value: item.id_medico, label: item.nome }
        })
        lista.splice(0, 0, {value: '', label: 'Todos'})
        setListaMedicos(lista) 

      })   

  }, [])

  function filtrarDados(data){
    // if (!listaAgendamentoOriginal) return
    let listaTemporaria = listaAgendamentoOriginal    
    if (filtroMedico) {
      listaTemporaria = listaAgendamentoOriginal.filter((item) => {
        if (item.id_medico == filtroMedico) return item
      })
    }

    if (filtroDataInicial) {
      let dataInicial = new Date(filtroDataInicial)
      dataInicial = new Date(dataInicial.getFullYear(), 
                              dataInicial.getMonth(),
                              dataInicial.getDate() + 1)
      if (dataInicial.getFullYear() > 1900) {
        listaTemporaria = listaTemporaria.filter((item) => {
          let dataAgenda = new Date(item.data_agendamento)
          if (dataAgenda >= dataInicial) {
            return item
          }
        })  
      }      
    }

    if (filtroDataFinal) {
      let dataFinal = new Date(filtroDataFinal)
      dataFinal = new Date(dataFinal.getFullYear(), 
                              dataFinal.getMonth(),
                              dataFinal.getDate() + 1)
     
      if (dataFinal.getFullYear() > 1900) {
        listaTemporaria = listaTemporaria.filter((item) => {
          let dataAgenda = new Date(item.data_agendamento)
          if (dataAgenda <= dataFinal){
            return item
          }
        })  
      }      
    }    

    setListaAgendamento(listaTemporaria)
  }

  useEffect(() => {
    filtrarDados()
  }, [filtroMedico, filtroDataInicial, filtroDataFinal])
  
  function selecionaAgendamento(id) { 
    setLoadingVisible(true)
    const agendamentoSelecionado = 
      listaAgendamento.filter((item) => {
        if (item.id == id) { return item }
      }) 

    setLoadingVisible(false)
    setData(agendamentoSelecionado[0])
    setVisibleBlockingPanel(false)
  }

  function trocaStatus(id) {
    setLoadingVisible(true)
    trocarStatusAgendamento(id)
      .then((result) => {
        setLoadingVisible(false)
        atualizarGrade(true)
      }) 
  }

  function excluirAgendamento(id) {
    setLoadingVisible(true)
    cancelarAgendamento(id)
      .then((result) => {
        setLoadingVisible(false)
        atualizarGrade(true)
      })    
  }

  function prontuario(index) {
    const agendamento = listaAgendamento[index]
    history.push('/paciente?id=' + agendamento.id_pessoa)
  }

  useEffect(() => {
    listarAgendamentos()
      .then((result) => {
        const listaAgendamentos = result.map((item) => {

          let data = new Date(item.data_agendamento);
          var dia = ("0" + data.getDate()).slice(-2);
          var mes = ("0" + (data.getMonth() + 1)).slice(-2);  
          var dataFinal = dia + '/' + mes + '/'  +  data.getFullYear(); 
          return { data_formatada: dataFinal,...item }
        })
        carregaGrid(listaAgendamentos)
        atualizarGrade(false)
      })
  }, [atualizarGrade, precisaAtualizar])

const columns = [
        {
          Header: "Id",
          accessor: "id",
        },
        {
          Header: "Nome",
          accessor: "nome",
        },
        {
          Header: "Whatsapp",
          accessor: "whatsapp"
        }, 
        {
          Header: "Telefone",
          accessor: "telefone"
        },  
        {
          Header: "Data",
          accessor: "data_formatada"
        }, 
        {
          Header: "Hora",
          accessor: "hora_agendamento"
        },      

        {
          Header: "",
          accessor: "confirmado",
          Cell: row => (
            <div className="iconConfirma">
              <SimpleButton 
                title='Confirmação do agendamento'
                onClick={() => {trocaStatus(row.row.cells[0].value)}}>
                  {                  
                    row.row.cells[5].value ? <FaRegCalendarCheck /> : <FaRegCalendarMinus />
                  }
              </SimpleButton>
            </div>
            )
        },  
        {          
          Header: '',
          accessor: 'prontuario',
          Cell: row => (   
            <>
              {
                row.row.cells[7].value == true ? 
                <SimpleButton 
                  alt='Prontuário paciente'
                  title='Prontuário paciente'
                  onClick={() => { prontuario(row.row.index) } }>
                    <FaRegStickyNote />
                </SimpleButton> :
                <></>
              }                                          
            </>
          )
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: row => (
          <SimpleButton 
            alt='Editar cadastro'
            title='Editar cadastro'
            onClick={() => {selecionaAgendamento(row.row.cells[0].value)} }><FaEdit /></SimpleButton>
          )
        },
        {
          Header: '',
          accessor: 'delete',
          Cell: row => (
          <SimpleButton 
            buttonType='delete' 
            alt='Excluir cadastro'
            title='Excluir cadastro'
            onClick={() => {excluirAgendamento(row.row.cells[0].value)} }><FaTrashAlt /></SimpleButton>
          )
        },      
       
      ]
    return (
        <>
          <Loading visible={ loadingVisible }/>
          <div className="mainContent">
            <Navigator />
            <DataList 
              filter={
                (<div className='filtrosAgendamentos'>
                <InputText 
                    value={ filtroDataInicial }
                    onChange={(e) => {
                        setFiltroDataInicial(e.target.value)
                    }}
                    type="date"
                    containerstyle="filtrosAgendamentosItem" 
                    label="Data inicial" 
                    name="filtroDataInicial">
                </InputText>
                <InputText 
                    value={ filtroDataFinal }
                    onChange={(e) => {
                      setFiltroDataFinal(e.target.value)       
                    }}
                    type="date"
                    
                    containerstyle="filtrosAgendamentosItem" 
                    label="Data final" 
                    name="filtroDataFinal">
                </InputText>
                <InputSelect 
                    containerstyle="filtrosAgendamentosItem" 
                    value={ filtroMedico }
                    onChange={(e) => {
                        setFiltroMedico(e.target.value)
                    }}
                    options={ listaMedicos } label="Médico">                            
                </InputSelect>
              </div>)
              }  columns={columns} data= {listaAgendamento ? listaAgendamento : []}></DataList>  
          </div>
        </>
    )
}

export default GridAgendamentos