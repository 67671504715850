import React from "react";
import { usePatientRecordContext } from "../../../../context/patientRecordContext";
import InputText from "../../../../components/formComponents/inputText";
import { onlyLetters } from "../../../../services/utils/onlyLetters";
import { consultarCEP } from "../../../../services/utils/cep";

const AdditionalInformation = () => {
  const { patientCreateData, setPatientCreateData } = usePatientRecordContext();

  async function handleConsultaCEP() {
    if (!patientCreateData?.cep) return;
    if (patientCreateData?.cep?.length < 8) return;
    try {
      const addresData = await consultarCEP(patientCreateData?.cep);
      if (addresData?.data?.localidade?.length) {
        setPatientCreateData({
          ...patientCreateData,
          logradouro: addresData?.data?.logradouro,
          bairro: addresData?.data?.bairro,
          cidade: addresData?.data?.localidade,
          uf: addresData?.data?.uf,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="basicDataContainer">
      <div className="col-2">
        <InputText
          value={patientCreateData?.convenio}
          onChange={(e) =>
            setPatientCreateData({
              ...patientCreateData,
              convenio: e.target.value,
            })
          }
          containerstyle="input-col"
          label="Plano de Saúde"
          name="convenio"
        />
        <InputText
          value={patientCreateData?.numeroInscricaoConvenio}
          onChange={(e) => {
            setPatientCreateData({
              ...patientCreateData,
              numeroInscricaoConvenio: e.target.value,
            });
          }}
          containerstyle="input"
          type="numero_inscricao_convenio"
          label="N° de inscrição (convenio)"
          name="numero_inscricao_convenio"
        />
      </div>
      <div className="col-2">
        <InputText
          value={patientCreateData?.cep || ""}
          onChange={(e) => {
            setPatientCreateData({
              ...patientCreateData,
              cep: e.target.value,
            });
          }}
          onBlur={handleConsultaCEP}
          containerstyle="col-30-percent"
          label="CEP"
          id="cep"
          name="cep"
        />
        <InputText
          value={patientCreateData?.logradouro || ""}
          onChange={(e) => {
            setPatientCreateData({
              ...patientCreateData,
              logradouro: e.target.value,
            });
          }}
          containerstyle="input-col"
          label="Logradouro"
          name="logradouro"
        />
      </div>
      <div className="col-2">
        <InputText
          value={patientCreateData?.numero || ""}
          onChange={(e) => {
            setPatientCreateData({
              ...patientCreateData,
              numero: e.target.value,
            });
          }}
          containerstyle="col-30-percent"
          label="Número"
          id="numero"
          name="numero"
        />
        <InputText
          value={patientCreateData?.complemento || ""}
          onChange={(e) => {
            setPatientCreateData({
              ...patientCreateData,
              complemento: e.target.value,
            });
          }}
          containerstyle="input-col"
          label="Complemento"
          name="complemento"
        />
      </div>
      <InputText
        value={patientCreateData?.bairro || ""}
        onChange={(e) => {
          setPatientCreateData({
            ...patientCreateData,
            bairro: e.target.value,
          });
        }}
        containerstyle="input"
        label="Bairro"
        id="bairro"
        name="bairro"
      />
      <div className="col-2">
        <InputText
          value={patientCreateData?.cidade || ""}
          onChange={(e) => {
            setPatientCreateData({
              ...patientCreateData,
              cidade: e.target.value,
            });
          }}
          containerstyle="input-col"
          label="Cidade"
          id="cidade"
          name="cidade"
        />
        <InputText
          value={patientCreateData?.uf || ""}
          onChange={(e) => {
            const newUF = onlyLetters(String(e.target.value).toUpperCase());
            setPatientCreateData({
              ...patientCreateData,
              uf: newUF,
            });
          }}
          containerstyle="col-20-percent-right uf"
          label="UF"
          name="uf"
        />
      </div>
    </div>
  );
};

export default AdditionalInformation;
