import api from '../api'

async function calcularRisco(dados) {
    try {
        const response = await api.post('calculorisco', dados) 
        return response.data       
    } catch (error) {       
        return error.response.data
    }
}

export { calcularRisco }
