import React from 'react'

const AgendamentoContext = React.createContext({ 
        estado: '',
        precisaAtualizar: false,
        blockingPanel: false,
        setVisibleBlockingPanel: () => {},
        atualizarGrade: () => {},
        data: {}, 
        setData: () => {} })

export const AgendamentoProvider = AgendamentoContext.Provider

export { AgendamentoContext }
