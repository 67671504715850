import api from '../api'
import { deleteToken, deleteTokenAdm } from '../utils/token'
import { deleteClinicaAtiva } from '../clinicas'
import adapter from 'webrtc-adapter'
 
async function login(user) {
    const response = await api.post('login', user)
    return response.data  
}

async function loginAdm(user) {
    const response = await api.post('adm/login', user)
    return response.data  
}

async function logoutAdm() {
    deleteTokenAdm()
    window.location.pathname = '/adm'
}

async function logout() {
    const pessoa = localStorage.getItem('@aortic-app/p')
    if (pessoa) {
        const device_id = adapter.browserDetails.browser
        await api.delete(`token?pessoa=${pessoa}&device=${device_id}`)
    }
    deleteToken()
    deleteClinicaAtiva()
    window.location.pathname = '/'
}

export { login, logout, loginAdm, logoutAdm }