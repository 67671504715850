import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function cadastrarAfericao(afericao) {
    try {
        const response = await api.post('afericao/', afericao, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarAfericoes(paciente, tipo) {
    try {
        const response = await api.get('afericao?paciente='+paciente+'&tipo=' + tipo, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}


async function deletarAfericao(id) {
    try {
        const response = await api.delete('afericao/'+id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

export { cadastrarAfericao, listarAfericoes, deletarAfericao }
