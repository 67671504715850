import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function listarPacientes(medico) {
    const query = medico ? '?medico=' + medico : ''
    try {
        const response = await api.get('paciente' + query,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function pesquisarPacientes(search) {
    const query = search ? '?filter=' + search : ''
    try {
        const response = await api.get('paciente' + query,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data               
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function alterarPacientes(paciente) {
    try {
        const response = await api.put('paciente', paciente,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data             
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }


}

async function deletarPacientes(id) {
    try {
        const response = await api.delete('paciente/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data             
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function cadastrarPacientes(paciente) {
    try {
        const response = await api.post('paciente', paciente,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data             
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function buscarPacientes(id) {
    try {
        const response = await api.get('paciente/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data             
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

export { listarPacientes, alterarPacientes, deletarPacientes,
        cadastrarPacientes, buscarPacientes, pesquisarPacientes }