import api from "../api";
import { getToken, getTokenAdm } from "../utils/token";
import { logout } from "../login";
import { getClinicaAtiva } from "../clinicas";

async function listarUsuarios() {
  try {
    const response = await api.get("usuario", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function buscarDadosPerfilLogado() {
  try {
    const response = await api.get("perfil/usuario", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function buscarDadosPerfil() {
  try {
    const response = await api.get("perfil", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function gerarTokenSmartLink(id) {
  try {
    const response = await api.post("smartlink?usuario=" + id, "", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401)
      //logout()
      console.log(error);
    return [];
  }
}

async function requisitarNovaSenha(email) {
  try {
    const response = await api.post("novasenha", { email });
    return response.data;
  } catch (error) {
    if (error.response.status === 401)
      //logout()
      console.log(error);
    return [];
  }
}

async function validarTokenSmartLink(token) {
  try {
    const response = await api.get("smartlink?token=" + token);
    return response.data;
  } catch (error) {
    logout();
    return [];
  }
}

async function buscarFotoPerfilLogado() {
  try {
    const response = await api.get("perfil/foto", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data[0];
  } catch (error) {
    logout();
    return [];
  }
}

async function validarRota(rota) {
  let rotaValida = false;
  const { permissoes, usuario, clinica } = await listarPermissoes();
  const logado = { usuario, clinica };
  permissoes.map((item) => {
    if (item && item.items) {
      return item.items.map((rotaMenu) => {
        if (rotaMenu) {
          if (rotaMenu.link == rota) {
            rotaValida = true;
            return;
          }
        }
      });
    } else {
      if (item.link == rota) {
        rotaValida = true;
        return;
      }
    }
  });
  return { rotaValida, logado };
}

async function listarPermissoes() {
  try {
    const admin = localStorage.getItem("@aortic-app/a");
    const response = await api.get(`permissoes?adm=${admin}`, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function ativarUsuarioBase64(idUsuario) {
  try {
    const user = Buffer.from(String(idUsuario)).toString("base64");
    const clinica = Buffer.from(String(getClinicaAtiva())).toString("base64");

    const response = await api.put("ativar", {
      id_clinica: clinica,
      id_usuario: user,
    });
    return response.data;
  } catch (error) {
    logout();
    return [];
  }
}

async function ativarUsuario(idClinica, idUsuario) {
  try {
    const response = await api.put("ativar", {
      id_clinica: idClinica,
      id_usuario: idUsuario,
    });
    return response.data;
  } catch (error) {
    logout();
    return [];
  }
}

async function validarTokenNovaSenha(token) {
  try {
    const response = await api.get("novasenha?token=" + token);
    return response.data;
  } catch (error) {
    return { token: "undefined" };
  }
}

async function trocarSenha(id, senha) {
  try {
    const response = await api.put("novasenha", {
      senha,
      id_usuario: id,
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

async function enviarConviteNovoUsuario(usuario) {
  try {
    const response = await api.post("convite", usuario, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) return [];
  }
}

async function listarUsuarioAdm() {
  try {
    const response = await api.get("adm/usuario", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function cadastrarUsuarioAdm(usuario) {
  try {
    const response = await api.post(`adm/usuario`, usuario);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function alterarUsuarioAdm(usuario) {
  try {
    const response = await api.put(`adm/usuario`, usuario, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function excluirUsuarioAdm(id) {
  try {
    const response = await api.delete(`adm/usuario/${id}`, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function verificarEmailCadastradoAdm(email, clinica) {
  try {
    const clinicaParam = clinica || "null";
    const response = await api.get(`verifica/${email}/${clinicaParam}`, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function verificarEmailCadastrado(email, clinica) {
  try {
    const clinicaParam = clinica || "null";
    const response = await api.get(`verifica/${email}/${clinicaParam}`, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    console.log("%c ---> error: ", "color:#0F0;", error);
    if (error.response.status === 401) logout();
    return;
  }
}

async function gerarLinkAtivacao(usuario) {
  try {
    const response = await api.get(`usuario/ativacao/${usuario}`, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

const sendCreateUserMail = async ({ email, isAdm, isDoctor }) => {
  try {
    const response = await api.post(
      `user/new/email`,
      {
        email,
        isAdm,
        isDoctor,
      },
      {
        headers: {
          "x-access-token": getToken(),
          "app-clinica": getClinicaAtiva(),
        },
      },
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
};

export {
  sendCreateUserMail,
  listarUsuarios,
  listarPermissoes,
  validarRota,
  validarTokenSmartLink,
  gerarTokenSmartLink,
  buscarDadosPerfil,
  ativarUsuario,
  requisitarNovaSenha,
  validarTokenNovaSenha,
  trocarSenha,
  enviarConviteNovoUsuario,
  buscarFotoPerfilLogado,
  listarUsuarioAdm,
  cadastrarUsuarioAdm,
  alterarUsuarioAdm,
  excluirUsuarioAdm,
  verificarEmailCadastrado,
  gerarLinkAtivacao,
  verificarEmailCadastradoAdm,
  buscarDadosPerfilLogado,
  ativarUsuarioBase64,
};
