import React from 'react'
import FormDataContainer from '../../components/formDataContainer'
import Navigator from '../../components/navigator'
import PageDefault from '../../components/pageDefault'
import FormPaciente from './formPerfil'
import './style.css'

function Perfil() {
    const isMobile = window.innerWidth < 800
    return (
        
        <PageDefault>  
            {
                isMobile ? 
                <>
                    <Navigator />  
                    <FormPaciente />
                </>:
                <>
                <FormDataContainer title="Perfil">              
                    <FormPaciente />
                </FormDataContainer>    
                <Navigator />   
                </>
            }
                                             
        </PageDefault>
    )
}

export default Perfil