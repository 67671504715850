import React, { useState, useEffect, useContext } from 'react'
import InputText from '../../../components/formComponents/inputText'
import InputSelect from '../../../components/formComponents/inputSelect'
import Radio from '../../../components/formComponents/radioGroup'
import Check from '../../../components/formComponents/checkBox'
import PrimaryButton from '../../../components/formComponents/primaryButton'
import SecondaryButton from '../../../components/formComponents/secondaryButton'
import {  cadastrarTemplate, alterarTemplate } from '../../../services/templateNotificacoes'
import { FaCheck, FaTimes } from 'react-icons/fa';
import { TemplateContext } from '../context'
import Loading from '../../../components/loading'
import './style.css'

function FormPaciente() {
    
    const [loadingVisible, setLoadingVisible] = useState(false)  
    const { atualizarGrade, data, setVisibleBlockingPanel } = useContext(TemplateContext)    
    const tipoDadoOptions = [{ label: 'Personalizado', value: 'PERSONALIZADO'},
                             { label: 'Pressão arterial', value: 'PRESSAO_ARTERIAL' }]
    const tipoMensagemOptions = [{ label: 'Editável', value: 'EDITAVEL' },
                                 { label: 'Selecionável', value: 'SELECIONAVEL' }]
    const tipoControleOptions = [
        { id: 'unico', label: 'Único', value: 'unico' },
        { id: 'intervalo', label: 'Em um intervalo de tempo', value: 'intervalo' }
    ]
    /*-----Hooks campos formulario-----*/
    const [id, setId] = useState('')
    const [titulo, setTitulo] = useState('')
    const [periodicidade, setPeriodicidade] = useState('') 
    const [hora_notificacao, setHoraNotificacao] = useState('')
    const [data_hora_vigencia_inicial, setData_hora_vigencia_inicial] = useState('')
    const [data_hora_vigencia_final, setData_hora_vigencia_final] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [tipo_dado, setTipoDado] = useState('PERSONALIZADO')
    const [tipo_mensagem, setTipoMensagem] = useState('EDITAVEL')  
    const [opcoes, setOpcoes] = useState([{ descricao: '' }])
    const [quantidadeOpcoes, setQuantidadeOpcoes] = useState(0)
    const [teste, setTeste] = useState([])
    const [tipoControle, setTipoControle] = useState('intervalo')
    const [preenchimentoPadrao, setPreenchimentoPadrao] = useState(true)
    const [podeEditarOpcoes, setPodeEditarOpcoes] = useState({ visibility: 'visible' })
    /*----------------------------------*/    
    function limparFormulario() {
        setId('')
        setTitulo('')
        setPeriodicidade('')
        setHoraNotificacao('')
        setData_hora_vigencia_inicial('')
        setData_hora_vigencia_final('')
        setMensagem('')
        setTipoDado('PERSONALIZADO')
        setTipoMensagem('EDITAVEL')
        handleTipoDado('PERSONALIZADO')
        //setTipoMensagem()
        handleOpcoes(false)
        desabilitarTipoDado(false)
        setPodeEditarOpcoes({ visibility: 'visible' })
        setTipoControle('intervalo')
        setPreenchimentoPadrao(true)
        setVisibleBlockingPanel(true)
    }

    function formatDate(data) {
        if (!data) {
            return ''
        }
        const dataInicial = new Date(data);
        const dia = ("0" + dataInicial.getDate()).slice(-2);
        const mes = ("0" + (dataInicial.getMonth() + 1)).slice(-2);  
        return dataInicial.getFullYear()+"-"+(mes)+"-"+(dia);
    }

    function setDadosForm(template) {
      
        setTipoDado(template.tipo_dado)
        setTitulo(template.titulo)
        setPeriodicidade(template.periodicidade)
        setHoraNotificacao(String(template.hora_notificacao || '').substring(0, 5))        
        setData_hora_vigencia_inicial(formatDate(template.data_hora_vigencia_inicial))
        setData_hora_vigencia_final(formatDate(template.data_hora_vigencia_final))
        setMensagem(template.mensagem)        
        setTipoMensagem(template.tipo_mensagem)
        setOpcoes(template.template_notificacao_opcoes)
        setTipoControle(template.unico ? 'unico' : 'intervalo')
        setPreenchimentoPadrao(template.preenchimento_padrao || true)
        setId(template.id)
        if (template.id) {
            desabilitarTipoDado(true)
            handleOpcoes(true)  
        }
         
    }

    function getDadosForm(alterando) {
        const templateBase = {   
            titulo,
            periodicidade,
            hora_notificacao: ((hora_notificacao < 6) && (hora_notificacao)) ? hora_notificacao + ':00' : hora_notificacao,
            data_hora_vigencia_inicial,
            data_hora_vigencia_final,
            mensagem,
            tipo_dado,
            tipo_mensagem,
            templateNotificacaoOpcoes: opcoes,
            unico: tipoControle == 'unico' ? true : false,
            preenchimento_padrao: preenchimentoPadrao
        }
       
        if (alterando) {
          return {
            id,
            ...templateBase
            }  
        } 
        else {
            return templateBase
        }
         
    }

    function desabilitarTipoDado(disable) {
        const elementTipoDado = document.getElementById('tipoDado')
        elementTipoDado.disabled = disable
    }

    function handleOpcoes(readOnly) {
        const elementQuantidadeOpcoes = document.getElementById('quantidadeOpcoes')
        const elementTipoMensagem = document.getElementById('tipoMensagem')
        if (readOnly) {
            elementQuantidadeOpcoes.setAttribute('readonly', true)
            elementTipoMensagem.disabled = true
            setPodeEditarOpcoes({ display: 'none' })  
 
        }
        else {
            elementQuantidadeOpcoes.removeAttribute('readonly')
            elementTipoMensagem.disabled = false
            setPodeEditarOpcoes({ display: 'flex' })               
        }
               
    }

    function hendleDescricaoOpcao(index, value) {
        const tempArray = opcoes
        tempArray[index].descricao = value      
        setTeste(tempArray.map((item) => {return item}))  
        setOpcoes(tempArray)
    }

    useEffect(() => {
        setDadosForm(data)    
    }, [data])
    function handleTipoDado(dado) {
        if (dado === 'PRESSAO_ARTERIAL') {
            setQuantidadeOpcoes(0)            
            setTeste(opcoes.map((item) => { return item })) 
            setOpcoes([{ descricao: 'SYS'}, { descricao: 'DIA'} ])      
            handleOpcoes(true)
        }
        else {
            setQuantidadeOpcoes(0)
            setOpcoes([])
            handleOpcoes(false)
        }
    }

    useEffect(() => {
        document.getElementById('intervalo').checked = true
        setTipoDado(tipoDadoOptions[0].value)
        setTipoMensagem(tipoMensagemOptions[0].value)
    }, [])

    useEffect(() => {
        const array = []
        setOpcoes([])
        setTeste([])    
        for (let index = 0; index < quantidadeOpcoes; index++) {
            array.push({ descricao: '' })           
        }
        setTeste(array.map((item) => { return item }))       
        setOpcoes(array)
        
    }, [quantidadeOpcoes])

    function enviarDadosTemplateNotificacao() {
        setLoadingVisible(true)        
        const alterando = (id > 0) 
        const template = getDadosForm(alterando)
        if (alterando) {
            alterarTemplate(template)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)

                })
        }
        else {
            cadastrarTemplate(template)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)

                })   
        }              
    }   
    
    function handleChangeTipoControle(value) {
        setTipoControle(value)
    }
    return (
        <form onSubmit={(e) => {e.preventDefault()}} className="formData form">
            <Loading visible={ loadingVisible }/>
            
                <InputText 
                    value={ titulo }
                    onChange={(e) => {
                        setTitulo(e.target.value)
                    }}
                    containerstyle="input" 
                    label="Título" 
                    name="titulo" 
                />           
                <Check 
                    containerstyle='input' 
                    name='preenchimento'
                    label='Preenchimento padrão'
                    checked={ preenchimentoPadrao }
                    onCheck={() => { 
                        setPreenchimentoPadrao(!preenchimentoPadrao)                         
                    }}
                />
                {
                    preenchimentoPadrao == true ?
                    <>
                        <Radio 
                            containerstyle="input" 
                            name='tipoControle'
                            label='Tipo de controle'
                            orientation='horizontal' 
                            onSelect={(value) => { handleChangeTipoControle(value) }}
                            options={ tipoControleOptions } 
                        />
                {
                    tipoControle === 'intervalo' ?
                    <>
                        <div className="col-2">
                            <div className="text col-30-percent" > 
                                <label htmlFor="hiddenText2">Repetir a cada: </label> 
                                <input id='hiddenText2' type="hidden"/></div>
                            <InputText 
                                value={ periodicidade }
                                onChange={(e) => {
                                    setPeriodicidade(e.target.value)                    
                                }}
                                containerstyle="col-20-percent" 
                                label="Intervalo (dias)" 
                                name="peridiocidades">                    
                            </InputText>
                            <div className="text col-30-percent-right" >
                                <label htmlFor="hiddenText3">as: </label> 
                                <input id='hiddenText3' type="hidden"/> </div>
                                <InputText 
                                    value={ hora_notificacao }
                                    onChange={(e) => {
                                        setHoraNotificacao(e.target.value)                    
                                    }}
                                    containerstyle="col-20-percent" 
                                    label="Hora" 
                                    type="time"
                                    name="horaNotificacao">                    
                                </InputText>
                        </div>
                                {/* <div className="text col-30-percent" >
                                   <label htmlFor="hiddenText">No período de:</label> 
                                   <input id='hiddenText' type="hidden"/>
                                </div>
                                <div className="col-2">                                    
                                    <InputText 
                                        containerstyle="input-col" 
                                        value={data_hora_vigencia_inicial}
                                        onChange={(e) => {
                                            setData_hora_vigencia_inicial(e.target.value)
                                        }}
                                        type="date"
                                        name='dataInicial'
                                    label="Data inicial">  s                                                               
                                    </InputText>
                                    <InputText 
                                        containerstyle="input-col" 
                                        value={data_hora_vigencia_final}
                                        onChange={(e) => {
                                            setData_hora_vigencia_final(e.target.value)
                                        }}
                                        type="date"
                                        name='dataFinal'
                                        label="Data inicial">                                                                 
                                    </InputText> 
                                </div> */}
                            </>
                            : ''
                        }
                    </> :
                    ''
                }
               
                
            <InputText 
                    value={ mensagem }
                    onChange={(e) => {
                        setMensagem(e.target.value)
                    }}
                containerstyle="input" 
                label="Pergunta" 
                name="mensagem" />
        
            <InputSelect 
                containerstyle="input" 
                value={ tipo_dado }
                name="tipoDado"
                onChange={(e) => {
                    handleTipoDado(e.target.value)
                    setTipoDado(e.target.value || 'PERSONALIZADO')
                }}
                options={tipoDadoOptions} label="Tipo">                            
            </InputSelect>

            <div className="col-2" style={ podeEditarOpcoes }>
                <InputText 
                    value={ quantidadeOpcoes }
                    onChange={(e) => {
                        setQuantidadeOpcoes(e.target.value)
                    }}
                    containerstyle="input-col" 
                    label="Quantidade de opções" 
                    name="quantidadeOpcoes" />

                <InputSelect 
                    containerstyle="input-col" 
                    value={ tipo_mensagem }
                    onChange={(e) => {
                        setTipoMensagem(e.target.value || 'EDITAVEL')
                    }}
                    name="tipoMensagem"
                    options={ tipoMensagemOptions } label="Tipo opção">                            
                </InputSelect>
            </div>
        
           {   opcoes ?
               opcoes.map((item, index) => {
                    return (
                        <InputText 
                            value={ opcoes[index].descricao }
                            onChange={(e) => {
                               hendleDescricaoOpcao(index, e.target.value)                         
                            }}
                            containerstyle="input"                             
                            label={'Resposta ' + (index + 1) }
                            name={'Resposta_' + index } 
                        />

                    )
               }) : ''
           } 
            <div className="footer"> 
                <SecondaryButton 
                    type="submit" 
                    name='btnCancelar'                   
                    onClick={limparFormulario}
                    containerstyle ="footerButton" 
                    text="Cancelar"><FaTimes /></SecondaryButton>
                <PrimaryButton 
                    name='btnSalvar'
                    onClick={enviarDadosTemplateNotificacao}
                    containerstyle="footerButton" 
                    text="Salvar" ><FaCheck /></PrimaryButton>                
            </div>
        </form> 
    )
}

export default FormPaciente