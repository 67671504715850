import React, { useState } from "react";

import PageDefault from "../../components/pageDefault";
import FormDataContainer from "../../components/formDataContainer";
import FormPaciente from "./formPacientes";
import GridPaciente from "./gridPacientes";
import { PacienteProvider } from "./context";
import "./style.css";
import {
  STEP_CALCULATOR_RISK,
  usePatientRecordContext,
} from "../../context/patientRecordContext";

function Pacientes() {
  const [dadosPacientesSelecionado, setDadosPacientesSelecionado] = useState(
    {},
  );
  const [atualizar, setAtualizar] = useState(false);
  const [visibleBlockingPanel, setVisibleBlockingPanel] = useState(true);
  const FORM_STATE = {
    EDIT: 0,
    INSERT: 1,
    BLOCK: 2,
    NOTHING: 3,
  };
  const contexto = {
    FORM_STATE: FORM_STATE,
    estado: "I",
    atualizarGrade: (atualizar) => {
      setAtualizar(atualizar);
    },
    precisaAtualizar: atualizar,
    data: dadosPacientesSelecionado,
    setData: (newData) => {
      setDadosPacientesSelecionado(newData);
    },
    blockingPanel: visibleBlockingPanel,
    setVisibleBlockingPanel: (data) => {
      setVisibleBlockingPanel(data);
    },
  };

  return (
    <PageDefault>
      <PacienteProvider value={contexto}>
        <FormDataContainer
          isModePatitentRecord={true}
          visibleBlocking={visibleBlockingPanel}
          setVisibleBlocking={setVisibleBlockingPanel}
          title="Paciente"
          btnTitle="Novo Paciente"
        >
          <FormPaciente />
        </FormDataContainer>
        <GridPaciente />
      </PacienteProvider>
    </PageDefault>
  );
}

export default Pacientes;
