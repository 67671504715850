import React from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { BiBlock } from 'react-icons/bi'
import './style.css'
import SimpleButton from '../simpleButton'

const InputText = ({ name, label, containerstyle, type, status, statusMessage, autocomplete, onDeleteAutocomplete, onSelectAutocomplete, mandatory, ...rest}) => {
    return (    
        <div className={"inputTextContainer " + containerstyle}>
            <div>
                <label htmlFor={ name }>{ label }{mandatory && <span style={{color: 'red'}}> *</span>}</label>
            </div>
            <div className='autocompleteContainer'>
                <div className='inputContainer'>
                    <input type={ type || "text"} id={ name } { ...rest } /> 
                    {
                        status ? 
                        <div className='iconStatusInput'>
                            { (status == 'valid') ? <div title={statusMessage.valid}><FaCheck color='green' /></div> : null }
                            { (status == 'invalid') ? <div title={statusMessage.invalid}><BiBlock color='#940000' /></div> : null }
                            { (status == 'loading') ? <div className='loadingIcon'><AiOutlineLoading color='gray' /></div>  : null }
                        </div> :
                        null
                    } 
                </div>
                {
                    autocomplete?.visible == true &&
                    <ul className='autocomplete-list'>
                        {   
                            autocomplete.list.map((item) => {
                                return (                                    
                                    <li >
                                        <SimpleButton stylebutton='select-button' onClick={() => {
                                            onSelectAutocomplete(item.desc)
                                        }}>
                                            <span>{`${item.desc}`}</span>
                                        </SimpleButton>
                                        <SimpleButton stylebutton='delete-button' onClick={() => onDeleteAutocomplete(item.id)}>
                                            <i><FaTimes /></i> 
                                        </SimpleButton>
                                    </li>
                                )
                            })
                        }
                    </ul>
                }           
            </div> 
        </div>
    )
}

export default InputText