import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading";
import { HeaderProntuario } from "./components/HeaderPronturario";
import Comorbidades from "./components/Comorbidades";
import Medicamentos from "./components/Medicamentos";
import Afericoes from "./components/Afericoes";
import ExameFisico from "./components/ExameFisico";
import Exame from "./components/Exame";
import PrimaryButton from "../../components/formComponents/primaryButton";
import SecondaryButton from "../../components/formComponents/secondaryButton";
import ModalConfirmation from "../../components/modalConfirmation";

import Modal from "../../components/modal";
import {
  buscarDadosProntuario,
  atualizaIndice,
} from "../../services/prontuario";
import GraficoHemoglobinaGlicada from "./components/GraficoHemoglobinaGlicada";
import GraficoPerfilLipidico from "./components/GraficoPerfilLipidico";
import GraficoAfericoes from "./components/GraficoAfericoes";
import GraficoFuncaoRenal from "./components/GraficoFuncaoRenal";
import GraficoScoreFraminham from "./components/GraficoScoreFraminham";

import TabelaAfericaoResidencial from "./components/TabelaAfericaoResidencial";
import TabelaAfericaoConsultorio from "./components/TabelaAfericaoConsultorio";

import { Tooltip } from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";

import { atualizarDataProximaConsulta } from "../../services/prontuario";

import {
  calcColorRiscoRenal,
  calcColorFramingham,
  calcColorPressaoAlta,
} from "../../services/utils/colorsIndices";

import "./styles.css";

function Prontuario({ paciente, closeFunction }) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const circleObject = {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "3px",
    marginTop: "2px",
  };

  const inlineContainer = {
    display: "flex",
    flexDirection: "row",
  };

  const pressaoAltaOptions = [
    { label: "Não calculado", value: "-1" },
    { label: "Sem risco", value: "0" },
    { label: "Baixo risco", value: "1" },
    { label: "Moderado risco", value: "2" },
    { label: "Alto risco", value: "3" },
  ];
  const doencaRenalOptions = [
    { label: "Não calculado", value: "-1" },
    { label: "Baixo risco", value: "0" },
    { label: "Moderado risco", value: "1" },
    { label: "Alto risco", value: "2" },
    { label: "Muito alto risco", value: "3" },
  ];

  const flexRow = {
    display: "flex",
    flexDirection: "row",
    width: "95%",
  };
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [modalIndices, setModalIndices] = useState(false);
  const [indices, setIndices] = useState([]);
  const [prazoConsulta, setPrazoConsulta] = useState("");
  const [proximaConsulta, setProximaConsulta] = useState("");
  const [comorbidades, setComorbidades] = useState([]);
  const [visibleNotificacao, setVisibleNotificacao] = useState(false);
  const [graficoHemoglobina, setGraficoHemoglobina] = useState([]);
  const [graficoFramingham, setGraficoFramingham] = useState({
    datas: [],
    valores: [{}],
  });
  const [graficoPerfilLipidico, setGraficoPerfilLipidico] = useState({
    datas: [],
    valores: [{}],
  });
  const [graficoAfericoes, setGraficoAfericoes] = useState({
    datas: [],
    valores: [{}],
  });
  const [graficoFuncaoRenal, setGraficoFuncaoRenal] = useState({
    datas: [],
    valores: [{}],
  });
  const [tabelaAfericaoResidencial, setTabelaAfericaoResidencial] = useState(
    [],
  );
  const [tabelaAfericaoConsultorio, setTabelaAfericaoConsultorio] = useState(
    [],
  );
  const [medicamentos, setMedicamentos] = useState([]);
  const [afericao, setAfericao] = useState([]);
  const [exames, setExames] = useState([]);
  const [exameFisico, setExameFisico] = useState([]);
  const [geral, setGeral] = useState([]);
  const [toolTipRiscoRenal, setToolTipRiscoRenal] = useState(false);
  const [toolTipFramingham, setToolTipFramingham] = useState(false);
  const [toolTipCkedEpi, setToolTipCkedEpi] = useState(false);
  const [visibleConfirmationClosePage, setVisibleConfirmationClosePage] =
    useState(false);
  const query = useQuery();
  useEffect(() => {
    setLoadingVisible(true);
    atualizaProntuario();
  }, [paciente]);

  const toggleToolTipFramingham = () => {
    setToolTipFramingham(!toolTipFramingham);
  };

  const toggleToolTipCkedEpi = () => {
    setToolTipCkedEpi(!toolTipCkedEpi);
  };

  const toggleToolTipRiscoRenal = () => {
    setToolTipRiscoRenal(!toolTipRiscoRenal);
  };

  function handleAtualizaIndices() {
    setLoadingVisible(true);
    atualizaIndice(paciente)
      .then((result) => {
        setPrazoConsulta(result.prazo_proxima_consulta);
        if (result.proxima_consulta) {
          const hoje = new Date(result.proxima_consulta);
          let dia = ("0" + hoje.getDate()).slice(-2);
          let mes = ("0" + (hoje.getMonth() + 1)).slice(-2);
          let data = dia + "/" + mes + "/" + hoje.getFullYear();
          setProximaConsulta(data);
        }
        const indicesModificados = [];
        indicesModificados.push({
          tipo: "FRAMINGHAM",
          descricao: "Score Framingham",
          old:
            result.old_score_framingham == result.score
              ? ""
              : result.old_score_framingham,
          new: result.score == -1 ? "Não calculado" : result.score,
        });
        indicesModificados.push({
          tipo: "PRESSAO_ALTA",
          descricao: "Risco cardiovascular",
          value: result.risco,
          old:
            result.old_risco_pressao_arterial == result.risco
              ? ""
              : pressaoAltaOptions.filter(
                  (item) => item.value == result.old_risco_pressao_arterial,
                )[0].label,
          new: pressaoAltaOptions.filter(
            (item) => item.value == result.risco,
          )[0].label,
        });
        indicesModificados.push({
          tipo: "RISCO_RENAL",
          descricao: "Risco de doença renal crônica",
          value: result.riscoRenal,
          old:
            result.old_risco_renal == result.riscoRenal
              ? ""
              : doencaRenalOptions.filter(
                  (item) => item.value == result.old_risco_renal,
                )[0].label,
          new: doencaRenalOptions.filter(
            (item) => item.value == result.riscoRenal,
          )[0].label,
        });
        indicesModificados.push({
          tipo: "CKD_EPI",
          descricao: "CKD-EPI",
          old: result.old_ckd_epi == result.ckd_epi ? "" : result.old_ckd_epi,
          new: result.ckd_epi == -1 ? "Não calculado" : result.ckd_epi,
        });

        setIndices(indicesModificados);
        setModalIndices(true);

        atualizaProntuario();
        // callNotificacoesPaciente()
      })
      .catch(() => {
        setLoadingVisible(false);
      });
  }

  function callNotificacoesPaciente() {
    setVisibleNotificacao(true);
  }

  function atualizaProntuario() {
    if (!paciente) return;
    buscarDadosProntuario(paciente)
      .then((result) => {
        const dataAtual = new Date();
        const dataNascimento = new Date(result.dt_nascimento);
        const idade = dataAtual.getFullYear() - dataNascimento.getFullYear();
        setAfericao({
          sis: result.sis,
          dia: result.dia,
          fc: result.fc,
          sat: result.sat,
          glicemia: result.glicemia,
          ultima_afericao: result.ultima_afericao,
          paciente: result.id,
          refresh: () => {
            atualizaProntuario();
          },
        });

        setGeral({
          paciente: result.id,
          nome: result.nome,
          idade: idade + " anos",
          whatsapp: result.whatsapp,
          anotacao: result.anotacao,
          score_framingham:
            result.score_framingham == -1
              ? "Não Calculado"
              : result.score_framingham,
          risco_pressao_arterial: result.risco_pressao_arterial,
          risco_cardiovascular: result.risco_cardiovascular,
          risco_renal: result.risco_renal,
          ckd_epi: result.ckd_epi == -1 ? "Não Calculado" : result.ckd_epi,
          id_pessoa: result.id_pessoa,
          dh_ultima_consulta: result.dh_ultima_consulta,
          proxima_consulta: result.proxima_consulta,
        });

        setExameFisico({
          altura: result.altura,
          peso: result.peso,
          imc: result.imc,
          classificacao: result.classificacao,
          paciente: result.id,
          refresh: () => {
            atualizaProntuario();
          },
        });
        setMedicamentos({
          paciente: result.id,
          medicamentos: result.medicamentos,
          ativo: result.ativo,
          refresh: () => {
            atualizaProntuario();
          },
        });
        setExames({
          paciente: result.id,
          refresh: () => {
            atualizaProntuario();
          },
          exames: [
            {
              descricao: "Colesterol",
              resultado: result.colesterol && `${result.colesterol} mg/dl`,
            },
            {
              descricao: "HDL",
              resultado: result.hdl && `${result.hdl} mg/dl`,
            },
            {
              descricao: "Triglicerídios",
              resultado: result.trigliceres && `${result.trigliceres} mg/dl`,
            },
            {
              descricao: "LDL",
              resultado: result.ldl && `${result.ldl} mg/dl`,
            },
            {
              descricao: "Hemoglobina Glicada",
              resultado:
                result.hemoglobina_glicada && `${result.hemoglobina_glicada} %`,
            },
            {
              descricao: "Glicemia Jejum",
              resultado:
                result.glicemia_jejum && `${result.glicemia_jejum} mg/dl`,
            },
            {
              descricao: "Microalbuminuria",
              resultado:
                result.microalbuminuria && `${result.microalbuminuria} mg/g`,
            },
            {
              descricao: "Creatina",
              resultado: result.creatina && `${result.creatina} mg/dl`,
            },
          ],
        });
        setComorbidades({
          paciente: result.id,
          comorbidades: result.comorbidades,
          refresh: () => {
            atualizaProntuario();
          },
        });

        setTabelaAfericaoConsultorio({ dados_medicao: result.media });
        setTabelaAfericaoResidencial({
          dados_medicao: result.afericao_residencial,
        });

        if (result.grafico_hemoglobina) {
          const datas = [];
          const valores = [];
          result.grafico_hemoglobina.map((item) => {
            datas.push(item.data);
            valores.push(item.valor);
          });
          setGraficoHemoglobina({
            datas: datas.reverse(),
            valores: [
              {
                name: "",
                data: valores.reverse(),
              },
            ],
          });
        }

        if (result.grafico_score) {
          const datas = [];
          const valores = [];
          result.grafico_score.map((item) => {
            datas.push(item.data);
            valores.push(item.valor);
          });
          setGraficoFramingham({
            datas: datas.reverse(),
            valores: [
              {
                name: "",
                data: valores.reverse(),
              },
            ],
          });
        }

        graficoFuncaoRenal.datas = [];
        graficoFuncaoRenal.valores = [
          {
            name: "",
            data: "",
          },
        ];
        graficoFuncaoRenal.valores.pop();
        if (result.grafico_creatina) {
          const datas = [];
          const valores = [];
          result.grafico_creatina.map((item) => {
            datas.push(item.data);
            valores.push(item.valor);
          });
          graficoFuncaoRenal.datas = graficoFuncaoRenal.datas.concat(
            datas.reverse(),
          );
          graficoFuncaoRenal.valores.push({
            name: "Creatina",
            data: valores.reverse(),
          });
        }

        if (result.grafico_ckd_epi) {
          const datas = [];
          const valores = [];
          result.grafico_ckd_epi.map((item) => {
            datas.push(item.data);
            valores.push(item.valor);
          });
          graficoFuncaoRenal.datas = graficoFuncaoRenal.datas.concat(
            datas.reverse(),
          );
          graficoFuncaoRenal.datas.sort();
          graficoFuncaoRenal.valores.push({
            name: "CKD EPI",
            data: valores.reverse(),
          });
        }

        graficoPerfilLipidico.datas = [];
        graficoPerfilLipidico.valores = [
          {
            name: "",
            data: "",
          },
        ];
        graficoPerfilLipidico.valores.pop();
        if (result.grafico_hdl) {
          const datas = [];
          const valores = [];
          result.grafico_hdl.map((item) => {
            datas.push(item.data);
            valores.push(item.valor);
          });
          graficoPerfilLipidico.datas = graficoPerfilLipidico.datas.concat(
            datas.reverse(),
          );
          graficoPerfilLipidico.valores.push({
            name: "HDL",
            data: valores.reverse(),
          });
        }

        if (result.grafico_ldl) {
          const datas = [];
          const valores = [];
          result.grafico_ldl.map((item) => {
            datas.push(item.data);
            valores.push(item.valor);
          });
          graficoPerfilLipidico.datas = graficoPerfilLipidico.datas.concat(
            datas.reverse(),
          );
          graficoPerfilLipidico.valores.push({
            name: "LDL",
            data: valores.reverse(),
          });
        }

        if (result.grafico_trigliceres) {
          const datas = [];
          const valores = [];
          result.grafico_trigliceres.map((item) => {
            datas.push(item.data);
            valores.push(item.valor);
          });
          graficoPerfilLipidico.datas = graficoPerfilLipidico.datas.concat(
            datas.reverse(),
          );
          graficoPerfilLipidico.valores.push({
            name: "Trigliceres",
            data: valores.reverse(),
          });
        }

        graficoAfericoes.datas = [];
        graficoAfericoes.valores = [
          {
            name: "",
            data: "",
          },
        ];
        graficoAfericoes.valores.pop();
        if (result.grafico_afericoes) {
          const datas = [];
          const valoresSis = [];
          const valoresDia = [];
          result.grafico_afericoes.map((item) => {
            datas.push(item.data);
            valoresSis.push(item.sis);
            valoresDia.push(item.dia);
          });

          graficoAfericoes.datas = graficoAfericoes.datas.concat(
            datas.reverse(),
          );
          graficoAfericoes.valores.push({
            name: "Sistólica",
            data: valoresSis.reverse(),
          });
          graficoAfericoes.valores.push({
            name: "Diastólica",
            data: valoresDia.reverse(),
          });
        }

        setLoadingVisible(false);
      })
      .catch((error) => {
        setLoadingVisible(false);
      });
  }

  useEffect(() => {
    if (prazoConsulta) {
      const hoje = new Date();
      hoje.setMonth(hoje.getMonth() + Number(prazoConsulta));
      let dia = ("0" + hoje.getDate()).slice(-2);
      let mes = ("0" + hoje.getMonth()).slice(-2);
      let data = dia + "/" + mes + "/" + hoje.getFullYear();
      setProximaConsulta(data);
      atualizarDataProximaConsulta(
        hoje.getFullYear() + "-" + mes + "-" + dia,
        paciente,
      );
    }
  }, [prazoConsulta]);

  return (
    <>
      <Modal style="modalFull" visible={modalIndices}>
        <div className="modalContainer" style={{ height: "50%", width: "40%" }}>
          <div className="modalHeader">
            <h4 className="titleProntuario">Alerta</h4>
            <div className="fecharModal">
              <button
                onClick={() => {
                  setModalIndices(!modalIndices);
                }}
              >
                X
              </button>
            </div>
          </div>
          <div className="modalAlertContent">
            <div className="modalAlerta">
              <div className="alertaIndiciesContainer">
                {indices.map((item) => {
                  return (
                    <div className="alertaIndice">
                      <span className="alertaTitle">
                        <b>{item.descricao}</b>
                      </span>
                      <span className="alertaOldInidice">
                        {item.old == ""
                          ? "Índice não alterado"
                          : "De: " +
                            (String(item.old) == "-1"
                              ? "Não calculado"
                              : item.old)}
                      </span>
                      <div className="alertaNewIndice">
                        {item.tipo == "RISCO_RENAL" ? (
                          <>
                            <Tooltip
                              placement="right"
                              isOpen={toolTipRiscoRenal}
                              toggle={toggleToolTipRiscoRenal}
                              target={item.tipo}
                            >
                              <span style={{ textAlign: "left" }}>
                                Dados Obrigatórios:
                                <ul>
                                  <li>Creatinina</li>
                                  <li>Idade</li>
                                  <li>Microalbuminúria</li>
                                </ul>
                              </span>
                            </Tooltip>
                            <div
                              style={{
                                backgroundColor: calcColorRiscoRenal(
                                  item.value,
                                ),
                                ...circleObject,
                              }}
                            ></div>
                          </>
                        ) : null}
                        {item.tipo == "PRESSAO_ALTA" ? (
                          <div
                            style={{
                              backgroundColor: calcColorPressaoAlta(item.value),
                              ...circleObject,
                            }}
                          ></div>
                        ) : null}
                        {item.tipo == "FRAMINGHAM" ? (
                          <>
                            <Tooltip
                              placement="right"
                              isOpen={toolTipFramingham}
                              toggle={toggleToolTipFramingham}
                              target={item.tipo}
                            >
                              <span style={{ textAlign: "left" }}>
                                Dados Obrigatórios:
                                <ul>
                                  <li>Idade (35-74 anos)</li>
                                  <li>Colesterol Total</li>
                                  <li>HDL</li>
                                  <li>Pressão Sistólica</li>
                                </ul>
                              </span>
                            </Tooltip>
                            <div
                              style={{
                                backgroundColor: calcColorFramingham(item.new),
                                ...circleObject,
                              }}
                            ></div>{" "}
                          </>
                        ) : null}
                        {item.tipo == "CKD_EPI" ? (
                          <Tooltip
                            placement="right"
                            isOpen={toolTipCkedEpi}
                            toggle={toggleToolTipCkedEpi}
                            target={item.tipo}
                          >
                            <span style={{ textAlign: "left" }}>
                              Dados Obrigatórios:
                              <ul>
                                <li>Creatinina</li>
                                <li>Peso</li>
                              </ul>
                            </span>
                          </Tooltip>
                        ) : null}

                        <span className="alertaNewIndice">{item.new}</span>
                        {item.tipo !== "PRESSAO_ALTA" && (
                          <i className="iconInfo" id={item.tipo}>
                            <FaInfoCircle />
                          </i>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                Conforme a classificação de risco do paciente e seus dados
                atuais sugere-se retorno em
                <input
                  className="inputMeses"
                  type="text"
                  value={prazoConsulta}
                  onChange={(e) => {
                    setPrazoConsulta(e.target.value);
                  }}
                />
                mês(es).
              </div>
            </div>
            <div className="containerBtnAlertProntuario">
              <PrimaryButton
                onClick={() => {
                  callNotificacoesPaciente();
                }}
              >
                OK
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  setModalIndices(!modalIndices);
                }}
              >
                Voltar para o prontuário
              </SecondaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <Loading forced={true} visible={loadingVisible} />
      <div className="prontuarioContainer">
        <div className="prontuarioBackground">
          <HeaderProntuario
            closeProntuario={closeFunction}
            notificacoes={{ visibleNotificacao, setVisibleNotificacao }}
            content={geral}
          />
          <Comorbidades content={comorbidades} />
          <Medicamentos content={medicamentos} />
          <Afericoes content={afericao} />
          <ExameFisico content={exameFisico} />
          <Exame content={exames} />
          <GraficoScoreFraminham content={graficoFramingham} />
          <GraficoHemoglobinaGlicada content={graficoHemoglobina} />
          <GraficoPerfilLipidico content={graficoPerfilLipidico} />
          <GraficoFuncaoRenal content={graficoFuncaoRenal} />
          <GraficoAfericoes content={graficoAfericoes} />
          <TabelaAfericaoConsultorio content={tabelaAfericaoConsultorio} />
          <TabelaAfericaoResidencial content={tabelaAfericaoResidencial} />
        </div>
        <ModalConfirmation
          visible={visibleConfirmationClosePage}
          setVisible={setVisibleConfirmationClosePage}
          actionOK={closeFunction}
          actionNO={() => {}}
          title="Fechar"
          text="Deseja realmente fechar o prontuário? Dados não salvos serão perdidos."
        />

        <div className="btnProntuario">
          <SecondaryButton
            containerstyle="overlay90"
            onClick={() => {
              setVisibleConfirmationClosePage(true);
            }}
            style={{ marginRight: "10px" }}
          >
            Fechar prontuário
          </SecondaryButton>

          <PrimaryButton
            containerstyle="overlay90"
            onClick={() => {
              handleAtualizaIndices();
            }}
            style={{ marginRight: "10px" }}
          >
            Concluir consulta
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}

export default Prontuario;
