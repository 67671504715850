const dataBuilder = ({ calculatorRiskData }) => {
  const parseToFloat = (value) => {
    return parseFloat(value.replace(",", ".")).toFixed(2);
  };

  function convertDateFormat(dateString) {
    const [day, month, year] = dateString.split("/");
    return `${month}/${day}/${year}`;
  }

  function getValidDiabeteMelitusDate(value) {
    return new Date(value, 0, 1);
  }

  return {
    dataNascimento: convertDateFormat(calculatorRiskData.dataNascimento),
    sexo: calculatorRiskData.sexo,
    tabagismo: calculatorRiskData.tabagismo,
    dcvPrematuraParentes: calculatorRiskData.dcvPrematuraParentes ?? false,
    doencaAteroscleroticaSignificativaOuObstrucao50:
      calculatorRiskData.doencaAteroscleroticaSignificativaOuObstrucao50 ??
      false,
    doencaAteroscleroticaSubclinica:
      calculatorRiskData.doencaAteroscleroticaSubclinica ?? false,
    aneurismaAortaAbdominal:
      calculatorRiskData.aneurismaAortaAbdominal ?? false,
    insuficienciaCardiaca: calculatorRiskData.insuficienciaCardiaca ?? false,
    diabetesMelitus: calculatorRiskData.diabetesMelitus ?? false,
    diabetesMelitusTipo: parseInt(calculatorRiskData.diabetesMelitusTipo),
    diabetesMelitusDataDiagnostico: calculatorRiskData.diabetesMelitus
      ? getValidDiabeteMelitusDate(
          calculatorRiskData.diabetesMelitusDataDiagnostico,
        )
      : "",
    medicacaoHipertensao: calculatorRiskData.medicacaoHipertensao ?? false,
    pressaoArterialSistolica: parseInt(
      calculatorRiskData.pressaoArterialSistolica,
    ),
    pressaoArterialDiastolica: parseInt(
      calculatorRiskData.pressaoArterialDiastolica,
    ),
    altura: !!calculatorRiskData.altura
      ? parseToFloat(calculatorRiskData.altura)
      : 0,
    peso: !!calculatorRiskData.peso ? parseToFloat(calculatorRiskData.peso) : 0,
    dataExame: calculatorRiskData.dataExame
      ? convertDateFormat(calculatorRiskData.dataExame)
      : undefined,
    colesterolTotal: !!calculatorRiskData.colesterolTotal
      ? parseInt(calculatorRiskData.colesterolTotal)
      : 0,
    ldl: !!calculatorRiskData.ldl ? parseInt(calculatorRiskData.ldl) : 0,
    hdl: !!calculatorRiskData.hdl ? parseInt(calculatorRiskData.hdl) : 0,
    triglicerideos: !!calculatorRiskData.triglicerideos
      ? parseInt(calculatorRiskData.triglicerideos)
      : 0,
    glicemia: !!calculatorRiskData.glicemia
      ? parseInt(calculatorRiskData.glicemia)
      : 0,
    hba1c: !!calculatorRiskData.hba1c
      ? parseToFloat(calculatorRiskData.hba1c)
      : 0,
    creatinina: !!calculatorRiskData.creatinina
      ? parseToFloat(calculatorRiskData.creatinina)
      : 0,
    microalbuminuria: calculatorRiskData.microalbuminuria,
  };
};

export default dataBuilder;
