import React, { useState, useEffect } from 'react'
import  { useHistory, Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import SimpleButton from '../formComponents/simpleButton'
import { listarPermissoes } from '../../services/usuarios'
import { logout } from '../../services/login'
import LogoWhite from '../../images/logo-white.png'
import ToggleComponent from '../../components/formComponents/toggle'
import './style.css'

function Navigator({ disableMobileNavigator, style}) {
    const [menu, setMenu] = useState([])
    const [lastMenuClicked, setLasMenuClicked] = useState('')
    const [lastElement, setLastElement] = useState('')
    const [admMode, setAdmMode] = useState(false)
    const [admModeVisible, setAdmModeVisible] = useState(false)

    window.addEventListener('click', (e) => {     
        if ((e.target.classList.contains('nav'))) {            
            return
        }
    
        if (lastElement) {
            const element = document.getElementById('collapseMenu' + lastElement)
            if (!element) return
            if (element.classList.contains('visible')) {
                collapseMenu(lastElement)
                setLastElement('')
            }
        }   
    })

    const isMobile = window.innerWidth < 800
    const router = useHistory()
    useEffect(() => {
        const adm = localStorage.getItem('@aortic-app/a')   
        changeMode(true, (adm == 'true'))
        listarPermissoes()
            .then((result) => { 
                setMenu(result.permissoes)
                if ((result.perfilUsuario == 'ADMINISTRADOR') &&
                    (result.perfil !== 'ADMINISTRADOR') )
                    setAdmModeVisible(true)
                else 
                    setAdmModeVisible(false)
            })
    }, [])

    function changeMode(setValue, value) {
        if (setValue) {
            setAdmMode(value)
            //localStorage.setItem('@aortic-app/a', value)
            return
        }
        const atual = admMode
        setAdmMode(!atual)
        localStorage.setItem('@aortic-app/a', !atual)
        listarPermissoes()
            .then((result) => { 
                setMenu(result.permissoes)
                setAdmModeVisible(result.perfilUsuario == 'ADMINISTRADOR')
            })
    }

    function handleGoBack() {        
        router.goBack()
    }

    function setInvisibleLastMenu() {
        if (lastMenuClicked) {
            const lastElement = document.getElementById('collapseMenu' + lastMenuClicked)
            lastElement.setAttribute('class', 'secondaryContainer invisible')              
        }
    }
   
    function collapseMenu(id) {
        
        const element = document.getElementById('collapseMenu' + id)
        if (lastMenuClicked) {
            const lastElement = document.getElementById('collapseMenu' + lastMenuClicked)            
            if (id == lastMenuClicked) {
                if (lastElement.classList.contains('visible')) {
                    lastElement.setAttribute('class', 'secondaryContainer invisible')  
                    setLastElement('')  
                }
                else {
                    lastElement.setAttribute('class', 'secondaryContainer visible')    
                    setLastElement(lastMenuClicked)                
                }
                return
            }
            lastElement.setAttribute('class', 'secondaryContainer invisible')  
            setLastElement('')                   
        }
        if (element.classList.contains('visible')) {
            element.setAttribute('class', 'secondaryContainer invisible') 
            setLastElement('')  
        }
        else {
           element.setAttribute('class', 'secondaryContainer visible') 
           setLasMenuClicked(id)
           setLastElement(id)  

        }
        
    }

    function MobileNavigator() {
        return (
            <div className="mobileNavigator">               
                <div className="returnButton">
                    <SimpleButton onClick={ handleGoBack }><FaArrowLeft /></SimpleButton>
                </div>
                <div>
                    <img className='logoMobileNavigator' src={LogoWhite} />
                </div>
            </div>
        )
    }

    return (
        <>
            {
                (isMobile) ?
                <>{(!disableMobileNavigator) ? <MobileNavigator /> : null }</> :
                <>
                
                <div className={ style ? style : ' navigatorContainer' + ' noselect'}>
                    
                    
                    <div className='primaryContainer'>
                        {   menu ?
                            menu.map((item) => {
                                if (item && !item.items) {
                                    return (
                                        <Link key={item.id} onClick={setInvisibleLastMenu} to={'/' + item.link} className="no-underline">
                                            <div className="pill-primary2">
                                                <span>{item.label}</span>
                                            </div>
                                        </Link>
                                    );
                                } else
                                if (item && item.items) {
                                    return (
                                        <div key={item.id} className='menuContainer'>  
                                            <div className='nav pill-primary' id={item.id} onClick={(e) => { collapseMenu(e.currentTarget.id) }}>
                                                <span className='nav'>{item.label}</span>
                                            </div>                       
                                            {
                                                <div id={'collapseMenu' + item.id} className={"secondaryContainer invisible"}>
                                                    {item.items.map((subItem, index) => {
                                                        if (!subItem) return null;
                                                        const style = { marginTop: (index * 40) + 'px' };
                                                        return (
                                                            <Link key={index} onClick={setInvisibleLastMenu} to={'/' + subItem.link}>
                                                                <div style={style} className="pill-secondary">
                                                                    <span>{subItem.label}</span>
                                                                </div>
                                                            </Link>
                                                        );
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    );
                                } 
                            }) : ''}
                    </div>
                    <div className='containerToggleLogout'>
                        <div>
                        {
                            admModeVisible ?
                            <div className='toggleContainer'>
                                <div>
                                    <ToggleComponent
                                        hint='Trocar para modo Adminstrador'
                                        onCheck={() => { changeMode() }}
                                        checked={admMode}
                                    />
                                </div>
                                <div>
                                    <span>Alternar para administrador</span>
                                </div>
                            </div>
                            :
                            null                   

                        }
                        </div>
                        <div>
                            <SimpleButton 
                                buttonType='logout'
                                onClick={ logout } 
                                stylebutton='btnLogout'
                            >
                                    <FiLogOut />
                            </SimpleButton>
                        </div>
                    </div>
                    
                   
                </div>
                </>
            }
        </>
    )
}

export default Navigator