import React, { useEffect, useState } from "react";
import { usePatientRecordContext } from "../../../../context/patientRecordContext";
import InputText from "../../../../components/formComponents/inputText";
import InputSelect from "../../../../components/formComponents/inputSelect";
import { cpfMask, phoneMask } from "../../../../services/utils/mask";
import { isValidCPF } from "../../../../services/utils/validate";
import {
  ativarUsuarioBase64,
  verificarEmailCadastrado,
} from "../../../../services/usuarios";
import ModalAlert from "../../../../components/modalAlert";
import ModalConfirmation from "../../../../components/modalConfirmation";
import Loading from "../../../../components/loading";
import InputTextDate from "../../../../components/inputTextDate";

const BasicData = ({ stateDoc, setStateDoc }) => {
  const { calculatorRiskData, patientCreateData, setPatientCreateData } =
    usePatientRecordContext();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObject, setAlertObject] = useState({});
  const [confirmation, setConfirmation] = useState({
    actionNO: () => {},
    actionOK: () => {},
  });
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const statusMessageDoc = {
    valid: "Documento válido",
    invalid: "Documento inválido",
  };

  function ativarCadastro(id) {
    setLoadingVisible(true);
    ativarUsuarioBase64(id)
      .then(() => {
        setLoadingVisible(false);
      })
      .catch((e) => {
        setLoadingVisible(false);
        console.error(e);
      });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const btn = document.getElementById("btnPacientCreate");

      if (
        patientCreateData?.documento?.length > 0 &&
        !isValidCPF(patientCreateData?.documento)
      ) {
        setStateDoc("invalid");
        return;
      } else {
        setStateDoc("");
      }

      if (patientCreateData?.documento?.length) {
        setStateDoc("loading");
        verificarEmailCadastrado(patientCreateData.documento).then((result) => {
          if (result.message == "CADASTRO_CLINICA") {
            const alert = {
              title: "",
              text: "Esse documento já está sendo utilizado por outro cadastro!",
              widht: "30%",
            };
            setAlertObject(alert);
            setVisibleAlert(true);
            setStateDoc("invalid");
            setPatientCreateData({
              isButtonPatientCreateDisabled: true,
              ...patientCreateData,
            });
            return;
          }

          if (result.message == "CADASTRO_OUTRO") {
            setConfirmation({
              Title: "Usuário já cadastrado",
              Question:
                "Esse documento já possui cadastro no sistema Aortic. Deseja " +
                "enviar um convite para ingressar na sua clínica?",
              hasExtra: result.whatsapp ? true : false,
              extra: {
                type: "whatsapp_link",
                data: {
                  whatsapp: result.whatsapp,
                  message: `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`,
                },
              },
              actionOK: false,
              actionNO: false,
            });
            setVisibleConfirmation(true);
            setStateDoc("invalid");
            setPatientCreateData({
              isButtonPatientCreateDisabled: true,
              ...patientCreateData,
            });
            return;
          }

          if (result.message == "INATIVO") {
            setConfirmation({
              Title: "Usuário inativo",
              Question:
                "Esse documento já possuiu cadastro no sistema Aortic e está inativo no momento. Deseja " +
                "reativá-lo?",
              hasExtra: false,
              actionOK: () => ativarCadastro(result.id),
              actionNO: () => {},
            });
            setVisibleConfirmation(true);
            setStateDoc("invalid");
            setPatientCreateData({
              isButtonPatientCreateDisabled: true,
              ...patientCreateData,
            });
            return;
          }

          setPatientCreateData({
            isButtonPatientCreateDisabled: false,
            ...patientCreateData,
          });
          setStateDoc("valid");
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [patientCreateData?.documento]);

  useEffect(() => {
    if (calculatorRiskData?.dataNascimento) {
      setPatientCreateData({
        ...patientCreateData,
        dataNascimento: calculatorRiskData?.dataNascimento,
      });
    }
  }, [calculatorRiskData]);

  return (
    <div className="basicDataContainer">
      <InputText
        value={patientCreateData?.nome}
        onChange={(e) => {
          setPatientCreateData({ ...patientCreateData, nome: e.target.value });
        }}
        containerstyle="input"
        label="Nome*"
        name="nome"
        required
      />
      <div className="col-2">
        <InputTextDate
          containerstyle="input-col"
          value={patientCreateData?.dataNascimento}
          onDateChange={(dateValue) => {
            setPatientCreateData({
              ...patientCreateData,
              dataNascimento: dateValue,
            });
          }}
          label="Data Nascimento*"
          mask="99/99/9999"
          placeHolderValue="dd/mm/yyyy"
          name="dataNascimento"
        />
        <InputSelect
          containerstyle="input-col"
          value={patientCreateData?.sexo}
          required
          onChange={(e) => {
            setPatientCreateData({
              ...patientCreateData,
              sexo: e.target.value,
            });
          }}
          name="sexo"
          options={[
            { label: "Selecione", value: "" },
            { label: "Masculino", value: "M" },
            { label: "Feminino", value: "F" },
          ]}
          label="Sexo*"
        ></InputSelect>
      </div>
      <div className="col-2">
        <InputText
          value={patientCreateData?.whatsapp}
          onChange={(e) => {
            const whatsappWithMask = phoneMask(e.target.value);
            setPatientCreateData({
              ...patientCreateData,
              whatsapp: whatsappWithMask,
            });
          }}
          containerstyle="input-col"
          type="text"
          label="Whatsapp"
          name="whatsapp"
        />
        <InputText
          value={patientCreateData?.documento}
          status={stateDoc}
          statusMessage={statusMessageDoc}
          onChange={(e) => {
            const validCPF = cpfMask(e.target.value);
            setPatientCreateData({
              ...patientCreateData,
              documento: validCPF,
            });
          }}
          containerstyle="input-col"
          label="Documento (CPF)"
          name="documento"
        />
      </div>
      <Loading forced={true} visible={loadingVisible} />
      <ModalAlert
        visible={visibleAlert}
        setVisible={setVisibleAlert}
        title={alertObject.title}
        text={alertObject.text}
        width={alertObject.widht}
      />
      <ModalConfirmation
        height={"160px"}
        visible={visibleConfirmation}
        setVisible={setVisibleConfirmation}
        actionOK={confirmation.actionOK}
        actionNO={confirmation.actionNO}
        title={confirmation.Title}
        text={confirmation.Question}
        hasExtra={confirmation.hasExtra}
        extras={confirmation.extra}
      />
    </div>
  );
};

export default BasicData;
