import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageDefault from '../../components/pageDefault'
import HistoricoMensagem from './historicoMensagem'
import MenuMensagem from './menuMensagem'
import { MensagemProvider } from './context'
import Navigator from '../../components/navigator'

function Mensagem() {
    const [dadosConversaSelecionada, setDadosConversaSelecionada] = useState([])
    const [ idDestinatario, setIdDestinatario] = useState(0)
    const [ nomeDest, setNomeDest] = useState('')
    const [atualizar, setAtualizar] = useState(false)
    const [malaDireta, setMalaDireta] = useState(false)
    const [indexTab, setIndexTab] = useState(0)
    const [fotoDestinatario, setFotoDestinatario] = useState('')
    const isMobile = window.innerWidth < 800
    const contexto = {
        estado: 'I',
        atualizarGrade: (atualizar) => {
            setAtualizar(atualizar)
        },
        precisaAtualizar: atualizar,
        destinatario: idDestinatario,
        malaDireta: malaDireta,
        handleMalaDireta: () => {
            setMalaDireta(!malaDireta)
        },
        nomeDestinatario: nomeDest,
        atualizaNomeDestinatario: (novo) =>{
            setNomeDest(novo)
        },
        voltarTab: () => {
            setIndexTab(0)
        },
        setDestinatario: (newDestinatario) => {
            setIdDestinatario(newDestinatario)
        },
        data: dadosConversaSelecionada,
        fotoDestinatario: fotoDestinatario,
        setFotoDestinatario: (foto) => {
            setFotoDestinatario(foto)
        },
        setData: (newData) => {
            setDadosConversaSelecionada(newData)
             if (indexTab === 0) setIndexTab(1)
            // else setIndexTab(0)
        },
    }
        
    return (
        
        <PageDefault>            
            <MensagemProvider value={ contexto }> 
                { 
                    isMobile ?
                    <>
                        <Navigator />                           
                        <Tabs selectedIndex={indexTab}>
                            <TabList  style={ { display: 'none' } }>
                                <Tab></Tab>
                                <Tab></Tab>
                            </TabList>
                            <TabPanel>
                                <MenuMensagem /> 
                            </TabPanel>
                            <TabPanel>
                                <HistoricoMensagem />
                            </TabPanel>
                        </Tabs> 
                    </>

                    : 
                    <>
                        <MenuMensagem /> 
                        <HistoricoMensagem />
                    </>
                }
                
            </MensagemProvider>                       
        </PageDefault>
    )
}

export default Mensagem