import React from 'react'
import PageDefaultAdm from './pageDefaultAdm'

function PageAdm() {
    return (
        <div>
            <PageDefaultAdm 
                formArea={{ title: 'Administrador', content: null }}
                gridArea=''
            />
        </div>
    )
}

export default PageAdm