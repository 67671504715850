import React, { useState, useEffect, useContext } from 'react'
import InputText from '../../../../components/formComponents/inputText'
import Check from '../../../../components/formComponents/checkBox'
import PrimaryButton from '../../../../components/formComponents/primaryButton'
import SecondaryButton from '../../../../components/formComponents/secondaryButton'
import {  cadastrarClinica, alterarClinica } from '../../../../services/clinicas'
import { FaCheck, FaTimes } from 'react-icons/fa';
import { ClinicasAdmContext } from '../context'

import Loading from '../../../../components/loading'
import { useHistory } from 'react-router-dom'

import './style.css'

function FormClinica({ value, classForm, perfil}) {
    
    const [loadingVisible, setLoadingVisible] = useState(false)
    const { atualizarGrade, data, setData } = useContext(ClinicasAdmContext)
    /*-----Hooks campos formulario-----*/
    const [id, setId] = useState('')
    const [status, setStatus] = useState('')
    const [nome, setNome] = useState('')    
    /*----------------------------------*/    
    function limparFormulario() {
        setId('')
        setStatus('')
        setNome('')        
    }
    
    function setDadosForm(clinica) {
        setId(clinica.id)
        setStatus(clinica.ativa)
        setNome(clinica.nome)
    }
    
    function getDadosForm(alterando) {
        const clinicaBase = {            
            nome,
            ativa: status
        }
        if (alterando) {
            return {
            id,
            ...clinicaBase
            }  
        } 
        else {
            return clinicaBase
        }        
    }
    
    useEffect(() => {
        setDadosForm(data)        
    }, [data])
    
    useEffect(() => {        
        if (value) setDadosForm(value)         
    }, [])

    function enviarDadosClinica() {      
        setLoadingVisible(true)        
        const alterando = (id > 0) 
        const template = getDadosForm(alterando)
        if (alterando) {
            alterarClinica(template)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                })
        }
        else {
            cadastrarClinica(template)
                .then((result) => {
                    limparFormulario()
                    atualizarGrade(true)
                    setLoadingVisible(false)
                })   
        }              
        
    }
   
    return (
        <>           
            <form onSubmit={(e) => {e.preventDefault()}} className={ classForm ? classForm : 'form' }>
                <Loading 
                    forced={ perfil ? true : false }
                    visible={ loadingVisible }
                />
                 
                <Check 
                    containerstyle='input marginTop20' 
                    name='preenchimento'
                    label='Clínica ativa'
                    checked={ status }
                    onCheck={() => { 
                        setStatus(!status)                         
                    }}
                />
                               
                <InputText 
                    value={ nome }
                    onChange={(e) => {
                        setNome(e.target.value)
                    }}
                    containerstyle="input" 
                    label="Nome" 
                    name="nome"></InputText>
              
                <div className="footer"> 
                    <SecondaryButton 
                        type="submit"                    
                        onClick={limparFormulario}
                        containerstyle ="footerButton" 
                        text="Cancelar"><FaTimes /></SecondaryButton>
                    <PrimaryButton 
                        onClick={enviarDadosClinica }
                        containerstyle="footerButton" 
                        text="Salvar" ><FaCheck /></PrimaryButton>                
                </div>
            </form> 
        </>
    )
}

export default FormClinica