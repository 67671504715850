import React, { useState } from "react";

import PageDefault from "../../components/pageDefault";
import FormDataContainer from "../../components/formDataContainer";
import FormMedico from "./formMedicos";
import GridMedicos from "./gridMedicos";
import { MedicoProvider } from "./context";
import "./style.css";
import ModalNewUser from "../../components/modalNewUser";

function Medicos() {
  const [dadosMedicoSelecionado, setDadosMedicoSelecionado] = useState({});
  const [atualizar, setAtualizar] = useState(false);
  const [visibleBlockingPanel, setVisibleBlockingPanel] = useState(true);
  const [isShowModalNewUser, setIsShowModalNewUser] = useState(false);

  const contextoMedico = {
    estado: "I",
    atualizarGrade: (atualizar) => {
      setAtualizar(atualizar);
    },
    precisaAtualizar: atualizar,
    data: dadosMedicoSelecionado,
    setData: (newData) => {
      setDadosMedicoSelecionado(newData);
    },
    blockingPanel: visibleBlockingPanel,
    setVisibleBlockingPanel: (data) => {
      setVisibleBlockingPanel(data);
    },
  };

  return (
    <PageDefault>
      <MedicoProvider value={contextoMedico}>
        <ModalNewUser
          visible={isShowModalNewUser}
          setIsShowModalNewUser={setIsShowModalNewUser}
          isDoctor
        />
        <FormDataContainer
          visibleBlocking={visibleBlockingPanel}
          setVisibleBlocking={setVisibleBlockingPanel}
          title="Corpo Clínico"
          btnTitle="Novo médico"
          setIsShowModalNewUser={setIsShowModalNewUser}
          isCreateUserByMail
        >
          <FormMedico />
        </FormDataContainer>
        <GridMedicos />
      </MedicoProvider>
    </PageDefault>
  );
}

export default Medicos;
