import React, { useState, useEffect } from 'react'
import LogoAortic from '../../../images/logo-aortic.png'
import { useHistory } from 'react-router-dom'
import { loginAdm } from '../../../services/login'
import { setTokenAdm, getTokenAdm  } from '../../../services/utils/token'
import { registrarPushToken } from '../../../services/pushNotification'
import ModalAlert from '../../../components/modalAlert'
import Loading from '../../../components/loading'
import './styles.css'

function Login() {
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [visibleAlert, setVisibleAlert] = useState(false)
    const [alertObject, setAlertObject] = useState({})
    const history = useHistory()

    useEffect(() => {
        if (getTokenAdm()) {
            history.push('/adm')
        }
    }, [])


    function autenticarUsuario() {
        setLoadingVisible(true)
        loginAdm({email, senha})
            .then((retornoAutenticacao) => {
                setLoadingVisible(false)
                if (retornoAutenticacao.auth === true) {
                    registrarPushToken(retornoAutenticacao.pessoa)
                    setTokenAdm(retornoAutenticacao.token, retornoAutenticacao.pessoa)
                    window.location.pathname = '/adm/clinicas'
                    //history.push('/adm')
                }
                else {
                    const alert = {
                        title: '',
                        text: retornoAutenticacao.message
                    }
                    setAlertObject(alert)
                    setVisibleAlert(true)  
                }
            })
            .catch((err) => {              
                setLoadingVisible(false)
                const alert = {
                    title: '',
                    text: 'Usuário ou senha inválidos. Tente novamente',
                }
                setAlertObject(alert)
                setVisibleAlert(true)     
            })
        
    }
    return (
        <>
            <ModalAlert 
                visible={visibleAlert} 
                setVisible={setVisibleAlert} 
                title={alertObject.title} 
                text={alertObject.text}
            />           
            <Loading visible={loadingVisible} />
            <div className="containerLogin">                 
                <div>                
                    <form onSubmit={(e) => {e.preventDefault()}}>
                        <h4>LOGIN</h4>
                        <div className="inputLogin">
                            <div>
                                <label htmlFor='login'>Email</label>
                            </div>
                            <div>
                                <input 
                                    value={ email }
                                    onChange={ (e) => { setEmail(e.currentTarget.value) }}
                                    type='text' 
                                    name='login' 
                                    id='loginUser'  />    
                            </div>  
                        </div>
                        <div className="inputLogin">
                            <div>
                                <label htmlFor='senha'>Senha</label>
                            </div>
                            <div>
                                <input 
                                    value={ senha }
                                    onChange={ (e) => { setSenha(e.currentTarget.value) }}
                                    type='password' 
                                    name='senha' 
                                    id='senhaLogin'  />    
                            </div>  
                        </div>  
                        <button className='login' onClick={autenticarUsuario}>Entrar</button>   
                    </form>
                </div>
                <div className="logoLogin">
                    <img src={ LogoAortic } alt=""/>
                </div>
            </div>
        </>
        
    )
}

export default Login