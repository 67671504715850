import React, { useState, useEffect, useContext } from 'react'
import DataList from '../../../../components/dataList'
import { FaEdit, FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa'
import SimpleButton from '../../../../components/formComponents/simpleButton'
import { listarClinicasAdm } from '../../../../services/clinicas'
import { ClinicasAdmContext } from '../context'
// import { buscarMedico } from '../../../../services/medicos'
import Loading from '../../../../components/loading'

import './styles.css'

function GridClinicas() {
  const { setData, precisaAtualizar, atualizarGrade } = useContext(ClinicasAdmContext)
  const [listaClinicas, setListaClinicas] = useState([])
  const [originalListaClinicas, setOriginalListaClinicas] = useState([])
  const [loadingVisible, setLoadingVisible] = useState(false)

  let toFilterText = ''
  useEffect(() => {
    listarClinicasAdm()
      .then((result) => {
        setListaClinicas(result) 
        setOriginalListaClinicas(result)
      })    
  }, [])
  
  function selecionaClinica(id) {
    setLoadingVisible(true)
    setData(listaClinicas.filter((item) => item.id == id)[0])
    setLoadingVisible(false)   
  }

  function filter(value) {
    if (String(value.id).includes(toFilterText)) return value
    if (value.nome.includes(toFilterText)) return value
  }

  function filtrarClinicas(filterText) {
    setListaClinicas(originalListaClinicas)     
    if (!filterText) return
    toFilterText = filterText
    const filteredListaClinicas = listaClinicas.filter(filter)
    setListaClinicas(filteredListaClinicas)
  }

  useEffect(() => {
    listarClinicasAdm()
    .then((result) => {
      setListaClinicas(result) 
      setOriginalListaClinicas(result)
      atualizarGrade(false)
    })   
  }, [atualizarGrade, precisaAtualizar])

const columns = [
        {
          Header: "Nome",
          accessor: "nome",
        },
        {
          Header: "Número de Médicos",
          accessor: "qtd_medicos",
        },
        {
          Header: "Número de Pacientes",
          accessor: "qtd_pacientes",
        },
        {
          Header: 'Status',
          accessor: 'ativa',
          Cell: row => (  
              <SimpleButton>
                  { 
                      row.row.cells[2].value == true ? 
                      <FaCheck color='green' /> :
                      <FaTimes /> 
                  }
              </SimpleButton>
          )
        },
        {
          Header: '',
          accessor: 'edit',
          Cell: row => (
          <SimpleButton 
            alt='Editar cadastro'
            title='Editar cadastro'
            onClick={() => {selecionaClinica(row.row.cells[0].value)} }><FaEdit /></SimpleButton>
          )
        },       
      ]
    return (
        <>
          <Loading visible={ loadingVisible }/>
            <DataList searchfunction={ filtrarClinicas } columns={columns} data= {listaClinicas}></DataList>  
        </>
    )
}

export default GridClinicas