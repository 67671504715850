import React from 'react'
import './styles.css'

function TabelaAfericaoResidencial({ content }) {
    return (
        <div className='cardContainer'>
        <h4 className='titleProntuario'>Aferição Pressão Arterial Consultorio</h4>            
        <div className="cardProntuario">       
            <div className="tableDefault">
                <div className="tableColumn">
                    <div className="secondaryCell"></div>
                    <div className="secondaryCell"><span>PAS</span><div className="blueCircle"></div></div>
                    <div className="secondaryCell"><span>PAD</span> <div className="yellowCircle"></div></div>
                    
                </div>
                {
                    content.dados_medicao &&
                    content.dados_medicao.map((item) => {
                        return (
                            <div className="tableColumn">
                                <div className="secondaryCell">{ item.data }</div>
                                <div className="neutralCell">{ item.sis }<div className="blueCircle"> </div></div>
                                <div className="neutralCell">{ item.dia }<div className="yellowCircle"></div></div>
                            </div>
                        )
                    })
                }
            </div>     
            
        </div>            
    </div>
    )
}

export default TabelaAfericaoResidencial