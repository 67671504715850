import React, { useState } from 'react'

import PageDefault from '../../components/pageDefault'
import FormDataContainer from '../../components/formDataContainer'
import FormAgendamento from './formAgendamentos'
import GridAgendamento from './gridAgendamentos'
import { AgendamentoProvider } from './context'
import './style.css'

function Agendamentos() {
    const [dadosAgendamentoSelecionado, setDadosAgendamentoSelecionado] = useState({})
    const [atualizar, setAtualizar] = useState(false)
    const [visibleBlockingPanel, setVisibleBlockingPanel] = useState(true)
    const contexto = {
        estado: 'I',
        atualizarGrade: (atualizar) => {
            setAtualizar(atualizar)
        },
        precisaAtualizar: atualizar,
        data: dadosAgendamentoSelecionado,
        setData: (newData) => {
            setDadosAgendamentoSelecionado(newData)
        },
        blockingPanel: visibleBlockingPanel,
        setVisibleBlockingPanel: (data) => {
            setVisibleBlockingPanel(data)
        }
    }
        
    return (
        
        <PageDefault>            
            <AgendamentoProvider value={ contexto }>           
                <FormDataContainer 
                    visibleBlocking={visibleBlockingPanel}
                    setVisibleBlocking={setVisibleBlockingPanel}
                    title="Agendamentos"
                    btnTitle="Novo agendamento">              
                    <FormAgendamento />
                </FormDataContainer>   
                <GridAgendamento />  
            </AgendamentoProvider>                       
        </PageDefault>
    )
}

export default Agendamentos