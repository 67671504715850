import React, { useState, useEffect } from 'react'
import SimpleButton from '../../../../components/formComponents/simpleButton'
import SecondaryButton from '../../../../components/formComponents/secondaryButton'
import { FaEdit, FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa'
import InputText from '../../../../components/formComponents/inputText'
import InputSelect from '../../../../components/formComponents/inputSelect'
import DataList from '../../../../components/dataList'
import Modal from '../../../../components/modal'
import Loading from '../../../../components/loading'
import { cadastrarMedicamento, listarMedicamentos, deletarMedicamento, 
         listarMedicamentosMedicos, alteraStatusMedicamento,
        deletarMedicamentoMedico } from '../../../../services/medicamentos'

import './styles.css'

function Medicamentos({ content }) {
    const [modalVisivel, setModalVisivel] = useState(false)
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [listaMedicamentos, setListaMedicamentos] = useState([])
    const [descricao, setDescricao] = useState('')
    const [posologia, setPosologia] = useState('')
    const [autocomplete, setAutocomplete] = useState({ visible: false, list: []}) 
    const lista = [
        'Teste 1', 'Teste 2', 'Teste 2'
        ]
   
    const [viaReceita, setViaReceita] = useState(false)
    const [tipoReceita, setTipoReceita] = useState('simples')
    //const controladoOptions = [{label: 'Sim', value: 'true'},{label: 'Não', value: 'false'}]
    const tipoReceitaOptions = [ {label:'Simples', value: 'simples'},                               
                                {label:'Especial (2 vias)', value: 'especial_2_vias'},
                                {label:'Azul (B1/B2)', value: 'azul_b1_b2'},
                                {label:'Amarela (A1/A2)', value: 'amarela_a1_a2'}]

    const viaReceitaOptions = [ {label:'Selecione', value: ''},
                                {label:'Oral', value: 'oral'},                               
                                {label:'Intramuscular', value: 'intramuscular'},
                                {label:'Endovenosa', value: 'endovenosa'},
                                {label:'Retal', value: 'retal'},
                                {label:'Dermatológica', value: 'dermatologica'},                               
                                {label:'Nasal', value: 'nasal'},
                                {label:'Inalatória', value: 'inalatoria'},
                                {label:'Oftálmica', value: 'oftalmica'},
                                {label:'Otológica', value: 'otoloica'}]

   
    useEffect(() => {
        document.getElementById("posologia").addEventListener("focus", hideAutoComplete, true)
        document.getElementById("tipo_receita").addEventListener("focus", hideAutoComplete, true)
    }, [])
    function handleIncluirMedicamento() {
        setLoadingVisible(true)
        const medicamento = { id_paciente: content.paciente, descricao, posologia, tipo_receita: tipoReceita, via: viaReceita }
        cadastrarMedicamento(medicamento)
            .then((result) => {      
                content.refresh()
                handleCarregarListaMedicamentos()          
                setDescricao('')
                setPosologia('')               
                setLoadingVisible(false)
            })
            .catch((erro) => {
                setLoadingVisible(false)
            })
    }

    function handleDeletarMedicamento(id) {
        console.log(id)
        setLoadingVisible(true)
        deletarMedicamento(id)
            .then(() => {
                //content.refresh()
                handleCarregarListaMedicamentos()          
                .then(() => {
                    setLoadingVisible(false)
                })
                .catch(() => {
                    setLoadingVisible(false)
                })  
            })
            .catch(() => {
                setLoadingVisible(false)
            })
    }

    function handleCarregarListaMedicamentos() {
        listarMedicamentos(content.paciente)
            .then((result) => {
                setListaMedicamentos(result)
            })
    }

    function handleCarregarMedicamentos() {
        setModalVisivel(true)
        handleCarregarListaMedicamentos()
    }

    function handleChangeStatus(id) {
        setLoadingVisible(true)
        alteraStatusMedicamento(id)
            .then(() => {
                setLoadingVisible(false)
                content.refresh()
                handleCarregarListaMedicamentos()
            })
            .catch(() => {
                setLoadingVisible(false)
            })
    }

    function hideAutoComplete() { 
        setAutocomplete({
            visible: false,
            list: []
        }) 
    }

    function pesquisaMedicamento(value) {
        setDescricao(value)
        if (!value) {
            setAutocomplete({
                visible: false,
                list: []
            })
            return
        }
        listarMedicamentosMedicos(value)
            .then((response) => {
                setAutocomplete({
                    visible: response.length > 0,
                    list: response.map(item => {
                        return { desc: item.descricao_posologia,
                                id: item.id }
                        })
                })        
            })        
    }

    function deletarMedicamentoLista(id) {
        deletarMedicamentoMedico(id)
            .then((response) => {
                listarMedicamentosMedicos(descricao)
                    .then((response) => {                       
                        setAutocomplete({
                            visible: response.length > 0,
                            list: response.map(item => {
                                return { desc: item.descricao_posologia,
                                        id: item.id }
                                })
                        })              
                    }) 
            })
    }

    function selecionaMedicamento(desc) {
        const splitValue = desc.split('(')
        setDescricao(splitValue[0])
        setPosologia(splitValue[1].substring(0, splitValue[1].length - 1))
        setAutocomplete({
            visible: false,
            list: []
        })
    }

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
          Header: "Medicamento",
          accessor: "descricao",
        },
        {
          Header: "Posologia",
          accessor: "posologia",
        },
        {
            Header: "Inclusao",
            accessor: "created_at"
        },
        {
            Header: "Tipo de receita",
            accessor: "tipo_receita",
            Cell: row => (
                <>
                    {
                        tipoReceitaOptions.filter(a => a.value == row.row.cells[4].value)[0] &&
                        tipoReceitaOptions.filter(a => a.value == row.row.cells[4].value)[0].label
                    }
                </>
            )
        },
        {
            Header: "Via medicamento",
            accessor: "via",
            Cell: row => (
                <>
                    {
                        viaReceitaOptions.filter(a => a.value == row.row.cells[5].value)[0] &&
                        viaReceitaOptions.filter(a => a.value == row.row.cells[5].value)[0].label
                    }
                </>
            )
        },
        {
            Header: 'Ativo',
            accessor: 'ativo',
            Cell: row => (  
                <SimpleButton onClick={() => { handleChangeStatus(row.row.cells[0].value)} }>
                    { 
                        row.row.cells[6].value == true ? 
                        <FaCheck color='green' /> :
                        <FaTimes /> 
                    }
                </SimpleButton>
            )
        },
        {
          Header: '',
          accessor: 'delete',
          Cell: row => (
              
          <SimpleButton 
            needPrevent={true}
            alt='Excluir cadastro'
            title='Excluir cadastro'
            buttonType='delete' onClick={(e) => { handleDeletarMedicamento(row.row.cells[0].value); } }><FaTrashAlt /></SimpleButton>
          )
        },      
       
      ]
     return (
        <>
            <Loading forced={true} visible={ loadingVisible } />
            <Modal onClick={ () => { hideAutoComplete(); }} style='modalFull' visible={ modalVisivel }>
                <div className="modalContainer">
                    <div>
                        <div className="modalHeader">
                            <h4 className="titleProntuario">
                                Medicamentos de uso contínuo
                            </h4> 
                            <div className='fecharModal'>
                                <button onClick={ () => { hideAutoComplete(); setModalVisivel(!modalVisivel) } }>X</button> 
                            </div>     
                        </div>
                        <div className="modalAfericoesContent">                            
                            <div className="modalAfericoesGrid">
                                <DataList hideDirDescription={ true } stylecontainer='gridModal' filter={<></>} columns={columns} data= {listaMedicamentos}></DataList> 
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='cardContainer'>
                <h4 className='titleProntuario'>Medicamentos de uso contínuo</h4>     
                <div className="cardProntuarioBackground">
                    <div className="modalAfericoesForm">
                        <div  style={{ width: '100%' }} className="inputContainer">
                            <div className="medicamentosForm">
                                <div className="medicamentosFormLine">
                                    <InputText 
                                        value={ descricao }
                                        onChange={(e) => {
                                            pesquisaMedicamento(e.target.value)
                                        }}
                                        autoComplete="off"
                                        autocomplete={autocomplete}
                                        onDeleteAutocomplete={deletarMedicamentoLista}
                                        onSelectAutocomplete={selecionaMedicamento}
                                        containerstyle='line1'
                                        label="Medicação" 
                                        name="medicacao" 
                                        tabIndex={2}                                                    

                                    />
                                    <InputSelect 
                                        containerstyle="col-20-percent tipo-receita" 
                                        value={ tipoReceita }
                                        onChange={(e) => {
                                            setTipoReceita(e.target.value)
                                        }}
                                        name='tipo_receita'
                                        options={tipoReceitaOptions} label="Tipo de receita:">                            
                                    </InputSelect>    
                                </div>
                                <div className="medicamentosFormLine">
                                    <InputText 
                                        value={ posologia }
                                        onChange={(e) => {
                                            setPosologia(e.target.value)
                                        }}
                                        containerstyle='line1'
                                        label="Posologia" 
                                        name="posologia" 
                                    />      
                                    <InputSelect 
                                            containerstyle="col-20-percent tipo-receita" 
                                            value={ viaReceita }
                                            onChange={(e) => {
                                                setViaReceita(e.target.value)
                                            }}
                                            name='via_receita'
                                            options={viaReceitaOptions} label="Via:">                            
                                    </InputSelect>    
                                </div>       
                            </div>
                                                    
                        </div>                                
                        <SecondaryButton onClick={ handleIncluirMedicamento  }>+ Incluir</SecondaryButton>
                    </div>    
                    <div className="cardProntuario">            
                        <ul>
                            { 
                                content.medicamentos ?
                                content.medicamentos.map((item) => {                                
                                    return (
                                        <li><span className='titleCard'>{ item.descricao }:</span> { item.posologia } - { item.via } </li>
                                    )                                
                                }) :
                                <li>-----</li>
                            }            
                        </ul>    
                        <SimpleButton onClick={() => {handleCarregarMedicamentos()} }><FaEdit /></SimpleButton>
                    </div>            
                </div>       
            </div>
        </>
    )
}

export default Medicamentos