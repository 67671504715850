import React, { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import './styles.css'

function inputSeacrh({ label, placeholder, searchfunction, ...rest }) {
    let timer
    function changeContent(value) {    
        clearTimeout(timer)
        timer = setTimeout(() => {
            searchfunction(value)
        }, 500)    
        //return () => ;
    }
    
    return (
        <div className='searchContainer'>
            <div>
                { label ? 
                <div>
                    <label htmlFor="searchInput">{ label }</label>
                </div> 
                : '' }
                
                <div>
                    <i className="searchIcon"><FaSearch /></i>   
                    <input 
                        alt={'Digite ao menos 3 caracteres para pesquisar'}
                        {...rest}
                        onChange={ (e) => { changeContent(e.target.value) } }
                        type='text' 
                        placeholder={ placeholder ? placeholder : 'Procurar'}   
                    /> 
                </div> 
            </div>   
        </div> 
    )
           
}

export default inputSeacrh