import React, { useState, useEffect, useContext } from "react";
import DataList from "../../../components/dataList";
import { FaEdit, FaTrashAlt, FaCheck, FaTimes } from "react-icons/fa";
import SimpleButton from "../../../components/formComponents/simpleButton";
import {
  listarMedicos,
  deletarMedico,
  alteraStatusAdmin,
  pesquisarMedicos,
} from "../../../services/medicos";
import { MedicoContext } from "../context";
import { buscarMedico } from "../../../services/medicos";
import Navigator from "../../../components/navigator";
import Loading from "../../../components/loading";

import "./style.css";

function GridMedicos() {
  const { setData, precisaAtualizar, atualizarGrade, setVisibleBlockingPanel } =
    useContext(MedicoContext);
  const [listaMedicos, setListaMedicos] = useState([]);
  const [originalListaMedicos, setOriginalListaMedicos] = useState([]);
  const [loadingVisible, setLoadingVisible] = useState(false);

  //const [searchText, setSearchText] = useState('')
  let toFilterText = "";
  useEffect(() => {
    listarMedicos().then((result) => {
      setListaMedicos(result);
      setOriginalListaMedicos(result);
    });
  }, []);

  function selecionaMedico(id) {
    setLoadingVisible(true);
    buscarMedico(id).then((result) => {
      setLoadingVisible(false);
      setData(result);
      setVisibleBlockingPanel(false);
    });
  }

  function excluirMedico(id) {
    setLoadingVisible(true);
    deletarMedico(id).then((result) => {
      setLoadingVisible(false);
      atualizarGrade(true);
    });
  }

  function filter(value) {
    if (String(value.rqe).includes(toFilterText)) return value;
    if (value.email.includes(toFilterText)) return value;
    if (value.nome.includes(toFilterText)) return value;
    if (value.crm.includes(toFilterText)) return value;
    if (String(value.id).includes(toFilterText)) return value;
    if (String(value.especialidade).includes(toFilterText)) return value;
  }

  function filtrarMedicos(filterText) {
    // setListaMedicos(originalListaMedicos)
    // if (!filterText) return
    // toFilterText = filterText
    // const filteredListaMedicos = listaMedicos.filter(filter)
    // setListaMedicos(filteredListaMedicos)
    if (String(filterText).length < 3 && String(filterText).length > 0) return;
    pesquisarMedicos(filterText).then((result) => {
      setListaMedicos(result);
      setOriginalListaMedicos(result);
      atualizarGrade(false);
    });
  }

  function handleAdmin(id) {
    setLoadingVisible(true);
    alteraStatusAdmin(id)
      .then(() => {
        setLoadingVisible(false);
        listarMedicos().then((result) => {
          setListaMedicos(result);
          setOriginalListaMedicos(result);
          atualizarGrade(false);
        });
      })
      .catch(() => {
        setLoadingVisible(false);
      });
  }

  useEffect(() => {
    listarMedicos().then((result) => {
      setListaMedicos(result);
      setOriginalListaMedicos(result);
      atualizarGrade(false);
    });
  }, [atualizarGrade, precisaAtualizar]);

  const columns = [
    /*{
          Header: "Id",
          accessor: "id",
        },*/
    {
      Header: "Nome",
      accessor: "nome",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "CRM",
      accessor: "crm",
    },
    {
      Header: "Especialidade",
      accessor: "especialidade",
    },
    {
      Header: "RQE",
      accessor: "rqe",
    },
    {
      Header: (props) =>
        listaMedicos[0] ? (listaMedicos[0].altera_adm ? "Admin" : "") : "",
      accessor: "administrador",
      Cell: (row) => {
        return (
          <>
            {listaMedicos[0].altera_adm ? (
              <SimpleButton
                alt="Administrador?"
                title="Administrador?"
                onClick={() => {
                  handleAdmin(row.row.original.id);
                }}
              >
                {row.row.values.administrador == true ? (
                  <FaCheck color="green" />
                ) : (
                  <FaTimes />
                )}
              </SimpleButton>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "edit",
      Cell: (row) => (
        <SimpleButton
          alt="Editar cadastro"
          title="Editar cadastro"
          onClick={() => {
            selecionaMedico(row.row.original.id);
          }}
        >
          <FaEdit />
        </SimpleButton>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      Cell: (row) => (
        <SimpleButton
          alt="Excluir cadastro"
          title="Excluir cadastro"
          buttonType="delete"
          onClick={() => {
            excluirMedico(row.row.original.id);
          }}
        >
          <FaTrashAlt />
        </SimpleButton>
      ),
    },
  ];
  return (
    <>
      <Loading visible={loadingVisible} />
      <div className="mainContent">
        <Navigator />
        <DataList
          searchfunction={filtrarMedicos}
          columns={columns}
          data={listaMedicos}
        ></DataList>
      </div>
    </>
  );
}

export default GridMedicos;
