import React, { useState, useContext } from 'react'
import Modal from '../../../components/modal'
import InputSelect from '../../../components/formComponents/inputSelect'
import InputText from '../../../components/formComponents/inputText'
import SecondaryButton from '../../../components/formComponents/secondaryButton'
import PrimaryButton from '../../../components/formComponents/primaryButton'
import { MensagemContext } from '../context'
import { FaCheck, FaTimes } from 'react-icons/fa';
import { novaMalaDireta } from '../../../services/mensagens'
import Loading from '../../../components/loading'
import ModalAlert from '../../../components/modalAlert'

import './styles.css'

function MalaDireta({ visible }) {
    const [grupo, setGrupo] = useState('')
    const [sexo, setSexo] = useState('')
    const [titulo, setTitulo] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [loadingVisible, setLoadingVisible] = useState(false)    
    const [visibleAlert, setVisibleAlert] = useState(false)
    const [alertObject, setAlertObject] = useState({})
    const sexoOptions = [{ label: 'Masculino', value: 'M' },
                         { label: 'Feminino', value: 'F' },
                         { label: 'Todos', value: 'T' }]
    const gruposOptions = [ { label: 'Pacientes', value: 'PACIENTE' },
                            { label: 'Médicos', value: 'MEDICO' },
                            { label: 'Recepção', value: 'RECEPCAO' },
                            { label: 'Todos', value: 'TODOS' }]
    
    function handleCloseModal() {
        handleMalaDireta()
    }

    const { handleMalaDireta, atualizarGrade } = useContext(MensagemContext)

    function handleNovaMalaDireta() {
        setLoadingVisible(true)
        const objetoMalaDireta = {
            titulo,
            mensagem,
            sexo,
            grupo
        }
        
        novaMalaDireta(objetoMalaDireta)
            .then((result) => {      
                const alert = {
                    title: '',
                    text: 'Mala direta enviada com sucesso',
                    widht: '20%'
                }
                setAlertObject(alert)
                setVisibleAlert(true)  
                handleCloseModal()
                setLoadingVisible(false)
                atualizarGrade(true)                
            })
            .catch((err) => {
                setLoadingVisible(false)
               // alert('Ocorreu um erro ao processar a requisição. Tente novamente');
            })
    }
    return (
        <>
            <ModalAlert 
                visible={visibleAlert} 
                setVisible={setVisibleAlert} 
                title={alertObject.title} 
                text={alertObject.text}
                width={alertObject.widht}
            />
            <Modal visible={ visible }>
                <Loading visible={ loadingVisible }/>
                <div className='malaDireitaContainer'>
                    <h5>Envio de mala direta</h5>
                    <InputSelect                     
                            value={grupo}
                            onChange={(e) => {
                                setGrupo(e.target.value)
                            }}
                            options={gruposOptions} label="Grupo">                            
                    </InputSelect>
                    <InputSelect 
                        containerstyle="input-col" 
                        value={sexo}
                        onChange={(e) => {
                            setSexo(e.target.value)
                        }}
                        options={sexoOptions} label="Sexo">

                    </InputSelect>
                    <InputText                     
                        value={ titulo }
                        onChange={(e) => {
                            setTitulo(e.target.value)
                        }} 
                        containerstyle="input-col" 
                        label="Título" 
                        name="titulo" />
                    <InputText                     
                        value={ mensagem }
                        onChange={(e) => {
                            setMensagem(e.target.value)
                        }} 
                        containerstyle="input-col" 
                        label="Mensagem" 
                        name="mensagem" />
                    <div className='buttonMalaDiretaContainer'>
                        <SecondaryButton onClick={handleCloseModal} ><FaTimes /> Cancelar</SecondaryButton>
                        <PrimaryButton onClick={handleNovaMalaDireta } ><FaCheck /> Enviar</PrimaryButton>
        
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default MalaDireta