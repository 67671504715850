import api from "../api";
import { logout } from "../login";
import { getToken } from "../utils/token";

async function listarEspecialidades() {
  try {
    const response = await api.get("especialidade");
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

export { listarEspecialidades };
