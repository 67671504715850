
function getToken() {
    return localStorage.getItem('@aortic-app/token')
}

function setToken(value, pessoa) {   
    localStorage.setItem('@aortic-app/token', value)
    if (pessoa) {
        localStorage.setItem('@aortic-app/p', pessoa)
    }
    return localStorage.getItem('@aortic-app/token')        
}

function getTokenAdm() {
    return localStorage.getItem('@aortic-app/adm-token')
}

function setTokenAdm(value) {   
    localStorage.setItem('@aortic-app/adm-token', value)
    return localStorage.getItem('@aortic-app/adm-token')        
}

function deleteToken() {
    localStorage.removeItem('@aortic-app/token')
    localStorage.removeItem('@aortic-app/p')
}

function deleteTokenAdm() {
    localStorage.removeItem('@aortic-app/adm-token')
}

export { getToken, setToken, deleteToken,
         getTokenAdm, setTokenAdm, deleteTokenAdm }