import React, { useState, useEffect, useRef } from 'react'
import { buscarDadosProntuarioPaciente } from '../../../services/prontuario'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import SimpleButton from '../../../components/formComponents/simpleButton'
import { FiDownload } from 'react-icons/fi'
import './style.css'

function Prontuario() {   
    const [content, setContent] = useState({})
    useEffect(() => {
        buscarDadosProntuarioPaciente()
            .then((result) => {  
                const dataAtual = new Date()
                const dataNascimento = new Date(result.dt_nascimento)
                const idade = (dataAtual.getFullYear() - dataNascimento.getFullYear()) + ' anos'
                let alturaFinal = ''
                
                if (Number(result.altura) > 90)
                    alturaFinal = (Number(result.altura / 100).toFixed(2))
                else  
                    alturaFinal = (result.altura)  

                setContent({
                    nome: result.nome,
                    idade,
                    proximaConsulta: `Próxima consulta em ${result.proxima_consulta}`,
                    comorbidades: result.comorbidades,
                    medicamentos: result.medicamentos,
                    peso: result.peso,
                    altura: alturaFinal,
                    imc: result.imc,
                    classificacao: result.classificacao,
                    exames:  [
                        { descricao: 'Colesterol', resultado: result.colesterol && 
                                                              `${result.colesterol} mg/dl`   },
                        { descricao: 'HDL', resultado: result.hdl && 
                                        `${result.hdl} mg/dl` },
                        { descricao: 'Triglicerídios', resultado: result.trigliceres && 
                                                    `${result.trigliceres} mg/dl`  },
                        { descricao: 'LDL', resultado: result.ldl && `${result.ldl} mg/dl`},
                        { descricao: 'Hemoglobina Glicada', resultado: result.hemoglobina_glicada &&
                                                        `${result.hemoglobina_glicada} %` },
                        { descricao: 'Glicemia Jejum', resultado: result.glicemia_jejum && 
                                                    `${result.glicemia_jejum} mg/dl`  },
                        { descricao: 'Microalbuminuria', resultado: result.microalbuminuria && 
                                                    `${result.microalbuminuria} mg/g`    },
                        { descricao: 'Creatina', resultado: result.creatina && `${result.creatina} mg/dl` }

                    ]
                })
            })
    }, [])

    const savePDF = (type, name) => {
        let data = new Date();
        var dia = ("0" + data.getDate()).slice(-2);
        var mes = ("0" + (data.getMonth() + 1)).slice(-2);  
        var dataFinal = (dia)+"-"+ (mes)+"-"+data.getFullYear();
        name = name + '-' + dataFinal
        html2canvas(document.querySelector(`#report`)).then(canvas => {
          let dataURL = canvas.toDataURL('image/png');
    
          if (type === 'pdf') {
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "in",
              format: [14, 10]
            });
    
            pdf.addImage(dataURL, 'PNG', .6, .6);
            pdf.save(`${name}.pdf`);
    
          } else if (type === 'png') {
            var link = document.createElement('a');
            link.download = `${name}.png`;
            link.href = dataURL;
            link.click();
          }
        });
     }
    return (
      
        <div className='containerProntuarioPaciente'>
                <div className="containerButton">
                    <SimpleButton stylebutton='btnReport' onClick={()=>{                     
                            savePDF("png", "meuProntuario")
                        }}><FiDownload /> 
                    </SimpleButton>
                </div>
             <div  id='report' className='contentProntuarioPaciente' >
                <div><h6 className='nome'>{ content.nome }</h6></div>
                <div><h6 className='h6ProntuarioPaciente'>{ content.idade }</h6></div>
                <div><h6 className='h6ProntuarioPaciente'>{ content.proximaConsulta }</h6></div>
                
                <div className='comorbidadesContainer'>
                    <h4 className='titleProntuario'>Comorbidades</h4>                
                    <div className="cardProntuario whiteCard">                
                        <ul>
                            { 
                                content.comorbidades ?
                                content.comorbidades.map((item) => {
                                    return (
                                        <li>
                                            { item.descricao } 
                                            { item.conteudo ? '('+item.conteudo+')' : ''} 
                                            { item.obs && ` Obs: ${item.obs} ` }
                                        </li>
                                        )
                                    }) :
                                    <li></li>
                            }            
                        </ul> 
                    </div>
                </div>
                <div className='cardContainer'>
                    <h4 className='titleProntuario'>Medicamentos</h4>            
                    <div className="cardProntuario whiteCard">            
                        <ul>
                            { 
                                content.medicamentos ?
                                content.medicamentos.map((item) => {
                                    return (
                                        <li><span className='titleCard'>{ item.descricao }:</span> { item.posologia } </li>
                                    )
                                }) :
                                <li></li>
                            }            
                        </ul>    
                    </div>            
                </div>
                <div className='cardContainer'>
                    <h4 className='titleProntuario'>Exame físico</h4>            
                    <div className="cardProntuario whiteCard">            
                        <ul>             
                            <li><span className='titleCard'>Altura:</span> { content.altura ? `${(content.altura / 100).toFixed(2)} m` : '' } </li>
                            <li><span className='titleCard'>Peso:</span> { content.peso && `${content.peso} kg` } </li>
                            <li><span className='titleCard'>IMC:</span> { content.imc && `${content.imc} kg/m²` } </li>      
                            <li><span className='titleCard'>Classificação:</span> { content.classificacao } </li>
                                  
                        </ul>    
                    </div>            
                </div>

                <div className='cardContainer lastContainer'>
                    <h4 className='titleProntuario'>Exames laboratoriais</h4>            
                    <div className="cardProntuario whiteCard">            
                        <ul>
                            { 
                                content.exames ?
                                content.exames.map((item) => {
                                    if (item.resultado) {
                                        return (
                                            <li><span className='titleCard'>{ item.descricao }:</span> { item.resultado } </li>
                                        )   
                                    }
                                    
                                }) :
                                <li></li>
                            }            
                        </ul>    
                    </div>            
                </div>
            </div>
        </div>
  
    )
}

export default Prontuario