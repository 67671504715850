import React, { useState, useEffect, useContext } from 'react'
import InputText from '../../../components/formComponents/inputText'
import InputSelect from '../../../components/formComponents/inputSelect'
import PrimaryButton from '../../../components/formComponents/primaryButton'
import SecondaryButton from '../../../components/formComponents/secondaryButton'
import {  cadastrarPacientes, alterarPacientes, buscarPacientes } from '../../../services/pacientes'
import { listarMedicos } from '../../../services/medicos'
import { FaCheck, FaTimes } from 'react-icons/fa';
import { PacienteContext } from '../context'
import { cpfMask, phoneMask } from '../../../services/utils/mask'
import { onlyLetters } from '../../../services/utils/onlyLetters'
import Loading from '../../../components/loading'
import { consultarCEP } from '../../../services/utils/cep'
import { isValidCPF } from '../../../services/utils/validate'
import UploadArea from '../../../components/uploadArea'
import { useHistory } from 'react-router-dom'
import ModalAlert from '../../../components/modalAlert'
import { FaEdit } from 'react-icons/fa'
import SimpleButton from '../../../components/formComponents/simpleButton'
import { enviarConviteNovoUsuario, verificarEmailCadastrado, gerarLinkAtivacao, ativarUsuarioBase64 } from '../../../services/usuarios'
import ModalConfirmation from '../../../components/modalConfirmation'

import './style.css'

function FormPaciente({value, classForm, perfil}) {
    const statusMessageEmail = {
        valid: 'Email válido',
        invalid: 'Email inválido'
    }
    const statusMessageDoc = {
        valid: 'Documento válido',
        invalid: 'Documento inválido'
    }
    const history = useHistory()
    const sexoOptions = [{ label: 'Selecione', value: '' }, { label: 'Masculino', value: 'M' },{label: 'Feminino', value: 'F'}]
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [listaMedicos, setListaMedicos] = useState([])    
    const [visibleAlert, setVisibleAlert] = useState(false)

    const [alertObject, setAlertObject] = useState({})
    //const [stateEmail, setStateEmail] = useState('')
    const [stateDoc, setStateDoc] = useState('')

    const [linkAtivacao, setLinkAtivacao] = useState('')
    const [visibleConfirmation, setVisibleConfirmation] = useState(false)
    const [confirmation, setConfirmation] = useState({
        actionNO: () => {},
        actionOK: () => {}
    })
    const [blockingPanelVisible, setBlockingPanelVisible] = useState(true)
    const { atualizarGrade, data, setVisibleBlockingPanel, setData, FORM_STATE } = useContext(PacienteContext)    

    /*-----Hooks campos formulario-----*/
    const [id, setId] = useState('')
    const [idPaciente, setIdPaciente] = useState('')
    const [nome, setNome] = useState('')
    const [documento, setDocumento] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [telefone, setTelefone] = useState('')
    const [whatsapp, setWhatsapp] = useState('') 
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [logradouro, setLogradouro] = useState('')
    const [numero, setNumero] = useState('')
    const [bairro, setBairro] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cidade, setCidade] = useState('')
    const [uf, setUf] = useState('')
    const [medicoResponsavel, setMedicoResponsavel] = useState('')
    const [convenio, setConvenio] = useState('')
    const [numeroInscricaoConvenio, setNumeroInscricaoConvenio] = useState('')
    const [fotoPerfil, setFotoPerfil] = useState('')
    /*----------------------------------*/
    let limpando_form = false

    useEffect(() => {
        if (data.limparDados) {
            limpando_form = true
        }
        setDadosForm(data, data.limparDados)  
        const promisse = new Promise((resolve, reject) => {
             resolve((setBlockingPanelVisible(data.bloquearDados)))
        })
        promisse
            .then(() => {
                if (data.id) {
                    const editEmail = document.getElementById('documento')
                    if (editEmail)
                        editEmail.setAttribute('disabled', true)  
                }
            })   
            if (data.limparDados) {
                limparFormulario()
            }
     
    }, [data])

    function ativarCadastro(id) {
        setLoadingVisible(true)
        ativarUsuarioBase64(id)
            .then(() => {
                atualizarGrade(true)             
                setLoadingVisible(false)
            })
            .catch((e) => {
                setLoadingVisible(false)
                console.error(e)
            })
    }

    function confirmacaoProntuario(id_pessoa, id_paciente) {
        setConfirmation({
            Title: '',
            Question: 'Deseja abrir prontuário do paciente?',
            hasExtra: false,
            extra: {},
            actionOK: () => {
                history.push('/paciente?id=' + id_pessoa)
                buscarPacientes(id_paciente)
                    .then((result) => {
                        setLoadingVisible(false)
                        setData({...result, bloquearDados: true})
                        setVisibleBlockingPanel(false)
                    })   
                history.go(0)
            },
            actionNO: () => {
            }  
        })
        setVisibleConfirmation(true)     
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            if (id) return
            const btn = document.getElementById('btnSalvar')
              
            if (!documento) {
                setStateDoc('')
                return            
            }
            setStateDoc('invalid')
            //if (email.length < 5 ) return 
            //const pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            //  if (!pattern.test(email)) return

            if (!isValidCPF(documento)) return
    
            setStateDoc('loading')
            verificarEmailCadastrado(documento)
                .then((result) => {
                    if (result.message == 'CADASTRO_CLINICA') {
                        const alert = {
                            title: '',
                            text: 'Esse documento já está sendo utilizado por outro cadastro!',
                            widht: '30%'
                        }
                        setAlertObject(alert)
                        setVisibleAlert(true) 
                        setStateDoc('invalid')
                        btn.setAttribute('disabled', true)
                        return
                    } 
    
                    if (result.message == 'CADASTRO_OUTRO') {
                        setConfirmation({
                            Title: 'Usuário já cadastrado',
                            Question: 'Esse documento já possui cadastro no sistema Aortic. Deseja ' +
                            'enviar um convite para ingressar na sua clínica?',
                            hasExtra: result.whatsapp ? true : false,
                            extra: {
                                type: 'whatsapp_link',
                                data: {
                                    whatsapp: result.whatsapp,
                                    message: `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`
                                }
                            },
                            actionOK: false,
                            actionNO: false
                        })
                        setVisibleConfirmation(true)     
                        setStateDoc('invalid')
                        btn.setAttribute('disabled', true)
                        return
                    } 

                    if (result.message == 'INATIVO') {
                        setConfirmation({
                            Title: 'Usuário inativo',
                            Question: 'Esse documento já possuiu cadastro no sistema Aortic e está inativo no momento. Deseja ' +
                            'reativá-lo?',
                            hasExtra: false,                          
                            actionOK: () => ativarCadastro(result.id),
                            actionNO: () => {}
                        })
                        setVisibleConfirmation(true)     
                        setStateDoc('invalid')
                        btn.setAttribute('disabled', true)
                        return
                    } 

                    setStateDoc('valid')
                    btn.removeAttribute('disabled')    
                })
    
            }, 500)    
            return () => clearTimeout(timer);
        }, [documento])
    // useEffect(() => {
    // const timer = setTimeout(() => {
    //     if (id) return
    //     const btn = document.getElementById('btnSalvar')
          
    //     if (!email) {
    //         setStateEmail('')
    //         return            
    //     }
    //     setStateEmail('invalid')
    //     if (email.length < 5 ) return 
    //     const pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    //     if (!pattern.test(email)) return

    //     setStateEmail('loading')
    //     verificarEmailCadastrado(email)
    //         .then((result) => {
    //             if (result.message == 'CADASTRO_CLINICA') {
    //                 const alert = {
    //                     title: '',
    //                     text: 'Esse email já está sendo utilizado por outro cadastro!',
    //                     widht: '30%'
    //                 }
    //                 setAlertObject(alert)
    //                 setVisibleAlert(true) 
    //                 setStateEmail('invalid')
    //                 btn.setAttribute('disabled', true)
    //                 return
    //             } 

    //             if (result.message == 'CADASTRO_OUTRO') {
    //                 setConfirmation({
    //                     Title: 'Usuário já cadastrado',
    //                     Question: 'Esse email já possui cadastro no sistema Aortic. Deseja ' +
    //                     'enviar um convite para ingressar na sua clínica?',
    //                     hasExtra: result.whatsapp ? true : false,
    //                     extra: {
    //                         type: 'whatsapp_link',
    //                         data: {
    //                             whatsapp: result.whatsapp,
    //                             message: `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`
    //                         }
    //                     },
    //                     actionOK: () => {
    //                         enviarConviteNovoUsuario({
    //                             perfil: 'PACIENTE',
    //                             id_pessoa: result.id_pessoa
    //                         })
    //                     },
    //                     actionNO: () => {
    //                     }  
    //                 })
    //                 setVisibleConfirmation(true)     
    //                 setStateEmail('invalid')
    //                 btn.setAttribute('disabled', true)
    //                 return
    //             } 
    //             setStateEmail('valid')
    //             btn.removeAttribute('disabled')    
    //         })

    //     }, 500)    
    //     return () => clearTimeout(timer);
    // }, [email])

    useEffect(() => {
        if (perfil) {
            const elementMedico = document.getElementById('medicoResponsavel')
            elementMedico.disabled = true
        }
        if (value) { 
            setDadosForm(value) 
        }
        listarMedicos()
            .then((response) => {
                const lista = response.map((item) => {
                    return { label: item.nome, value: item.id_medico }
                })
                lista.splice(0, 0, "Selecione");
                setListaMedicos(lista)
                setMedicoResponsavel(lista[0].value)
            })
    }, [])
    function limparFormulario() {
        if (data.formState == FORM_STATE.NOTHING) return
        if (data.bloquearDados == true) {
            setBlockingPanelVisible(true)
            return
        }
        if (perfil) {
            history.push('/menu')
        } else {
            setId('')
            setIdPaciente('')
            setNome('')
            setDocumento('')
            setDataNascimento('')
            setTelefone('')
            setWhatsapp('')
            setEmail('')
            setLogradouro('')
            setNumero('')
            setBairro('')
            setComplemento('')
            setCidade('')
            setUf('')
            setMedicoResponsavel('')
            setConvenio('')
            setNumeroInscricaoConvenio('')
            setSexo('')
            setCep('')
            setFotoPerfil('')
            const editEmail = document.getElementById('documento')
            if (editEmail)
                editEmail.removeAttribute('disabled')  
            setVisibleBlockingPanel(true)
        }
    }

    function setDadosForm(paciente, limpando) {
        //controlaEstado()
        let dataNascimento = new Date(paciente.dt_nascimento);
        var dia = ("0" + dataNascimento.getDate()).slice(-2);
        var mes = ("0" + (dataNascimento.getMonth() + 1)).slice(-2);  
        var dataNascimentoFinal = dataNascimento.getFullYear()+"-"+(mes)+"-"+(dia);

        setId(paciente.id)
        
        setIdPaciente(paciente.id_paciente)
        setNome(paciente.nome)
        setDocumento(paciente.documento || '')
        setDataNascimento(dataNascimentoFinal)        
        setTelefone(phoneMask(paciente.telefone))
        setWhatsapp(phoneMask(paciente.whatsapp))
        setEmail(paciente.email)
        setCep(paciente.cep)
        setLogradouro(paciente.logradouro)
        setNumero(paciente.numero)
        setBairro(paciente.bairro)
        setComplemento(paciente.complemento)
        setCidade(paciente.cidade)
        setUf(paciente.uf)
        setMedicoResponsavel(paciente.medico_responsavel)
        setConvenio(paciente.convenio)
        setNumeroInscricaoConvenio(paciente.numero_inscricao_convenio)
        setFotoPerfil(paciente.foto)
        setSexo(paciente.sexo)

        if (paciente.id) {
            gerarLinkAtivacao(paciente.id)
                .then((result) => {
                    const link = 
                    `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`                     
                    setLinkAtivacao(link)
                })
        }
    }

    function getDadosForm(alterando) {
        const pacienteBase = {            
            nome,
            documento,
            sexo,
            dt_nascimento: dataNascimento,
            telefone:  String(telefone)
                        .replace(' ', '')
                        .replace('-', '')
                        .replace('(', '')
                        .replace(')', '')
                        .trim(),
            whatsapp: String(whatsapp)
                            .replace(' ', '')
                            .replace('-', '')
                            .replace('(', '')
                            .replace(')', '')
                            .trim(),
            email,
            cep,
            logradouro,
            numero,
            bairro,
            complemento,
            cidade,
            uf,
            id_medico_responsavel: medicoResponsavel,
            convenio,
            numero_inscricao_convenio: numeroInscricaoConvenio,
            foto: fotoPerfil
        }
        if (alterando) {
          return {
            id,
            id_paciente: idPaciente,
            ...pacienteBase
            }  
        } 
        else {
            return pacienteBase
        }
         
    }


    function enviarDadosPaciente() {
        if (documento)
            if (isValidCPF(documento) === false) {
                //alert()
                const alert = {
                    title: '',
                    text: 'CPF inválido',
                    widht: '20%'
                }
                setAlertObject(alert)
                setVisibleAlert(true)  
                return
            }

        if (!sexo.length)
            return
            
        setLoadingVisible(true)
       
        const alterando = ((id) && (idPaciente)) 
        const paciente = getDadosForm(alterando)
        if (alterando) {
            alterarPacientes(paciente)
                .then((result) => {
                    if (!perfil) {
                        limparFormulario()
                        atualizarGrade(true) 
                    }                   
                    setLoadingVisible(false)
                })
                .catch((error) => {
                    setLoadingVisible(false)
                })
        }
        else {
            cadastrarPacientes(paciente)
                .then((result) => {
                    if (!perfil) {
                        limparFormulario()
                        atualizarGrade(true) 
                    }
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)
                    confirmacaoProntuario(result.id_pessoa, result.id)
                })   
                .catch((error) => {
                    setLoadingVisible(false)
                    setVisibleBlockingPanel(true)

                })
        }      
        
    }

    function handleUpload(files) {
        const reader = new FileReader()
        reader.readAsBinaryString(files[0])
        reader.onload = function() {
           setFotoPerfil( (btoa(reader.result))) 
        }   
    }

    function handleConsultaCEP() {
        if(!cep) return        
        if (cep.length < 8) return 
        consultarCEP(cep)
            .then((dadosEndereco) => {
                if (limpando_form) {
                    limpando_form = false
                    return
                }
                if (dadosEndereco.data.logradouro)
                    setLogradouro(dadosEndereco.data.logradouro)
                if (dadosEndereco.data.bairro)
                    setBairro(dadosEndereco.data.bairro)
                if (dadosEndereco.data.localidade)
                    setCidade(dadosEndereco.data.localidade)
                if (dadosEndereco.data.uf)
                    setUf(dadosEndereco.data.uf)
            })
            .catch((err) => {
                console.log(err)
            })

    }
    return (
        <>
            <ModalAlert 
                visible={visibleAlert} 
                setVisible={setVisibleAlert} 
                title={alertObject.title} 
                text={alertObject.text}
                width={alertObject.widht}
            />


            <ModalConfirmation
                height={'160px'} 
                visible={ visibleConfirmation } 
                setVisible= { setVisibleConfirmation }
                actionOK={ confirmation.actionOK }
                actionNO={ confirmation.actionNO }
                title={ confirmation.Title }
                text={ confirmation.Question }
                hasExtra={confirmation.hasExtra}
                extras={confirmation.extra}
            />
            <form autoComplete="off" onSubmit={(e) => {e.preventDefault()}} className={ 'formData ' + (classForm ? classForm : 'form') }>
                <div className={ (blockingPanelVisible ? ' modalVisible ' : ' modalInvisible ') + 'blockingPanel ' } >
                    <SimpleButton 
                        className='btnEditarNoProntuario'
                        onClick={() => { setBlockingPanelVisible(false) } }>
                        <FaEdit /> Editar
                    </SimpleButton>

                </div>
             <Loading 
                forced={ perfil ? true : false }
                visible={ loadingVisible }
            />
            <UploadArea 
                onUpload={ handleUpload } 
                uploadedPhoto={ 'data:image/png;base64, ' + (fotoPerfil || '') } 
            />
            <InputText 
                 value={ documento }
                 status={stateDoc}
                statusMessage={statusMessageDoc}
                 onChange={(e) => {
                     const validCPF = cpfMask(e.target.value)
                     setDocumento(validCPF)
                 }}
                containerstyle="input" 
                label="Documento (CPF)"
                name="documento"></InputText>
            {/* <InputText
                status={stateEmail}
                statusMessage={statusMessageEmail}
                autoComplete="off"                     
                value={ email }
                onChange={(e) => {
                     setEmail(e.target.value)
                 }}
                containerstyle="input" 
                type="email"
                label="Email" 
                name="email" /> */}
            <InputText 
                value={ nome }
                onChange={(e) => {
                    setNome(e.target.value)
                }}
                containerstyle="input" 
                label="Nome" 
                name="nome"></InputText>
            
            <div className="col-2">
                <InputSelect 
                    containerstyle="input-col" 
                    value={sexo}
                    required
                    onChange={(e) => {
                        setSexo(e.target.value)
                    }}
                    name='sexo'
                    options={sexoOptions} label="Sexo">                            
                </InputSelect>
                <InputText 
                    required
                    value={ dataNascimento }
                    onChange={(e) => {
                        setDataNascimento(e.target.value)
                    }}
                    type="date" 
                    containerstyle="input-col" 
                    label="Data Nascimento" 
                    name="dtNascimento" />
            </div>
            <div className="col-2">                        
                <InputText 
                     value={ telefone }
                     onChange={(e) => {
                        const phone = phoneMask(e.target.value)                        
                        setTelefone(phone)
                     }}
                    containerstyle="input-col" 
                    label="Telefone" 
                    name="telefone" />
                <InputText 
                     value={ whatsapp }
                     onChange={(e) => {
                         const phone = phoneMask(e.target.value)         
                         setWhatsapp(phone)
                     }}
                    containerstyle="input-col" 
                    type="text"
                    label="Whatsapp" 
                    name="whatsapp" />
            </div>            
           <div className="col-2"> 
               {/* <InputText 
                     value={ medicoResponsavel }
                     onChange={(e) => {
                         setMedicoResponsavel(e.target.value)
                     }}
                    containerstyle="input-col" 
                    label="Médico Responsável" 
                    id="medico_responsavel"
                    name="medico_responsavel" />{}*/}

                    <InputSelect 
                        containerstyle="input-col" 
                        name='medicoResponsavel'
                        value={medicoResponsavel}
                        onChange={(e) => {
                            setMedicoResponsavel(e.target.value)
                        }}
                        options={listaMedicos} label="Médico responsável">                            
                    </InputSelect>    
                <InputText 
                    value={ convenio }
                    onChange={(e) => {
                        setConvenio(e.target.value)
                    }}
                    containerstyle="input-col"                             
                    label="Plano de Saúde" 
                    name="convenio" />   
            </div>  
            <InputText                     
                 value={ numeroInscricaoConvenio }
                 onChange={(e) => {
                     setNumeroInscricaoConvenio(e.target.value)
                 }}
                containerstyle="input" 
                type="numero_inscricao_convenio"
                label="N° de inscrição (convenio)" 
                name="numero_inscricao_convenio" />
            <div className="col-2"> 
                <InputText 
                     value={ cep }
                     onChange={(e) => {
                         setCep(e.target.value)
                     }}
                     onBlur={handleConsultaCEP()}
                    containerstyle="col-30-percent" 
                    label="CEP" 
                    id="cep"
                    name="cep" />
                <InputText 
                    value={ logradouro }
                    onChange={(e) => {
                        setLogradouro(e.target.value)
                    }}
                    containerstyle="input-col"                             
                    label="Logradouro" 
                    name="logradouro" />   
            </div>  
            <div className="col-2"> 
                <InputText 
                     value={ numero }
                     onChange={(e) => {
                         setNumero(e.target.value)
                     }}
                    containerstyle="col-30-percent" 
                    label="Número" 
                    id="numero"
                    name="numero" />
                <InputText 
                    value={ complemento }
                    onChange={(e) => {
                        setComplemento(e.target.value)
                    }}
                    containerstyle="input-col"                             
                    label="Complemento" 
                    name="complemento" />   
            </div> 
            <InputText 
                     value={ bairro }
                     onChange={(e) => {
                         setBairro(e.target.value)
                     }}
                    containerstyle="input" 
                    label="Bairro" 
                    id="bairro"
                    name="bairro" />
            <div className="col-2"> 
                <InputText 
                     value={ cidade }
                     onChange={(e) => {
                         setCidade(e.target.value)
                     }}
                    containerstyle="input-col" 
                    label="Cidade" 
                    id="cidade"
                    name="cidade" />
                <InputText 
                    value={ uf }
                    onChange={(e) => {
                        const newUF = onlyLetters(String(e.target.value).toUpperCase())
                        setUf(newUF)
                    }}
                    containerstyle="col-20-percent-right uf"                             
                    label="UF" 
                    name="uf" />   
            </div> 
            <div className='linkAtivacaoFormulario'>
                {
                     ((linkAtivacao && whatsapp) && (!perfil)) ?
                    /*<a className='primaryButtonLink ' 
                    href={`https://wa.me/55${String(whatsapp)
                                            .replace(' ', '')
                                            .replace('-', '')
                                            .replace('(', '')
                                            .replace(')', '')
                                            .trim()}?text=${linkAtivacao}`} 
                    target='_blank'>Link Whatsapp</a>*/ null
                    : null
                }
            </div>
            <div className="footer"> 
                <SecondaryButton 
                    type="submit"        
                    name='btnCancelar'
                    onClick={limparFormulario}
                    containerstyle ="footerButton" 
                    text="Cancelar"><FaTimes /></SecondaryButton>
                <PrimaryButton 
                    name='btnSalvar'
                    onClick={enviarDadosPaciente}
                    containerstyle="footerButton" 
                    text="Salvar" ><FaCheck /></PrimaryButton>                
            </div>
        </form> 
        </>
    )
}

export default FormPaciente