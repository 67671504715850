import firebase from './firebase'
import adapter from 'webrtc-adapter'
import api from '../api'
import { getToken } from '../utils/token'
import { DeviceUUID } from 'device-uuid'
import { getClinicaAtiva } from '../clinicas'
import { logout } from '../login'

async function registrarPushToken(id_pessoa) {
    const messaging = firebase.messaging()
    await messaging.requestPermission()
    const token = await messaging.getToken()   
    const uuid = new DeviceUUID().get()
    const device_id = uuid || adapter.browserDetails.browser
    const pushToken = { device_id, id_pessoa, token }

    try {
        const response = await api.post('token', pushToken, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

export { registrarPushToken }