import React, { useEffect } from "react";
import SimpleButton from "../formComponents/simpleButton";
import { FaPlus } from "react-icons/fa";
import "./style.css";
import {
  STEP_CALCULATOR_RISK,
  usePatientRecordContext,
} from "../../context/patientRecordContext";
function FormDataContainer(props) {
  const { setStepper } = usePatientRecordContext();

  function setFormStatus() {
    const fContrl = document.getElementsByClassName("formData");
    const form = fContrl[0];
    if (!form) return;
    for (let i = 0; i < form.length; i++) {
      if (form[i].id) {
        const labels = document.querySelector(`label[for="${form[i].id}"]`);
        if (props.visibleBlocking == true) {
          form[i].setAttribute("disabled", true);
          if (labels) labels.setAttribute("disabled", true);
        } else {
          form[i].removeAttribute("disabled");
          if (labels) labels.removeAttribute("disabled");
        }
      }
    }
  }

  function setComponentInvisible() {
    if (props.setVisibleBlocking) {
      props.setVisibleBlocking(false);
    }
  }

  useEffect(() => {
    setFormStatus();
  }, [props.visibleBlocking]);

  return (
    <div id="formDataContainer" className="formDataContainer">
      <div className="headerFormDataContainer">
        <div>{props.title}</div>
        <div
          style={{
            display: props.visibleBlocking == true ? "flex" : "none",
          }}
        >
          <SimpleButton
            className="btnNovoForm"
            onClick={() => {
              if (props?.isModePatitentRecord) {
                setStepper(STEP_CALCULATOR_RISK);
                return;
              }

              if (props?.isCreateUserByMail) {
                props.setIsShowModalNewUser(true);
                return;
              }

              setComponentInvisible();
            }}
          >
            <FaPlus style={{ marginRight: "5px" }} />{" "}
            {props.btnTitle ? props.btnTitle : "Incluir"}
          </SimpleButton>
        </div>
      </div>
      <div className="contentFormDataContainer">{props.children}</div>
    </div>
  );
}

export default FormDataContainer;
