import React from 'react'
import './style.css'

const InputSelect = ({ name, label, options, containerstyle, showError, mandatory, ...rest}) => {
    return (    
        
        <div className={ "inputSelectContainer " + containerstyle }>
            <label htmlFor={ name }>{ label }{mandatory && <span style={{color: 'red'}}> *</span>}</label>
            <div className="inputSelect">
                <select  value="" id={ name } {...rest} >                    
                    { options.map( option => {
                        return <option 
                                    key={option.value} 
                                    value={ option.value }> 
                                        { option.label } 
                                </option>
                    })}
                </select>    
                {(showError) && <span className="error">Selecione um valor para o campo {label}</span>}
            </div>
            
        </div>
    )
}

export default InputSelect