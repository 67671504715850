import React from "react";
import {
  STEP_CALCULATOR_RESULT,
  STEP_CALCULATOR_RISK,
  STEP_CLOSE_MODAL,
  STEP_COMPLETE_REGISTRATION,
  usePatientRecordContext,
} from "../context/patientRecordContext";
import Modal from "../components/modal";
import CalculatorRisk from "./components/calculatorRisk/calculatorRisk";
import CalculatorResult from "./components/calculatorResult/calculatorResult";
import PatientCreate from "./components/patientCreate/patientCreate";

const PatientRecord = () => {
  const { stepper } = usePatientRecordContext();

  return (
    stepper !== STEP_CLOSE_MODAL && (
      <Modal style="modalFull" visible={stepper !== STEP_CLOSE_MODAL}>
        {stepper === STEP_CALCULATOR_RISK && <CalculatorRisk />}
        {stepper === STEP_CALCULATOR_RESULT && <CalculatorResult />}
        {stepper === STEP_COMPLETE_REGISTRATION && <PatientCreate />}
      </Modal>
    )
  );
};

export default PatientRecord;
