import React, { useState } from 'react';
import './style.css'
import ReactInputMask from 'react-input-mask';

const InputMax = ({ name, label, containerstyle, type, status, statusMessage, autocomplete, onDeleteAutocomplete, onSelectAutocomplete, onDateChange, showError, placeHolderValue, mandatory, ...rest}) => {
    
    return (    
        <div className={"inputTextContainer " + containerstyle}>
            <div>
                <label htmlFor={ name }>{ label }{mandatory && <span style={{color: 'red'}}> *</span>}</label>
            </div>
            <div>
                <ReactInputMask type={ type || "text"} id={ name } placeholder={ placeHolderValue || "0,00"} {...rest } />
            </div>
        </div>
    )
}

export default InputMax