import api from "../api";
import { logout } from "../login";
import { getToken, getTokenAdm } from "../utils/token";

async function listarClinicas() {
  try {
    const response = await api.get("clinica", {
      headers: {
        "x-access-token": getToken(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function listarClinicasAdm(filter) {
  try {
    const response = await api.get("adm/clinica", {
      headers: {
        "x-access-token": getTokenAdm(),
      },
    });
    if (filter) {
      return response.data.filter((item) => item.ativa == true);
    }
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function cadastrarClinica(clinica) {
  try {
    const response = await api.post("adm/clinica", clinica, {
      headers: {
        "x-access-token": getTokenAdm(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function cadastrarClinicaToken(clinica) {
  try {
    const response = await api.post("adm/clinica", clinica, {
      headers: {
        "x-access-token": getToken(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function alterarClinica(clinica) {
  try {
    const response = await api.put("adm/clinica", clinica, {
      headers: {
        "x-access-token": getTokenAdm(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
  }
}

async function buscarLogo() {
  try {
    const response = await api.get("clinica/logo", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data[0];
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function alterarConfiguracao(configuracao) {
  try {
    const response = await api.post("clinica/configuracao", configuracao, {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

async function listarConfiguracao() {
  try {
    const response = await api.get("clinica/configuracao", {
      headers: {
        "x-access-token": getToken(),
        "app-clinica": getClinicaAtiva(),
      },
    });
    return response.data[0];
  } catch (error) {
    if (error.response.status === 401) logout();
    return;
  }
}

function getClinicaAtiva() {
  const clinica = localStorage.getItem("@aortic-app/clinica");
  if (!clinica) {
    window.location.pathname = "/clinicas";
    return;
  }
  return clinica;
}

function setClinicaAtiva(value) {
  localStorage.setItem("@aortic-app/clinica", value);
  return localStorage.getItem("@aortic-app/clinica");
}

function deleteClinicaAtiva() {
  localStorage.removeItem("@aortic-app/clinica");
}

export {
  listarClinicas,
  getClinicaAtiva,
  setClinicaAtiva,
  deleteClinicaAtiva,
  alterarConfiguracao,
  listarConfiguracao,
  buscarLogo,
  listarClinicasAdm,
  cadastrarClinica,
  alterarClinica,
  cadastrarClinicaToken,
};
