import React, { useState } from "react";
import { sendCreateUserMail } from "../../services/usuarios";
import Modal from "../modal";
import InputText from "../formComponents/inputText";
import Check from "../formComponents/checkBox";
import "./styles.css";
import SecondaryButton from "../formComponents/secondaryButton";
import { FaCheck, FaTimes } from "react-icons/fa";
import PrimaryButton from "../formComponents/primaryButton";
import Loading from "../../components/loading";

const ModalNewUser = ({
  visible,
  setIsShowModalNewUser,
  isRecepcionista,
  isDoctor,
  isAdministrador = false,
}) => {
  const [isloading, setIsloading] = useState(false);
  const [email, setEmail] = useState("");
  const [isAdm, setIsAdm] = useState(isAdministrador);

  const cleanUp = () => {
    setEmail("");
    setIsAdm(false);
    setIsloading(false);
    setIsShowModalNewUser(false);
  };

  const handleSendNewUser = async () => {
    setIsloading(true);
    await sendCreateUserMail({ email, isAdm, isDoctor });
    cleanUp();
  };

  return (
    <Modal style="modalFull" visible={visible}>
      <Loading visible={isloading} />
      <div className="containerModalNewUser">
        {!isRecepcionista && (
          <Check
            containerstyle="input marginTop20"
            name="preenchimento"
            label="Administrador"
            checked={isAdm}
            onCheck={() => {
              setIsAdm(!isAdm);
            }}
          />
        )}
        <InputText
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          containerstyle="input"
          type="email"
          label="Email"
          name="email"
        />
        <div className="modalActions">
          <SecondaryButton
            type="submit"
            name="btnCancelar"
            onClick={() => cleanUp()}
            containerstyle="footerButton"
            text="Cancelar"
          >
            <FaTimes />
          </SecondaryButton>
          <PrimaryButton
            disabled={!email}
            onClick={handleSendNewUser}
            name="btnSalvar"
            containerstyle="footerButton"
            text="Enviar convite"
          >
            <FaCheck />
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNewUser;
