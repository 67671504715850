export const calculateDir = (value) => {
  const sanitized = String(value.pathname).replace("/", "");

  const dirMapping = {
    paciente: "Pacientes",
    medicos: "Usuários > Corpo Clínico",
    recepcao: "Usuários > Secretaria",
    administrador: "Usuários > Administrador",
    agendamentos: "Atendimento > Agendamentos",
    mensagens: "Comunicação > Mensagens",
    notificacao: "Comunicação > Notificações",
    template: "Configurações > Templates",
    configuracao: "Configuração > Outras",
  };

  return dirMapping[sanitized];
};
