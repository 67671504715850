import React, { useState, useEffect } from 'react'
import SimpleButton from '../../../../components/formComponents/simpleButton'
import SecondaryButton from '../../../../components/formComponents/secondaryButton'
import { FaEdit, FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa'
import InputText from '../../../../components/formComponents/inputText'
import InputSelect from '../../../../components/formComponents/inputSelect'
import DataList from '../../../../components/dataList'
import Modal from '../../../../components/modal'
import Loading from '../../../../components/loading'
import { cadastrarComorbidade, listarComorbidades, listarOpcoesComorbidades, deletarComorbidade} from '../../../../services/comorbidades'

import './styles.css'

function Comorbidades({ content }) {

    const simNaoOptions = [
        { label: 'Sim', value: 'Sim'},         
        { label: 'Não', value: 'Não'}
    ]

    const tabagistaOptions = [
        { label: 'Ativo', value: 'Ativo'},         
        { label: 'Ex-Tabagista', value: 'Ex-Tabagista'}
    ]
    const nyhaOptions = [
        { label: 'Classe I', value: 'Classe I'},         
        { label: 'Classe II', value: 'Classe II'},
        { label: 'Classe III', value: 'Classe III'},
        { label: 'Classe IV', value: 'Classe IV'}
    ]

    const [modalVisivel, setModalVisivel] = useState(false)
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [listaComorbidades, setListaComorbidades] = useState([])
    const [idComorbidade, setIdComorbidade] = useState()
    const [descricaoComorbidade, setDescricaoComorbidade] = useState('')
    const [anotacaoComorbidade, setAnotacaoComorbidade] = useState('')
    const [tipoComorbidade, setTipoComorbidade] = useState('')
    const [comorbidadesOption, setComorbidadesOption] = useState([{label: '', value: ''}])
    const [insulinoDepente, setInsulinoDependente] = useState('')
    const [opcaoTabagista, setOpcaoTabagista] = useState('')
    const [macosTabagista, setMacosTabagista] = useState('')
    const [observacao, setObservacao] = useState('')
    const [alterouDados, setAlterouDados] = useState(false)

    const [nyha, setNyha] = useState('')
    useEffect(() => {
        listarOpcoesComorbidades()
            .then((response) => {
                
                const lista = response.map((item) => {
                    return { label: item.descricao, value: item.id, tipo: item.tipo, descricao: item.descricao }
                })
                lista.unshift({
                    label: 'Selecione uma comorbidade',
                    value: '',
                    tipo: '',
                    descricao: '',
                })
                setComorbidadesOption(lista)
                setIdComorbidade(lista[0].value)
            })
    }, [])

    useEffect(() => {
        const filter = comorbidadesOption.filter((item) => item.value == idComorbidade)
        if (filter[0]) {
            setDescricaoComorbidade(filter[0].descricao)
            setAnotacaoComorbidade('')
            setObservacao('')
            setTipoComorbidade(filter[0].tipo)    
        }        
    }, [idComorbidade])

    useEffect(() => {
        if (tipoComorbidade == 'TABAGISTA') {            
            setOpcaoTabagista('Ativo')         
        } else if (tipoComorbidade == 'DIABETES') {
            setInsulinoDependente('Sim')
        } else if (tipoComorbidade == 'OUTROS') {
            setDescricaoComorbidade('')
        }    
    }, [tipoComorbidade])

    function handleIncluirComorbidade() {
        if (!idComorbidade) return       
        
        setLoadingVisible(true)
        let anotacao = ''
        if (tipoComorbidade == 'TABAGISTA') {            
            anotacao = (opcaoTabagista + ',' + macosTabagista + ' maços/ano')           
        } else if (tipoComorbidade == 'NYHA') {
            anotacao = nyha
        } else if (tipoComorbidade == 'DIABETES') {
            anotacao = 'Insulino dependente: ' + insulinoDepente
        } else {
            anotacao = anotacaoComorbidade
        }

        const comorbidade = { id_paciente: content.paciente,
                              id_comorbidade: idComorbidade,
                              descricao: descricaoComorbidade,
                              anotacao,
                              observacao  }
        cadastrarComorbidade(comorbidade)
            .then((result) => {         
                setAlterouDados(true)             
                handleCarregarListaComorbidades()          
                setIdComorbidade(comorbidadesOption[0].value)        
                setObservacao('')   
                content.refresh()
                setLoadingVisible(false)
            })
            .catch((erro) => {
                setLoadingVisible(false)
            })
    }

    function handleClose() {
        setModalVisivel(!modalVisivel)
        if (alterouDados) {
            content.refresh()
            setAlterouDados(false)
        }
    }

    function handleDeletarComorbidade(id) {
        setLoadingVisible(true)
        deletarComorbidade(id)
            .then(() => {         
                setAlterouDados(true)
                handleCarregarListaComorbidades()          
                setLoadingVisible(false)
            })
            .catch(() => {
                setLoadingVisible(false)
            })
    }

    function handleCarregarListaComorbidades() {
        listarComorbidades(content.paciente)
            .then((result) => {
                setListaComorbidades(result)
            })
    }

    function handleCarregarComorbidades() {
        setModalVisivel(true)
        handleCarregarListaComorbidades()
    }
    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
          Header: "Comorbidade",
          accessor: "descricao_completa",
        },
        {
          Header: "Anotação",
          accessor: "anotacao",
        },
        {
            Header: "Data",
            accessor: "created_at"
        },
        {
            Header: 'Ativo',
            accessor: 'ativo',
            Cell: row => (                
            <>{row.row.cells[4].value == true ? <FaCheck color='green' /> : <FaTimes /> }</>
            )
        },
        {
          Header: '',
          accessor: 'delete',
          Cell: row => (
          <SimpleButton
           alt='Excluir cadastro'
           title='Excluir cadastro'
           buttonType='delete' onClick={() => { handleDeletarComorbidade(row.row.cells[0].value)} }><FaTrashAlt /></SimpleButton>
          )
        },      
       
      ]
     return (
        <>
            <Loading forced={true} visible={ loadingVisible } />
            <Modal style='modalFull' visible={ modalVisivel }>
                <div className="modalContainer">
                    <div>
                        <div className="modalHeader">
                            <h4 className="titleProntuario">
                                Comorbidades
                            </h4> 
                            <div className='fecharModal'>
                                <button onClick={ handleClose }>X</button> 
                            </div>     
                        </div>
                        <div className="modalAfericoesContent">
                            
                            <div className="modalAfericoesGrid">
                                <DataList hideDirDescription={ true } stylecontainer='gridModal' filter={<></>} columns={columns} data= {listaComorbidades}></DataList> 
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='comorbidadesContainer'>
                <h4 className='titleProntuario'>Comorbidades</h4>                
                <div className="cardProntuarioBackground">
                    <div className="modalAfericoesForm">
                                <div  style={{ width: '100%' }} className="inputContainer">
                                    <div className="medicamentosForm">
                                       
                                            <InputSelect 
                                                value={ idComorbidade }
                                                onChange={(e) => {
                                                    setIdComorbidade(e.target.value)
                                                }}
                                                options={ comorbidadesOption } label="Comorbidade">                            
                                            </InputSelect> 
                                            { tipoComorbidade != 'OUTROS' && 
                                                <InputText 
                                                    value={ observacao }
                                                    onChange={(e) => {
                                                        setObservacao(e.target.value)
                                                    }}                                                   
                                                    containerstyle=''
                                                    label="Observação" 
                                                    name="obs" 
                                                />   
                                            }
                                            { tipoComorbidade == 'DIABETES' ? 
                                                <div className='tabagistaContainer'>
                                                    <InputSelect 
                                                        containerstyle='tabagistaItem col-20-percent'
                                                        value={ insulinoDepente }
                                                        onChange={(e) => {
                                                            setInsulinoDependente(e.target.value)
                                                        }}
                                                        options={ simNaoOptions } label="Insulino depentende?">                            
                                                    </InputSelect>                                                                           
                                                </div> : ''}  
                                            { tipoComorbidade == 'TABAGISTA' ? 
                                                <div className='tabagistaContainer'>
                                                    <InputSelect 
                                                        containerstyle='tabagistaItem col-20-percent'
                                                        value={ opcaoTabagista }
                                                        onChange={(e) => {
                                                            setOpcaoTabagista(e.target.value)
                                                        }}
                                                        options={ tabagistaOptions } label="Tabagista">                            
                                                    </InputSelect> 
                                                    <InputText 
                                                        containerstyle='tabagistaItem'
                                                        value={ macosTabagista }
                                                        onChange={(e) => {
                                                            setMacosTabagista(e.target.value)
                                                        }}
                                                        containerstyle='inputAfericao'
                                                        label="Carga tabágica" 
                                                        name="pul" 
                                                    />    
                                                    <span className='textoUnidade'> maços/ano </span>     
                                                                       
                                                </div> : ''}
                                            { tipoComorbidade == 'OUTROS' ? 
                                                <div className='tabagistaContainer'>
                                                    <InputText 
                                                        containerstyle='tabagistaItem'
                                                        value={ descricaoComorbidade }
                                                        onChange={(e) => {
                                                            setDescricaoComorbidade(e.target.value)
                                                        }}
                                                        containerstyle='inputAfericao'
                                                        label="Nome da comorbidade" 
                                                        name="descricaoOutros" 
                                                    />  
                                                    <InputText 
                                                        containerstyle='tabagistaItem'
                                                        value={ anotacaoComorbidade }
                                                        onChange={(e) => {
                                                            setAnotacaoComorbidade(e.target.value)
                                                        }}
                                                        containerstyle='inputAfericao'
                                                        label="Anotação" 
                                                        name="anotacaoOutros" 
                                                    />                                                                        
                                                </div> : ''}
                                            { tipoComorbidade == 'NYHA' ? 
                                                <div className='tabagistaContainer'>
                                                    <InputSelect 
                                                        containerstyle='tabagistaItem col-20-percent'
                                                        value={ nyha }
                                                        onChange={(e) => {
                                                            setNyha(e.target.value)
                                                        }}
                                                        options={ nyhaOptions } label="NYHA">                            
                                                    </InputSelect>               
                                                                        
                                                </div>: ''}
                                    </div>               
                                </div>                                
                                <SecondaryButton onClick={ handleIncluirComorbidade  }>+ Incluir</SecondaryButton>
                            </div>
                    <div className="cardProntuario">
                        <ul>
                            { 
                                content.comorbidades ?
                                content.comorbidades.map((item) => {
                                    return (
                                        <li>
                                            { item.descricao } 
                                            { item.conteudo ? '('+item.conteudo+')' : ''} 
                                            { item.obs && ` Obs: ${item.obs} ` }
                                        </li>
                                    )
                                }) :
                                <li>-----</li>
                            }            
                        </ul>    
                        <SimpleButton onClick={() => {handleCarregarComorbidades()} }><FaEdit /></SimpleButton>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default Comorbidades