import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function cadastrarMedicamento(medicamento) {
    try {
        const response = await api.post('medicamento/', medicamento, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarMedicamentos(paciente, tipo) {
    try {
        const response = await api.get('medicamento?paciente='+paciente, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarMedicamentosMedicos(valor) {
    try {
        const response = await api.get(`medicamento/medico?query=${valor}`, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}


async function deletarMedicamento(id) {
    try {
        const response = await api.delete('medicamento/'+id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function deletarMedicamentoMedico(id) {
    try {
        const response = await api.delete('medicamento/medico/'+id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function alteraStatusMedicamento(id) {
    try {        
        const response = await api.put('medicamento/status/'+id, {}, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data        
    } catch (error) {   
        if (error.response.status === 401) logout() 
        return
    }
}

export { cadastrarMedicamento, listarMedicamentos, deletarMedicamento, 
         alteraStatusMedicamento, listarMedicamentosMedicos, deletarMedicamentoMedico }
