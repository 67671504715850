import React from 'react' 
import './styles.css'

function BoxOptions({ classe, options, visible }) {
    return (
        <ul className={ classe + ' boxContainer ' + (visible ? ' showBoxOptions ' : ' hideBoxOptions ') }>
            { options.map((item) => {
                return (
                    <li className="boxItem" onClick={ (e) => { item.action() } }>
                        { item.descricao }
                    </li>
                )
            }) }
        </ul>
    )
}

export default BoxOptions