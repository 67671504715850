const alreadyRegisteredEmail = ({
  setConfirmation,
  setLoadingVisible,
  setVisibleConfirmation,
  whatsapp,
  link,
}) => {
  setConfirmation({
    Title: "Usuário já cadastrado",
    Question:
      "Esse email já possui cadastro no sistema Aortic. Deseja " +
      "enviar um convite para ingressar na sua clínica?",
    hasExtra: true,
    extra: {
      type: "whatsapp_link",
      data: {
        whatsapp: whatsapp,
        message: `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(link)}`,
      },
    },
  });
  setLoadingVisible(false);
  setVisibleConfirmation(true);
};

export default alreadyRegisteredEmail;
