import React, { useEffect, useState } from "react";
import UploadArea from "../../components/uploadArea";
import InputText from "../../components/formComponents/inputText";
import InputSelect from "../../components/formComponents/inputSelect";
import { onlyLetters } from "../../services/utils/onlyLetters";
import SecondaryButton from "../../components/formComponents/secondaryButton";
import { FaCheck, FaTimes } from "react-icons/fa";
import PrimaryButton from "../../components/formComponents/primaryButton";
import { useHistory, useParams } from "react-router-dom";
import "./styles.css";
import Check from "../../components/formComponents/checkBox";
import {
  ativarUsuario,
  cadastrarUsuarioAdm,
  enviarConviteNovoUsuario,
} from "../../services/usuarios";
import ModalAlert from "../../components/modalAlert";
import ModalConfirmation from "../../components/modalConfirmation";
import Loading from "../../components/loading";
import { cadastrarMedico } from "../../services/medicos";
import alreadyRegisteredClinic from "./functions/alreadyRegisteredClinic";
import alreadyRegisteredEmail from "./functions/alreadyRegisteredEmail";
import { listarEspecialidades } from "../../services/especialidades";
import { cadastrarRecepcionistas } from "../../services/recepcionistas";

function booleanfy(value) {
  return typeof value === "boolean"
    ? value
    : typeof value === "string"
      ? value.toLowerCase() === "true"
      : typeof value === "number"
        ? value !== 0
        : Boolean(value);
}

const CreateAccount = () => {
  const {
    typeDoctor,
    clinica,
    typeAdm = true,
    email: emailParam,
  } = useParams();
  const isTypeDoctor = booleanfy(typeDoctor);
  const isAdm = booleanfy(typeAdm);
  const idClinica = Number(clinica);
  const history = useHistory();
  const statusMessageEmail = {
    valid: "Email válido",
    invalid: "Email inválido",
  };
  const [nome, setNome] = useState("");
  const [sexo, setSexo] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [telefone, setTelefone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirmacao, setSenhaConfirmacao] = useState("");
  const [crm, setCrm] = useState("");
  const [ufCrm, setUfCrm] = useState("");
  const [email, setEmail] = useState(emailParam);
  const sexoOptions = [
    { label: "Selecione", value: "" },
    { label: "Masculino", value: "M" },
    { label: "Feminino", value: "F" },
  ];
  const listaDefault = [{ id: 1, rqe: 0 }];
  const [listaEspecialidadesDefault, setListaEspecialidadesDefault] = useState(
    [],
  );
  const [especialidade, setEspecialidade] = useState([1]);
  const [listaEspecialidades, setListaEspecialidades] = useState(listaDefault);
  const [stateEmail, setStateEmail] = useState("");
  const [fotoPerfil, setFotoPerfil] = useState("");
  const [teste, setTeste] = useState("");
  const [isDoctor, setIsDoctor] = useState(isTypeDoctor);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObject, setAlertObject] = useState({});
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [confirmation, setConfirmation] = useState({
    actionNO: () => {},
    actionOK: () => {},
  });

  function handleUpload(files) {
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);
    reader.onload = function () {
      setFotoPerfil(btoa(reader.result));
    };
  }

  function handleTrocaItemEspecialidade(index, value) {
    const tempArray = listaEspecialidades;
    tempArray[index].id = value;
    setTeste(
      tempArray.map((item) => {
        return item;
      }),
    );
    setListaEspecialidades(tempArray);
  }

  function isValidPassword() {
    return senha === senhaConfirmacao;
  }

  const getTypeUser = () => {
    if (isDoctor) {
      return "MEDICO";
    }

    if (isAdm) {
      return "ADMINISTRADOR";
    }

    return "RECEPCIONISTA";
  };

  const getDadosForm = () => ({
    nome,
    sexo,
    dt_nascimento: dataNascimento,
    telefone: String(telefone)
      .replace(" ", "")
      .replace("-", "")
      .replace("(", "")
      .replace(")", "")
      .trim(),
    whatsapp: String(whatsapp)
      .replace(" ", "")
      .replace("-", "")
      .replace("(", "")
      .replace(")", "")
      .trim(),
    senha,
    ...(crm && { crm }),
    ...(ufCrm && { uf_crm: ufCrm }),
    email,
    foto: fotoPerfil,
    ...(listaEspecialidades.length && { especialidades: listaEspecialidades }),
    tipo: getTypeUser(),
    isAdm,
    idClinica,
  });

  const hasEmptyRequiredInputs = () =>
    !nome ||
    !sexo ||
    !dataNascimento ||
    !email ||
    (isDoctor ? !crm || !ufCrm : false);

  const handleSave = async () => {
    if (hasEmptyRequiredInputs()) return;

    if (!isValidPassword()) {
      const element = document.getElementById("senha");
      element.setAttribute("title", "Senhas não conferem");
      element.focus();
      setLoadingVisible(false);
      return;
    }
    setLoadingVisible(true);

    const data = getDadosForm();
    const typeUser = getTypeUser();
    const userCreate = {
      MEDICO: async () => {
        const createdDoctor = await cadastrarMedico(data);
        const doctorFeedBack = {
          CADASTRO_CLINICA: async () =>
            alreadyRegisteredClinic({
              setAlertObject,
              setVisibleAlert,
              setLoadingVisible,
            }),
          CADASTRO_OUTRO: async () =>
            alreadyRegisteredEmail({
              setConfirmation,
              setLoadingVisible,
              setVisibleConfirmation,
              whatsapp: createdDoctor?.whatsapp,
              link: createdDoctor?.link,
            }),
          INATIVO: async (idDoctor) => {
            alreadyRegisteredEmail({
              setConfirmation,
              setLoadingVisible,
              setVisibleConfirmation,
              actionOk: ativarUsuario(idClinica, idDoctor),
            });
          },
        };

        if (createdDoctor?.message) {
          await doctorFeedBack[createdDoctor?.message?.message](
            createdDoctor.id_pessoa,
          );
        } else {
          history.push("/");
        }
      },
      ADMINISTRADOR: async () => {
        const admCreated = await cadastrarUsuarioAdm(data);
        const admFeedBack = {
          CADASTRO_CLINICA: async () =>
            alreadyRegisteredClinic({
              setAlertObject,
              setVisibleAlert,
              setLoadingVisible,
            }),
          CADASTRO_OUTRO: async () =>
            alreadyRegisteredEmail({
              setConfirmation,
              setLoadingVisible,
              setVisibleConfirmation,
              whatsapp: admCreated?.whatsapp,
              link: admCreated?.link,
            }),
          INATIVO: async (idReceptionist) => {
            alreadyRegisteredEmail({
              setConfirmation,
              setLoadingVisible,
              setVisibleConfirmation,
              actionOk: ativarUsuario(idClinica, idReceptionist),
            });
          },
        };

        if (admCreated?.message) {
          await admFeedBack[admCreated?.message?.message](admCreated.id_pessoa);
        } else {
          history.push("/");
        }
      },
      RECEPCIONISTA: async () => {
        const createdReceptionist = await cadastrarRecepcionistas(data);
        const receptionistFeedBack = {
          CADASTRO_CLINICA: async () =>
            alreadyRegisteredClinic({
              setAlertObject,
              setVisibleAlert,
              setLoadingVisible,
            }),
          CADASTRO_OUTRO: async () =>
            alreadyRegisteredEmail({
              setConfirmation,
              setLoadingVisible,
              setVisibleConfirmation,
              whatsapp: createdReceptionist?.whatsapp,
              link: createdReceptionist?.link,
            }),
          INATIVO: async (idReceptionist) => {
            alreadyRegisteredEmail({
              setConfirmation,
              setLoadingVisible,
              setVisibleConfirmation,
              actionOk: ativarUsuario(idClinica, idReceptionist),
            });
          },
        };

        if (createdReceptionist?.message) {
          await receptionistFeedBack[createdReceptionist?.message?.message](
            createdReceptionist.id_pessoa,
          );
        } else {
          history.push("/");
        }
      },
    };

    try {
      await userCreate[typeUser]();
    } catch (e) {
      console.error(`Create account ${e?.message} ${e}`);
    } finally {
      setLoadingVisible(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setStateEmail("loading");
      const btn = document.getElementById("btnSalvar");

      if (!email) {
        setStateEmail("");
        return;
      }
      setStateEmail("invalid");
      if (email.length < 5) return;
      const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!pattern.test(email)) return;

      setStateEmail("valid");
      btn.removeAttribute("disabled");
    }, 500);
    return () => clearTimeout(timer);
  }, [email]);

  useEffect(() => {
    listarEspecialidades().then((result) => {
      const tempResult = result.map((item) => {
        return { value: item.id, label: item.descricao, other: item.rqe };
      });
      setListaEspecialidadesDefault(tempResult);
    });
  }, []);

  return (
    <>
      <Loading forced={false} visible={loadingVisible} />
      <ModalAlert
        visible={visibleAlert}
        setVisible={setVisibleAlert}
        title={alertObject.title}
        text={alertObject.text}
        width={alertObject.widht}
      />
      <ModalConfirmation
        height={"160px"}
        visible={visibleConfirmation}
        setVisible={setVisibleConfirmation}
        actionOK={confirmation.actionOK}
        actionNO={confirmation.actionNO}
        title={confirmation.Title}
        text={confirmation.Question}
        hasExtra={confirmation.hasExtra}
        extras={confirmation.extra}
      />
      <form
        className="containerCreateAccount"
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{ width: 500 }}
      >
        <UploadArea
          onUpload={handleUpload}
          uploadedPhoto={"data:image/png;base64, " + (fotoPerfil || "")}
        />
        <InputText
          value={nome}
          onChange={(e) => {
            setNome(e.target.value);
          }}
          containerstyle="input"
          label="Nome*"
          name="nome"
          required
        ></InputText>
        <div className="col-2" style={{ width: "100%" }}>
          <InputSelect
            containerstyle="input"
            value={sexo}
            onChange={(e) => {
              setSexo(e.target.value);
            }}
            name="sexo"
            options={sexoOptions}
            label="Sexo*"
            required
          ></InputSelect>
          <InputText
            required
            value={dataNascimento}
            onChange={(e) => {
              setDataNascimento(e.target.value);
            }}
            type="date"
            containerstyle="input"
            label="Data Nascimento*"
            name="dtNascimento"
          />
        </div>
        {(!typeDoctor || isTypeDoctor) && (
          <>
            <div className="col-2" style={{ width: "100%", paddingLeft: 20 }}>
              <Check
                name="preenchimento"
                label="Sou médico(a)"
                checked={isDoctor}
                onCheck={() => setIsDoctor(!isDoctor)}
              />
            </div>
            {isDoctor && (
              <div className="col-2" style={{ width: "100%" }}>
                <InputText
                  value={crm}
                  onChange={(e) => {
                    setCrm(e.target.value);
                  }}
                  containerstyle="input"
                  type="text"
                  label="CRM"
                  name="crmMedico"
                  disabled={!isDoctor}
                  required={isDoctor}
                />
                <InputText
                  value={ufCrm}
                  onChange={(e) => {
                    const newUF = onlyLetters(
                      String(e.target.value).toUpperCase(),
                    );
                    setUfCrm(newUF);
                  }}
                  containerstyle="col-20-percent-right uf input"
                  label="UF"
                  name="uf"
                  disabled={!isDoctor}
                  required={isDoctor}
                  style={{ maxWidth: 100 }}
                />
                {especialidade.map((item, index) => {
                  return (
                    <div key={index} className="containerSelectEspecialidade">
                      <InputSelect
                        containerstyle="input"
                        value={
                          listaEspecialidades[index]
                            ? listaEspecialidades[index].id
                            : ""
                        }
                        onChange={(e) => {
                          handleTrocaItemEspecialidade(
                            index,
                            Number(e.target.value),
                          );
                        }}
                        name={"especialidade" + index}
                        options={listaEspecialidadesDefault}
                        label="Especialidades"
                        disabled={!isDoctor}
                        required={isDoctor}
                        style={{ minWidth: 200 }}
                      ></InputSelect>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
        <InputText
          status={stateEmail}
          statusMessage={statusMessageEmail}
          autoComplete="off"
          required
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          containerstyle="input"
          type="email"
          label="Email*"
          name="email"
        />
        <div className="col-2" style={{ width: "100%" }}>
          <InputText
            value={senha}
            onChange={(e) => {
              setSenha(e.target.value);
            }}
            type="password"
            autoComplete="new-password"
            containerstyle="input"
            label="Senha*"
            id="senha"
            name="senha"
          />
          <InputText
            value={senhaConfirmacao}
            onChange={(e) => {
              setSenhaConfirmacao(e.target.value);
            }}
            type="password"
            autoComplete="new-password"
            containerstyle="input"
            label="Confirme a senha*"
            name="confirmacaoSenha"
          />
        </div>
        <div className="footer">
          <SecondaryButton
            type="submit"
            name="btnCancelar"
            onClick={() => history.push("/")}
            containerstyle="footerButton"
            text="Cancelar"
          >
            <FaTimes />
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            name="btnSalvar"
            containerstyle="footerButton"
            text="Salvar"
          >
            <FaCheck />
          </PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default CreateAccount;
