import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function listarMedicos() {
    try {
        const response = await api.get('medico',{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        const dados = response.data
        const lista = dados.map((item) => {
        const {
                id,
                id_medico,
                nome ,
                documento ,
                telefone ,
                whatsapp ,
                sexo,
                dt_nascimento ,
                email,
                crm,
                especialidades,
                administrador,
                altera_adm
            } = item
            let listaEspecialidades = []
            let listaRQE = []
            
            if (especialidades) {
                listaEspecialidades = especialidades.map((item, index) => {
                    return item.especialidade + (index === especialidades.length - 1 ? '' : '/')           
                })
        
                listaRQE = especialidades.map((item, index) => {
                    return item.rqe + (index === especialidades.length - 1 ? '' : '/')
                })
            } 
            
            
            const listaTratada = {
                id,
                id_medico,
                nome ,
                documento ,
                telefone ,
                whatsapp ,
                sexo,
                dt_nascimento ,
                email,
                crm,
                especialidade: listaEspecialidades,
                rqe: listaRQE,
                administrador,
                altera_adm
            }
            return listaTratada
        })
        return lista
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }    
}

async function pesquisarMedicos(filter) {
    try {
        let query = ''
        if (filter) query = '?filter=' + filter
        const response = await api.get('medico'+query,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        const dados = response.data
        const lista = dados.map((item) => {
        const {
                id,
                id_medico,
                nome ,
                documento ,
                telefone ,
                whatsapp ,
                sexo,
                dt_nascimento ,
                email,
                crm,
                especialidades,
                administrador,
                altera_adm
            } = item
            let listaEspecialidades = []
            let listaRQE = []
            
            if (especialidades) {
                listaEspecialidades = especialidades.map((item, index) => {
                    return item.especialidade + (index === especialidades.length - 1 ? '' : '/')           
                })
        
                listaRQE = especialidades.map((item, index) => {
                    return item.rqe + (index === especialidades.length - 1 ? '' : '/')
                })
            } 
            
            
            const listaTratada = {
                id,
                id_medico,
                nome ,
                documento ,
                telefone ,
                whatsapp ,
                sexo,
                dt_nascimento ,
                email,
                crm,
                especialidade: listaEspecialidades,
                rqe: listaRQE,
                administrador,
                altera_adm
            }
            return listaTratada
        })
        return lista
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }    
}

async function alterarMedico(medico) {
    try {
        const response = await api.put('medico', medico,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}


async function alteraStatusAdmin(id) {
    try {
        const response = await api.put(`medico/admin/${id}`, {},{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function deletarMedico(id) {
    try {
        const response = await api.delete('medico/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function cadastrarMedico(medico) {
    try {
        const response = await api.post('medico', medico,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function buscarMedico(id) {
    try {
        const response = await api.get('medico/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

export { listarMedicos, cadastrarMedico, buscarMedico, pesquisarMedicos,
         deletarMedico, alterarMedico, alteraStatusAdmin }