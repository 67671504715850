import React, { useContext, useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { calcColorFramingham } from "../../../services/utils/colorsIndices";
import { Tooltip } from "reactstrap";
import PrimaryButton from "../../../components/formComponents/primaryButton";
import SecondaryButton from "../../../components/formComponents/secondaryButton";
import { useHistory } from "react-router-dom";
import LogoAortic from "../../../images/logo-aortic.png";
import {
  calcularIdade,
  printaSexo,
  printaIdade,
  printaScoreFramingham,
  printaClassificacaoIMC,
  printaClassificacaoPressaoArterial,
  printaPrognosticoDRC,
  printaRiscoCardioVascular,
  calcColorRiscoCardioVascular,
  calcColorPrognosticoDRC,
  calculateFutureDate,
  monthsToReturnByCardioVascular,
} from "./functions/utils";
import { circleObject, inlineContainer } from "./styles";
import "./styles.css";
import InputText from "../../../components/formComponents/inputText";
import {
  STEP_CALCULATOR_RISK,
  STEP_COMPLETE_REGISTRATION,
  STEP_PUBLIC_CALCULATOR_RISK,
  usePatientRecordContext,
} from "../../../context/patientRecordContext";

const ResultadoCalculoRisco = ({ isPublic = false }) => {
  const history = useHistory();
  const {
    calculatorRiskData,
    resultRiskData,
    setStepper,
    setResultRiskData,
    clearPatientRecordContext,
    setStepperPublic,
  } = usePatientRecordContext();
  const [monthsToReturn, setMonthsToReturn] = useState("");
  const [toolTipPrognosticoDRC, setToolTipPrognosticoDRC] = useState(false);
  const [toolTipFramingham, setToolTipFramingham] = useState(false);
  const [toolTipCkedEpi, setToolTipCkedEpi] = useState(false);
  const {
    sexo,
    dataNascimento,
    pressaoArterialSistolica,
    pressaoArterialDiastolica,
    doencaAteroscleroticaSignificativaOuObstrucao50,
  } = calculatorRiskData;
  const {
    classificacaoIMC,
    classificacaoPressaoArterial,
    scoreFramingham,
    riscoCardioVascular,
    riscoRenal,
    ckdEpi,
    imc,
  } = resultRiskData;
  const ckdEpiFormatado = parseFloat(ckdEpi).toFixed(1);
  const idade = calcularIdade(dataNascimento);

  const toggleToolTipFramingham = () => {
    setToolTipFramingham(!toolTipFramingham);
  };

  const toggleToolTipCkedEpi = () => {
    setToolTipCkedEpi(!toolTipCkedEpi);
  };

  const toggleToolTipPrognosticoDRC = () => {
    setToolTipPrognosticoDRC(!toolTipPrognosticoDRC);
  };

  const newCalc = () => {
    clearPatientRecordContext();
    if (isPublic) {
      setStepperPublic(STEP_PUBLIC_CALCULATOR_RISK);
    } else {
      setStepper(STEP_CALCULATOR_RISK);
    }
  };

  const handleRecordPatient = () => {
    setStepper(STEP_COMPLETE_REGISTRATION);

    if (isPublic) {
      setStepperPublic(STEP_PUBLIC_CALCULATOR_RISK);
      setStepper(STEP_COMPLETE_REGISTRATION);
      history.push("/clinicas");
    } else {
      setStepper(STEP_COMPLETE_REGISTRATION);
    }
  };

  const handleInputChange = (e) => setMonthsToReturn(String(e.target.value));

  useEffect(() => {
    if (monthsToReturn > 0) {
      const prazoConsulta = calculateFutureDate(monthsToReturn);
      setResultRiskData({ ...resultRiskData, prazoConsulta });
    }
  }, [monthsToReturn]);

  useEffect(() => {
    if (resultRiskData?.riscoCardioVascular) {
      const months = monthsToReturnByCardioVascular(
        resultRiskData.riscoCardioVascular,
      );
      setMonthsToReturn(months);
    }
  }, [resultRiskData?.riscoCardioVascular]);

  return (
    <div className="parentResultado">
      <div className="resultadoCalculoRisco">
        <h1 className="label-titulo">Resultado</h1>
        <div className="dadosPessoa">
          <p style={{ flexBasis: "30%" }}>
            <span style={{ color: "var(--primary)" }}>Sexo:</span>{" "}
            {printaSexo(sexo)}
          </p>
          <p style={{ flexBasis: "70%" }}>
            <span style={{ color: "var(--primary)" }}>Idade:</span>{" "}
            {printaIdade(idade)}
          </p>
        </div>

        <div className="grupoResultado">
          <div style={{ flexBasis: "50%" }}>
            <h3 className="label-titulo">Classificação IMC</h3>
            {
              <div style={inlineContainer}>
                <div>
                  <p>
                    <span style={{ color: "var(--primary)" }}>IMC:</span> {imc}{" "}
                    kg/m²
                  </p>
                  <p>
                    <span style={{ color: "var(--primary)" }}>
                      Classificação:
                    </span>{" "}
                    {printaClassificacaoIMC(classificacaoIMC)}
                  </p>
                </div>
              </div>
            }
          </div>

          <div style={{ flexBasis: "50%" }}>
            <h3 className="label-titulo">Pressão Arterial</h3>
            {
              <div style={inlineContainer}>
                <div>
                  <p>
                    <span style={{ color: "var(--primary)" }}>SIS:</span>{" "}
                    {pressaoArterialSistolica} mmHg
                  </p>
                  <p>
                    <span style={{ color: "var(--primary)" }}>DIA:</span>{" "}
                    {pressaoArterialDiastolica} mmHg
                  </p>
                  <p>
                    <span style={{ color: "var(--primary)" }}>
                      Classificação:
                    </span>{" "}
                    {printaClassificacaoPressaoArterial(
                      classificacaoPressaoArterial,
                    )}
                  </p>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="grupoResultado">
          <div style={{ flexBasis: "50%" }}>
            <h3 className="label-titulo">Score Framingham</h3>
            {
              <div style={inlineContainer}>
                <Tooltip
                  placement="right"
                  isOpen={toolTipFramingham}
                  toggle={toggleToolTipFramingham}
                  target="infoScoreFraminghamTooltip"
                >
                  <span style={{ textAlign: "left" }}>
                    <ul>
                      <li>
                        Pacientes 30-74 anos sem Doença Aterosclerótica
                        Significativa
                      </li>
                      <li>Dados Obrigatórios:</li>
                      <li>Colesterol Total</li>
                      <li>HDL</li>
                      <li>Pressão Sistólica</li>
                    </ul>
                  </span>
                </Tooltip>
                <div
                  style={{
                    backgroundColor: calcColorFramingham(
                      scoreFramingham,
                      doencaAteroscleroticaSignificativaOuObstrucao50,
                    ),
                    ...circleObject,
                  }}
                ></div>
                <div>
                  {printaScoreFramingham(
                    scoreFramingham,
                    doencaAteroscleroticaSignificativaOuObstrucao50,
                  )}
                  <i className="iconInfo" id="infoScoreFraminghamTooltip">
                    <FaInfoCircle />
                  </i>
                </div>
              </div>
            }
          </div>

          <div style={{ flexBasis: "50%" }}>
            <h3 className="label-titulo">Risco cardiovascular</h3>
            {
              <div style={inlineContainer}>
                <div
                  style={{
                    backgroundColor:
                      calcColorRiscoCardioVascular(riscoCardioVascular),
                    ...circleObject,
                  }}
                ></div>
                <div>{printaRiscoCardioVascular(riscoCardioVascular)}</div>
              </div>
            }
          </div>
        </div>

        <div className="grupoResultado">
          <div style={{ flexBasis: "50%" }}>
            <h3 className="label-titulo">Prognóstico DRC</h3>
            {
              <div style={inlineContainer}>
                <Tooltip
                  placement="right"
                  isOpen={toolTipPrognosticoDRC}
                  toggle={toggleToolTipPrognosticoDRC}
                  target="infoPrognosticoTooltip"
                >
                  <span style={{ textAlign: "left" }}>
                    Dados Obrigatórios:
                    <ul>
                      <li>Creatinina</li>
                      <li>Idade</li>
                      <li>Microalbuminúria</li>
                    </ul>
                  </span>
                </Tooltip>
                <div
                  style={{
                    backgroundColor: calcColorPrognosticoDRC(riscoRenal),
                    ...circleObject,
                  }}
                ></div>
                <div>
                  {printaPrognosticoDRC(riscoRenal)}
                  <i className="iconInfo" id="infoPrognosticoTooltip">
                    <FaInfoCircle />
                  </i>
                </div>
              </div>
            }
          </div>

          <div style={{ flexBasis: "50%" }}>
            <h3 className="label-titulo">TFG (CKD-EPI)</h3>
            {
              <div style={inlineContainer}>
                <Tooltip
                  placement="right"
                  isOpen={toolTipCkedEpi}
                  toggle={toggleToolTipCkedEpi}
                  target="infoCKDEpiTooltip"
                >
                  <span style={{ textAlign: "left" }}>
                    Dados Obrigatórios:
                    <ul>
                      <li>Creatinina</li>
                      <li>Peso</li>
                    </ul>
                  </span>
                </Tooltip>
                <div
                  style={{
                    backgroundColor: calcColorFramingham(
                      ckdEpi,
                      doencaAteroscleroticaSignificativaOuObstrucao50,
                    ),
                    ...circleObject,
                  }}
                ></div>
                <div>
                  {ckdEpi == "-1" || ckdEpi == "NaN"
                    ? "Não calculado"
                    : ckdEpiFormatado + " ml/min/1.73m²"}
                  <i className="iconInfo" id="infoCKDEpiTooltip">
                    <FaInfoCircle />
                  </i>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="grupoResultado">
          <div>
            <InputText
              type="text"
              label={
                "Conforme a classificação de risco do paciente e seus dados atuais sugere-se retorno em mês(es):"
              }
              value={monthsToReturn}
              onChange={handleInputChange}
              name="monthsToReturn"
              style={{ maxWidth: 50, fontSize: "14px" }}
            ></InputText>
          </div>
        </div>
      </div>
      <div className="fonte">
        <p>
          Diretriz Brasileira de Dislipidemias e Prevenção da Aterosclerose –
          2017. Arq Bras Cardiol. 2017;109(2Supl.1):1-76.
        </p>
        <p>
          Diretrizes Brasileiras de Hipertensão Arterial - 2020. Arq Bras
          Cardiol. 2021; 116(3):516-658.
        </p>
        <p>
          General cardiovascular risk profile for use in primary care.
          Circulation. 2008 Feb 12;117:743-53. PMID:18212285.
        </p>
      </div>
      <div className="botoes">
        <PrimaryButton
          style={{ width: "300px", fontSize: "16px" }}
          onClick={handleRecordPatient}
        >
          Cadastrar Paciente
        </PrimaryButton>
        <SecondaryButton
          onClick={newCalc}
          style={{ width: "200px", fontSize: "16px" }}
        >
          Fazer novo cálculo
        </SecondaryButton>
      </div>
    </div>
  );
};

export default ResultadoCalculoRisco;
