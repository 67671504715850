import React, { useEffect, useState } from "react";
import InputText from "../../../../components/formComponents/inputText";
import InputSelect from "../../../../components/formComponents/inputSelect";
import SecondaryButton from "../../../../components/formComponents/secondaryButton";
import { FaStar } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import NotesImage from "../../../../images/notes.png";
import Modal from "../../../../components/modal";
import Loading from "../../../../components/loading";
import "./styles.css";
import SimpleButton from "../../../../components/formComponents/simpleButton";
import Notificacoes from "../Notificacoes";
import { preencheAnotacao } from "../../../../services/prontuario";

function HeaderProntuario({ content, notificacoes, closeProntuario }) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [loadingVisible, setLoadingVisible] = useState(false);
  const [modalVisivel, setModalVisivel] = useState(false);
  const [modalNotificacaoVisivel, setModalNotificacaoVisivel] = useState(false);
  const [anotacao, setAnotacao] = useState("");
  const [novaMensagem, setNovaMensagem] = useState("");
  const query = useQuery();

  useEffect(() => {
    setModalNotificacaoVisivel(notificacoes.visibleNotificacao);
  }, [notificacoes.visibleNotificacao]);

  const riscoCardiovascularOptions = [
    { label: "Não calculado", value: "-1" },
    { label: "Sem Risco", value: "1" },
    { label: "Baixo Risco", value: "2" },
    { label: "Risco Moderado", value: "3" },
    { label: "Alto Risco", value: "4" },
    { label: "Risco Muito Alto", value: "5" },
  ];

  const doencaRenalOptions = [
    { label: "Não calculado", value: "-1" },
    { label: "Baixo risco", value: "0" },
    { label: "Moderado risco", value: "1" },
    { label: "Alto risco", value: "2" },
    { label: "Muito alto risco", value: "3" },
  ];

  useEffect(() => {
    let elementTipoDado = document.getElementById("idadeProntuario");
    elementTipoDado.setAttribute("readonly", true);

    elementTipoDado = document.getElementById("dataUltimaConsulta");
    elementTipoDado.setAttribute("readonly", true);

    elementTipoDado = document.getElementById("dataProximaConsulta");
    elementTipoDado.setAttribute("readonly", true);

    setAnotacao(content.anotacao);
  }, []);

  useEffect(() => {
    setAnotacao(content.anotacao);
    const hash = btoa(content.id_pessoa + ":" + content.nome);
    setNovaMensagem(`/mensagens?hash=${hash}`);
  }, [content]);

  function handleIncluirAnotacao() {
    setLoadingVisible(true);
    preencheAnotacao(content.paciente, { anotacao })
      .then((result) => {
        setModalVisivel(!modalVisivel);
        setLoadingVisible(false);
      })
      .catch((erro) => {
        setLoadingVisible(false);
      });
  }

  return (
    <>
      <Loading forced={true} visible={loadingVisible} />
      <Modal style="modalFull" visible={modalVisivel}>
        <div className="modalContainer" style={{ height: "310px" }}>
          <div>
            <div className="modalHeader">
              <h4 className="titleProntuario">Anotações</h4>
              <div className="fecharModal">
                <button
                  onClick={() => {
                    setModalVisivel(!modalVisivel);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <div className="modalAfericoesContent">
              <div className="modalAfericoesForm">
                <div style={{ width: "100%" }} className="inputContainer">
                  <textarea
                    value={anotacao}
                    onChange={(e) => {
                      setAnotacao(e.target.value);
                    }}
                    className="textAreaAnotacao"
                    name=""
                    id=""
                    cols="90"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <SecondaryButton onClick={handleIncluirAnotacao}>
                + Incluir
              </SecondaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <div className="headerProntuarioContainer">
        <div>
          <h4 className="nome">{content.nome}</h4>
        </div>
        <div className="headerProntuarioContent">
          <div>
            <div className="headerProntuarioInputs">
              <InputText
                value={content.idade}
                name="idadeProntuario"
                label="Idade"
                containerstyle="input-short"
              />
              <InputText
                value={content.dh_ultima_consulta}
                name="dataUltimaConsulta"
                type="date"
                label="Última consulta"
              />
              <InputText
                value={content.proxima_consulta}
                name="dataProximaConsulta"
                type="date"
                label="Proxima consulta"
              />
            </div>
            <div className="headerProntuarioIndices">
              <InputSelect
                name="idadeProntuario"
                value={content.risco_cardiovascular}
                label="Risco cardiovascular"
                containerstyle="input-indice"
                options={riscoCardiovascularOptions}
              ></InputSelect>
              <InputText
                name="idadeProntuario"
                value={
                  content.score_framingham && `${content.score_framingham} %`
                }
                label="Score de Framingham"
                containerstyle="input-indice"
              />
              <InputText
                name="idadeProntuario"
                label="TFG (ckd-epi)"
                value={content.ckd_epi}
                containerstyle="input-indice"
              />
              <InputSelect
                name="idadeProntuario"
                value={content.risco_renal}
                label="Risco de doença renal crônica"
                containerstyle="input-indice"
                options={doencaRenalOptions}
              ></InputSelect>
            </div>
          </div>
          <div className="headerProntuarioButton">
            <div className="anotacaoContainer">
              <span>Anotação</span>
              {anotacao ? (
                <FaStar
                  style={{ stroke: "#333", strokeWidth: "50" }}
                  color="yellow"
                />
              ) : (
                ""
              )}

              <SimpleButton
                onClick={() => {
                  setModalVisivel(!modalVisivel);
                }}
                className="btnAnotacao"
              >
                <img src={NotesImage} alt="" />
              </SimpleButton>
            </div>
            <a
              className="primaryButtonLink headerProntuarioButtonItem"
              href={novaMensagem}
              target="_blank"
            >
              Enviar mensagem
            </a>

            <Notificacoes
              content={{
                id_pessoa: content.id_pessoa,
                paciente: content.paciente,
              }}
              onClick={() => {
                setModalNotificacaoVisivel(!modalNotificacaoVisivel);
              }}
              close={() => {
                setModalNotificacaoVisivel(false);
                if (notificacoes.visibleNotificacao) {
                  notificacoes.setVisibleNotificacao(false);
                  closeProntuario();
                }
              }}
              visible={modalNotificacaoVisivel}
            />
            {/* <PrimaryButton containerstyle='headerProntuarioButtonItem'>Comunicação interna</PrimaryButton> */}
          </div>
        </div>
      </div>
    </>
  );
}
export { HeaderProntuario };
