

import axios from 'axios'

const apiCep = axios.create({
    baseURL: 'https://viacep.com.br/ws/', 
})

async function consultarCEP(cep) {
    const endereco = apiCep.get('/' + cep + '/json')
    return endereco
}

export { consultarCEP }