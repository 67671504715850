import React from 'react'
import FormDataContainer from '../../components/formDataContainer'
import Navigator from '../../components/navigator'
import PageDefault from '../../components/pageDefault'
import Prontuario from './prontuario'
import './style.css'

function ProntuarioPerfil() {
    const isMobile = window.innerWidth < 800
    return (        
        <PageDefault>  
            {
                isMobile ? 
                <>
                    <Navigator />  
                    <Prontuario />
                </>:
                <>
                    <FormDataContainer title="Meu Prontuário">              
                        
                    </FormDataContainer>  
                    <div className="mainContent">
                        <Navigator /> 
                        <div className="prontuarioPacienteContainer">
                        <div className="prontuarioPacienteBackground">
                            <Prontuario />  
                            </div>
                        </div>
                    </div>  
                </>
            }                                             
        </PageDefault>
    )
}

export default ProntuarioPerfil