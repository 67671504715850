import React from 'react'
import Modal from '../modal'
import PrimaryButton from '../formComponents/primaryButton'
import SecondaryButton from '../formComponents/secondaryButton'

import './styles.css'

function ModalConfirmation({ visible, actionOK, actionNO, setVisible, text, title, height,
                             hasExtra, extras }) {
    function clickNo() {
        actionNO()
        setVisible(false)
    }

    function Extras() {
        if (!extras) return
        if (extras.type == 'whatsapp_link') {
            const clearWhatsapp = String(extras.data.whatsapp)
                                        .replace(' ', '')
                                        .replace('-', '')
                                        .trim()
            return (
                <a className='primaryButtonLink linkWhats' 
                    href={`https://wa.me/55${clearWhatsapp}?text=${extras.data.message}`} 
                    target='_blank'>Link Whatsapp</a>

            )
        }
    }

    function clickYes() {
        actionOK()
        setVisible(false)
    }
    function primary() {
       if (!actionOK ) return null
       return <PrimaryButton onClick={clickYes}>Sim</PrimaryButton>
    }

    function secondary() {
       if (!actionNO ) return null
       return <SecondaryButton onClick={clickNo}>Não</SecondaryButton>
    }
    return (
        <Modal style='modalFull' visible={ visible }>
            <div style={ {height: height || '140px'}} className="modalContainerConfirmation">
                <div>
                    <div className="modalHeader">
                        <h6 className="titleProntuario">
                            {title}
                        </h6> 
                        <div className='fecharModal'>
                            <button onClick={ () => { setVisible(false) } }>X</button> 
                        </div>     
                    </div>
                </div>
                <div className='modalConfirmation'>
                    <span>{text}</span> 
                    <div className="buttonConfirmationContainer">
                        {primary()}
                        {secondary()}
                        {
                            hasExtra == true ?
                            <Extras /> :
                            null
                        }
                    </div>
                </div>     
            </div>
        </Modal>
    )
}

export default ModalConfirmation