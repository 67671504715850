import React from 'react';
import './style.css'

const InputPressaoArterial = ({ name, label, containerstyle, type, status, statusMessage, autocomplete, onChangeSistolica, onChangeDiastolica, showError, placeHolderValue, mandatory, valueSistolica, valueDiastolica, ...rest}) => {
    const handleChangeSistolica = (e) => {
        const { value } = e.target;
        onChangeSistolica(value)
    }

    const handleChangeDiastolica = (e) => {
        const { value } = e.target;
        onChangeDiastolica(value)
    }

    const nameSistolica = name + 'Sistolica'
    const nameDiastolica = name + 'Diastolica'
    
    return (    
        <div className={"inputTextContainer " + containerstyle}>
            <div style={{ marginLeft: '20px' }}>
                <label htmlFor={ nameSistolica }>{ label }{mandatory && <span style={{color: 'red'}}> *</span>}</label>
            </div>
            <div>
                <input type={ type || "text"} id={ nameSistolica } onChange={handleChangeSistolica} value={valueSistolica} { ...rest } /> 
                <span>x</span>
                <input type={ type || "text"} id={ nameDiastolica } onChange={handleChangeDiastolica} value={valueDiastolica} { ...rest } /> 
                {(showError) && <span className="error">Insira os valores para PAS e PAD</span>}
            </div>
        </div>
    )
}

export default InputPressaoArterial