import React from 'react'
import Modal from '../modal'
import PrimaryButton from '../formComponents/primaryButton'
import SecondaryButton from '../formComponents/secondaryButton'

import './styles.css'

function ModalAlert({ visible, setVisible, text, title, width, height, onClickOK, hasExtra, extras }) {
    function Extras() {
        if (!extras) return
        if (extras.type == 'whatsapp_link') {
            const clearWhatsapp = String(extras.data.whatsapp)
                                        .replace(' ', '')
                                        .replace('-', '')
                                        .trim()
            return (
                <a onClick={() => { 
                        setVisible(false)
                        if (onClickOK) 
                            onClickOK()
                    }} className='primaryButtonLink linkWhats' 
                    href={`https://wa.me/55${clearWhatsapp}?text=${extras.data.message}`} 
                    target='_blank'>Link Whatsapp</a>

            )
        }
    }
    return (
        <Modal style='modalFull' visible={ visible }>
            <div 
                className="modalContainerAlert"
                
                style={ {height: height || '120px', width: width || '30%'}}
            >
                <div>
                    <div className="modalHeader">
                        <h6 className="titleProntuario">
                            {title}
                        </h6>    
                    </div>
                </div>
                <div className='modalAlertAlert'>
                    <span>{text}</span> 
                    <div className="buttonAlertContainer">
                    {
                            hasExtra == true ?
                            <Extras /> :                            
                            <PrimaryButton onClick={() => { 
                                setVisible(false)
                                if (onClickOK) 
                                    onClickOK()
                            }}>OK</PrimaryButton>
                        
                    }
                    </div>
                    
                </div>     
            </div>
        </Modal>
    )
}

export default ModalAlert