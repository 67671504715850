import React from 'react'

const MensagemContext = React.createContext({ 
        estado: '',
        malaDireta: false,
        handleMalaDireta: () => {},
        precisaAtualizar: false,
        atualizarGrade: () => {},
        nomeDestinatario: '',
        atualizaNomeDestinatario: () => {},
        voltarTab: () => {},
        destinatario: 0,
        setDestinatario: () => {},
        fotoDestinatario: 0,
        setFotoDestinatario: () => {},
        data: [], 
        setData: () => {} })

export const MensagemProvider = MensagemContext.Provider

export { MensagemContext }
