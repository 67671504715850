export function calcColorRiscoRenal(value) {
    switch (value) {
      case -1: return 'gray'        
      case 0: return '#27AE60'        
      case 1: return '#F2C94C'        
      case 2: return '#F2994A'    
      case 3: return '#EB5757'            

      default:
        return 'gray' ;
    }
  }

  export function calcColorFramingham(value, doencaAteroscleroticaSignificativaOuObstrucao50) {   

    if (doencaAteroscleroticaSignificativaOuObstrucao50) return 'gray'
    if (Number(value) == -1) return 'gray'  
    if (Number(value) < 10) return '#27AE60'         
    if ((Number(value) >= 10) &&
        (Number(value) <= 20)) return '#F2C94C'    
    if (Number(value) > 20) return '#EB5757' 
    return 'gray'     
  }

  export function calcColorPressaoAlta(value) {
    switch (value) {
      case -1: return 'gray'        
      case 0: return '#27AE60'        
      case 1: return '#2D9CDB'        
      case 2: return '#F2C94C'    
      case 3: return '#EB5757'            

      default:
        return 'gray' ;
    }
  }