import React, { useState, useEffect, useContext } from 'react'
import NoPhotoImage from '../../../images/no-photo.png'
import { MdMoreVert, MdSend, MdArrowBack, MdRestaurant } from 'react-icons/md'
import { MensagemContext } from '../context'
import BoxOptions from '../../../components/boxOptions'
import Mensagem from '../mensagem'
import { novaMensagem, listarMensagens, deletarMensagens } from '../../../services/mensagens'
import { buscarDadosPerfilLogado } from '../../../services/usuarios'
import Loading from '../../../components/loading'
import Navigator from '../../../components/navigator'
import './styles.css'

function HistoricoMensagem() {
    const optionsBoxHistorico = [ {
        descricao: 'Limpar conversa', action: () => { handleDeletarMensagens() } }]
        const isMobile = window.innerWidth < 800
        const [textoMensagem, setTextoMensagem] = useState('')
        const [loadingVisible, setLoadingVisible] = useState(false)
        const [boxVisible, setBoxVisible] = useState(false)
        const [perfil, setPerfil] = useState('')
        const { data, setData, destinatario, fotoDestinatario, 
                nomeDestinatario, voltarTab, atualizarGrade,
                setDestinatario, setFotoDestinatario, atualizaNomeDestinatario } = useContext(MensagemContext)

        let dataMensagem = ''

        useEffect(() => {
            const objDiv = document.getElementById("scroll");
            objDiv.scrollTo(0, objDiv.scrollHeight)
            buscarDadosPerfilLogado()
                .then((result) => {          
                    setPerfil(result.perfil.perfil) 
                })
                .catch(() => {
                    setPerfil('PACIENTE')
                })
                    
        }, [])

        function updateScroll() {
            const objDiv = document.getElementById("scroll")
            if (objDiv)
                objDiv.scrollTo(0, objDiv.scrollHeight)
        }

        function getMinhaData(hora) {

            let data = (`${hora.getDate()}/${String(hora.getMonth() + 1).padStart(2, '0')}/${hora.getFullYear()}`) 
            if ((dataMensagem ) && (data == dataMensagem )) {
                return ''
            } else {
                dataMensagem = (data)
                return data
            }
        }

        function listarMensagensConversa() {
            if (!data[0]) return
            listarMensagens(data[0].id_conversa)
            .then((result) => {
                if (JSON.stringify(result) == JSON.stringify(data) ) {
                    createTimer()
                    return
                }
                setData(result)
            })
            .catch((err) => {
            })
        }

        function createTimer() {
            const timer = setTimeout(() => {
                listarMensagensConversa()
            }, 5000)    
            return () => clearTimeout(timer);
        }
      
        useEffect(() => {
            createTimer()
          }, [data]);
        
            
        function handleDeletarMensagens() {
            if (!data[0]) return
            setLoadingVisible(true)   
            deletarMensagens(data[0].id_conversa)
                .then(() => {
                    listarMensagens(data[0].id_conversa)
                        .then((result) => {
                            setData(result)
                            setTextoMensagem('')
                            setLoadingVisible(false)   
                            atualizarGrade(true)
                            setBoxVisible(false)
                            setDestinatario('')
                            setFotoDestinatario('')
                            atualizaNomeDestinatario('')
                        })
                        .catch((err) => {
                            setLoadingVisible(false)   
                        })
                })
        }

        function handleNovaMensagem() {
            if ((!textoMensagem) || (!data[0])) return
            setLoadingVisible(true)   
            const conversa = {
                titulo: '',
                mensagem: textoMensagem,
                id_conversa: data[0].id_conversa,
                id_pessoa_destinatario: destinatario
            }
            novaMensagem(conversa)
                .then((result) => {
                    listarMensagens(data[0].id_conversa)
                        .then((result) => {
                            setData(result)
                            setTextoMensagem('')
                            setLoadingVisible(false)   
                        })
                        .catch((err) => {
                            setLoadingVisible(false)   
                        })
                })
                .catch((err) => {
                    setLoadingVisible(false)   
                })
        }

        function handleKeyDown(e) {
            if( (e.key === 'Enter') && (textoMensagem)) {
                handleNovaMensagem()
              }
        }
        return (
        <>
            <Loading visible={ loadingVisible } />
            <div className='mensagensContainer'>            
                <Navigator 
                    disableMobileNavigator={true} 
                    style='navigatorHistoricoMensagem'
                />
                <div className="historicoMensagemHeader">
                        <div>
                            { 
                                isMobile ?
                                <button title="Voltar" onClick={() => { voltarTab() } }><MdArrowBack /></button>
                                : ''
                            }
                            {
                                fotoDestinatario ? 
                                <div  style={ { 
                                        width: '40px',
                                        height: '40px',
                                        margign: '10px',
                                        backgroundImage: "url('data:image/png;base64, " + fotoDestinatario + " ')",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    } }> </div> : 
                                <img className="historicoMensagemHeaderImg" src={ NoPhotoImage } alt=""/>
                            }
                            <span>{ nomeDestinatario }</span> 
                        </div>
                       
                        <div>
                           
                            {
                                (perfil == 'MEDICO' || perfil == 'ADMINISTRADOR') ?
                                <>
                                    <button onClick={() => {setBoxVisible(!boxVisible)} }>
                                        <MdMoreVert />                                
                                    </button> 
                                    <BoxOptions classe='optionsHistorico' options={ optionsBoxHistorico } visible={ boxVisible } /> 
                                </>:
                                null

                            } 
                                                    </div>        
                </div>
                <div id="scroll"  className='historicoMensagemContainer'>
                    
                    <div className="historicoMensagemContent">
                        
                        
                        {   data ?
                            (data.map((item, index) => {
                                if (!item.mensagem ) return
                                const hora = new Date(item.data_hora_mensagem)
                                
                                let minhaData = getMinhaData(hora)
                                const horaFormatada =  String(hora.getHours()).padStart(2, '0') + ':' + String(hora.getMinutes()).padStart(2, '0')
                                if (index == data.length - 1) {
                                    updateScroll()
                                }
                                if (!item.usuarioremetente) {
                                    return (
                                        <>
                                            {
                                                minhaData &&
                                                <div className='pillData'>
                                                    <span>
                                                        { minhaData }
                                                    </span>
                                                </div>
                                            }
                                            
                                            <div className="mensagemOut">
                                                <Mensagem tipo='remetente' hora={horaFormatada} texto={ item.mensagem }/>                   
                                            </div>
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <>
                                            {
                                                minhaData &&
                                                <div className='pillData'>
                                                    <span>
                                                        { minhaData }
                                                    </span>
                                                </div>
                                            }
                                            <div className="mensagemIn">
                                                <Mensagem tipo='destinatario' hora={horaFormatada} texto={ item.mensagem }/> 
                                            </div>
                                        </>
                                    )
                                }
                               
                            })
                            
                            )
                            : ''
                        }                                    
                    </div>   
                        
                </div>
                <div className="historicoMensagemFooter">                                         
                    <input 
                        value={ textoMensagem } 
                        onKeyDown={handleKeyDown}
                        onChange={ (e) => { setTextoMensagem(e.currentTarget.value) }} 
                        type='text' />                
                    <button onClick={handleNovaMensagem}><MdSend className='btnIconSend' /></button> 
                </div>    
            </div>
        </>    
        
    )
}

export default HistoricoMensagem