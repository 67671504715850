import React from 'react'
import FormDataContainer from '../../components/formDataContainer'
import Navigator from '../../components/navigator'
import PageDefault from '../../components/pageDefault'
import Agenda from './agenda'
import './style.css'

function ProntuarioPerfil() {
    const isMobile = window.innerWidth < 800
    return (        
        <PageDefault>  
            {
                isMobile ? 
                <>
                    <Navigator />  
                    <Agenda />
                </>:
                <>
                    <FormDataContainer title="Agenda">              
                        
                    </FormDataContainer>  
                    <div className="mainContent">
                        <Navigator /> 
                        <div className="prontuarioPacienteContainer">
                        <div className="prontuarioPacienteBackground">
                            <Agenda />  
                            </div>
                        </div>
                    </div>  
                </>
            }                                             
        </PageDefault>
    )
}

export default ProntuarioPerfil