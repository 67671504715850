import React, { useState, useEffect, useContext } from 'react'
import NoPhotoImage from '../../../images/no-photo.png'
import { FaSearch } from 'react-icons/fa'
import { MdMessage, MdMoreVert, MdArrowBack } from 'react-icons/md'
import { listarUsuarios, buscarFotoPerfilLogado } from '../../../services/usuarios'
import { listarConversas, novaConversa, listarMensagens } from '../../../services/mensagens'
import { MensagemContext } from '../context'
import Loading from '../../../components/loading'
import { buscarDadosPerfilLogado } from '../../../services/usuarios'
import { useHistory, useLocation } from 'react-router-dom'
import BoxOptions from '../../../components/boxOptions'
import MalaDireta from '../malaDireta'
import './styles.css'

function MenuMensagem() {
    const history = useHistory()
    const optionsBoxMenu = [{ descricao: 'Perfil', action: () => { history.push('/perfil') } }, 
                            { descricao: 'Mala direta', action: () => {executaMalaDireta() }  }]
    const optionsMenuReduzida = [{ descricao: 'Perfil', action: () => { history.push('/perfil') } } ]
    const tabsMenu = ['conversa', 'contatos']
    const [activeTabMenu, setActiveTabMenu] = useState(tabsMenu[0])
    const [listaUsuarios, setListaUsuarios] = useState([])
    const [listaUsuariosOriginal, setListaUsuariosOriginal] = useState([])
    const [listaConversas, setListaConversas] = useState([])
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [textFilter, setTextFilter] = useState('')
    const [boxVisible, setBoxVisible] = useState(false)
    const [base64FotoPerfil, setBase64FotoPerfil] = useState('')
    const [perfil, setPerfil] = useState('')

    function useQuery() {
        return new URLSearchParams(useLocation().search)
      }
    const query = useQuery()
    
    function carregarPacienteQuery(lista, listaUsuario) {
        const hash = (query.get('hash'))
        if (!hash) return 
        const hashString = atob(hash)
        const id = hashString.split(':')[0]
        const nome = hashString.split(':')[1]
        if (!id) return
        handleNovaConversa(id, nome, lista, listaUsuario)        
    }

    const { precisaAtualizar, 
            atualizarGrade, 
            setData, 
            setDestinatario, 
            atualizaNomeDestinatario, 
            malaDireta, 
            setFotoDestinatario,
            handleMalaDireta } = useContext(MensagemContext)

    useEffect(() => {
        buscarFotoPerfilLogado()
            .then((result) => {
                if (result.foto !== '')
                    setBase64FotoPerfil('data:image/png;base64, ' + result.foto)
            })
        let tempListaConversas = []
        let tempListaUsuario = []

        Promise.all([
            listarUsuarios()
                .then((result) => {           
                    setListaUsuarios(result)
                    setListaUsuariosOriginal(result)
                    tempListaUsuario = result
                }),
            listarConversas()
                .then((result) => {
                    tempListaConversas = result
                    setListaConversas(result)                   
                })]
        ).then(() => carregarPacienteQuery(tempListaConversas, tempListaUsuario))
        buscarDadosPerfilLogado()
            .then((result) => {               
                setPerfil(result.perfil.perfil) 
            })
            .catch(() => {
                setPerfil('PACIENTE')
            })
    }, [])
    
    useEffect(() => {
        if (precisaAtualizar) {
            listarConversas()
                .then((result) => {
                    setListaConversas(result)
                    atualizarGrade(false)
                })
            
        }
    }, [precisaAtualizar])

    useEffect(() => {
        filtrarContatos()
    }, [textFilter])

    function filtrarContatos() {       
       setListaUsuarios(listaUsuariosOriginal)
        if (!textFilter) return
       const listaFiltrada = listaUsuarios.filter((value) => {
            if (String(value.nome.toUpperCase()).includes(String(textFilter).toUpperCase())) return value
       }) 
       setListaUsuarios(listaFiltrada)
       
    }

    function executaMalaDireta() {
        handleMalaDireta()
    }

    function handleSelecionaConversa(idconversa, idDest, nomeDest, listaUsuario) {
       setListaUsuarios(listaUsuariosOriginal)
       const temp = listaUsuario || listaUsuarios
       const listaFiltrada = temp.filter((value) => (value.id == idDest))
       console.log(listaFiltrada, listaUsuarios, listaUsuariosOriginal)
       setListaUsuarios(listaUsuariosOriginal)
        setLoadingVisible(true)
        listarMensagens(idconversa)
            .then((result) => {
                setData(result)
                setDestinatario(idDest)
                setFotoDestinatario(listaFiltrada[0].foto)
                atualizaNomeDestinatario(nomeDest)
                setLoadingVisible(false)
            })
            .catch((err) => {
                setLoadingVisible(false)
                console.log(err)
            })
    }

    function handleNovaConversa(id_destinatario, nome, lista, listaUsuario) {       
        
        const temp = lista || listaConversas
        const [conversa] = temp.filter((item) => item.id_destinatario == id_destinatario)
        if (conversa) {
            setActiveTabMenu(tabsMenu[0])
            handleSelecionaConversa(conversa.id, 
                                    conversa.id_destinatario, 
                                    conversa.nome,
                                    listaUsuario)
        }
        else {            
            const conversa = {
                id_pessoa_destinatario: id_destinatario
            }
            novaConversa(conversa)
                .then((result) => {
                    setActiveTabMenu(tabsMenu[0])
                    const id = result.id_conversa
                    listarConversas()
                        .then((result) => {
                            setListaConversas(result)
                            setLoadingVisible(false)
                            handleSelecionaConversa(
                                id,
                                id_destinatario,
                                nome,
                                listaUsuario)
                        })
                        .catch((err) => {
                            setLoadingVisible(false)
                            console.log(err)
                        })
                })
                .catch((err) => {
                    setLoadingVisible(false)
                    console.log(err)
                })
        }
       
    }

    function handleChangeTabMenu() {
        if (activeTabMenu == tabsMenu[0]) setActiveTabMenu(tabsMenu[1])
        else setActiveTabMenu(tabsMenu[0])
    }
    function TabMenu() {
        if (activeTabMenu == tabsMenu[1]) {
            return (
                <>  
                    
                    <div className="headerMenuMensagem">
                        <div className='headerBackContainer'>
                            <button style={{
                                paddingTop: '10px'
                            }} title="Voltar" onClick={() => { handleChangeTabMenu() } }><MdArrowBack /></button>
                            {
                                base64FotoPerfil ?
                                <div  style={ { 
                                    width: '40px',
                                    height: '40px',
                                    margin: '10px',
                                    backgroundImage: "url('" + base64FotoPerfil + "')",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                } }> </div>:
                                <img style={ { 
                                    width: '40px',
                                    height: '40px',
                                    margin: '10px'}}
                                    className='' 
                                    src={ NoPhotoImage } alt=""
                                />  
                            }                      
                        </div> 
                        <div className='iconesConversa '>
                            <button onClick={() => {setBoxVisible(!boxVisible)} }><MdMoreVert /></button>
                            <BoxOptions classe='optionsMenu' options={ optionsBoxMenu } visible={ boxVisible } />     
                        </div>                
                    </div>  
                    <div className="searchMenuMensagem"> 
                        <div style={{ height: '60px' }}>
                            <i className="searchMenuMensagemIcon"><FaSearch /></i>   
                            <input 
                                id="pesquisaContatos"
                                value={textFilter}
                                onChange={ (e) => { setTextFilter(e.target.value) } }
                                type='text' 
                                autoFocus
                                placeholder='Procurar'  
                            />
                        </div>                   
                        
                        <div className='menuMensagemContainer'>
                            { listaUsuarios ? listaUsuarios.map((item) => {
                                return (
                                    <div 
                                        value={item.id}                                     
                                        onClick={(e) => { 
                                            setLoadingVisible(true)
                                            handleNovaConversa(
                                                e.currentTarget.attributes['value'].value,
                                                item.nome) 
                                        }} 
                                        className='menuMensagemItem'>
                                        {
                                            item.foto ? 
                                            <div  style={ { 
                                                    width: '40px',
                                                    height: '40px',
                                                    margin: '10px',
                                                    backgroundImage: "url('data:image/png;base64, " + item.foto + " ')",
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat'
                                                } }> </div> : 
                                            <img src={ NoPhotoImage } alt=""/>
                                        }
                                        <span>{ item.nome }</span>
                                    </div>
                                )
                            }) :''
                        }                            
                            
                        </div>  
                    </div>
                </>

                
            )
        }
        else if (activeTabMenu == tabsMenu[0]) {
            return (
                <>
                    <MalaDireta visible={ malaDireta } />
                    <Loading forced={true} visible={loadingVisible} />
                    <div className="headerMenuMensagem">
                    {
                                base64FotoPerfil ?
                                <div  style={ { 
                                    width: '40px',
                                    height: '40px',
                                    margin: '10px',
                                    backgroundImage: "url('" + base64FotoPerfil + "')",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                } }> </div>:
                                <img style={ { 
                                    width: '40px',
                                    height: '40px',
                                    margin: '10px'}}
                                    className='' src={ NoPhotoImage } alt=""/>  
                            }  
                        {/* <img className='headerProfileImage' src={ NoPhotoImage } alt=""/> */}
                        <div className='iconesConversa'>
                            <button title="Nova conversa" onClick={() => {handleChangeTabMenu()} }><MdMessage /></button>
                            <button onClick={() => { setBoxVisible(!boxVisible) } }><MdMoreVert /></button>  
                            <BoxOptions 
                                classe='optionsMenu' 
                                options={ 
                                    (perfil == 'MEDICO' || perfil == 'ADMINISTRADOR') ?
                                     optionsBoxMenu:
                                     optionsMenuReduzida } 
                                visible={ boxVisible } />     
                        
                        </div>                
                    </div>  
                    <div className='menuMensagemContainer'>
                        {
                            listaConversas ? 
                            listaConversas.map((item) => {
                                return (
                                    <div id={ item.id } 
                                         dest={ item.id_destinatario }    
                                         nomeDest={ item.nome }
                                         onClick={ (e) => { handleSelecionaConversa(e.currentTarget.attributes['id'].value,
                                                                                    e.currentTarget.attributes['dest'].value,
                                                                                    e.currentTarget.attributes['nomeDest'].value) }}
                                         className='menuMensagemItem'>
                                        {
                                            item.foto ? 
                                            <div  style={ { 
                                                    width: '40px',
                                                    height: '40px',
                                                    margin: '10px',
                                                    backgroundImage: "url('data:image/png;base64, " + item.foto + " ')",
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat'
                                                } }> </div> : 
                                            <img src={ NoPhotoImage } alt=""/>
                                        }
                                        <span>{ item.nome }</span>
                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                </>
                  
            )
        }
    }

    return (
        <div className='formDataContainer'>
                      
            <TabMenu />
        </div>
        
    )
}

export default MenuMensagem