import React ,{ useState, useEffect } from 'react'

import Chart from 'react-apexcharts'


function GraficoFuncaoRenal({ content }) {

  const [updateNow, setUpdateNow] = useState(true)
    useEffect(()=> {
      setUpdateNow(!updateNow)
    }, [content])
 
   const series = content.valores || [{name:'', data: []}]
   const options = {
      chart: {        
        height: 350,        
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: { 
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: '',
        align: 'center'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories:content.datas || [],
      }
    }
    return (
        <div className='cardContainer'>
            <h4 className='titleProntuario'>Função Renal (CKD-EPI e Creatina)</h4>            
            <div className="cardProntuario">            
                <Chart 
                    options={ options } 
                    series={ series } 
                    type="line"                     
                    width={500} 
                    height={200} />
            </div>            
        </div>
    )
}

export default GraficoFuncaoRenal