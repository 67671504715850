import React from "react";
import {
  STEP_PUBLIC_CALCULATOR_RESULT,
  STEP_PUBLIC_CALCULATOR_RISK,
  usePatientRecordContext,
} from "../../context/patientRecordContext";
import CalculatorResult from "../../patientRecord/components/calculatorResult/calculatorResult";
import CalculatorRisk from "../../patientRecord/components/calculatorRisk/calculatorRisk";

const Calculator = () => {
  const { stepperPublic } = usePatientRecordContext();

  return (
    <>
      {stepperPublic === STEP_PUBLIC_CALCULATOR_RISK && (
        <CalculatorRisk isPublic />
      )}
      {stepperPublic === STEP_PUBLIC_CALCULATOR_RESULT && (
        <CalculatorResult isPublic />
      )}
    </>
  );
};

export default Calculator;
