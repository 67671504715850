import React from 'react'

const ClinicasAdmContext = React.createContext({ 
        estado: '',
        precisaAtualizar: false,
        atualizarGrade: () => {},
        data: {}, 
        setData: () => {} })

export const ClinicasAdmProvider = ClinicasAdmContext.Provider

export { ClinicasAdmContext }
