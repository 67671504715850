import React, { useState, useEffect, useContext } from 'react'
import NoPhotoImage from '../../../images/no-photo.png'
import { MdMoreVert } from 'react-icons/md'
import { listarNotificacoes, buscarNotificacao } from '../../../services/notificacoes'
import { buscarFotoPerfilLogado } from '../../../services/usuarios'
import { buscarLogo } from '../../../services/clinicas'


import { NotificacaoContext } from '../context'
import Loading from '../../../components/loading'
import BoxOptions from '../../../components/boxOptions'
import { useHistory } from 'react-router-dom'
import './styles.css'

function MenuNotificacao() {
    const history = useHistory()
    const optionsBoxMenu = [{ descricao: 'Perfil', action: () => { history.push('/perfil') } }]
    const [listaNotificacoes, setListaNotificacoes] = useState([])
    const [loadingVisible, setLoadingVisible] = useState(false)
    const [boxVisible, setBoxVisible] = useState(false)    
    const [base64FotoPerfil, setBase64FotoPerfil] = useState('')
    const [logoClinica, setLogoClinica] = useState('')

    const {  setData } = useContext(NotificacaoContext)

    useEffect(() => {        
        buscarFotoPerfilLogado()
            .then((result) => {
                if (result.foto !== '')
                    setBase64FotoPerfil('data:image/png;base64, ' + result.foto)
            })
        buscarLogo()
            .then((result) => {
                setLogoClinica(`data:image/png;base64, ${result.foto}`)
            })
        listarNotificacoes()
            .then((result) => {
                setListaNotificacoes(result)
            })
    }, [])

    function handleSelecionaConversa(idTemplate) {
        setLoadingVisible(true)
        buscarNotificacao(idTemplate)
            .then((result) => {
                setData(result)
                setLoadingVisible(false)
            })
            .catch((err) => {
                setLoadingVisible(false)
                console.log(err)
            })
    }

    return (
        <div className='formDataContainer'>                      
            <>                   
                <Loading visible={ loadingVisible } />
                <div className="headerMenuMensagem">
                    {
                        base64FotoPerfil ?
                        <div  style={ { 
                            width: '40px',
                            height: '40px',
                            margin: '10px',
                            backgroundImage: "url('" + base64FotoPerfil + "')",
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                        } }> </div>:
                        <img 
                            className='headerProfileImage'
                            style={ { 
                            width: '40px',
                            height: '40px',
                            margin: '10px'}}
                            className='' 
                            src={ NoPhotoImage } alt=""
                        />  
                    }     
                    <div>
                        <button onClick={() => { setBoxVisible(!boxVisible) } }><MdMoreVert /></button>  
                        <BoxOptions classe='optionsMenu' options={ optionsBoxMenu } visible={ boxVisible } />     
                    
                    </div>                
                </div>  
                <div className='menuMensagemContainer'>
                    {
                        listaNotificacoes ? 
                        listaNotificacoes.map((item) => {
                            return (
                                <div value={ item.id }    
                                    onClick={ (e) => { handleSelecionaConversa(e.currentTarget.attributes['value'].value) }}
                                    className={'menuMensagemItem ' + (item.pendente ? ' notficacaoPendente ' : ' notficacaoNaoPendente ')}>
                                    {
                                        logoClinica ?
                                        <div  style={ { 
                                            width: '40px',
                                            height: '40px',
                                            margin: '10px',
                                            backgroundImage: "url('" + logoClinica + "')",
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat'
                                        } }> </div> :
                                        <img src={ NoPhotoImage } alt=""/>          
                                    }
                                    <span>{ item.titulo }</span>
                                </div>
                            )
                        }) : ''
                    }
                </div>
            </>
        </div>
        
    )
}

export default MenuNotificacao