import firebase from 'firebase'

  var firebaseConfig = {
    apiKey: "AIzaSyAfxVVtlMM66BxQu0IkuvQs30mhkZp6m8Q",
    authDomain: "aortic-assistence.firebaseapp.com",
    databaseURL: "https://aortic-assistence.firebaseio.com",
    projectId: "aortic-assistence",
    storageBucket: "aortic-assistence.appspot.com",
    messagingSenderId: "922048438168",
    appId: "1:922048438168:web:589121278c5bafba5a55ed",
    measurementId: "G-70JXMDP9YN"
  }

firebase.initializeApp(firebaseConfig);

export default firebase 