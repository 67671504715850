import React from 'react'
import './styles.css'

function ToggleComponent({onCheck, hint, ...rest}) {
    return (
        <div title={hint} style={{marginTop: '5px', marginRight: '5px'}}>
            <label className="switch">
                <input onChange={onCheck} {...rest} type="checkbox" />
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default ToggleComponent