import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function cadastrarExameFisico(exame) {
    try {
        const response = await api.post('examefisico/', exame, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data[0]         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function listarExameFisico(paciente) {
    try {
        const response = await api.get('examefisico?paciente=' + paciente, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

async function deletarExameFisico(id) {
    try {
        const response = await api.delete('examefisico/' + id, {  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }
}

function calcularIMC(peso, altura) {
    let alturaNormalizada

    if (altura > 10) 
        alturaNormalizada = altura / 100
    else    
        alturaNormalizada = altura  

    const imc = peso.replace(',', '.') / ((alturaNormalizada) * (alturaNormalizada))
    let classificacao = ''
    if (imc <= 18.5){
        classificacao = 'desnutrição'
    } else if (imc > 18.5 && imc < 25){
        classificacao = 'eutrofia' 
    } else if (imc >= 25 && imc <= 30){
        classificacao = 'sobrepeso'  
    } else if (imc > 30 && imc <= 35){
        classificacao = 'obesidade grau I'   
    } else if (imc > 35 && imc <= 40){
        classificacao = 'obesidade grau II'
    } else {
        classificacao = 'obesidade grau III'
    }
    return { imc: Math.round(imc), classificacao }
}


export { calcularIMC, cadastrarExameFisico, listarExameFisico, deletarExameFisico }