import React, { useState } from 'react'
import PageDefaultAdm from '../pageDefaultAdm'
import Grid from './gridClinicas'
import Form from './formClinicas'
import { ClinicasAdmProvider } from './context'

function PageAdm() {
    const [dadosClinicaSelecionado, setDadosClinicaSelecionado] = useState({})
    const [atualizar, setAtualizar] = useState(false)
    const contexto = {
        estado: 'I',
        atualizarGrade: (atualizar) => {
            setAtualizar(atualizar)
        },
        precisaAtualizar: atualizar,
        data: dadosClinicaSelecionado,
        setData: (newData) => {
            setDadosClinicaSelecionado(newData)
        },
    }
    return (
        <div>
            <ClinicasAdmProvider value={ contexto }>
                <PageDefaultAdm 
                    formArea={{ title: 'Clinicas', content: <Form /> }}
                    gridArea={<Grid />}
                />
            </ClinicasAdmProvider>
        </div>
    )
}

export default PageAdm