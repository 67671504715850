import React from 'react'
import './styles.css'

function Modal({ visible, children, style, ...etc }) {
    return (
        <div {...etc} className={' modalBackground '  + style + (visible ? ' modalVisible ' : ' modalInvisible ')}>
            { children }
        </div>
    )
}

export default Modal