import React, { useState, useEffect } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { useLocation } from "react-router-dom";
import { calculateDir } from "../../services/utils/calculateDir";
import InputSearch from "../inputSearch";

import "./style.css";

function DataList({
  columns,
  data,
  styletable,
  searchfunction,
  filter,
  stylecontainer,
  hideDirDescription,
}) {
  const [diretorio, setDiretorio] = useState("");
  const location = useLocation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useSortBy,
    );

  useEffect(() => {
    const calculatedDir = calculateDir(location);
    setDiretorio(calculatedDir);
  }, [location]);

  return (
    <div className={"dataListContainer " + stylecontainer}>
      <div className="diretorio">
        <h3> {hideDirDescription ? "" : diretorio} </h3>
      </div>
      {filter ? (
        filter
      ) : (
        <div className="searchInputContainer">
          <InputSearch label="Buscar" searchfunction={searchfunction} />
        </div>
      )}

      <table {...getTableProps()} className={styletable}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.style + column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={"lineGrid"}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DataList;
