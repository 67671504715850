import React, { useState, useEffect, useContext } from 'react'
import InputText from '../../../components/formComponents/inputText'
import InputSelect from '../../../components/formComponents/inputSelect'
import PrimaryButton from '../../../components/formComponents/primaryButton'
import SecondaryButton from '../../../components/formComponents/secondaryButton'
import {  cadastrarRecepcionistas, alterarRecepcionistas } from '../../../services/recepcionistas'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { RecepcionistaContext } from '../context'
import { cpfMask, phoneMask } from '../../../services/utils/mask'
import { onlyLetters } from '../../../services/utils/onlyLetters'
import Loading from '../../../components/loading'
import { consultarCEP } from '../../../services/utils/cep'
import UploadArea from '../../../components/uploadArea'
import { isValidCPF } from '../../../services/utils/validate'
import { useHistory } from 'react-router-dom'
import { enviarConviteNovoUsuario, verificarEmailCadastrado, gerarLinkAtivacao } from '../../../services/usuarios'
import ModalAlert from '../../../components/modalAlert'
import ModalConfirmation from '../../../components/modalConfirmation'

import './style.css'

function FormMedico({ value, classForm, perfil}) {
    const statusMessageEmail = {
        valid: 'Email válido',
        invalid: 'Email inválido'
    }
    const sexoOptions = [{label: 'Masculino', value: 'M'},{label: 'Feminino', value: 'F'}]
    const [loadingVisible, setLoadingVisible] = useState(false)
    const { atualizarGrade, data, setVisibleBlockingPanel } = useContext(RecepcionistaContext)
    const history = useHistory()
    const [visibleAlert, setVisibleAlert] = useState(false)
    const [alertObject, setAlertObject] = useState({})
    const [visibleConfirmation, setVisibleConfirmation] = useState(false)
    const [stateEmail, setStateEmail] = useState('')
    const [linkAtivacao, setLinkAtivacao] = useState('')
    const [confirmation, setConfirmation] = useState({
        actionNO: () => {},
        actionOK: () => {}
    })
    /*-----Hooks campos formulario-----*/
    const [id, setId] = useState('')
    const [idRecepcionista, setIdRecepcionista] = useState('')
    const [nome, setNome] = useState('')
    const [documento, setDocumento] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [telefone, setTelefone] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [senha, setSenha] = useState('')
    const [senhaConfirmacao, setSenhaConfirmacao] = useState('')    
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [logradouro, setLogradouro] = useState('')
    const [numero, setNumero] = useState('')
    const [bairro, setBairro] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cidade, setCidade] = useState('')
    const [uf, setUf] = useState('')
    const [fotoPerfil, setFotoPerfil] = useState('')
    /*----------------------------------*/
    
    function limparFormulario() {
        if (perfil) {
            history.push('/menu')
            return
        }
        setId('')
        setIdRecepcionista('')
        setNome('')
        setDocumento('')
        setDataNascimento('')
        setTelefone('')
        setWhatsapp('')
        setEmail('')
        setSenha('')
        setCep('')
        setLogradouro('')
        setNumero('')
        setBairro('')
        setComplemento('')
        setCidade('')
        setUf('')
        setFotoPerfil('')
        const editEmail = document.getElementById('email')
        editEmail.removeAttribute('disabled')  
        setVisibleBlockingPanel(true)
    }
    
    function setDadosForm(recepcionista) {
        //controlaEstado()
        let dataNascimento = new Date(recepcionista.dt_nascimento);
        var dia = ("0" + dataNascimento.getDate()).slice(-2);
        var mes = ("0" + (dataNascimento.getMonth() + 1)).slice(-2);  
        var dataNascimentoFinal = dataNascimento.getFullYear()+"-"+(mes)+"-"+(dia);

        setId(recepcionista.id)
        setIdRecepcionista(recepcionista.id_recepcionista)
        setNome(recepcionista.nome)
        setDocumento(recepcionista.documento)
        setDataNascimento(dataNascimentoFinal)        
        setTelefone(phoneMask(recepcionista.telefone))
        setWhatsapp(phoneMask(recepcionista.whatsapp))
        setEmail(recepcionista.email)
        setCep(recepcionista.cep)
        setLogradouro(recepcionista.logradouro)
        setNumero(recepcionista.numero)
        setBairro(recepcionista.bairro)
        setComplemento(recepcionista.complemento)
        setCidade(recepcionista.cidade)
        setUf(recepcionista.uf)
        setFotoPerfil(recepcionista.foto)
        setSenha('')
        setSenhaConfirmacao('')

        if (recepcionista.id) {
            gerarLinkAtivacao(recepcionista.id)
                .then((result) => {
                    const link = 
                    `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`                     
                    setLinkAtivacao(link)
                })
        }
    }
    
    function getDadosForm(alterando) {
        const recepcionistaBase = {            
            nome,
            documento,
            sexo,
            dt_nascimento: dataNascimento,
            telefone: String(telefone)
                            .replace(' ', '')
                            .replace('-', '')
                            .replace('(', '')
                            .replace(')', '')
                            .trim(),
            whatsapp: String(whatsapp)
                            .replace(' ', '')
                            .replace('-', '')
                            .replace('(', '')
                            .replace(')', '')
                            .trim(),
            senha,
            email,
            cep,
            logradouro,
            numero,
            bairro,
            complemento,
            cidade,
            uf,
            foto: fotoPerfil
        }
        if (alterando) {
            return {
            id,
            id_recepcionista: idRecepcionista,
            ...recepcionistaBase
            }  
        }   
        else {
            return recepcionistaBase
        }        
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (id) return
        const btn = document.getElementById('btnSalvar')
        btn.setAttribute('disabled', true)  
        if (!email) {
            setStateEmail('')
            return            
        }
        setStateEmail('invalid')
        if (email.length < 5 ) return 
        const pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!pattern.test(email)) return

        setStateEmail('loading')
        verificarEmailCadastrado(email)
            .then((result) => {
                if (result.message == 'CADASTRO_CLINICA') {
                    const alert = {
                        title: '',
                        text: 'Esse email já está sendo utilizado por outro cadastro!',
                        widht: '30%'
                    }
                    setAlertObject(alert)
                    setVisibleAlert(true) 
                    setStateEmail('invalid')
                    return
                } 
                if (result.message == 'CADASTRO_OUTRO') {
                    setConfirmation({
                        Title: 'Usuário já cadastrado',
                        Question: 'Esse email já possui cadastro no sistema Aortic. Deseja ' +
                        'enviar um convite para ingressar na sua clínica?',
                        hasExtra: result.whatsapp ? true : false,
                        extra: {
                            type: 'whatsapp_link',
                            data: {
                                whatsapp: result.whatsapp,
                                message: `Ative seu cadastro Aortic pelo link: ${encodeURIComponent(result.link)}`
                            }
                        },
                        actionOK: () => {
                            enviarConviteNovoUsuario({
                                perfil: 'RECEPCAO',
                                id_pessoa: result.id_pessoa
                            })
                        },
                        actionNO: () => {
                        }  
                    })
                    setVisibleConfirmation(true)     
                    setStateEmail('invalid')
                    return
                } 
                setStateEmail('valid')
                btn.removeAttribute('disabled')    
            })
        }, 500)    
        return () => clearTimeout(timer);
        
    }, [email])
    
    useEffect(() => {
        const promisse = new Promise((resolve, reject) => {
            resolve(setDadosForm(data))
       })
       promisse
           .then(() => {
               if (data.id) {
                   const editEmail = document.getElementById('email')
                   editEmail.setAttribute('disabled', true)  
               }
           }) 
         
    }, [data])
    
    useEffect(() => {        
        if (value) setDadosForm(value)         
    }, [])
    
    function isValidPassword() {
        return senha === senhaConfirmacao
    }
    
    function validateValues(alterando) {
        if (alterando)
            return (nome && dataNascimento)
        else
            return (nome && dataNascimento && senha)

    }

    function enviarDadosMedico() {
        if (documento)
            if (isValidCPF(documento) === false) {                
                const alert = {
                    title: '',
                    text: 'CPF inválido',
                    widht: '20%'
                }
                setAlertObject(alert)
                setVisibleAlert(true)  
                return
            }
       
            if (!isValidPassword()) {
                const element = document.getElementById('senha')
                element.setAttribute('title', 'Senhas não conferem')
                element.focus()
                setLoadingVisible(false)
                return
            }
        const alterando = ((id) && (idRecepcionista)) 
        if (!validateValues(alterando)) return
        setLoadingVisible(true)
        const recepcionista = getDadosForm(alterando)
        if (alterando) {
            alterarRecepcionistas(recepcionista)
                .then((result) => {
                    if (!perfil) {
                        limparFormulario()
                        atualizarGrade(true) 
                    }   
                    setLoadingVisible(false)
                })
        }
        else {
            cadastrarRecepcionistas(recepcionista)
                .then((result) => {                   
                    if (result.message) {
                        if (result.message.message == 'CADASTRO_CLINICA') {
                            const alert = {
                                title: '',
                                text: 'Esse email já está sendo utilizado por outro cadastro!',
                                widht: '30%'
                            }
                            setAlertObject(alert)
                            setVisibleAlert(true) 
                            setLoadingVisible(false)
                            return
                        } else  if (result.message.message == 'CADASTRO_OUTRO') {
                            setConfirmation({
                                Title: 'Usuário já cadastrado',
                                Question: 'Esse email já possui cadastro no sistema Aortic. Deseja ' +
                                'enviar um convite para ingressar na sua clínica?',
                                actionOK: () => {
                                    enviarConviteNovoUsuario({
                                        perfil: 'RECEPCAO',
                                        id_pessoa: result.id_pessoa
                                    })
                                },
                                actionNO: () => {
                                }  
                            })
                            setLoadingVisible(false)
                            setVisibleConfirmation(true)                          
                            return
                        } 

                    }
                    if (!perfil) {
                        limparFormulario()
                        atualizarGrade(true) 
                        setVisibleBlockingPanel(true)
                    }   
                    setLoadingVisible(false)

                })   
        }      
        
    }
    function handleUpload(files) {
        const reader = new FileReader()
        reader.readAsBinaryString(files[0])
        reader.onload = function() {
           setFotoPerfil( (btoa(reader.result))) 
        }   
    }
    function handleConsultaCEP() {
        if(!cep) return
        if (cep.length < 8) return 
        consultarCEP(cep)
            .then((dadosEndereco) => {
                setLogradouro(dadosEndereco.data.logradouro)
                setBairro(dadosEndereco.data.bairro)
                setCidade(dadosEndereco.data.localidade)
                setUf(dadosEndereco.data.uf)
            })
            .catch((err) => {
                console.log(err)
            })

    }
    return (
        <>
            <ModalAlert 
                visible={visibleAlert} 
                setVisible={setVisibleAlert} 
                title={alertObject.title} 
                text={alertObject.text}
                width={alertObject.widht}
            />
            <ModalConfirmation 
                height={'160px'}
                visible={ visibleConfirmation } 
                setVisible= { setVisibleConfirmation }
                actionOK={ confirmation.actionOK }
                actionNO={ confirmation.actionNO }
                title={ confirmation.Title }
                text={ confirmation.Question }
                hasExtra={confirmation.hasExtra}
                extras={confirmation.extra}
            />
            <form autocomplete='off' onSubmit={(e) => {e.preventDefault()}} className={'formData ' + ( classForm ? classForm : 'form' )}>
                <Loading 
                    forced={ perfil ? true : false }
                    visible={ loadingVisible }
                />
                <UploadArea onUpload={ handleUpload } uploadedPhoto={ 'data:image/png;base64, ' + (fotoPerfil || '') } />
                <InputText
                    status={stateEmail}
                    statusMessage={statusMessageEmail}
                    autocomplete="off" 
                    required    
                    value={ email }
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    containerstyle="input" 
                    type="email"
                    
                    label="Email" 
                    name="email" />
                <InputText 
                    value={ nome }
                    onChange={(e) => {
                        setNome(e.target.value)
                    }}
                    containerstyle="input" 
                    label="Nome" 
                    name="nome"></InputText>
                <InputText 
                    value={ documento }
                    onChange={(e) => {
                        const validCPF = cpfMask(e.target.value)
                        setDocumento(validCPF)
                    }}
                    containerstyle="input" 
                    label="Documento (CPF)" 
                    name="documento"></InputText>
                <div className="col-2">
                    <InputSelect 
                        containerstyle="input-col" 
                        value={sexo}
                        onChange={(e) => {
                            setSexo(e.target.value)
                        }}
                        name='sexo'
                        options={sexoOptions} label="Sexo">                            
                    </InputSelect>
                    <InputText 
                        required
                        value={ dataNascimento }
                        onChange={(e) => {
                            setDataNascimento(e.target.value)
                        }}
                        type="date" 
                        containerstyle="input-col" 
                        label="Data Nascimento" 
                        name="dtNascimento" />
                </div>
                <div className="col-2">                        
                    <InputText 
                        value={ telefone }
                        onChange={(e) => {
                            const phone = phoneMask(e.target.value)     
                            setTelefone(phone)
                        }}
                        containerstyle="input-col" 
                        label="Telefone" 
                        name="telefone" />
                    <InputText 
                        value={ whatsapp }
                        onChange={(e) => {
                            const phone = phoneMask(e.target.value)     
                            setWhatsapp(phone)
                        }}
                        containerstyle="input-col" 
                        type="text"
                        label="Whatsapp" 
                        name="whatsapp" />
                </div>                
                <div className="col-2"> 
                    <InputText 
                        value={ senha }
                        onChange={(e) => {
                            setSenha(e.target.value)
                        }}
                        type="password"
                        containerstyle="input-col" 
                        label="Senha"    
                        autocomplete="new-password"                      
                        id="senha"
                        name="senha" />
                    <InputText 
                        value={senhaConfirmacao}
                        onChange={(e) => {
                            setSenhaConfirmacao(e.target.value)
                        }}
                        type="password"
                        autocomplete="new-password" 
                        containerstyle="input-col"                             
                        label="Confirme a senha" 
                        name="confirmacaoSenha" />   
                </div>
                <div className="col-2"> 
                    <InputText 
                        value={ cep }
                        onChange={(e) => {
                            setCep(e.target.value)
                        }}
                        onfocusout={handleConsultaCEP()}
                        containerstyle="col-30-percent" 
                        label="CEP" 
                        id="cep"
                        name="cep" />
                    <InputText 
                        value={ logradouro }
                        onChange={(e) => {
                            setLogradouro(e.target.value)
                        }}
                        containerstyle="input-col"                             
                        label="Logradouro" 
                        name="logradouro" />   
                </div>  
                <div className="col-2"> 
                    <InputText 
                        value={ numero }
                        onChange={(e) => {
                            setNumero(e.target.value)
                        }}
                        containerstyle="col-30-percent" 
                        label="Número" 
                        id="numero"
                        name="numero" />
                    <InputText 
                        value={ complemento }
                        onChange={(e) => {
                            setComplemento(e.target.value)
                        }}
                        containerstyle="input-col"                             
                        label="Complemento" 
                        name="complemento" />   
                </div> 
                <InputText 
                        value={ bairro }
                        onChange={(e) => {
                            setBairro(e.target.value)
                        }}
                        containerstyle="input" 
                        label="Bairro" 
                        id="bairro"
                        name="bairro" />
                <div className="col-2"> 
                    <InputText 
                        value={ cidade }
                        onChange={(e) => {
                            setCidade(e.target.value)
                        }}
                        containerstyle="input-col" 
                        label="Cidade" 
                        id="cidade"
                        name="cidade" />
                    <InputText 
                        value={ uf }
                        onChange={(e) => {
                            const newUF = onlyLetters(String(e.target.value).toUpperCase())
                            setUf(newUF)
                        }}
                        containerstyle="col-20-percent-right uf"                             
                        label="UF" 
                        name="uf" />   
                </div> 
                <div className='linkAtivacaoFormulario'>
                    {
                        ((linkAtivacao && whatsapp) && (!perfil)) ?
                        <a className='primaryButtonLink ' 
                        href={`https://wa.me/55${String(whatsapp)
                                                .replace(' ', '')
                                                .replace('-', '')
                                                .replace('(', '')
                                                .replace(')', '')
                                                .trim()}?text=${linkAtivacao}`} 
                        target='_blank'>Link Whatsapp</a>
                        : null
                    }
                </div>
                <div className="footer"> 
                    <SecondaryButton 
                        type="submit"                    
                        onClick={limparFormulario}
                        name='btnCancelar'
                        containerstyle ="footerButton" 
                        text="Cancelar"><FaTimes /></SecondaryButton>
                    <PrimaryButton 
                        onClick={enviarDadosMedico}
                        name='btnSalvar'
                        containerstyle="footerButton" 
                        text="Salvar" ><FaCheck /></PrimaryButton>                
                </div>
            </form> 
        </>
    )
}

export default FormMedico