import React from 'react'
import Dropzone from "react-dropzone";
import './styles.css'
import NoPhotoImage from '../../images/no-photo.png'
import CameraIcone from '../../images/cam-icon.png'


function UploadArea({ onUpload, uploadedPhoto, personalClassName }) {
    
    return (
        <div  className={ personalClassName ? personalClassName : ' defaultDropContainer' }>
            <Dropzone accept="image/*" onDropAccepted={onUpload}>
                {({ getRootProps, getInputProps }) => (
                <div 
                    className='dropImageContainer' 
                    style={ 
                            { 
                                backgroundImage: "url('" + uploadedPhoto + " ')",
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            } 
                        }                 
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />  
                    { 
                        (uploadedPhoto === 'data:image/png;base64, ') ? 
                        <div className='containerImagemDefault'>
                            <img className='imageDefault' src={ NoPhotoImage } alt=""/> 
                            <img className='iconeCamera' src={ CameraIcone } alt=""/> 
                        </div>:''
                    }          
                    
                </div>
                )}
            </Dropzone>    
        </div>
       
    )
}

export default UploadArea