import React, { useState, useContext } from "react";
import InputText from "../../../components/formComponents/inputText";
import InputMax from "../../../components/inputMask";
import InputSelect from "../../../components/formComponents/inputSelect";
import InputTextDate from "../../../components/inputTextDate";
import InputPressaoArterial from "../../../components/formComponents/inputPressaoArterial";
import PrimaryButton from "../../../components/formComponents/primaryButton";
import { FaCheck, FaTimes } from "react-icons/fa";
import ToggleComponent from "../../../components/formComponents/toggle";
import "./styles.css";
import { calcularRisco } from "../../../services/calculadoraRisco";
import LogoAortic from "../../../images/logo-aortic.png";
import {
  STEP_CALCULATOR_RESULT,
  STEP_CLOSE_MODAL,
  STEP_PUBLIC_CALCULATOR_RESULT,
  usePatientRecordContext,
} from "../../../context/patientRecordContext";
import validationForm from "./functions/validationForm";
import dataBuilder from "./functions/dataBuilder";
import SecondaryButton from "../../../components/formComponents/secondaryButton";

const CalculatorRisk = ({ isPublic = false }) => {
  const {
    calculatorRiskData,
    setCalculatorRiskData,
    setResultRiskData,
    setStepper,
    setPatientCreateData,
    patientCreateData,
    setStepperPublic,
  } = usePatientRecordContext();
  const [error, setError] = useState({
    dataNascimento: false,
    sexo: false,
    pressaoArterial: false,
    dataDiagnostico: false,
    dataExame: false,
  });

  const handleCalculate = async () => {
    const validationFormResponse = validationForm({ calculatorRiskData });
    const isValidForm = validationFormResponse.isValid ?? false;

    setError({ ...validationFormResponse.error });

    if (isValidForm) {
      try {
        const data = dataBuilder({ calculatorRiskData });
        const response = await calcularRisco(data);

        setResultRiskData({ ...response });
        setPatientCreateData({
          ...patientCreateData,
          dataNascimento: calculatorRiskData?.dataNascimento,
          sexo: calculatorRiskData?.sexo,
        });

        if (isPublic) {
          setStepperPublic(STEP_PUBLIC_CALCULATOR_RESULT);
        } else {
          setStepper(STEP_CALCULATOR_RESULT);
        }
      } catch (e) {
        console.info(`error : `, e);
        alert("Erro, tente novamente.");
      }
    } else {
      alert("Preencha os campos corretamente");
    }
  };

  return (
    <div className="formulario">
      <div className="logoLogin">
        <img src={LogoAortic} alt="" />
      </div>
      <h1 className="titulo">Calculadora de Risco</h1>

      <div className="primeiro-group">
        <InputTextDate
          className="data-nascimento"
          value={calculatorRiskData?.dataNascimento}
          label="Data de Nascimento"
          name="dataNascimento"
          mask="99/99/9999"
          mandatory={true}
          placeholder="dd/MM/yyyy"
          onDateChange={(dateValue) => {
            setCalculatorRiskData({
              ...calculatorRiskData,
              dataNascimento: dateValue,
            });
            setError({ ...error, dataNascimento: false });
          }}
          showError={error.dataNascimento}
        ></InputTextDate>

        <InputSelect
          className="sexo"
          value={calculatorRiskData?.sexo}
          onChange={(e) => {
            setCalculatorRiskData({
              ...calculatorRiskData,
              sexo: e.target.value,
            });
            setError({ ...error, sexo: false });
          }}
          name="sexo"
          options={[
            { label: "Selecione", value: "" },
            { label: "Masculino", value: "M" },
            { label: "Feminino", value: "F" },
          ]}
          label="Sexo"
          mandatory={true}
          showError={error.sexo}
        ></InputSelect>

        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Tabagismo Ativo"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  tabagismo: e.target.checked,
                })
              }
              checked={calculatorRiskData?.tabagismo}
            />
          </div>
          <div>
            <span>Tabagismo Ativo</span>
          </div>
        </div>

        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Doença Cardiovascular Prematura"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  dcvPrematuraParentes: e.target.checked,
                })
              }
              checked={calculatorRiskData?.dcvPrematuraParentes}
            />
          </div>
          <div>
            <span>
              DCV prematura em parentes de 1º grau (M &lt; 55 anos e F &lt; 65
              anos)
            </span>
          </div>
        </div>
      </div>

      <div className="segundo-group">
        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Doença Aterosclerótica"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  doencaAteroscleroticaSignificativaOuObstrucao50:
                    e.target.checked,
                })
              }
              checked={
                calculatorRiskData?.doencaAteroscleroticaSignificativaOuObstrucao50
              }
            />
          </div>
          <div>
            <span>
              Doença Aterosclerótica significativa (IAM, AVC ou DAOP) ou
              obstrução ≥ 50%
            </span>
          </div>
        </div>

        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Doença Subclínica"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  doencaAteroscleroticaSubclinica: e.target.checked,
                })
              }
              checked={calculatorRiskData?.doencaAteroscleroticaSubclinica}
            />
          </div>
          <div>
            <span>Doença aterosclerótica subclínica</span>
          </div>
        </div>

        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Aneurisma de Aorta"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  aneurismaAortaAbdominal: e.target.checked,
                })
              }
              checked={calculatorRiskData?.aneurismaAortaAbdominal}
            />
          </div>
          <div>
            <span>Aneurisma Aorta Abdominal</span>
          </div>
        </div>

        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Insuficiência Cardíaca"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  insuficienciaCardiaca: e.target.checked,
                })
              }
              checked={calculatorRiskData?.insuficienciaCardiaca}
            />
          </div>
          <div>
            <span>Insuficiência Cardíaca</span>
          </div>
        </div>
      </div>

      <div className="terceiro-group">
        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Diabetes Melitus"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  diabetesMelitus: e.target.checked,
                })
              }
              checked={calculatorRiskData?.diabetesMelitus}
            />
          </div>
          <div>
            <span>Diabetes Melitus</span>
          </div>
        </div>

        {calculatorRiskData?.diabetesMelitus ? (
          <>
            <InputSelect
              className="tipo-diabetes"
              value={calculatorRiskData?.diabetesMelitusTipo}
              onChange={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  diabetesMelitusTipo: e.target.value,
                })
              }
              name="tipoDiabetes"
              options={[
                { label: "Selecione", value: "" },
                { label: "Tipo 1", value: "1" },
                { label: "Tipo 2", value: "2" },
              ]}
              label="Tipo de Diabetes"
            ></InputSelect>
            <InputTextDate
              value={calculatorRiskData?.diabetesMelitusDataDiagnostico}
              onDateChange={(dateValue) => {
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  diabetesMelitusDataDiagnostico: dateValue,
                });
                setError({ ...error, dataDiagnostico: false });
              }}
              label="Ano Diagnóstico"
              showError={error.dataDiagnostico}
              mask="9999"
              placeHolderValue="yyyy"
              name="tempoDiagnostico"
            ></InputTextDate>
          </>
        ) : null}

        <div className="toggle">
          <div>
            <ToggleComponent
              hint="Hipertensão Arterial"
              onCheck={(e) =>
                setCalculatorRiskData({
                  ...calculatorRiskData,
                  medicacaoHipertensao: e.target.checked,
                })
              }
              checked={calculatorRiskData?.medicacaoHipertensao}
            />
          </div>
          <div>
            <span>Hipertensão Arterial Sistêmica</span>
          </div>
        </div>
      </div>
      <div className="quarto-group">
        <div className="pressao-arterial">
          <InputPressaoArterial
            className="input"
            valueSistolica={calculatorRiskData?.pressaoArterialSistolica}
            valueDiastolica={calculatorRiskData?.pressaoArterialDiastolica}
            label="Pressão Arterial"
            name="pressaoArterial"
            onChangeSistolica={(dateValue) => {
              setCalculatorRiskData({
                ...calculatorRiskData,
                pressaoArterialSistolica: dateValue,
              });
              setError({ ...error, pressaoArterial: false });
            }}
            onChangeDiastolica={(dateValue) => {
              setCalculatorRiskData({
                ...calculatorRiskData,
                pressaoArterialDiastolica: dateValue,
              });
              setError({ ...error, pressaoArterial: false });
            }}
            showError={error.pressaoArterial}
            mandatory={true}
          ></InputPressaoArterial>
        </div>

        <InputMax
          value={calculatorRiskData?.altura}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              altura: e.target.value,
            })
          }
          label="Altura"
          mask="9,99"
          placeHolderValue={"0,00"}
          name="altura"
        ></InputMax>
        <InputText
          value={calculatorRiskData?.peso}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              peso: e.target.value,
            })
          }
          label="Peso"
          name="peso"
        ></InputText>
      </div>

      <div className="quinto-group">
        <InputTextDate
          value={calculatorRiskData?.dataExame}
          onDateChange={(dateValue) => {
            setCalculatorRiskData({
              ...calculatorRiskData,
              dataExame: dateValue,
            });
            setError({ ...error, dataExame: false });
          }}
          label="Data de Realização"
          showError={error.dataExame}
          mask="99/99/9999"
          placeholder="dd/MM/yyyy"
          name="dataRealizacao"
        ></InputTextDate>
      </div>

      <div className="quinto-group">
        <InputText
          value={calculatorRiskData?.colesterolTotal}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              colesterolTotal: e.target.value,
            })
          }
          label="Colesterol Total (mg/dL)"
          name="colesterolTotal"
        ></InputText>

        <InputText
          value={calculatorRiskData?.ldl}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              ldl: e.target.value,
            })
          }
          label="LDL (mg/dL)"
          name="ldl"
        ></InputText>

        <InputText
          value={calculatorRiskData?.hdl}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              hdl: e.target.value,
            })
          }
          label="HDL (mg/dL)"
          name="hdl"
        ></InputText>

        <InputText
          value={calculatorRiskData?.triglicerideos}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              triglicerideos: e.target.value,
            })
          }
          label="Triglicerídeos (mg/dL)"
          name="triglicerideos"
        ></InputText>
      </div>
      <div className="quinto-group bottom">
        <InputText
          value={calculatorRiskData?.glicemia}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              glicemia: e.target.value,
            })
          }
          label="Glicemia (mg/dL)"
          name="glicemia"
        ></InputText>

        <InputText
          value={calculatorRiskData?.hba1c}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              hba1c: e.target.value,
            })
          }
          label="HbA1c (%)"
          name="hba1c"
        ></InputText>

        <InputText
          value={calculatorRiskData?.creatinina}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              creatinina: e.target.value,
            })
          }
          label="Creatinina (mg/dL)"
          name="creatinina"
        ></InputText>

        <InputText
          value={calculatorRiskData?.microalbuminuria}
          onChange={(e) =>
            setCalculatorRiskData({
              ...calculatorRiskData,
              microalbuminuria: e.target.value,
            })
          }
          label="Microalbuminúria (mg/g)"
          name="microalbuminuria"
        ></InputText>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        {!isPublic && (
          <SecondaryButton
            type="submit"
            onClick={() => {
              setCalculatorRiskData({
                sexo: "",
                dataNascimento: "",
                tabagismo: false,
                dcvPrematuraParentes: false,
                doencaAteroscleroticaSignificativaOuObstrucao50: false,
                doencaAteroscleroticaSubclinica: false,
                aneurismaAortaAbdominal: false,
                insuficienciaCardiaca: false,
                diabetesMelitus: false,
                diabetesMelitusTipo: "",
                diabetesMelitusDataDiagnostico: "",
                medicacaoHipertensao: false,
                pressaoArterialSistolica: "",
                pressaoArterialDiastolica: "",
                altura: "",
                peso: "",
                colesterolTotal: "",
                ldl: "",
                hdl: "",
                triglicerideos: "",
                glicemia: "",
                hba1c: "",
                creatinina: "",
                microalbuminuria: "",
              });
              setStepper(STEP_CLOSE_MODAL);
            }}
            containerstyle="footerButton"
            text="Fechar"
          >
            <FaTimes />
          </SecondaryButton>
        )}

        <PrimaryButton
          onClick={handleCalculate}
          name="btnCalcular"
          containerstyle="footerButton"
          text="Calcular"
        >
          <FaCheck />
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CalculatorRisk;
