export const calcularIdade = (dataNascimento) => {
  const partesData = dataNascimento.split("/");
  const dia = parseInt(partesData[0], 10);
  const mes = parseInt(partesData[1], 10) - 1;
  const ano = parseInt(partesData[2], 10);

  const dataNascimentoObj = new Date(ano, mes, dia);
  const dataAtual = new Date();

  let diferencaMilissegundos = dataAtual - dataNascimentoObj;

  const idade = Math.floor(
    diferencaMilissegundos / (1000 * 60 * 60 * 24 * 365),
  );

  return idade;
};

export const printaSexo = (sexo) =>
  String(sexo) === "M" ? "Masculino" : "Feminino";

export const printaIdade = (idade) => `${idade} anos`;

export const printaScoreFramingham = (
  scoreFramingham,
  doencaAteroscleroticaSignificativaOuObstrucao50,
) =>
  scoreFramingham == -1 || doencaAteroscleroticaSignificativaOuObstrucao50
    ? "Não calculado"
    : scoreFramingham + " %";

export const printaClassificacaoIMC = (classificacaoIMC) =>
  ({
    0: "Baixo peso",
    1: "Peso normal",
    2: "Sobrepeso",
    3: "Obesidade grau I (Obesidade leve)",
    4: "Obesidade grau II (Obesidade moderada)",
    5: "Obesidade grau III (Obesidade mórbida)",
  })[classificacaoIMC];

export const printaClassificacaoPressaoArterial = (
  classificacaoPressaoArterial,
) =>
  ({
    0: "Ótima",
    1: "Normal",
    2: "Pré-hipertenso",
    3: "HA estágio 1",
    4: "HA estágio 2",
    5: "HA estágio 3",
    6: "HA sistólica isolada",
    7: "HA diastólica isolada",
  })[classificacaoPressaoArterial];

export const printaPrognosticoDRC = (riscoRenal) =>
  ({
    "-1": "Não calculado",
    0: "Baixo Risco",
    1: "Risco Moderado",
    2: "Alto Risco",
    3: "Risco Muito alto",
  })[riscoRenal.toString()];

export const printaRiscoCardioVascular = (riscoCardioVascular) =>
  ({
    1: "Sem Risco",
    2: "Baixo Risco",
    3: "Risco Moderado",
    4: "Alto Risco",
    5: "Risco Muito alto",
  })[riscoCardioVascular.toString()];

export const monthsToReturnByCardioVascular = (riscoCardioVascular) =>
  ({
    1: 24,
    2: 12,
    3: 6,
    4: 3,
    5: 2,
  })[Number(riscoCardioVascular)];

export const calcColorRiscoCardioVascular = (value) =>
  ({
    1: "#0000FF",
    2: "#00FF00",
    3: "#FFF000",
    4: "#FF0000",
    5: "#980000",
  })[value.toString()] || "gray";

export const calcColorPrognosticoDRC = (value) =>
  ({
    "-1": "gray",
    0: "#00FF00",
    1: "#FFF000",
    2: "#FF0000",
    3: "#980000",
  })[value.toString()] || "gray";

export const calculateFutureDate = (months) => {
  const hoje = new Date();
  hoje.setMonth(hoje.getMonth() + Number(months));
  let dia = ("0" + hoje.getDate()).slice(-2);
  let mes = ("0" + hoje.getMonth()).slice(-2);
  let data = dia + "/" + mes + "/" + hoje.getFullYear();

  return data;
};
