import React, { useState } from 'react';
import './style.css'
import ReactInputMask from 'react-input-mask';

const InputTextDate = ({ name, label, containerstyle, type, status, statusMessage, autocomplete, onDeleteAutocomplete, onSelectAutocomplete, onDateChange, showError, placeHolderValue, mandatory, ...rest}) => {

    const handleChange = (e) => {
        const { value } = e.target;
        onDateChange(value)
    }
    
    return (    
        <div className={"inputTextContainer " + containerstyle}>
            <div>
                <label htmlFor={ name }>{ label }{mandatory && <span style={{color: 'red'}}> *</span>}</label>
            </div>
            <div>
                <ReactInputMask type={ type || "text"} id={ name } onChange={handleChange} placeholder={ placeHolderValue || "dd/MM/yyyy"} {...rest } />
                {(showError) && <span className="error">Insira a data no formato válido: {placeHolderValue || "dd/MM/yyyy"} </span>}
            </div>
        </div>
    )
}

export default InputTextDate