import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { setToken } from '../../services/utils/token'
import { validarTokenSmartLink  } from '../../services/usuarios'
import { registrarPushToken } from '../../services/pushNotification'
function SmartLink() {
    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    const history = useHistory()
    
    useEffect(() => {
        validarTokenSmartLink(query.get('token'))
            .then((result) => {
                registrarPushToken(result.pessoa)
                setToken(result.token, result.pessoa)
                history.push('/clinicas')
            })
    }, [])
    return (
        <div></div>
    )
}

export default SmartLink