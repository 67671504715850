import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Medicos from "./pages/medicos";
import Recepcionistas from "./pages/recepcionistas";
import Pacientes from "./pages/pacientes";
import Menu from "./pages/menu";
import Login from "./pages/login";
import Clinicas from "./pages/clinicas";
import Mensagens from "./pages/mensagens";
import Agendamentos from "./pages/agendamentos";
import TemplateNotificacao from "./pages/templateNotificacao";
import Notificacoes from "./pages/notificacoes";
import Perfil from "./pages/perfil";
import Ativacao from "./pages/ativacao";
import Prontuario from "./pages/prontuario";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SmartLink from "./pages/smartlink";
import RecuperaSenha from "./pages/recuperarSenha";
import Configuracao from "./pages/configuracoes";
import ProntuarioPaciente from "./pages/prontuarioPaciente";
import AgendaPaciente from "./pages/agendaPaciente";

import Adm from "./pages/administrador";
import LoginAdm from "./pages/administrador/login";
import ClinicasAdm from "./pages/administrador/clinicas";
import UsuariosAdm from "./pages/administrador/usuarios";

import * as serviceWorker from "./serviceWorker";
import PacienteRecordProvider from "./context/patientRecordContext";
import Calculator from "./pages/calculator";
import CreateAccount from "./pages/createAccount";
import Administradores from "./pages/administradores";

ReactDOM.render(
  <BrowserRouter>
    <PacienteRecordProvider>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/menu" component={Menu} />
        <Route path="/medicos" component={Medicos} />
        <Route path="/recepcao" component={Recepcionistas} />
        <Route path="/administrador" component={Administradores} />
        <Route path="/paciente" component={Pacientes} />
        <Route path="/clinicas" component={Clinicas} />
        <Route path="/mensagens" component={Mensagens} />
        <Route path="/template" component={TemplateNotificacao} />
        <Route path="/notificacoes" component={Notificacoes} />
        <Route path="/agendamentos" component={Agendamentos} />
        <Route path="/smartlink" component={SmartLink} />
        <Route path="/perfil" component={Perfil} exact />
        <Route path="/ativacao" component={Ativacao} />
        <Route path="/forgot-password" component={RecuperaSenha} />
        <Route path="/prontuario" component={Prontuario} />
        <Route path="/configuracao" component={Configuracao} />
        <Route path="/calculadorarisco" component={Calculator} />
        <Route path="/adm" component={Adm} exact />
        <Route path="/adm/login" component={LoginAdm} exact />
        <Route path="/adm/clinicas" component={ClinicasAdm} exact />
        <Route path="/adm/usuarios" component={UsuariosAdm} exact />
        <Route path="/perfil/prontuario" component={ProntuarioPaciente} exact />
        <Route path="/agenda" component={AgendaPaciente} exact />
        <Route
          path="/create-account/:typeDoctor?/:clinica?/:typeAdm?/:email?"
          component={CreateAccount}
          exact
        />
      </Switch>
    </PacienteRecordProvider>
  </BrowserRouter>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
