import api from '../api'
import { getToken } from '../utils/token'
import { logout } from '../login'
import { getClinicaAtiva } from '../clinicas'

async function listarNotificacoes() {
    try {
        const response = await api.get('notificacao',{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data         
    } catch (error) {    
        if (error.response.status === 401) logout() 
        return
    }

}

async function responderNotificacao(template) {
    try {
        const response = await api.put('notificacao', template,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }

}

async function buscarNotificacao(id) {
    try {
        const response = await api.get('notificacao/' + id,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function cadastrarNotificacaoPesoa(notificacao) {
    try {
        const response = await api.post('prontuario/notificacao', notificacao,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}


async function alterarStatusNotificacaoPesoa(id) {
    try {
        const response = await api.put(`prontuario/notificacao/${id}`, {},{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function excluirNotificacaoPesoa(id) {
    try {
        const response = await api.delete(`prontuario/notificacao/${id}`,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function listarNotificacaoPesoa(id) {
    try {
        const response = await api.get(`prontuario/notificacao/${id}`,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}

async function listarRespostasPessoa(id) {
    
    try {
        const response = await api.get(`prontuario/notificacao/resposta/${id}`,{  
            headers: {
                'x-access-token': getToken(),
                'app-clinica': getClinicaAtiva()
            } 
        }) 
        return response.data       
    } catch (error) {       
        if (error.response.status === 401) logout()  
    }
}


export { listarNotificacoes, responderNotificacao, buscarNotificacao,
         cadastrarNotificacaoPesoa, alterarStatusNotificacaoPesoa,
         excluirNotificacaoPesoa, listarNotificacaoPesoa, listarRespostasPessoa }