import React, { useState, useEffect, useContext } from 'react'
import FormPaciente from '../../pacientes/formPacientes'
import FormMedico from '../../medicos/formMedicos'
import FormRecepcao from '../../recepcionistas/formRecepcionistas'
import { buscarDadosPerfil } from '../../../services/usuarios'

import './style.css'

function FormPerfil() {
    const [perfilUsuario, setPerfilUsuario] = useState('')
    const [context, setContext] = useState({}) 
   
    useEffect(() => {
        buscarDadosPerfil()
            .then((result) => {               

                switch (result.perfil) {
                    case 'MEDICO':
                        setContext(result)
                        setPerfilUsuario('MEDICO')
                        break;
                    case 'PACIENTE':    
                        setContext(result)                    
                        setPerfilUsuario('PACIENTE')   
                        break;
                    case 'RECEPCAO':
                        setContext(result)
                        setPerfilUsuario('RECEPCAO')
                        break;                
                }
            })
    }, [])


    return (
        <div className='formContainer perfilContainer'>
            { perfilUsuario == 'PACIENTE' ? <FormPaciente value={context} classForm='formTelaPerfil' perfil={ true }/>  : ''}
            { perfilUsuario == 'MEDICO' ? <FormMedico value={context} classForm='formPerfilMedico' perfil={ true }/>  : ''}
            { perfilUsuario == 'RECEPCAO' ? <FormRecepcao value={context} classForm='formTelaPerfil' perfil={ true }/>  : ''}
            
        
        
        </div>
    )
}

export default FormPerfil