import React from 'react'

const NotificacaoContext = React.createContext({ 
        estado: '',        
        precisaAtualizar: false,
        atualizarGrade: () => {},
        nomeDestinatario: '',
        atualizaNomeDestinatario: () => {},
        voltarTab: () => {},
        destinatario: 0,
        setDestinatario: () => {},
        data: [], 
        setData: () => {} })

export const NotificacaoProvider = NotificacaoContext.Provider

export { NotificacaoContext }
